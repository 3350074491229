import { useState } from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import GnzButton from '../../shared/gnz-button/GnzButton';

// import './CancelFeedbackForm.css';

interface ICancelFeedbackForm {
  confirmSubmit: any,
  cancelSubmit: any,
}

function CancelFeedbackForm(props: ICancelFeedbackForm) {
  const [reason, setReason] = useState('');

  const confirmSubmit = () => {
    if (reason.length) {
      props.confirmSubmit(reason);
    }
  }

  return (
    <div className="CancelFeedbackForm">
      <DialogTitle id="responsive-dialog-title">
        Annulation de la feedback
      </DialogTitle>
      <DialogContent>
        <div className="CancelFeedbackFormLabel">Raison de l’annulation de la feedback::</div>
        <input type="text" placeholder="Raison" className="AppFormInput" value={reason} onChange={(e) => setReason(e.target.value)} spellCheck={false}></input>
      </DialogContent>
      <DialogActions>
        <GnzButton label="Confirmer" onClick={confirmSubmit} backgroundColor="#dc3545" />
        <GnzButton label="Annuler" onClick={props.cancelSubmit} />
      </DialogActions>
    </div>
  );
}

export default CancelFeedbackForm;
