import { createSlice } from '@reduxjs/toolkit';

export interface UserState {
  userName: string,
  userLoggedIn: boolean;
}

const initialState: UserState = {
  userName: localStorage.getItem('username') ?? '',
  userLoggedIn: !!localStorage.getItem('jwt')
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserName: (state, data): void => {
      state.userName = data.payload;
    },
    setUserLoggedIn: (state, data): void => {
      state.userLoggedIn = data.payload;
    },
  }
});

export const { setUserName, setUserLoggedIn } = userSlice.actions;

export default userSlice.reducer;