import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Drawer from '@mui/material/Drawer';
import './ReservationRestau.css';
import { useState } from 'react';
import AddReservation from './AddReservation';
import Calendar from '../calendar/calendar';

function ReservationRestau() {
    const [updateType, setUpdateType] = useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setUpdateType(event.target.value);
    };

    const [drawerState, setDrawerState] = useState(false);
    const toggleDrawer = (open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
    
        setDrawerState(open);
    };

    const [showCalendarModal, setCalendarModal] = useState(false);
    const openCalendarModal = () => { setCalendarModal(true); };
    const closeCalendarModal = () => { setCalendarModal(false); };

    return (
        <div className='service-container'>
            <h1>Restaurant Hotel {'>'} Restaurant 'X'</h1>
            <div className="service-filter">
                <div className="service-filter-left">
                    <FormControl sx={{ m: 1, minWidth: 120, width: 220, border: "none" }} size="small">
                        <InputLabel id="demo-select-small-label">Restaurant</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={updateType}
                            label="Restaurant"
                            onChange={handleChange}
                            style={{ backgroundColor: "#fff", border: "none" }}
                        >
                            <MenuItem value={"horaire"}>Restau X</MenuItem>
                            <MenuItem value={"date"}>Restau Y</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 120, width: 220 }} size="small">
                        <InputLabel id="demo-select-small-label">Service</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={updateType}
                            label="Service"
                            onChange={handleChange}
                            style={{ backgroundColor: "#fff" }}
                        >
                            <MenuItem value={"horaire"}>Non-Stop</MenuItem>
                            <MenuItem value={"date"}>Déjeuner</MenuItem>
                            <MenuItem value={"date"}>Dîner</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className="service-filter-right">
                    <button className='service-filtre-btn'>
                        Filtres
                    </button>
                    <FormControl sx={{ m: 1, minWidth: 120, width: 220 }} size="small">
                        <InputLabel id="demo-select-small-label">Trier par</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={updateType}
                            label="Trier par"
                            onChange={handleChange}
                            style={{ backgroundColor: "#fff" }}
                        >
                            <MenuItem value={"horaire"}>Heure-&gt; plus recentes à la plus anciennes</MenuItem>
                            <MenuItem value={"date"}>Heure-&gt; plus anciennes à la plus recentes</MenuItem>
                            <MenuItem value={"date"}>Nombre de table-&gt; plus petits au plus grands</MenuItem>
                            <MenuItem value={"date"}>Nombre de table-&gt; plus grandes au plus petits</MenuItem>
                            <MenuItem value={"date"}>Nom-&gt; ordre alphebatique a-z</MenuItem>
                            <MenuItem value={"date"}>Nom-&gt; ordre alphebatique z-a</MenuItem>
                        </Select>
                    </FormControl>
                    <button className='service-reservation-btn' onClick={toggleDrawer(true)}>
                        Nouvelle réservation
                    </button>
                    <Drawer
                        anchor="right"
                        open={drawerState}
                        onClose={toggleDrawer(false)}
                        sx={{ width: 800 }}
                    >
                        <AddReservation />
                    </Drawer>
                </div>
            </div>

            <div className="service-body">
                <div className="service-body-header">
                    <h2>
                        Déjeuner - 1er service - 08h00/10h00
                    </h2>

                    <ErrorOutlineIcon style={{ cursor: "pointer" }} />
                </div>

                <div className="service-body-table">
                    <div className="service-body-table-header">
                        <h3>Heure</h3>
                        <h3>Nom Client</h3>
                        <h3>Table</h3>
                        <h3>Statut</h3>
                    </div>

                    <div className='service-body-table-row'>
                        <h3> </h3>
                        <h3> </h3>
                        <h3>1</h3>
                        <KeyboardArrowDownIcon style={{ color: "#1D62AF" }} />
                    </div>
                    <div className='service-body-table-row'>
                        <h3> </h3>
                        <h3> </h3>
                        <h3>2</h3>
                        <KeyboardArrowUpIcon style={{ color: "#1D62AF" }} />
                    </div>
                    <div className='service-body-table-row-details'>
                        <h3>Client</h3>
                        <h3>Chambre</h3>
                        <h3>Personnes</h3>
                        <h3>Occasion spécial</h3>
                        <h3>Allergies(s)</h3>

                        <div>
                            <button>
                                Modifier
                            </button>
                            <button style={{ backgroundColor: "#EAF4FF" }}>
                                Annuler la réservation
                            </button>
                        </div>
                    </div>
                    <div className='service-body-table-row'>
                        <h3> </h3>
                        <h3> </h3>
                        <h3>3</h3>
                        <KeyboardArrowDownIcon style={{ color: "#1D62AF" }} />
                    </div>
                    <div className='service-body-table-row'>
                        <h3> </h3>
                        <h3> </h3>
                        <h3>4</h3>
                        <KeyboardArrowDownIcon style={{ color: "#1D62AF" }} />
                    </div>
                </div>
            </div>

            <div className='service-body-footer'>
                <button style={{ backgroundColor: "#1D62AF", color: "#fff" }}>
                    Plan de table
                </button>

                <button onClick={openCalendarModal} style={{ backgroundColor: "#A0E295", color: "rgba(0, 0, 0, 0.60)" }}>
                    Visualisation globale
                </button>

                {
                    showCalendarModal &&
                    <Calendar onClose={closeCalendarModal} />
                }
            </div>
        </div>
    );
}

export default ReservationRestau