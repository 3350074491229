import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Select,
  FormControl,
  Stack,
  Paper,
  Typography,
  TextField,
  Container,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Box,
} from "@mui/material";
import {
  format,
  eachMonthOfInterval,
  eachWeekOfInterval,
  getYear,
  eachDayOfInterval,
} from "date-fns";
import { fr } from "date-fns/locale";
import Filter from "../liste/filterEmployee";
import NewPlanning from "./new_planning";
const monthNames = [
  "janvier",
  "février",
  "mars",
  "avril",
  "mai",
  "juin",
  "juillet",
  "août",
  "septembre",
  "octobre",
  "novembre",
  "décembre",
];
const apiUrl =
  window.location.hostname === "localhost"
    ? "https://localhost:7142/api"
    : "/api";
const DateDisplay = ({ date }: any) => {
  const formattedDate = format(date, "dd/MM/Y", { locale: fr });
  const dayOfWeek = format(date, "EEEE", { locale: fr });
  return (
    <Typography width={183} mx={1}>
      {dayOfWeek} {formattedDate}
    </Typography>
  );
};
const DateSelector = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const employeesPerPage = 10;
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPage));
  };
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedWeek, setSelectedWeek] = useState(Number);
  const [index, setIndex] = useState<number | null>(null);
  const [selectedYear, setSelectedYear] = useState(2024);
  const [weeks, setWeeks] = useState<Date[]>([]);
  const [selectedWeekDays, setSelectedWeekDays] = useState<Date[]>([]);
  const [employees, setEmployees] = React.useState([]);
  const [selectedDpts, setSelectedDpts] = React.useState<String[]>([]);
  const [responsabilité, setResponsabilite] = React.useState<String[]>([]);
  const [fromdate, setFromdate] = React.useState(null);
  const [todate, setTodate] = React.useState(null);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (employeeId: any) => {
    setSelectedEmployeeId(employeeId);
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen(false);
  };
  const [selectedEmployeeId, setSelectedEmployeeId] = React.useState(null);

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleButtonClick = () => {
    setOpenDialog(!openDialog);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  React.useEffect(() => {
    const fetchEmployees = async () => {
      try {
        let url = `${apiUrl}/Employee`;

        const filters = [];
        if (selectedDpts.length > 0) {
          const selectedDptFilter = selectedDpts.map(
            (dpt) => `dpt=${encodeURIComponent(String(dpt))}`
          );
          filters.push(...selectedDptFilter);
        }
        if (responsabilité.length > 0) {
          const responsabilitéFilter = responsabilité.map(
            (respo) => `respo=${encodeURIComponent(String(respo))}`
          );
          filters.push(...responsabilitéFilter);
        }

        if (fromdate) {
          filters.push(`fromdate=${encodeURIComponent(fromdate)}`);
        }
        if (todate) {
          filters.push(`todate=${encodeURIComponent(todate)}`);
        }

        if (filters.length > 0) {
          url += `?${filters.join("&")}`;
        }

        const response = await fetch(url);
        const data = await response.json();

        setEmployees(data);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, [selectedDpts, responsabilité, fromdate, todate]);
  const months = eachMonthOfInterval({
    start: new Date(selectedYear, 0, 1),
    end: new Date(selectedYear, 11, 31),
  });
  useEffect(() => {
    if (selectedMonth !== "") {
      const monthIndex = monthNames.indexOf(selectedMonth);
      const lastDayOfMonth = new Date(
        selectedYear,
        monthIndex + 1,
        0
      ).getDate();

      const newWeeks = eachWeekOfInterval(
        {
          start: new Date(selectedYear, monthIndex, 1),
          end: new Date(selectedYear, monthIndex, lastDayOfMonth),
        },
        { locale: fr }
      );
      const startingDates = newWeeks.map((weekStart) =>
        format(weekStart, "yyyy-MM-dd")
      );

      if (index !== null) {
        const startingDate =
          startingDates.length > 0 ? startingDates[index] : null;
        if (startingDate) {
          const startDateObject = new Date(startingDate);
          const endOfWeek = new Date(startDateObject);
          endOfWeek.setDate(endOfWeek.getDate() + 6);
          const selectedWeekDays = eachDayOfInterval({
            start: startDateObject,
            end: endOfWeek,
          });
          setSelectedWeekDays(selectedWeekDays);
        }
      }
      setWeeks(newWeeks);
    }
  }, [selectedMonth, selectedYear, selectedWeek, index]);

  const years = [
    getYear(new Date()),
    getYear(new Date()) + 1,
    getYear(new Date()) + 2,
  ];

  const handleMonthChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedMonth(event.target.value);
  };

  const handleWeekChange = (event: any) => {
    const selectedValue = event.target.value as any;
    const selectedIndex = weeks.findIndex(
      (week) => format(week, "w", { locale: fr }) === selectedValue
    );

    setSelectedWeek(selectedValue);
    setIndex(selectedIndex);
  };

  const handleYearChange = (event: {
    target: { value: React.SetStateAction<any> };
  }) => {
    setSelectedYear(event.target.value);
  };
  const countResponsabilite = (date: Date) => {
    const formattedDate = convertDateToISOString(date, 1);

    const employeesOnSelectedDate = employees.filter((employee: any) => {
      return employee.plannings.some(
        (planning: any) => planning.date === formattedDate
      );
    });

    const cadreCount = employeesOnSelectedDate.reduce(
      (count, employee: any) => {
        return employee.responsabilite === "cadre" ? count + 1 : count;
      },
      0
    );

    const nonCadreCount = employeesOnSelectedDate.reduce(
      (count, employee: any) => {
        return employee.responsabilite !== "cadre" ? count + 1 : count;
      },
      0
    );
    const offCount = employeesOnSelectedDate.reduce((count, employee: any) => {
      return employee.plannings.some(
        (planning: any) =>
          planning.date === formattedDate && planning.status === "OFF"
      )
        ? count + 1
        : count;
    }, 0);

    return { cadreCount, nonCadreCount, offCount };
  };
  const convertDateToISOString = (date: Date, daysToAdd: number = 0) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + daysToAdd);

    const isoString = newDate.toISOString();
    return isoString.slice(0, 10) + "T00:00:00";
  };
  const startIndex = (currentPage - 1) * employeesPerPage;
  const endIndex = startIndex + employeesPerPage;
  const paginatedEmployees = employees.slice(startIndex, endIndex);
  const totalPage = Math.ceil(employees.length / employeesPerPage);

  return (
    <Container maxWidth="xl" sx={{ padding: 5 }}>
      <Paper sx={{ padding: 2, width: "1450px" }}>
        <Grid container justifyContent="space-between" mb={6}>
          <Grid item>
            <FormControl>
              <Select
                value={selectedMonth}
                onChange={handleMonthChange}
                sx={{ backgroundColor: "#F4F4F4", minWidth: 150 }}
              >
                {months.map((month, index) => (
                  <MenuItem
                    key={index}
                    value={format(month, "MMMM", { locale: fr })}
                  >
                    {format(month, "MMMM", { locale: fr })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <Select
                sx={{ backgroundColor: "#F4F4F4", minWidth: 150 }}
                value={selectedWeek}
                onChange={handleWeekChange}
              >
                {weeks.map((week, index) => (
                  <MenuItem
                    key={index}
                    value={format(week, "w", { locale: fr })}
                  >
                    {format(week, "w", { locale: fr }) + " Semaine"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <Select
                value={selectedYear}
                onChange={handleYearChange}
                sx={{ backgroundColor: "#F4F4F4", minWidth: 150 }}
              >
                {years.map((year, index) => (
                  <MenuItem key={index} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item flexDirection="row" display="flex" alignItems="center">
            <Typography mx={1}>Date</Typography>
            {selectedWeekDays.length > 0 ? (
              <>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={format(selectedWeekDays[0], "dd/MM/yyyy")}
                />
                <TextField
                  variant="outlined"
                  fullWidth
                  value={format(
                    selectedWeekDays[selectedWeekDays.length - 1],
                    "dd/MM/yyyy"
                  )}
                />
              </>
            ) : (
              <>
                <TextField variant="outlined" fullWidth value="" />
                <TextField variant="outlined" fullWidth value="" />
              </>
            )}
          </Grid>
        </Grid>
        {/* <Button onClick={handleButtonClick}>
          <Typography
            sx={{
              textTransform: "none",
              color: "rgba(0,0,0,0.4)",
              border: "1px solid rgba(0,0,0,0.4)",
            }}
            padding={1}
            borderRadius={1}
          >
            Filtres - Trie
          </Typography>
        </Button>
        <Dialog open={openDialog} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogContent>
            <Filter
              setSelectedValue={setSelectedValue}
              selectedValue={selectedValue}
              selectedDpts={selectedDpts}
              setSelectedDpts={setSelectedDpts}
              responsabilité={responsabilité}
              setResponsabilite={setResponsabilite}
              fromdate={fromdate}
              setFromdate={setFromdate}
              todate={todate}
              setTodate={setTodate}
            />
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center", marginBottom: 2 }}>
            <Button onClick={handleClose} color="primary">
              <Typography
                sx={{
                  backgroundColor: "#0B4B92",
                  color: "white",
                  textTransform: "none",
                }}
                paddingY={1}
                paddingX={2}
              >
                Recherche
              </Typography>
            </Button>
          </DialogActions>
        </Dialog> */}
        <Stack
          direction="row"
          sx={{ marginLeft: "148px", backgroundColor: "#cccccc" }}
          width={1281}
        >
          {selectedWeekDays.map((date, index) => (
            <DateDisplay key={index} date={date} />
          ))}
        </Stack>
        <Box minHeight={650}>
          <Stack direction="row" my={1} alignItems="center">
            <Stack>
              <Typography py={2} px={3} width={100}>
                Total employés
              </Typography>
            </Stack>
            <Stack display="flex" flexDirection="row">
              {selectedWeekDays.map((date) => {
                const employeesWithCantine = employees.filter(
                  (employee: any) => {
                    return employee.cantines.some((cantine: any) => {
                      const cantineDate = new Date(cantine.date);

                      return (
                        format(cantineDate, "dd/MM/yyyy") ===
                        format(date, "dd/MM/yyyy")
                      );
                    });
                  }
                );

                return (
                  <Typography
                    py={1}
                    px={3}
                    width={135}
                    height={26}
                    textAlign="center"
                  >
                    {employeesWithCantine.length}
                  </Typography>
                );
              })}
            </Stack>
          </Stack>
          <Stack direction="row" my={1} alignItems="center">
            <Stack>
              <Typography py={2} px={3} width={100}>
                Repas midi
              </Typography>
            </Stack>
            <Stack display="flex" flexDirection="row">
              {selectedWeekDays.map((date) => {
                const employeesWithCantine = employees.filter(
                  (employee: any) => {
                    return employee.cantines.some((cantine: any) => {
                      const cantineDate = new Date(cantine.date);
                      const startTime_Un = cantine.startTime_Un; // Assuming startTime_Un is in "HH:mm" format
                      const endTime_Un = cantine.endTime_Un; // Assuming endTime_Un is in "HH:mm" format
                      const startTime_Deux = cantine.startTime_Deux; // Assuming startTime_Deux is in "HH:mm" format
                      const endTime_Deux = cantine.endTime_Deux; // Assuming endTime_Deux is in "HH:mm" format

                      // Parse start and end times to compare with 12:00
                      const start_Un = new Date(`2000-01-01T${startTime_Un}`);
                      const end_Un = new Date(`2000-01-01T${endTime_Un}`);
                      const start_Deux = new Date(
                        `2000-01-01T${startTime_Deux}`
                      );
                      const end_Deux = new Date(`2000-01-01T${endTime_Deux}`);
                      const twelvePMStart = new Date(`2000-01-01T12:00`);
                      const twelvePMEnd = new Date(`2000-01-01T13:00`);
                      const sameDate =
                        cantineDate.toISOString().split("T")[0] ===
                        date.toISOString().split("T")[0];
                      const union_Un =
                        sameDate &&
                        ((start_Un <= twelvePMStart && end_Un >= twelvePMEnd) ||
                          (twelvePMStart <= end_Un && twelvePMEnd >= start_Un));
                      const union_Deux =
                        sameDate &&
                        ((start_Deux <= twelvePMStart &&
                          end_Deux >= twelvePMEnd) ||
                          (twelvePMStart <= end_Deux &&
                            twelvePMEnd >= start_Deux));

                      return union_Un || union_Deux;
                    });
                  }
                );

                // Check if any employee has dietary restrictions or allergies
                const hasAllergiesOrDietaryRestrictions =
                  employeesWithCantine.some((employee:any) => {
                    return (
                      employee.gluten ||
                      employee.oeuf ||
                      employee.soja ||
                      employee.moutarde ||
                      employee.mollusques ||
                      employee.champignons ||
                      employee.laitier ||
                      employee.poisson ||
                      employee.coques ||
                      employee.sesames ||
                      employee.ail ||
                      employee.tomates ||
                      employee.crustaces ||
                      employee.arachides ||
                      employee.celeri ||
                      employee.sulfites ||
                      employee.lupin ||
                      employee.halal ||
                      employee.pescatarien ||
                      employee.enceinte ||
                      employee.vegetarien ||
                      employee.kosher ||
                      employee.porc ||
                      employee.seafood ||
                      employee.diabetique ||
                      employee.paleo ||
                      employee.sucre ||
                      employee.vegan
                    );
                  });
                 const getAllergiesList = (employee: any) => {
                   const allergies = [];
                   if (employee.gluten) allergies.push("Gluten");
                   if (employee.oeuf) allergies.push("Oeuf");
                   if (employee.soja) allergies.push("Soja");
                   if (employee.moutarde) allergies.push("Moutarde");
                   if (employee.mollusques) allergies.push("Mollusques");
                   if (employee.champignons) allergies.push("Champignons");
                   if (employee.laitier) allergies.push("Laitier");
                   if (employee.poisson) allergies.push("Poisson");
                   if (employee.coques) allergies.push("Coques");
                   if (employee.sesames) allergies.push("Sésames");
                   if (employee.ail) allergies.push("Ail");
                   if (employee.tomates) allergies.push("Tomates");
                   if (employee.crustaces) allergies.push("Crustacés");
                   if (employee.arachides) allergies.push("Arachides");
                   if (employee.celeri) allergies.push("Céleri");
                   if (employee.sulfites) allergies.push("Sulfites");
                   if (employee.lupin) allergies.push("Lupin");
                   if (employee.halal) allergies.push("Halal");
                   if (employee.pescatarien) allergies.push("Pescatarien");
                   if (employee.enceinte) allergies.push("Enceinte");
                   if (employee.vegetarien) allergies.push("Végétarien");
                   if (employee.kosher) allergies.push("Kosher");
                   if (employee.porc) allergies.push("Porc");
                   if (employee.seafood) allergies.push("Fruits de mer");
                   if (employee.diabetique) allergies.push("Diabétique");
                   if (employee.paleo) allergies.push("Paléo");
                   if (employee.sucre) allergies.push("Sucre");
                   if (employee.vegan) allergies.push("Végan");

                   return allergies.join(", ");
                 };


            
                return (
                  <Typography
                    py={1}
                    px={3}
                    width={135}
                    height={26}
                    textAlign="center"
                  >
                      {employeesWithCantine.length}
                    {hasAllergiesOrDietaryRestrictions && (
                      <span style={{marginLeft:4}}>
                        <svg
                          width="20"
                          height="17"
                          viewBox="0 0 20 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.4999 17.0002H17.4999C19.0999 17.0002 19.9997 15.3302 19.2997 14.0002L11.7001 0.990176C11.0001 -0.339824 8.9997 -0.339824 8.2997 0.990176L0.700091 14.0002C9.10163e-05 15.3302 0.899896 17.0002 2.4999 17.0002ZM9.9999 10.0002C9.4999 10.0002 8.9999 9.55019 8.9999 9.00019V7.00019C8.9999 6.45019 9.4999 6.00019 9.9999 6.00019C10.5999 6.00019 10.9999 6.45019 10.9999 7.00019V9.00019C10.9999 9.55019 10.5999 10.0002 9.9999 10.0002ZM10.9999 14.0002H8.9999V12.0002H10.9999V14.0002Z"
                            fill="#D73535"
                          />
                        </svg>
                      </span>
                    )}
                    {/* {employeesWithCantine.map((employee: any) => (
                      <div key={employee.id}>
                        {employee.prenom} {employee.nom} - Allergies:{" "}
                        {getAllergiesList(employee)}
                      </div>
                    ))} */}
                  </Typography>
                );
              })}
            </Stack>
          </Stack>
          <Stack direction="row" my={1} alignItems="center">
            <Stack>
              <Typography py={2} px={3} width={100}>
                Repas soir
              </Typography>
            </Stack>
            <Stack display="flex" flexDirection="row">
              {selectedWeekDays.map((date) => {
                const employeesWithCantine = employees.filter(
                  (employee: any) => {
                    return employee.cantines.some((cantine: any) => {
                      const cantineDate = new Date(cantine.date);
                      const startTime_Un = cantine.startTime_Un; 
                      const endTime_Un = cantine.endTime_Un; 
                      const startTime_Deux = cantine.startTime_Deux; 
                      const endTime_Deux = cantine.endTime_Deux; 
                      const start_Un = new Date(`2000-01-01T${startTime_Un}`);
                      const end_Un = new Date(`2000-01-01T${endTime_Un}`);
                      const start_Deux = new Date(
                        `2000-01-01T${startTime_Deux}`
                      );
                      const end_Deux = new Date(`2000-01-01T${endTime_Deux}`);
                      const twelvePMStart = new Date(`2000-01-01T18:00`);
                      const twelvePMEnd = new Date(`2000-01-01T19:00`);
                      const sameDate =
                        cantineDate.toISOString().split("T")[0] ===
                        date.toISOString().split("T")[0];
                      const union_Un =
                        sameDate &&
                        ((start_Un <= twelvePMStart && end_Un >= twelvePMEnd) ||
                          (twelvePMStart <= end_Un && twelvePMEnd >= start_Un));
                      const union_Deux =
                        sameDate &&
                        ((start_Deux <= twelvePMStart &&
                          end_Deux >= twelvePMEnd) ||
                          (twelvePMStart <= end_Deux &&
                            twelvePMEnd >= start_Deux));

                      return union_Un || union_Deux;
                    });
                  }
                );

                // Check if any employee has dietary restrictions or allergies
                const hasAllergiesOrDietaryRestrictions =
                  employeesWithCantine.some((employee:any) => {
                    return (
                      employee.gluten ||
                      employee.oeuf ||
                      employee.soja ||
                      employee.moutarde ||
                      employee.mollusques ||
                      employee.champignons ||
                      employee.laitier ||
                      employee.poisson ||
                      employee.coques ||
                      employee.sesames ||
                      employee.ail ||
                      employee.tomates ||
                      employee.crustaces ||
                      employee.arachides ||
                      employee.celeri ||
                      employee.sulfites ||
                      employee.lupin ||
                      employee.halal ||
                      employee.pescatarien ||
                      employee.enceinte ||
                      employee.vegetarien ||
                      employee.kosher ||
                      employee.porc ||
                      employee.seafood ||
                      employee.diabetique ||
                      employee.paleo ||
                      employee.sucre ||
                      employee.vegan
                    );
                  });
                 const getAllergiesList = (employee: any) => {
                   const allergies = [];
                   if (employee.gluten) allergies.push("Gluten");
                   if (employee.oeuf) allergies.push("Oeuf");
                   if (employee.soja) allergies.push("Soja");
                   if (employee.moutarde) allergies.push("Moutarde");
                   if (employee.mollusques) allergies.push("Mollusques");
                   if (employee.champignons) allergies.push("Champignons");
                   if (employee.laitier) allergies.push("Laitier");
                   if (employee.poisson) allergies.push("Poisson");
                   if (employee.coques) allergies.push("Coques");
                   if (employee.sesames) allergies.push("Sésames");
                   if (employee.ail) allergies.push("Ail");
                   if (employee.tomates) allergies.push("Tomates");
                   if (employee.crustaces) allergies.push("Crustacés");
                   if (employee.arachides) allergies.push("Arachides");
                   if (employee.celeri) allergies.push("Céleri");
                   if (employee.sulfites) allergies.push("Sulfites");
                   if (employee.lupin) allergies.push("Lupin");
                   if (employee.halal) allergies.push("Halal");
                   if (employee.pescatarien) allergies.push("Pescatarien");
                   if (employee.enceinte) allergies.push("Enceinte");
                   if (employee.vegetarien) allergies.push("Végétarien");
                   if (employee.kosher) allergies.push("Kosher");
                   if (employee.porc) allergies.push("Porc");
                   if (employee.seafood) allergies.push("Fruits de mer");
                   if (employee.diabetique) allergies.push("Diabétique");
                   if (employee.paleo) allergies.push("Paléo");
                   if (employee.sucre) allergies.push("Sucre");
                   if (employee.vegan) allergies.push("Végan");

                   return allergies.join(", ");
                 };

                return (
                  <Typography
                    py={1}
                    px={3}
                    width={135}
                    height={26}
                    textAlign="center"
                  >
                      {employeesWithCantine.length}
                    {hasAllergiesOrDietaryRestrictions && (
                      <span style={{marginLeft:4}}>
                        <svg
                          width="20"
                          height="17"
                          viewBox="0 0 20 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.4999 17.0002H17.4999C19.0999 17.0002 19.9997 15.3302 19.2997 14.0002L11.7001 0.990176C11.0001 -0.339824 8.9997 -0.339824 8.2997 0.990176L0.700091 14.0002C9.10163e-05 15.3302 0.899896 17.0002 2.4999 17.0002ZM9.9999 10.0002C9.4999 10.0002 8.9999 9.55019 8.9999 9.00019V7.00019C8.9999 6.45019 9.4999 6.00019 9.9999 6.00019C10.5999 6.00019 10.9999 6.45019 10.9999 7.00019V9.00019C10.9999 9.55019 10.5999 10.0002 9.9999 10.0002ZM10.9999 14.0002H8.9999V12.0002H10.9999V14.0002Z"
                            fill="#D73535"
                          />
                        </svg>
                      </span>
                    )}
                    {/* {employeesWithCantine.map((employee: any) => (
                      <div key={employee.id}>
                        {employee.prenom} {employee.nom} - Allergies:{" "}
                        {getAllergiesList(employee)}
                      </div>
                    ))} */}
                  </Typography>
                );
              })}
            </Stack>
          </Stack>
          <Stack direction="row" my={1} alignItems="center">
            <Stack>
              <Typography py={2} px={3} width={100}>
                Repas nuit
              </Typography>
            </Stack>
            <Stack display="flex" flexDirection="row">
              {selectedWeekDays.map((date) => {
                const employeesWithCantine = employees.filter(
                  (employee: any) => {
                    return employee.cantines.some((cantine: any) => {
                      const cantineDate = new Date(cantine.date);
                      const startTime_Un = cantine.startTime_Un; 
                      const endTime_Un = cantine.endTime_Un; 
                      const startTime_Deux = cantine.startTime_Deux; 
                      const endTime_Deux = cantine.endTime_Deux; 
                      const start_Un = new Date(`2000-01-01T${startTime_Un}`);
                      const end_Un = new Date(`2000-01-01T${endTime_Un}`);
                      const start_Deux = new Date(
                        `2000-01-01T${startTime_Deux}`
                      );
                      const end_Deux = new Date(`2000-01-01T${endTime_Deux}`);
                      const twelvePMStart = new Date(`2000-01-01T22:00`);
                      const twelvePMEnd = new Date(`2000-01-01T23:59`);
                      const sameDate =
                        cantineDate.toISOString().split("T")[0] ===
                        date.toISOString().split("T")[0];
                      const union_Un =
                        sameDate &&
                        ((start_Un <= twelvePMStart && end_Un >= twelvePMEnd) ||
                          (twelvePMStart <= end_Un && twelvePMEnd >= start_Un));
                      const union_Deux =
                        sameDate &&
                        ((start_Deux <= twelvePMStart &&
                          end_Deux >= twelvePMEnd) ||
                          (twelvePMStart <= end_Deux &&
                            twelvePMEnd >= start_Deux));
                      return union_Un || union_Deux;
                    });
                  }
                );
                // Check if any employee has dietary restrictions or allergies
                const hasAllergiesOrDietaryRestrictions =
                  employeesWithCantine.some((employee:any) => {
                    return (
                      employee.gluten ||
                      employee.oeuf ||
                      employee.soja ||
                      employee.moutarde ||
                      employee.mollusques ||
                      employee.champignons ||
                      employee.laitier ||
                      employee.poisson ||
                      employee.coques ||
                      employee.sesames ||
                      employee.ail ||
                      employee.tomates ||
                      employee.crustaces ||
                      employee.arachides ||
                      employee.celeri ||
                      employee.sulfites ||
                      employee.lupin ||
                      employee.halal ||
                      employee.pescatarien ||
                      employee.enceinte ||
                      employee.vegetarien ||
                      employee.kosher ||
                      employee.porc ||
                      employee.seafood ||
                      employee.diabetique ||
                      employee.paleo ||
                      employee.sucre ||
                      employee.vegan
                    );
                  });
                 const getAllergiesList = (employee: any) => {
                   const allergies = [];
                   if (employee.gluten) allergies.push("Gluten");
                   if (employee.oeuf) allergies.push("Oeuf");
                   if (employee.soja) allergies.push("Soja");
                   if (employee.moutarde) allergies.push("Moutarde");
                   if (employee.mollusques) allergies.push("Mollusques");
                   if (employee.champignons) allergies.push("Champignons");
                   if (employee.laitier) allergies.push("Laitier");
                   if (employee.poisson) allergies.push("Poisson");
                   if (employee.coques) allergies.push("Coques");
                   if (employee.sesames) allergies.push("Sésames");
                   if (employee.ail) allergies.push("Ail");
                   if (employee.tomates) allergies.push("Tomates");
                   if (employee.crustaces) allergies.push("Crustacés");
                   if (employee.arachides) allergies.push("Arachides");
                   if (employee.celeri) allergies.push("Céleri");
                   if (employee.sulfites) allergies.push("Sulfites");
                   if (employee.lupin) allergies.push("Lupin");
                   if (employee.halal) allergies.push("Halal");
                   if (employee.pescatarien) allergies.push("Pescatarien");
                   if (employee.enceinte) allergies.push("Enceinte");
                   if (employee.vegetarien) allergies.push("Végétarien");
                   if (employee.kosher) allergies.push("Kosher");
                   if (employee.porc) allergies.push("Porc");
                   if (employee.seafood) allergies.push("Fruits de mer");
                   if (employee.diabetique) allergies.push("Diabétique");
                   if (employee.paleo) allergies.push("Paléo");
                   if (employee.sucre) allergies.push("Sucre");
                   if (employee.vegan) allergies.push("Végan");

                   return allergies.join(", ");
                 };

                return (
                  <Typography
                    py={1}
                    px={3}
                    width={135}
                    height={26}
                    textAlign="center"
                  >
                      {employeesWithCantine.length}
                    {hasAllergiesOrDietaryRestrictions && (
                      <span style={{marginLeft:4}}>
                        <svg
                          width="20"
                          height="17"
                          viewBox="0 0 20 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.4999 17.0002H17.4999C19.0999 17.0002 19.9997 15.3302 19.2997 14.0002L11.7001 0.990176C11.0001 -0.339824 8.9997 -0.339824 8.2997 0.990176L0.700091 14.0002C9.10163e-05 15.3302 0.899896 17.0002 2.4999 17.0002ZM9.9999 10.0002C9.4999 10.0002 8.9999 9.55019 8.9999 9.00019V7.00019C8.9999 6.45019 9.4999 6.00019 9.9999 6.00019C10.5999 6.00019 10.9999 6.45019 10.9999 7.00019V9.00019C10.9999 9.55019 10.5999 10.0002 9.9999 10.0002ZM10.9999 14.0002H8.9999V12.0002H10.9999V14.0002Z"
                            fill="#D73535"
                          />
                        </svg>
                      </span>
                    )}
                    {/* {employeesWithCantine.map((employee: any) => (
                      <div key={employee.id}>
                        {employee.prenom} {employee.nom} - Allergies:{" "}
                        {getAllergiesList(employee)}
                      </div>
                    ))} */}
                  </Typography>
                );
              })}
            </Stack>
          </Stack>
        </Box>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <div>
            <Button disabled={currentPage === 1} onClick={handlePrevPage}>
              Previous
            </Button>
            <Button
              disabled={currentPage === totalPage}
              onClick={handleNextPage}
            >
              Next
            </Button>
          </div>
          <Typography>{`Page ${currentPage} / ${totalPage}`}</Typography>
        </Stack>
      </Paper>
    </Container>
  );
};

export default DateSelector;
