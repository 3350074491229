import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Drawer, Accordion, Typography, Button } from '@mui/material';
import { Grid, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { useState, useEffect } from 'react';
import axios from 'axios';
import AddTransfer from './AddTransfer';
import UpdateTransfer from './UpdateTransfer';
import './Transfer.css';

const apiUrl = window.location.hostname === 'localhost' ? 'https://localhost:7142/api' : '/api';

const ListTransfer = () => {
    const [drawerState, setDrawerState] = useState(false);
    const [drawerStateUpdate, setDrawerStateUpdate] = useState<{ [key: number]: boolean }>({});
    const [currentTransferId, setCurrentTransferId] = useState<number | null>(null);
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/Transfer/AllTransfersDetails`);
            setData(response.data);
        } catch (error) {
            console.error('Error getting data:', error);
        }
    };

    const toggleDrawer = (open: boolean) => () => {
        setDrawerState(open);
    };

    const toggleDrawerUpdate = (id: number, open: boolean) => () => {
        setDrawerStateUpdate((prevState) => ({
            ...prevState,
            [id]: open
        }));
        if (open) {
            setCurrentTransferId(id);
        }
    };

    const handleCancelTransfer = (id: number) => {
        axios.put(`${apiUrl}/Transfer/DeleteTransfer/${id}`)
            .then(response => {
                console.log('Transfer deleted successfully');
                getData();  // Refresh data instead of reloading the page
            })
            .catch(error => {
                console.error('Error canceling transfer:', error);
            });
    };

    const goToProviderMenu = () => {
        navigate('/ListProvider'); // Replace with the correct path to your ListProvider component
    };

    return (
        <>
            <div className='service-container'>
                <div className="service-filter">
                    <div className="service-filter-left"></div>
                    <div className="service-filter-right">
                        <button className='service-reservation-btn' onClick={toggleDrawer(true)}>
                            Nouveau Transfert
                        </button>
                        <Drawer
                            anchor="right"
                            open={drawerState}
                            onClose={toggleDrawer(false)}
                        >
                            <AddTransfer onCloseDrawer={toggleDrawer(false)} />
                        </Drawer>
                    </div>
                </div>
                <br />
                <div className="service-body">
                    <div className="service-body-header">
                        <h2>Transfert</h2>
                    </div>
                    <div className="service-body-table">
                        <Grid container spacing={2} justifyContent="center">
                            <AccordionSummary sx={{ width: '100%' }} style={{ maxWidth: 1000 }}>
                                <TableCell sx={{ width: '25%', flexShrink: 0, fontWeight: 'bold' }}>
                                    Nom et Prénom
                                </TableCell>
                                <TableCell sx={{ width: '25%', flexShrink: 0, fontWeight: 'bold' }}>
                                    Chambre
                                </TableCell>
                                <TableCell sx={{ width: '25%', flexShrink: 0, fontWeight: 'bold' }}>
                                    Heure
                                </TableCell>
                                <TableCell sx={{ width: '25%', flexShrink: 0, fontWeight: 'bold' }}>
                                    Statut
                                </TableCell>
                            </AccordionSummary>

                            {data.map((prop: any, index: any) => (
                                <Grid item xs={12} key={prop.id} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Accordion sx={{ backgroundColor: index % 2 === 0 ? '#A0E2954D' : '#F5F5F5', width: '100%' }} style={{ maxWidth: 1000 }}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography sx={{ flex: 1, fontWeight: 'bold' }}>
                                                {prop.firstName} {prop.lastName}
                                            </Typography>
                                            <Typography sx={{ flex: 1, fontWeight: 'bold' }}>
                                                {prop.room}
                                            </Typography>
                                            <Typography sx={{ flex: 1, fontWeight: 'bold' }}>
                                                {prop.transferTime}
                                            </Typography>
                                            <Typography sx={{ flex: 1, fontWeight: 'bold' }}>
                                                {prop.statut}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ maxWidth: 1000 }}>
                                            <TableContainer>
                                                <Table sx={{ minWidth: 300 }}>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: 'bold' }}>Compagnie:</TableCell>
                                                            <TableCell>{prop.name}</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="left">Vers:</TableCell>
                                                            <TableCell>{prop.arrival}</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="left">Paiement:</TableCell>
                                                            <TableCell>{prop.paiement}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="left">Heure de fin:</TableCell>
                                                            <TableCell>{prop.endTime}</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="left">Véhicule:</TableCell>
                                                            <TableCell>{prop.car}</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="left">Préference transfert:</TableCell>
                                                            <TableCell>{prop.transfertNote}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="left">Depuis:</TableCell>
                                                            <TableCell>{prop.departure}</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="left">Prix:</TableCell>
                                                            <TableCell>{prop.price} €</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="left">Initiales:</TableCell>
                                                            <TableCell>-</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <small>
                                                <Button style={{ color: "black" }} size="small" startIcon={<EditIcon />} onClick={toggleDrawerUpdate(prop.id, true)}>
                                                    Modifier le transfert
                                                </Button>
                                                <Drawer
                                                    anchor="right"
                                                    open={!!drawerStateUpdate[prop.id]}
                                                    onClose={toggleDrawerUpdate(prop.id, false)}
                                                >
                                                    <UpdateTransfer transferId={currentTransferId} onCloseDrawerUpdate={toggleDrawerUpdate(prop.id, false)} />
                                                </Drawer>
                                                <Button style={{ color: "black" }} size="small" startIcon={<DeleteOutlineOutlinedIcon style={{ color: "red" }} />} onClick={() => handleCancelTransfer(prop.id)}>
                                                    Annuler le transfert
                                                </Button>
                                            </small>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </div>
                <div className='service-body-footer'>
                    <Button variant="contained" style={{ backgroundColor: "#1D62AF", color: "#fff" }} onClick={goToProviderMenu}>
                        Menu Prestataire
                    </Button>
                    <Button variant="contained" style={{ backgroundColor: "#A0E295", color: "rgba(0, 0, 0, 0.60)" }}>
                        Reminder globale
                    </Button>
                </div>
            </div>
        </>
    );
}

export default ListTransfer;
