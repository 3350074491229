import { useEffect, useRef, useState } from 'react';
import Customers from '../../../../models/Customers';
import FormMode from '../../../../enums/FormMode';
import GnzDrawer from '../../../shared/gnz-drawer/GnzDrawer';
import ProfilClientsPreferenceForm from '../ProfilClientPreferencesForm/ProfilClientsPreferenceForm'
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Customer from '../../../../models/Customers';
import GnzButton from '../../../shared/gnz-button/GnzButton';
import GnzImage from '../../../shared/gnz-image/GnzImage';
import plus2 from '../../../../images/shared/plus2.png';
import AllergiesCustomer from '../../../../models/AllergiesCustomer';
import FoodPreferencesCustomer from '../../../../models/FoodPreferencesCustomer';
import './ProfilClientPreferencesDetails.css';


interface IProfilClientPreferencesDetails {
}


function ProfilClientPreferencesDetails(props: IProfilClientPreferencesDetails) {
  const navigate = useNavigate();
  const [mode, setMode] = useState<FormMode>(FormMode.None);
  const drawerPreferences = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const myParam = searchParams.get('customerId');
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);
  const [Customers, Customer] = useState<Array<Customer>>([]);
  const [allergiesCustomer, AllergiesCustomer] = useState<Array<AllergiesCustomer>>([]);
  const [foodPreferencesCustomer, FoodPreferencesCustomer] = useState<Array<FoodPreferencesCustomer>>([]);

  const Url = 'https://www.mysharingsystem.com/api/';

  const ClientPreferences = () => {
    setMode(FormMode.New);
    openDrawerPreferences();
  };

  const openDrawerPreferences = () => {
    const drawerRefEl: any = drawerPreferences.current;
    drawerRefEl.open();
  }

  const closePreferencesEdit = () => {
    const drawerRefEl: any = drawerPreferences.current;
    drawerRefEl.close();
  }

  useEffect(() => {
    axios.get(Url + 'AllergiesCustomer/' + myParam)
      .then(res => AllergiesCustomer(res.data));
  }, [])

  useEffect(() => {
    axios.get(Url + 'FoodPreferenceCustomer/' + myParam)
      .then(res => FoodPreferencesCustomer(res.data));
  }, [])

  const handleChange = (event: any) => {
    axios.get(Url + 'Reservation/' + startDate + '/' + event.target.value)
      .then(res => Customer(res.data));

  };


  return (
    <div className='userPreference'>
      <h3 className='userPreference_header'>Fiche Client</h3>
      <div className='userPreference_content'>
      <div className="ProfilClientsPreferenceTopActions">
        <select className='selectSyle'>
          <option value={5} key={myParam} onClick={() => navigate(`/clientsPreferences?customerId=${myParam}`)} >Préférences</option>
          <option value={2}>Fiche client</option>
          <option value={3}>Email</option>
          <option value={4} key={myParam} onClick={() => navigate(`/feedback?customerId=${myParam}`)} >Feedback</option>
          <option value={5} key={myParam} onClick={() => navigate(`/clientsPreferences?customerId=${myParam}`)} >Préférences</option>
          <option value={1}>Profil</option>
          <option value={6}>Whatsapp</option>
        </select>

        <div className='PreferenceSelectSyleDiv'>
          <GnzButton  label="Partager ficher client" onClick={ClientPreferences} color="#ffffff" backgroundColor="#1D62AF" />

        </div>
        <div className='selectTimeDiv'>
          <input type="date" className="Datasyle" defaultValue={date} onChange={(e) => setStartDate(e.target.value)} />
          <input type="date" className="Datasyle" defaultValue={date} onChange={handleChange} />
        </div>
        </div>

        <div className="userPreference_info">
        <div className="userPreference_Items">
            <p><strong>Concierge</strong>:</p>
            <p>Voiture model Mercedes Class A TG501PV</p>
          </div>

          <div className="userPreference_Items">
            <p><strong>Housekeeping</strong>:</p>
            <p>Minibar</p>
            <p>Eau mineral temperature ambiente</p>
          </div>

          <div>
            <p>chambre lumineuse</p>
          </div>

          <div className="userPreference_Items">
            <p><strong>Liberie</strong>:</p>
            <p>chambre lumineuse</p>
          </div>


          <div className="userPreference_Items">
            <p><strong>Rest..HOtel</strong>:</p>
            {/* <p>Allergies</p> */}
          </div>

          <div className="moreContent">
          <div className=''>
            {allergiesCustomer.map((r) => (
              <p>{r.allergies.displayAs}</p>
              // <img className="TagLogo" src={`data:image/jpeg;base64,${r.allergies.displayAs}`} alt="" />
            ))}
              {foodPreferencesCustomer.map((r) => (
                <p>{r.food_Preferences.displayAs}</p>
              // <img className="TagLogo" src={`data:image/jpeg;base64,${r.foodPreferences.displayAs}`} alt="" />
            ))}
            {/* <a className="TagLogo" onClick={ClientTagEdit}> ... </a> */}
          </div>
        </div>


        </div>

        <GnzButton leftIcon={<GnzImage src={plus2} />} label="Preference" onClick={ClientPreferences} color="#ffffff" backgroundColor="#1D62AF" />

        <GnzDrawer ref={drawerPreferences} component={
          <ProfilClientsPreferenceForm
            myParam={myParam ?? "0"}
            allergiesCustomer={allergiesCustomer}
            foodPreferencesCustomer={foodPreferencesCustomer}
            closeForm={closePreferencesEdit}
          />
        } />

      </div>
    </div>
  );
}

export default ProfilClientPreferencesDetails