import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import './GnzBreadcrumb.css';

interface IGnzBreadcrumb {
  breadcrumbs: Array<string>
}

function GnzBreadcrumb(props: IGnzBreadcrumb) {
  return (
    <div className="GnzBreadcrumb">
      {props.breadcrumbs.map((breadcrumb, key) => key < props.breadcrumbs.length - 1 ? (
        <div key={key} className="GnzBreadcrumbLink">
          {breadcrumb}
          <ArrowForwardIosIcon fontSize="small" />
        </div>
      ) : (
        <div key={key} className="GnzBreadcrumbTypo">
          {breadcrumb}
        </div>
      ))}
      <div className="GnzBreadcrumbSeparator"></div>
    </div>
  );
}

export default GnzBreadcrumb;
