import { Table } from "../types/types";
import axiosInstance from "./axiosInstance";

const getTables = async (restaurantId: number): Promise<Table[]> => {
    const response = await axiosInstance.get<Table[]>('/Table/restaurant/' + restaurantId);
    return response.data;
};

const postTable = async (table: Table): Promise<Table> => {
    const response = await axiosInstance.post<Table>('/Table', table);
    return response.data;
}

const putTable = async (id: number, table: Table): Promise<Table> => {
    const response = await axiosInstance.put<Table>(`/Table/${id}`, table);
    return response.data;
}

const deleteTable = async (id: number): Promise<Table> => {
    const response = await axiosInstance.delete<Table>(`/Table/${id}`);
    return response.data;
}

export const tableService = {
    getTables,
    postTable,
    putTable,
    deleteTable
};