import React, { useEffect, useRef, useState } from "react";
import DirecteurAdjoint from "./directeurAdjoint";
import Directeur from "./directeur";
import Chef from "./chef";
import Employe from "./employee";
import { Tree, TreeNode } from "react-organizational-chart";
import Action from "./action";
import Edit from "./edit";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Button,
  Container,
  Grid,
  Menu,
  Paper,
  Typography,
} from "@mui/material";
import Empty from "./empty";
import logo from "../../static/hotel.png";

const apiUrl =
  window.location.hostname === "localhost"
    ? "https://localhost:7142/api"
    : "/api";

function App() {
  const [open1, setOpen1] = useState<any>({});
  const [zoomLevel, setZoomLevel] = useState(1);
  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
  };
  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 0.1);
  };
  const resetZoom = () => {
    setZoomLevel(1);
  };
  const [employees, setEmployees] = React.useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [filteredservice, setFilteredservice] = useState([]);
  const [directeur, setDirecteur] = useState<any>({});
  const [respoRh, setRespoRh] = useState<any>({});
  const [underRh, setUnderRh] = useState<any>({});

  const scrollRef = useRef<HTMLElement | null>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState<any>({});
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClick2 = (event: any) => {
    setAnchorEl2(anchorEl2 ? null : event.currentTarget);
  };
  const handleClick3 = (event: any) => {
    setAnchorEl3(anchorEl3 ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };
  const handleClick1 = (event: any, employeeId: string) => {
    setAnchorEl1({ ...anchorEl1, [employeeId]: event.currentTarget });
    setOpen1({ ...open1, [employeeId]: true });
  };

  const handleClose1 = (employeeId: string) => {
    setAnchorEl1({ ...anchorEl1, [employeeId]: null });
    setOpen1({ ...open1, [employeeId]: false });
  };
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);
  React.useEffect(() => {
    const fetchEmployees = async () => {
      try {
        let url = `${apiUrl}/NvDpt/all`;

        const response = await fetch(url);
        const data = await response.json();

        setEmployees(data);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, []);
  useEffect(() => {
    const filterEmployees = () => {
      const filteredData = employees.filter(
        (employee: any) =>
          employee.poste === "Chef Hebergement" ||
          employee.selectedLocalisation === "A droit de Chef d’Hebergement" ||
          employee.selectedLocalisation === "A droit de Spa Manager" ||
          employee.poste === "Spa Manager" ||
          employee.poste === "F&B Manager"
      );
      setFilteredEmployees(filteredData);
    };

    filterEmployees();
  }, [employees]);
  useEffect(() => {
    const filterEmployees = () => {
      const filteredData = employees.filter(
        (employee: any) => employee.selectedLocalisation === "En bas de RH"
      );
      setUnderRh(filteredData);
    };

    filterEmployees();
  }, [employees]);
  useEffect(() => {
    const filtersrv = () => {
      const filteredData = employees.filter(
        (employee: any) =>
          employee.poste === "Chef Réception" ||
          employee.selectedLocalisation === "A droit de Chef de réception" ||
          employee.selectedLocalisation === "A droit de Housekeeping Manager" ||
          employee.poste === "Chef Concierge" ||
          employee.poste === "Housekeeping Manager"
      );
      setFilteredservice(filteredData);
    };

    filtersrv();
  }, [employees]);
  useEffect(() => {
    const filterdirecteur = () => {
      const filteredData = employees.filter(
        (employee: any) => employee.poste === "Directeur Adjoint"
      );
      setDirecteur(filteredData);
    };

    filterdirecteur();
  }, [employees]);
  useEffect(() => {
    const filterRh = () => {
      const filteredData = employees.filter(
        (employee: any) => employee.poste === "Responsable RH"
      );
      setRespoRh(filteredData);
    };

    filterRh();
  }, [employees]);

  const renderDpt = (nodes: any) => {
    const filteredNodes = nodes.filter(
      (employee: any) =>
        employee.selectedLocalisation === "A droit de Chef d’Hebergement" ||
        employee.selectedLocalisation === "A droit de Spa Manager" ||
        employee.selectedLocalisation === null ||
        employee.selectedLocalisation === "Par defaut"
    );
    const sortedEmployees = filteredNodes.sort((a: any, b: any) => {
      const order = [
        "Chef Hebergement",
        "A droit de Chef d’Hebergement",
        "Spa Manager",
        "A droit de Spa Manager",
        "F&B Manager",
      ];
      const getOrderValue = (employee: any) => {
        if (employee.poste === "Chef Hebergement") {
          return order.indexOf("Chef Hebergement");
        } else if (
          employee.selectedLocalisation === "A droit de Chef d’Hebergement"
        ) {
          return order.indexOf("A droit de Chef d’Hebergement");
        } else if (employee.poste === "Spa Manager") {
          return order.indexOf("Spa Manager");
        } else if (employee.selectedLocalisation === "A droit de Spa Manager") {
          return order.indexOf("A droit de Spa Manager");
        } else {
          return order.indexOf(employee.poste);
        }
      };

      return getOrderValue(a) - getOrderValue(b);
    });

    return sortedEmployees.map((employee: any) => (
      <TreeNode
        key={employee.id}
        label={
          <div>
            <Button
              onClick={(event) => handleClick1(event, employee.id)}
              style={{
                textTransform: "none",
                padding: 0,
              }}
            >
              <Chef
                poste={employee.poste}
                nom={employee.nom}
                prenom={employee.prenom}
                profilePicture={employee.profilePicture}
              />
            </Button>
            <Paper>
              <Menu
                anchorEl={anchorEl1[employee.id]}
                open={open1[employee.id] || false}
                onClose={() => handleClose1(employee.id)}
              >
                <Edit selectedservice={employee.service} />
              </Menu>
            </Paper>
          </div>
        }
      >
        {employee.poste === "Chef Hebergement"
          ? renderService(filteredservice)
          : employee.poste === "Responsable RH"
          ? renderUnderRh(underRh)
          : employee.subordinates && renderDpt(employee.subordinates)}
      </TreeNode>
    ));
  };
  const renderUnderRh = (nodes: any) => {
    return nodes.map((employee: any) => (
      <TreeNode
        key={employee.id}
        label={
          <div>
            <Button
              onClick={(event) => handleClick1(event, employee.id)}
              style={{
                textTransform: "none",
                padding: 0,
              }}
            >
              <Chef
                poste={employee.poste}
                nom={employee.nom}
                prenom={employee.prenom}
                profilePicture={employee.profilePicture}
              />
            </Button>
            <Paper>
              <Menu
                anchorEl={anchorEl1[employee.id]}
                open={open1[employee.id] || false}
                onClose={() => handleClose1(employee.id)}
              >
                <Edit selectedservice={employee.service} />
              </Menu>
            </Paper>
          </div>
        }
      >
        {employee.poste === "Chef Hebergement"
          ? renderService(filteredservice)
          : employee.subordinates && renderDpt(employee.subordinates)}
      </TreeNode>
    ));
  };

  const renderService = (nodes: any) => {
    const sortedEmployees = nodes.sort((a: any, b: any) => {
      const order = [
        "Chef Réception",
        "A droit de Chef de réception",
        "Chef Concierge",
        "Housekeeping Manager",
        "A droit de Housekeeping Manager",
      ];
      const getOrderValue = (employee: any) => {
        if (employee.poste === "Chef Réception") {
          return order.indexOf("Chef Réception");
        } else if (
          employee.selectedLocalisation === "A droit de Chef de réception"
        ) {
          return order.indexOf("A droit de Chef de réception");
        } else if (employee.poste === "Chef Concierge") {
          return order.indexOf("Chef Concierge");
        } else if (employee.poste === "Housekeeping Manager") {
          return order.indexOf("Housekeeping Manager");
        } else if (
          employee.selectedLocalisation === "A droit de Housekeeping Manager"
        ) {
          return order.indexOf("A droit de Housekeeping Manager");
        } else {
          return order.indexOf(employee.poste);
        }
      };

      return getOrderValue(a) - getOrderValue(b);
    });
    return (sortedEmployees || []).map((employee: any) => (
      <TreeNode
        key={employee.id}
        label={
          <>
            <Button
              onClick={(event) => handleClick1(event, employee.id)}
              style={{
                textTransform: "none",
                padding: 0,
              }}
            >
              <Employe
                poste={employee.poste}
                nom={employee.nom}
                prenom={employee.prenom}
                profilePicture={employee.profilePicture}
              />
            </Button>

            <Paper>
              <Menu
                anchorEl={anchorEl1[employee.id]}
                open={open1[employee.id] || false}
                onClose={() => handleClose1(employee.id)}
              >
                <Edit selectedservice={employee.service} />
              </Menu>
            </Paper>
          </>
        }
      >
        {employee.subordinates && renderService(employee.subordinates)}
      </TreeNode>
    ));
  };

  return (
    <Container maxWidth="xl" sx={{ marginTop: 5, backgroundColor: "white" }}>
      <img src={logo} alt="logo" />
      {employees && employees.length > 0 ? (
        <>
          <PerfectScrollbar
            containerRef={(ref) => (scrollRef.current = ref)}
            options={{ wheelPropagation: false }}
            style={{
              maxHeight: "1000px",
              maxWidth: "1400px",
              margin: "auto",
              paddingBottom: 40,
              transform: `scale(${zoomLevel})`,
            }}
          >
            <Tree
              lineStyle="dotted"
              lineWidth="1px"
              lineHeight="70px"
              lineColor="#1D62AF"
              label={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: "30px",
                  }}
                >
                  <DirecteurAdjoint />
                </div>
              }
            >
              {" "}
              <TreeNode
                label={
                  <>
                    <Button
                      onClick={handleClick2}
                      style={{
                        textTransform: "none",
                        padding: 0,
                      }}
                    >
                      <Directeur
                        poste={directeur[0]?.poste}
                        nom={directeur[0]?.nom}
                        prenom={directeur[0]?.prenom}
                        profilePicture={directeur[0]?.profilePicture}
                      />
                    </Button>
                    <Paper>
                      <Menu
                        anchorEl={anchorEl2}
                        open={open2}
                        onClose={handleClose2}
                      >
                        <Edit selectedservice={directeur[0]?.service} />
                      </Menu>
                    </Paper>
                  </>
                }
              >
                <TreeNode label="">{renderDpt(filteredEmployees)}</TreeNode>

                {respoRh &&
                  respoRh.length > 0 &&
                  (respoRh[0]?.selectedLocalisation === "Par defaut" ||
                    respoRh[0]?.selectedLocalisation === null) && (
                    <TreeNode
                      label={
                        <>
                          <Button
                            onClick={handleClick3}
                            style={{
                              textTransform: "none",
                              padding: 0,
                            }}
                          >
                            <Directeur
                              poste={respoRh[0]?.poste}
                              nom={respoRh[0]?.nom}
                              prenom={respoRh[0]?.prenom}
                              profilePicture={respoRh[0]?.profilePicture}
                            />
                          </Button>
                          <Paper>
                            <Menu
                              anchorEl={anchorEl3}
                              open={open3}
                              onClose={handleClose3}
                            >
                              <Edit selectedservice={respoRh[0]?.service} />
                            </Menu>
                          </Paper>
                        </>
                      }
                    >
                      {renderUnderRh(underRh)}
                    </TreeNode>
                  )}
              </TreeNode>
            </Tree>
          </PerfectScrollbar>
          <br />
        </>
      ) : (
        <Empty />
      )}
      <Grid container flexDirection="row" justifyContent="space-between">
        <Grid
          container
          item
          flexDirection="row"
          alignItems="center"
          justifyContent={{ xs: "center", sm: "start" }}
          sm={6}
          my={2}
        >
          <Grid
            item
            sx={{ border: "solid 1px rgba(0,0,0,0.1)", borderRadius: 2 }}
            p={0}
          >
            <Button onClick={handleZoomIn} sx={{ height: 50 }}>
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.5 0C7.91 0 8.25 0.34 8.25 0.75V6.75H14.25C14.66 6.75 15 7.09 15 7.5C15 7.91 14.66 8.25 14.25 8.25H8.25V14.25C8.25 14.66 7.91 15 7.5 15C7.09 15 6.75 14.66 6.75 14.25V8.25H0.75C0.34 8.25 0 7.91 0 7.5C0 7.09 0.34 6.75 0.75 6.75H6.75V0.75C6.75 0.34 7.09 0 7.5 0Z"
                  fill="#040509"
                />
              </svg>
            </Button>
            <Button onClick={handleZoomOut} sx={{ height: 50 }}>
              <svg
                width="17"
                height="3"
                viewBox="0 0 17 3"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.5 0.5H1.5C0.947715 0.5 0.5 0.947715 0.5 1.5C0.5 2.05228 0.947715 2.5 1.5 2.5H15.5C16.0523 2.5 16.5 2.05228 16.5 1.5C16.5 0.947715 16.0523 0.5 15.5 0.5Z"
                  fill="black"
                />
              </svg>
            </Button>
          </Grid>
          <Grid item marginLeft={2}>
            <Button
              onClick={resetZoom}
              sx={{
                height: 50,
                width: 120,
                border: "solid 1px rgba(0,0,0,0.1)",
                borderRadius: 2,
              }}
            >
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.5 0.5H6.5V2.5H3.90991L6.90991 5.5L5.5 6.91003L2.5 3.91003V6.5H0.5V0.5ZM10.5 0.5H16.5V6.5H14.5V3.91003L11.5 6.91003L10.0901 5.5L13.0901 2.5H10.5V0.5ZM6.90991 11.5L3.90991 14.5H6.5V16.5H0.5V10.5H2.5V13.09L5.5 10.09L6.90991 11.5ZM11.5 10.09L14.5 13.09V10.5H16.5V16.5H10.5V14.5H13.0901L10.0901 11.5L11.5 10.09Z"
                  fill="black"
                />
              </svg>
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          item
          sm={6}
          justifyContent={{ xs: "center", sm: "end" }}
          my={2}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: "#F0F4F8", paddingY: 2, borderRadius: 10 }}
            onClick={handleClick}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10ZM10 2C7.88 2 5.83984 2.83997 4.33984 4.33997C2.83984 5.83997 2 7.88 2 10C2 12.12 2.83984 14.16 4.33984 15.66C5.83984 17.16 7.88 18 10 18C12.12 18 14.1602 17.16 15.6602 15.66C17.1602 14.16 18 12.12 18 10C18 7.88 17.1602 5.83997 15.6602 4.33997C14.1602 2.83997 12.12 2 10 2Z"
                fill="black"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 5C11 4.73 10.89 4.48004 10.71 4.29004C10.52 4.11004 10.27 4 10 4C9.73 4 9.48004 4.11004 9.29004 4.29004C9.11004 4.48004 9 4.73 9 5V9H5C4.73 9 4.48004 9.11004 4.29004 9.29004C4.11004 9.48004 4 9.73 4 10C4 10.27 4.11004 10.52 4.29004 10.71C4.48004 10.89 4.73 11 5 11H9V15C9 15.27 9.11004 15.52 9.29004 15.71C9.48004 15.89 9.73 16 10 16C10.27 16 10.52 15.89 10.71 15.71C10.89 15.52 11 15.27 11 15V11H15C15.27 11 15.52 10.89 15.71 10.71C15.89 10.52 16 10.27 16 10C16 9.73 15.89 9.48004 15.71 9.29004C15.52 9.11004 15.27 9 15 9H11V5Z"
                fill="black"
              />
            </svg>
            <Typography
              sx={{ textTransform: "none", marginLeft: 1, color: "black" }}
              fontWeight="bold"
            >
              Add new
            </Typography>
          </Button>
          <Menu
            className="menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Action />
          </Menu>
        </Grid>
      </Grid>
    </Container>
  );
}

export default App;
