import './MssAdminUsers.css';

interface IMssAdminUsers {
  logo?: string | undefined;
  name: string;
  children?: React.ReactNode;
  height?: number;
  scroll?: boolean;
}

function MssAdminUsers(props: IMssAdminUsers) {

  const MssAdminUsersHeaderLogo = () => props.logo ? <div className="MssAdminUsersHeaderLogo"><img src={props.logo} alt="" draggable="false" /></div> : null

  return (
    <div className="MssAdminUsers" style={{
      height: props.height || 'auto',
      paddingBottom: props.scroll ? '15px' : '0px'
    }}>
      <div className="MssAdminUsersHeader">
        {MssAdminUsersHeaderLogo()}
        <div className="MssAdminUsersHeaderName">{props.name}</div>
      </div>
      <div className="MssAdminUsersBody" style={{
        overflowY: props.scroll ? 'scroll' : 'hidden',
      }}>
        {props.children}
      </div>
    </div>
  );
}

export default MssAdminUsers;
