import Modal from '@mui/material/Modal';
import { SelectChangeEvent } from '@mui/material/Select';
import LogoutIcon from '@mui/icons-material/Logout';
import { useEffect, useState } from 'react';
import './historique.css';
import { HistoriqueFilter, Historique as HistoriqueType } from '../types/types';
import { historiqueService } from '../api/historiqueService';
import HistoriqueDesktop from './historiqueDesktop';
import HistoriqueMobile from './historiqueMobile';

interface HistoriqueProps {
    onClose: () => void;
    restaurantId: number | undefined;
}

const Historique: React.FC<HistoriqueProps> = ({ onClose, restaurantId }) => {

    const [historiques, setHistoriques] = useState < HistoriqueType[] > ([]);

    const initFilter: HistoriqueFilter = {
        dateDebutString: "1000-01-01",
        dateFinString: "9999-01-01",
        entity: "",
        utilisateur: "",
        restaurantId: restaurantId || 0,
    };

    const [filter, setFilter] = useState < HistoriqueFilter > (initFilter);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchHistorique = async () => {
            setLoading(true);
            try {
                const data = await historiqueService.getHistorique(restaurantId ?? 2);
                setHistoriques(data);
            } catch (error) {
                console.error('Error fetching historique data:', error);
            } finally {
                setLoading(false);
            }
        };

        if (restaurantId !== undefined) {
            fetchHistorique();
        }
    }, [restaurantId]);

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        let newValue = value;

        if (value === '') {
            if (name === 'dateDebutString') {
                newValue = '1000-01-01';
            } else if (name === 'dateFinString') {
                newValue = '9999-01-01';
            }
        }

        setFilter({
            ...filter,
            [name]: newValue,
        });
    };

    const handleChangeEntity = (event: SelectChangeEvent) => {
        setFilter({
            ...filter,
            entity: event.target.value,
        });
    };

    const handleChangeUser = (event: SelectChangeEvent) => {
        setFilter({
            ...filter,
            utilisateur: event.target.value,
        });
    };

    const handleFilterButtonClick = async () => {

        try {

            if (filter.dateDebutString && filter.dateFinString) {
                if (filter.dateDebutString > filter.dateFinString) {
                    alert("La date de début doit être inférieure à la date de fin");
                    setLoading(false);
                    return;
                }
            }

            setLoading(true);

            const filteredData = await historiqueService.getHistoriqueFilter(filter);
            setHistoriques(filteredData);
        } catch (error) {
            console.error('Error filtering historique data:', error);
        } finally {
            setLoading(false);
        }
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='modal-restau-historique'>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <h1>Historique</h1>
                    <LogoutIcon
                        style={{ color: "darkgray", fontSize: "36px", cursor: "pointer" }}
                        onClick={onClose}
                    />
                </div>

                {windowWidth > 620 ? (
                    <HistoriqueDesktop
                        filter={filter}
                        handleFilterChange={handleFilterChange}
                        handleChangeEntity={handleChangeEntity}
                        handleChangeUser={handleChangeUser}
                        handleFilterButtonClick={handleFilterButtonClick}
                        loading={loading}
                        historiques={historiques}
                    />
                ) : (
                    <HistoriqueMobile
                        filter={filter}
                        handleFilterChange={handleFilterChange}
                        handleChangeEntity={handleChangeEntity}
                        handleChangeUser={handleChangeUser}
                        handleFilterButtonClick={handleFilterButtonClick}
                        loading={loading}
                        historiques={historiques}
                    />
                )}
            </div>
        </Modal>
    );
}

export default Historique;