import './CheckListForm.css';

import { useEffect, useState } from 'react';
import { TimePickerProps } from 'antd';

import FormMode from '../../../../enums/FormMode';
import Mission from '../../../../models/Mission';
import MissionStatus from '../../../../enums/MissionStatus';
import MissionEvent from '../../../../models/MissionEvent';
import MissionReminder from '../../../../models/MissionReminder';
import GnzTimePicker from '../../../shared/gnz-time-picker/GnzTimePicker';
import GnzButton from '../../../shared/gnz-button/GnzButton';

interface ICheckListForm {
  mode: FormMode,
  currentDate: number,
  title: string,
  closeForm: () => void,
  mission: Mission,
  submit: (mission: Mission) => void
  submitLabel: string,
  submitColor: string,
}

function CheckListForm(props: ICheckListForm) {
  const [id, setId] = useState(0);
  const [schedule, setSchedule] = useState(Date.now());
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState(MissionStatus.None);
  const [events, setEvents] = useState<Array<MissionEvent>>([]);
  const [reminder, setReminder] = useState<MissionReminder>(new MissionReminder());

  const [timestamp, setTimestamp] = useState(0);

  useEffect(() => {
    setTimestamp(props.currentDate);
    if (props.mission.id) {
      setId(props.mission.id);
      setSchedule(props.mission.schedule);
      setDescription(props.mission.description);
      setStatus(props.mission.status);
      setEvents(props.mission.events);
      setReminder(props.mission.reminder);
    }
    // eslint-disable-next-line
  }, [])

  const submit = () => {
    if (schedule && description.length && status !== MissionStatus.None) {
      props.submit(new Mission(
        schedule,
        description,
        status,
        timestamp,
        events,
        reminder,
        id
      ));
    }
  }

  const onChangeTimePicker: TimePickerProps['onChange'] = (date: any) => {
    if (date) {
      date.$d = new Date(`${date.$d.getFullYear()}-${date.$d.getMonth() + 1}-${date.$d.getDate()} ${date.$H}:${date.$m}`);
      console.log(date.$d);
      setSchedule(date.$d.getTime());
    } else {
      setSchedule(0);
    }

  };

  return (
    <div className="CheckListForm">
      <div className="CheckListFormTitle">{props.title}</div>
      <div className="CheckListFormClose" onClick={props.closeForm}>X</div>
      <div className="CheckListFormInputTitle">Heure</div>
      <GnzTimePicker className="SecondaryDatePickerNoMarginBottom" currentDate={schedule} onChange={onChangeTimePicker} />
      <div className="CheckListFormInputTitle">Description</div>
      <textarea placeholder="Description de la mission" value={description} onChange={(e) => setDescription(e.target.value)} spellCheck={false}></textarea>
      <div className="CheckListFormInputTitle">Status</div>
      <select value={status} onChange={(e) => setStatus(e.target.value as unknown as MissionStatus)}>
        <option value={MissionStatus.None}></option>
        <option value={MissionStatus.Todo}>A faire</option>
        <option value={MissionStatus.Waiting}>En attente</option>
        <option value={MissionStatus.Processing}>En cours</option>
        <option value={MissionStatus.Done}>Fait</option>
      </select>
      <div className="CheckListFormActions">
        <GnzButton label={props.submitLabel} onClick={submit} backgroundColor={props.submitColor} />
      </div>
      <div className="CheckListFormActionsBottomGap"></div>
    </div>
  );
}

export default CheckListForm;
