import { Historique, HistoriqueFilter } from '../types/types';
import axiosInstance from './axiosInstance';

const getHistorique = async (restaurantId: number): Promise<Historique[]> => {
    const response = await axiosInstance.get<Historique[]>(`/Historique/restaurant/${restaurantId}`);
    return response.data;
}

const getHistoriqueFilter = async (historiqueFilter: HistoriqueFilter): Promise<Historique[]> => {
    const response = await axiosInstance.get<Historique[]>('/Historique/filter', {
        params: historiqueFilter
    });
    return response.data;
}

export const historiqueService = {
    getHistorique,
    getHistoriqueFilter
};