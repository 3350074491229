import { ChangeEvent, useEffect, useState } from 'react';
import FormMode from '../../../../enums/FormMode';
import ReglementType from '../../../../enums/ReglementType';
import Debour from '../../../../models/Debour';

import './DebourForm.css';
import GnzButton from '../../../shared/gnz-button/GnzButton';

interface IDebourForm {
  mode: FormMode,
  title: string,
  closeForm: () => void,
  debour: Debour,
  submit: (debour: Debour) => void
  submitLabel: string,
  submitColor: string,
  currentDate: number,
}

function DebourForm(props: IDebourForm) {
  const [id, setId] = useState(0);
  const [room, setRoom] = useState(0);
  const [noRoom, setNoRoom] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [description, setDescription] = useState('');
  const [prestataire, setPrestataire] = useState('');
  const [sortieCaisse, setSortieCaisse] = useState(0);
  const [reglement, setReglement] = useState<ReglementType>(ReglementType.SEE_PAYMENT);
  const [timestamp, setTimestamp] = useState(0);

  useEffect(() => {
    if (props.debour.id) {
      setId(props.debour.id);
      setRoom(props.debour.room);
      setFirstName(props.debour.firstName);
      setLastName(props.debour.lastName);
      setDescription(props.debour.description);
      setPrestataire(props.debour.prestataire);
      setSortieCaisse(props.debour.sortieCaisse);
      setReglement(props.debour.reglement);
      setTimestamp(props.debour.timestamp);
    }
    // eslint-disable-next-line
  }, [])

  const roomValue = () => room > 0 ? room : '';

  const changeRoom = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    if (!isNaN(Number(input.value)) && Number(input.value) > 0) {
      setRoom(Number(input.value));
    }
  }

  const changeSortieCaisse = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    if (!isNaN(Number(input.value))) {
      setSortieCaisse(Number(input.value));
    }
  }

  const submit = () => {
    const roomValid = noRoom || room > 0;
    const roomNamesValid = noRoom || firstName.length && lastName.length;
    if (roomValid && roomNamesValid && description.length && prestataire.length) {
      props.submit(new Debour(
        noRoom ? -1 : room,
        firstName,
        lastName,
        description,
        prestataire,
        sortieCaisse,
        reglement,
        timestamp ? timestamp : props.currentDate,
        id
      ));
    }
  }

  return (
    <div className="DebourForm">
      <div className="ConsigneFormTitle">{props.title}</div>
      <div className="ConsigneFormClose" onClick={props.closeForm}>X</div>
      <div className="ConsigneFormInputTitle">Chambre</div>
      <input type="text" placeholder="Chambre du client" className="AppFormInput" value={roomValue()} onChange={changeRoom} />
      <div className="ConsigneFormCheckbox">
        <input type="checkbox" id="noRoom" checked={noRoom} onChange={() => setNoRoom(!noRoom)}/>
        <label htmlFor="noRoom">Sans chambre</label>
      </div>
      <div className="ConsigneFormInputTitle">Nom</div>
      <input type="text" placeholder="Nom du client" className="AppFormInput" value={lastName} onChange={(e) => setLastName(e.target.value)} />
      <div className="ConsigneFormInputTitle">Prénom</div>
      <input type="text" placeholder="Prénom du client" className="AppFormInput" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
      <div className="ConsigneFormInputTitle">Description</div>
      <textarea placeholder="Description du débour" value={description} onChange={(e) => setDescription(e.target.value)} spellCheck={false}></textarea>
      <div className="ConsigneFormInputTitle">Prestataire</div>
      <input type="text" placeholder="Prestataire du débour" className="AppFormInput" value={prestataire} onChange={(e) => setPrestataire(e.target.value)} />
      <div className="ConsigneFormInputTitle">Sortie de caisse</div>
      <input type="text" placeholder="Sortie de caisse du débour" className="AppFormInput" value={sortieCaisse} onChange={changeSortieCaisse} />
      <div className="ConsigneFormInputTitle">Status</div>
      <select value={reglement} onChange={(e) => setReglement(e.target.value as unknown as ReglementType)}>
        <option value={ReglementType.SEE_PAYMENT}>Voir paiement</option>
        <option value={ReglementType.ROOM}>Sur chambre</option>
        <option value={ReglementType.DIRECTION}>Compte direction</option>
        <option value={ReglementType.HOTEL}>Compte Hotel</option>
        <option value={ReglementType.FREE}>Gratuité</option>
      </select>
      <div className="ConsigneFormActions">
        <GnzButton label={props.submitLabel} onClick={submit} backgroundColor={props.submitColor} />
      </div>
    </div>
  );
}

export default DebourForm;
