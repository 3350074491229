import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import "dayjs/locale/fr";
import './GnzDatePicker.css';

interface IGnzDatePicker {
  currentDate: number,
  onChange: any,
  disabled?: boolean,
}

function GnzDatePicker(props: IGnzDatePicker) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
      <MobileDatePicker value={dayjs(new Date(props.currentDate))} onChange={props.onChange} />
    </LocalizationProvider>
  );
}

export default GnzDatePicker;
