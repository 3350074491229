import * as React from "react";
import {
  Checkbox,
  FormGroup,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import axios from "axios";

const apiUrl =
  window.location.hostname === "localhost"
    ? "https://localhost:7142/api"
    : "/api";
export default function Filter({
  setSelectedValue,
  selectedValue,
  selectedDpts,
  setSelectedDpts,
  responsabilité,
  setResponsabilite,
  fromdate,
  setFromdate,
  todate,
  setTodate,
}: any) {
  const [dpts, setDpt] = React.useState([]);
  const Respos = [
    "Cadre",
    "Chef de service",
    "Collaborateur 1",
    "Collaborateur 2",
  ];
  const handleChange = (date: any, dateType: any) => {
    if (dateType === "from") {
      setFromdate(date);
    } else if (dateType === "to") {
      setTodate(date);
    }
  };

  const handleButtonClick = (value: any) => {
    setSelectedValue(value === selectedValue ? "" : value);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/NvDpt`);
        setDpt(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleDptCheckboxChange = (selectedDpt: string) => {
    setSelectedDpts((prev: any) => {
      if (selectedDpt === "") {
        return prev.length === 0 ? [""] : [];
      } else {
        if (prev.includes(selectedDpt)) {
          return prev.filter((dpt: string) => dpt !== selectedDpt);
        } else {
          return [...prev, selectedDpt];
        }
      }
    });
  };

  const handleRespoChange = (responsabilité: string) => {
    setResponsabilite((prev: any) => {
      if (responsabilité === "") {
        return prev.length === 0 ? [""] : [];
      } else {
        if (prev.includes(responsabilité)) {
          return prev.filter((respo: string) => respo !== responsabilité);
        } else {
          return [...prev, responsabilité];
        }
      }
    });
  };

  return (
    <>
      <Grid container my={2}>
        <Grid item xs={3}>
          <Typography sx={{ color: "#0B4B92" }} fontWeight="bold">
            Filtres- Service
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <svg
            width="auto"
            height="2"
            viewBox="0 0 385 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L384 0.999959"
              stroke="black"
              stroke-opacity="0.1"
              stroke-linecap="round"
            />
          </svg>
        </Grid>
      </Grid>
      <FormGroup
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <Checkbox
          checked={selectedDpts.length === 0}
          onChange={() => handleDptCheckboxChange("")}
          sx={{
            padding: 0,
            "&:hover": {
              backgroundColor: "transparent",
            },
            my: 0.5,
          }}
          icon={
            <Typography
              sx={{
                paddingX: 1.5,
                paddingY: 1,
                border: "2px solid #ccc",
                borderRadius: 50,
                width: 130,
                textAlign: "center",
              }}
            >
              Tous les services
            </Typography>
          }
          checkedIcon={
            <Typography
              sx={{
                paddingX: 1.5,
                paddingY: 1,
                border: "2px solid rgba(11,75,146,1)",
                borderRadius: 50,
                backgroundColor: "rgba(11,75,146,0.2)",
                width: 130,
                textAlign: "center",
              }}
            >
              Tous les services
            </Typography>
          }
        />
        {dpts.map((dpt) => (
          <Checkbox
            checked={selectedDpts.includes(dpt)}
            onChange={() => handleDptCheckboxChange(dpt)}
            sx={{
              padding: 0,
              "&:hover": {
                backgroundColor: "transparent",
              },
              my: 0.5,
            }}
            icon={
              <Typography
                sx={{
                  paddingX: 1.5,
                  paddingY: 1,
                  border: "2px solid #ccc",
                  borderRadius: 50,
                  width: 130,
                  textAlign: "center",
                }}
              >
                {dpt}
              </Typography>
            }
            checkedIcon={
              <Typography
                sx={{
                  paddingX: 1.5,
                  paddingY: 1,
                  border: "2px solid rgba(11,75,146,1)",
                  borderRadius: 50,
                  backgroundColor: "rgba(11,75,146,0.2)",
                  width: 130,
                  textAlign: "center",
                }}
              >
                {dpt}
              </Typography>
            }
          />
        ))}
      </FormGroup>
      <Grid container my={2}>
        <Grid item xs={4}>
          <Typography sx={{ color: "#0B4B92" }} fontWeight="bold">
            Filtres- Responsabilité
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <svg
            width="auto"
            height="2"
            viewBox="0 0 385 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L384 0.999959"
              stroke="black"
              stroke-opacity="0.1"
              stroke-linecap="round"
            />
          </svg>
        </Grid>
      </Grid>
      <FormGroup
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <Checkbox
          checked={responsabilité.length === 0}
          onChange={() => handleRespoChange("")}
          sx={{
            padding: 0,
            "&:hover": {
              backgroundColor: "transparent",
            },
            my: 0.5,
          }}
          icon={
            <Typography
              sx={{
                paddingX: 1.5,
                paddingY: 1,
                border: "2px solid #ccc",
                borderRadius: 50,
                width: 130,
                textAlign: "center",
              }}
            >
              Tous les responsabilités
            </Typography>
          }
          checkedIcon={
            <Typography
              sx={{
                paddingX: 1.5,
                paddingY: 1,
                border: "2px solid rgba(11,75,146,1)",
                borderRadius: 50,
                backgroundColor: "rgba(11,75,146,0.2)",
                width: 130,
                textAlign: "center",
              }}
            >
              Tous les responsabilités
            </Typography>
          }
        />
        {Respos.map((respo) => (
          <Checkbox
            checked={responsabilité.includes(respo)}
            onChange={() => handleRespoChange(respo)}
            sx={{
              padding: 0,
              "&:hover": {
                backgroundColor: "transparent",
              },
              my: 0.5,
            }}
            icon={
              <Typography
                sx={{
                  paddingX: 1.5,
                  paddingY: 1,
                  border: "2px solid #ccc",
                  borderRadius: 50,
                  width: 130,
                  textAlign: "center",
                }}
              >
                {respo}
              </Typography>
            }
            checkedIcon={
              <Typography
                sx={{
                  paddingX: 1.5,
                  paddingY: 1,
                  border: "2px solid rgba(11,75,146,1)",
                  borderRadius: 50,
                  backgroundColor: "rgba(11,75,146,0.2)",
                  width: 130,
                  textAlign: "center",
                }}
              >
                {respo}
              </Typography>
            }
          />
        ))}
      </FormGroup>
      <Grid container my={2}>
        <Grid item xs={1}>
          <Typography sx={{ color: "#0B4B92" }} fontWeight="bold">
            Trie
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <svg
            width="auto"
            height="2"
            viewBox="0 0 385 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L384 0.999959"
              stroke="black"
              stroke-opacity="0.1"
              stroke-linecap="round"
            />
          </svg>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-evenly" spacing={1}>
        <Grid item>
          <ToggleButtonGroup
            value={selectedValue}
            exclusive
            onChange={(event, value) => handleButtonClick(value)}
          >
            <ToggleButton
              value=""
              color="primary"
              sx={{
                padding: 0,
                border: 0,
                borderRadius: "50px !important",
                "&.Mui-selected": {
                  padding: 0,
                  textAlign: "center",
                  borderRadius: 50,
                  "& .MuiTypography-root": {
                    border: "2px solid rgba(11,75,146,1)",
                    backgroundColor: "rgba(11,75,146,0.2)",
                    borderRadius: 50,
                  },
                },
              }}
            >
              <Typography
                sx={{
                  width: 100,
                  paddingX: 1.5,
                  paddingY: 1,
                  border: "2px solid #ccc",
                  borderRadius: 50,
                  textAlign: "center",
                  fontSize: "medium",
                  textTransform: "none",
                }}
              >
                Par défaut
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            value={selectedValue}
            exclusive
            onChange={(event, value) => handleButtonClick(value)}
          >
            <ToggleButton
              color="primary"
              sx={{
                padding: 0,
                border: 0,
                borderRadius: "50px !important",
                "&.Mui-selected": {
                  padding: 0,
                  textAlign: "center",
                  borderRadius: 50,
                  "& .MuiTypography-root": {
                    border: "2px solid rgba(11,75,146,1)",
                    backgroundColor: "rgba(11,75,146,0.2)",
                    borderRadius: 50,
                  },
                },
              }}
              value="asc"
            >
              <Typography
                sx={{
                  width: 100,
                  paddingX: 1.5,
                  paddingY: 1,
                  border: "2px solid #ccc",
                  borderRadius: 50,
                  textAlign: "center",
                  fontSize: "medium",
                  textTransform: "none",
                }}
              >
                Asc. (A-&gt;Z){" "}
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            value={selectedValue}
            exclusive
            onChange={(event, value) => handleButtonClick(value)}
          >
            <ToggleButton
              color="primary"
              sx={{
                padding: 0,
                border: 0,
                borderRadius: "50px !important",
                "&.Mui-selected": {
                  padding: 0,
                  textAlign: "center",
                  borderRadius: 50,
                  "& .MuiTypography-root": {
                    border: "2px solid rgba(11,75,146,1)",
                    backgroundColor: "rgba(11,75,146,0.2)",
                    borderRadius: 50,
                  },
                },
              }}
              value="desc"
            >
              <Typography
                sx={{
                  width: 100,
                  paddingX: 1.5,
                  paddingY: 1,
                  border: "2px solid #ccc",
                  borderRadius: 50,
                  textAlign: "center",
                  fontSize: "medium",
                  textTransform: "none",
                }}
              >
                Disc. (Z -&gt; A)
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            value={selectedValue}
            exclusive
            onChange={(event, value) => handleButtonClick(value)}
          >
            <ToggleButton
              color="primary"
              sx={{
                padding: 0,
                border: 0,
                borderRadius: "50px !important",
                "&.Mui-selected": {
                  padding: 0,
                  textAlign: "center",
                  borderRadius: 50,
                  "& .MuiTypography-root": {
                    border: "2px solid rgba(11,75,146,1)",
                    backgroundColor: "rgba(11,75,146,0.2)",
                    borderRadius: 50,
                  },
                },
              }}
              value="newer"
            >
              <Typography
                sx={{
                  width: 120,
                  paddingX: 1.5,
                  paddingY: 1,
                  border: "2px solid #ccc",
                  borderRadius: 50,
                  textAlign: "center",
                  fontSize: "medium",
                  textTransform: "none",
                }}
              >
                De plus recent
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            value={selectedValue}
            exclusive
            onChange={(event, value) => handleButtonClick(value)}
          >
            <ToggleButton
              color="primary"
              sx={{
                padding: 0,
                border: 0,
                borderRadius: "50px !important",
                "&.Mui-selected": {
                  padding: 0,
                  textAlign: "center",
                  borderRadius: 50,
                  "& .MuiTypography-root": {
                    border: "2px solid rgba(11,75,146,1)",
                    backgroundColor: "rgba(11,75,146,0.2)",
                    borderRadius: 50,
                  },
                },
              }}
              value="older"
            >
              <Typography
                sx={{
                  width: 120,
                  paddingX: 1.5,
                  paddingY: 1,
                  border: "2px solid #ccc",
                  borderRadius: 50,
                  textAlign: "center",
                  fontSize: "medium",
                  textTransform: "none",
                }}
              >
                De plus ancien
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Grid container my={2}>
        <Grid item xs={1}>
          <Typography sx={{ color: "#0B4B92" }} fontWeight="bold">
            Date
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <svg
            width="auto"
            height="2"
            viewBox="0 0 385 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L384 0.999959"
              stroke="black"
              stroke-opacity="0.1"
              stroke-linecap="round"
            />
          </svg>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            type="date"
            variant="outlined"
            fullWidth
            value={fromdate}
            onChange={(e) => handleChange(e.target.value, "from")}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="date"
            variant="outlined"
            fullWidth
            value={todate}
            onChange={(e) => handleChange(e.target.value, "to")}
          />
        </Grid>
      </Grid>
    </>
  );
}
