import './AddReservation.css'

function AddReservation() {
    return (
        <div className='add-reservation-container'>
            <div className='add-reservation-header'>
                <h1>Nouvelle réservation restaurant “Il Sogno”</h1>
                <h1 style={{ cursor: "pointer", color: "black" }}>X</h1>
            </div>

            <div className='add-reservation-form'>
                <div className="add-reservation-row">
                    <div className="add-reservation-column">
                        <label htmlFor="email-restau" className='label-restau'>Type client</label>
                        <input type="text" id='email-restau' className='text-restau' />
                    </div>
                    <div className="add-reservation-column">
                        <label htmlFor="email-restau" className='label-restau'>Chambre</label>
                        <input type="text" id='email-restau' className='text-restau' />
                    </div>
                    <div className="add-reservation-column">
                        <label htmlFor="email-restau" className='label-restau'>Titre</label>
                        <input type="text" id='email-restau' className='text-restau' />
                    </div>
                </div>
                <div className="add-reservation-row">
                    <div className="add-reservation-column">
                        <label htmlFor="email-restau" className='label-restau'>Nom client</label>
                        <input type="text" id='email-restau' className='text-restau' />
                    </div>
                    <div className="add-reservation-column">
                        <label htmlFor="email-restau" className='label-restau'>Prénom client</label>
                        <input type="text" id='email-restau' className='text-restau' />
                    </div>
                    <div className="add-reservation-column">
                        <label htmlFor="email-restau" className='label-restau'>Date de naissance</label>
                        <input type="text" id='email-restau' className='text-restau' />
                    </div>
                </div>
                <div className="add-reservation-row">
                    <div className="add-reservation-column">
                        <label htmlFor="email-restau" className='label-restau'>Email</label>
                        <input type="text" id='email-restau' className='text-restau' />
                    </div>
                    <div className="add-reservation-column">
                        <label htmlFor="email-restau" className='label-restau'>Langue</label>
                        <input type="text" id='email-restau' className='text-restau' />
                    </div>
                    <div className="add-reservation-column">
                    </div>
                </div>
            </div>

            <div className='add-reservation-form'>
                <div className="add-reservation-row">
                    <div className="add-reservation-column">
                        <label htmlFor="email-restau" className='label-restau'>Date réservation</label>
                        <input type="text" id='email-restau' className='text-restau' />
                    </div>
                    <div className="add-reservation-column">
                        <label htmlFor="email-restau" className='label-restau'>Heure</label>
                        <input type="text" id='email-restau' className='text-restau' />
                    </div>
                    <div className="add-reservation-column">
                        <label htmlFor="email-restau" className='label-restau'>Menu</label>
                        <input type="text" id='email-restau' className='text-restau' />
                    </div>
                </div>
                <div className="add-reservation-row">
                    <div className="add-reservation-column">
                        <label htmlFor="email-restau" className='label-restau'>Nombre d’adultes</label>
                        <input type="text" id='email-restau' className='text-restau' />
                    </div>
                    <div className="add-reservation-column">
                        <label htmlFor="email-restau" className='label-restau'>Nombre d’enfants</label>
                        <input type="text" id='email-restau' className='text-restau' />
                    </div>
                    <div className="add-reservation-column">
                        <label htmlFor="email-restau" className='label-restau'>Occasion spéciale</label>
                        <input type="text" id='email-restau' className='text-restau' />
                    </div>
                </div>

                <div className="add-reservation-row">
                    <div className="add-reservation-column">
                        <label htmlFor="email-restau" className='label-restau'>Allergies</label>
                        <input type="text" id='email-restau' className='text-restau' />
                    </div>
                    <div className="add-reservation-column">
                        <label htmlFor="email-restau" className='label-restau'>Allergies groupe</label>
                        <input type="text" id='email-restau' className='text-restau' />
                    </div>
                    <div className="add-reservation-column">
                    </div>
                </div>

                <div style={{ marginTop: "10px" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <label htmlFor="email-restau" className='label-restau'>Préférence Restaurant</label>
                        <input type="text" id='email-restau' className='text-restau' />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <label htmlFor="email-restau" className='label-restau'>Demande spécial</label>
                        <textarea name="" id="" rows={3}></textarea>
                    </div>
                </div>
            </div>

            <div className='add-reservation-form' style={{ borderBottom: "none" }}>
                <div className="add-reservation-row">
                    <div className="add-reservation-column">
                        <label htmlFor="email-restau" className='label-restau'>Status</label>
                        <input type="text" id='email-restau' className='text-restau' />
                    </div>
                    <div className="add-reservation-column" style={{ width: "50%" }}>
                        <label htmlFor="email-restau" className='label-restau'>Nombre de visites</label>
                        <input type="text" id='email-restau' className='text-restau' />
                    </div>
                </div>
                <div className="add-reservation-row">
                    <div className="add-reservation-column" style={{ width: "100%" }}>
                        <label htmlFor="email-restau" className='label-restau'>Historique réservation restaurant</label>
                        <textarea name="" id="" rows={7}></textarea>
                    </div>
                </div>
                <div className="add-reservation-row">
                    <div className="add-reservation-column add-reservation-column-ckeck">
                        <input type="checkbox" name="" id="check1" />
                        <label htmlFor="check1" className='label-restau'>Envoyer l’email pour l’empreinte bancaire</label>
                    </div>
                </div>
                <div className="add-reservation-row">
                    <div className="add-reservation-column  add-reservation-column-ckeck">
                        <input type="checkbox" name="" id="check2" />
                        <label htmlFor="check2" className='label-restau'>Envoyer la confirmation</label>
                    </div>
                </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <button className='add-reservation-btn'>
                    Valider
                </button>
            </div>
        </div>
    );
}

export default AddReservation