import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/store";
import App from "./App";
import initializeApp from "./Initialize";
import { createTheme, ThemeProvider } from '@mui/material/styles';

initializeApp();

const theme = createTheme({
    palette: {
        primary: {
          main: '#0B4B92'
        },
        secondary: {
            main: '#95e2af'
        }
      }
});

ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
).render(
    <BrowserRouter>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </Provider>
    </BrowserRouter>
);
