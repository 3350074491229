import * as React from "react";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import {
  Button,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Created from "./created";
import axios from "axios";

const apiUrl = window.location.hostname === 'localhost' ? 'https://localhost:7142/api' : '/api';

interface NewEmployeeProps {
  onCancel: () => void;
}
export default function NewEmployee({ onCancel }: NewEmployeeProps) {
  const [departmentServices, setDepartmentServices] = React.useState([]);
  const [formData, setFormData] = React.useState({
    ContractType: "",
    ContractStartDate: null,
    ContractEndDate: null,
    Service: "",
    Responsabilite: "",
    Nom: "",
    Prenom: "",
    Poste: "",
    Email: "",
    NvDptId: 0,
  });
  const [id, setId] = React.useState(0);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  React.useEffect(() => {
    const fetchDepartmentServices = async () => {
      try {
        const response = await fetch(`${apiUrl}/NvDpt`);
        if (response.ok) {
          const services = await response.json();
          setDepartmentServices(services);
        } else {
          console.error("Failed to fetch department services");
        }
      } catch (error) {
        console.error("Error fetching department services", error);
      }
    };

    fetchDepartmentServices();
  }, []);
  const handleChange = (key: any) => (event: any) => {
    let value;

    if (event.target.type === "checkbox") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      let serviceId;
      try {
        const response = await axios.get(`${apiUrl}/NvDpt/${formData.Service}`);
        serviceId = parseInt(response.data.id, 10);
      } catch (error) {
        console.error("Error fetching serviceId:", error);

        serviceId = 0;
      }

      const updatedFormData = {
        ...formData,
        NvDptId: serviceId,
      };

      const postResponse = await fetch(`${apiUrl}/Employee`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedFormData),
      });

      if (postResponse.ok) {
        const createdEmployeeId = await postResponse.json();
        setId(createdEmployeeId);
        setIsSubmitted(true);
      } else {
        console.error("Error submitting data");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  return (
    <Stack direction="row" spacing={2}>
      <Paper
        variant="elevation"
        sx={{ width: 340, padding: 4 }}
        className="contract"
      >
        {isSubmitted ? (
          <Created id={id} />
        ) : (
          <div>
            <Typography sx={{ color: "#003671" }} fontWeight="bold">
              Ajouter un nouveau employé
            </Typography>
            <RadioGroup
              name="use-radio-group"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                marginY: 3,
              }}
              onChange={handleChange("ContractType")}
            >
              <FormControlLabel
                value="CDI"
                label="CDI"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "rgba(29, 201, 160, 1)",
                      },
                    }}
                  />
                }
                sx={{
                  "&.Mui-checked": {
                    backgroundColor: "rgba(29, 201, 160,1)",
                  },
                }}
              />
              <FormControlLabel
                value="CDD"
                label="CDD"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "rgba(29, 201, 160, 1)",
                      },
                    }}
                  />
                }
              />
            </RadioGroup>
            <Typography fontWeight="bold" marginBottom={2}>
              Date
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  type="date"
                  placeholder="Du"
                  id="nom-du-service"
                  variant="outlined"
                  fullWidth
                  value={formData.ContractStartDate}
                  onChange={handleChange("ContractStartDate")}
                />
              </Grid>
              {formData.ContractType === "CDD" && (
                <Grid item xs={6}>
                  <TextField
                    type="date"
                    placeholder="Au"
                    id="nom-du-service"
                    variant="outlined"
                    fullWidth
                    value={formData.ContractEndDate || ""}
                    onChange={handleChange("ContractEndDate")}
                  />
                </Grid>
              )}
            </Grid>
            <Typography fontWeight="bold" marginTop={5} marginBottom={2}>
              Services
            </Typography>
            <Select
              variant="outlined"
              fullWidth
              sx={{ backgroundColor: "#F4F4F4" }}
              value={formData.Service || ""}
              onChange={handleChange("Service")}
            >
              {departmentServices.map((service) => (
                <MenuItem key={service} value={service}>
                  {service}
                </MenuItem>
              ))}
            </Select>
            <Typography
              marginBottom={2}
              marginTop={5}
              fontWeight="bold"
              sx={{ color: "rgba(0,54,113,0.8)" }}
            >
              Responsabilité
            </Typography>

            <RadioGroup
              row
              aria-label="Responsabilité"
              name="Responsabilite"
              value={formData.Responsabilite}
              onChange={handleChange("Responsabilite")}
            >
              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                sx={{ backgroundColor: "#F0F4F8", width: "100%" }}
                alignItems="center"
                marginY={1}
                borderRadius={2}
              >
                <FormControlLabel
                  value="Cadre"
                  control={
                    <Radio
                      icon={<ToggleOffIcon style={{ fontSize: 40 }} />}
                      checkedIcon={<ToggleOnIcon style={{ fontSize: 40 }} />}
                      sx={{ marginRight: 2 }}
                    />
                  }
                  label={
                    <Typography fontWeight="bold" color="rgba(0,0,0,0.8)">
                      Cadre
                    </Typography>
                  }
                  labelPlacement="start"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                />
              </Stack>
              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                sx={{ backgroundColor: "#F0F4F8", width: "100%" }}
                alignItems="center"
                marginY={1}
                borderRadius={2}
              >
                <FormControlLabel
                  value="Chef de service"
                  control={
                    <Radio
                      icon={<ToggleOffIcon style={{ fontSize: 40 }} />}
                      checkedIcon={<ToggleOnIcon style={{ fontSize: 40 }} />}
                      sx={{ marginRight: 2 }}
                    />
                  }
                  label={
                    <Typography fontWeight="bold" color="rgba(0,0,0,0.8)">
                      Chef de service
                    </Typography>
                  }
                  labelPlacement="start"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                />
              </Stack>
              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                sx={{ backgroundColor: "#F0F4F8", width: "100%" }}
                alignItems="center"
                marginY={1}
                borderRadius={2}
              >
                <FormControlLabel
                  value="Collaborateur 2"
                  control={
                    <Radio
                      icon={<ToggleOffIcon style={{ fontSize: 40 }} />}
                      checkedIcon={<ToggleOnIcon style={{ fontSize: 40 }} />}
                      sx={{ marginRight: 2 }}
                    />
                  }
                  label={
                    <Typography fontWeight="bold" color="rgba(0,0,0,0.8)">
                      Collaborateur 2
                    </Typography>
                  }
                  labelPlacement="start"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                />
              </Stack>
              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                sx={{ backgroundColor: "#F0F4F8", width: "100%" }}
                alignItems="center"
                marginY={1}
                borderRadius={2}
              >
                <FormControlLabel
                  value="Collaborateur 1"
                  control={
                    <Radio
                      icon={<ToggleOffIcon style={{ fontSize: 40 }} />}
                      checkedIcon={<ToggleOnIcon style={{ fontSize: 40 }} />}
                      sx={{ marginRight: 2 }}
                    />
                  }
                  label={
                    <Typography fontWeight="bold" color="rgba(0,0,0,0.8)">
                      Collaborateur 1
                    </Typography>
                  }
                  labelPlacement="start"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                />
              </Stack>
            </RadioGroup>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <InputLabel
                  htmlFor="Nom"
                  sx={{ fontWeight: "bold", color: "black", marginY: 2 }}
                >
                  Nom
                </InputLabel>
                <TextField
                  id="Nom"
                  variant="outlined"
                  type="text"
                  fullWidth
                  onChange={handleChange("Nom")}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  htmlFor="Prenom"
                  sx={{ fontWeight: "bold", color: "black", marginY: 2 }}
                >
                  Prénom
                </InputLabel>
                <TextField
                  id="Prénom"
                  variant="outlined"
                  type="text"
                  fullWidth
                  onChange={handleChange("Prenom")}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  htmlFor="Poste"
                  sx={{ fontWeight: "bold", color: "black", marginBottom: 2 }}
                >
                  Nom du poste
                </InputLabel>
                <TextField
                  id="poste"
                  variant="outlined"
                  type="text"
                  fullWidth
                  onChange={handleChange("Poste")}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  htmlFor="Email"
                  sx={{ fontWeight: "bold", color: "black", marginBottom: 2 }}
                >
                  Email
                </InputLabel>
                <TextField
                  id="email"
                  variant="outlined"
                  type="email"
                  fullWidth
                  onChange={handleChange("Email")}
                />
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Button
                  variant="outlined"
                  sx={{
                    color: "black",
                    border: "1px solid rgb(0, 0, 0)",
                    fontWeight: "bold",
                    fontSize: "0.7rem",
                    paddingX: 5,
                    paddingY: 1,
                  }}
                  onClick={onCancel}
                >
                  Annuler
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    fontWeight: "bold",
                    fontSize: "0.7rem",
                    paddingX: 5,
                    paddingY: 1,
                    backgroundColor: "#0B4B92",
                  }}
                >
                  Valider
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </Paper>
    </Stack>
  );
}