import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export interface AppState {
  currentDate: number,
  countNotifications: number,
  alert: string,
}

const initialState: AppState = {
  currentDate: new Date(moment().format('YYYY-MM-DD')).getTime(),
  countNotifications: 3,
  alert: ''
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    incrementDate: (state): void => {
      const tomorow = new Date(state.currentDate);
      tomorow.setDate(tomorow.getDate() + 1);
      state.currentDate = tomorow.getTime();
    },
    decrementDate: (state) => {
      const yesterday = new Date(state.currentDate);
      yesterday.setDate(yesterday.getDate() - 1);
      state.currentDate = yesterday.getTime();
    },
    setDate: (state, data) => {
      state.currentDate = data.payload;
    },
    incrementNotifications: (state): void => {
      state.countNotifications++;
    },
    decrementNotifications: (state): void => {
      state.countNotifications++;
    },
    resetNotifications: (state): void => {
      state.countNotifications = 0;
    },
    setAlert: (state, data) => {
      state.alert = data.payload;
    },
  }
});

export const {
  incrementDate,
  decrementDate,
  setDate,

  incrementNotifications,
  decrementNotifications,
  resetNotifications,
  setAlert
} = appSlice.actions;

export default appSlice.reducer;