import React, { useEffect, useState } from "react";

import {
  MenuItem,
  Select,
  FormControl,
  Stack,
  Paper,
  Typography,
  TextField,
  Container,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Box,
} from "@mui/material";
import {
  format,
  eachMonthOfInterval,
  eachWeekOfInterval,
  getYear,
  eachDayOfInterval,
} from "date-fns";
import { fr } from "date-fns/locale";
import Filter from "../liste/filterEmployee";
import NewPlanning from "./new_planning";
const monthNames = [
  "janvier",
  "février",
  "mars",
  "avril",
  "mai",
  "juin",
  "juillet",
  "août",
  "septembre",
  "octobre",
  "novembre",
  "décembre",
];
const apiUrl =
  window.location.hostname === "localhost"
    ? "https://localhost:7142/api"
    : "/api";
const DateDisplay = ({ date }: any) => {
  const formattedDate = format(date, "dd/MM/Y", { locale: fr });
  const dayOfWeek = format(date, "EEEE", { locale: fr });
  return (
    <Typography width={183} mx={1}>
      {dayOfWeek} {formattedDate}
    </Typography>
  );
};
const DateSelector = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const employeesPerPage = 10;
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPage));
  };
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedWeek, setSelectedWeek] = useState(Number);
  const [index, setIndex] = useState<number | null>(null);
  const [selectedYear, setSelectedYear] = useState(2024);
  const [weeks, setWeeks] = useState<Date[]>([]);
  const [selectedWeekDays, setSelectedWeekDays] = useState<Date[]>([]);
  const [employees, setEmployees] = React.useState([]);
  const [selectedDpts, setSelectedDpts] = React.useState<String[]>([]);
  const [responsabilité, setResponsabilite] = React.useState<String[]>([]);
  const [fromdate, setFromdate] = React.useState(null);
  const [todate, setTodate] = React.useState(null);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (employeeId: any) => {
    setSelectedEmployeeId(employeeId);
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen(false);
  };
  const [selectedEmployeeId, setSelectedEmployeeId] = React.useState(null);

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleButtonClick = () => {
    setOpenDialog(!openDialog);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  React.useEffect(() => {
    const fetchEmployees = async () => {
      try {
        let url = `${apiUrl}/Employee`;

        const filters = [];
        if (selectedDpts.length > 0) {
          const selectedDptFilter = selectedDpts.map(
            (dpt) => `dpt=${encodeURIComponent(String(dpt))}`
          );
          filters.push(...selectedDptFilter);
        }
        if (responsabilité.length > 0) {
          const responsabilitéFilter = responsabilité.map(
            (respo) => `respo=${encodeURIComponent(String(respo))}`
          );
          filters.push(...responsabilitéFilter);
        }

        if (fromdate) {
          filters.push(`fromdate=${encodeURIComponent(fromdate)}`);
        }
        if (todate) {
          filters.push(`todate=${encodeURIComponent(todate)}`);
        }

        if (filters.length > 0) {
          url += `?${filters.join("&")}`;
        }

        const response = await fetch(url);
        const data = await response.json();

        setEmployees(data);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, [selectedDpts, responsabilité, fromdate, todate]);
  const months = eachMonthOfInterval({
    start: new Date(selectedYear, 0, 1),
    end: new Date(selectedYear, 11, 31),
  });
  useEffect(() => {
    if (selectedMonth !== "") {
      const monthIndex = monthNames.indexOf(selectedMonth);
      const lastDayOfMonth = new Date(
        selectedYear,
        monthIndex + 1,
        0
      ).getDate();

      const newWeeks = eachWeekOfInterval(
        {
          start: new Date(selectedYear, monthIndex, 1),
          end: new Date(selectedYear, monthIndex, lastDayOfMonth),
        },
        { locale: fr }
      );
      const startingDates = newWeeks.map((weekStart) =>
        format(weekStart, "yyyy-MM-dd")
      );

      if (index !== null) {
        const startingDate =
          startingDates.length > 0 ? startingDates[index] : null;
        if (startingDate) {
          const startDateObject = new Date(startingDate);
          const endOfWeek = new Date(startDateObject);
          endOfWeek.setDate(endOfWeek.getDate() + 6);
          const selectedWeekDays = eachDayOfInterval({
            start: startDateObject,
            end: endOfWeek,
          });
          setSelectedWeekDays(selectedWeekDays);
        }
      }
      setWeeks(newWeeks);
    }
  }, [selectedMonth, selectedYear, selectedWeek, index]);

  const years = [
    getYear(new Date()),
    getYear(new Date()) + 1,
    getYear(new Date()) + 2,
  ];

  const handleMonthChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedMonth(event.target.value);
  };

  const handleWeekChange = (event: any) => {
    const selectedValue = event.target.value as any;
    const selectedIndex = weeks.findIndex(
      (week) => format(week, "w", { locale: fr }) === selectedValue
    );

    setSelectedWeek(selectedValue);
    setIndex(selectedIndex);
  };

  const handleYearChange = (event: {
    target: { value: React.SetStateAction<any> };
  }) => {
    setSelectedYear(event.target.value);
  };
  const countResponsabilite = (date: Date) => {
    const formattedDate = convertDateToISOString(date, 1);

    const employeesOnSelectedDate = employees.filter((employee: any) => {
      return employee.plannings.some(
        (planning: any) => planning.date === formattedDate
      );
    });

    const cadreCount = employeesOnSelectedDate.reduce(
      (count, employee: any) => {
        return employee.responsabilite === "cadre" ? count + 1 : count;
      },
      0
    );

    const nonCadreCount = employeesOnSelectedDate.reduce(
      (count, employee: any) => {
        return employee.responsabilite !== "cadre" ? count + 1 : count;
      },
      0
    );
    const offCount = employeesOnSelectedDate.reduce((count, employee: any) => {
      return employee.plannings.some(
        (planning: any) =>
          planning.date === formattedDate && planning.status === "OFF"
      )
        ? count + 1
        : count;
    }, 0);

    return { cadreCount, nonCadreCount, offCount };
  };
  const convertDateToISOString = (date: Date, daysToAdd: number = 0) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + daysToAdd);

    const isoString = newDate.toISOString();
    return isoString.slice(0, 10) + "T00:00:00";
  };
  const startIndex = (currentPage - 1) * employeesPerPage;
  const endIndex = startIndex + employeesPerPage;
  const paginatedEmployees = employees.slice(startIndex, endIndex);
  const totalPage = Math.ceil(employees.length / employeesPerPage);

  return (
    <Container maxWidth="xl" sx={{ padding: 5 }}>
      <Paper sx={{ padding: 2, width: "1450px" }}>
        <Grid container justifyContent="space-between" mb={6}>
          <Grid item>
            <FormControl>
              <Select
                value={selectedMonth}
                onChange={handleMonthChange}
                sx={{ backgroundColor: "#F4F4F4", minWidth: 150 }}
              >
                {months.map((month, index) => (
                  <MenuItem
                    key={index}
                    value={format(month, "MMMM", { locale: fr })}
                  >
                    {format(month, "MMMM", { locale: fr })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <Select
                sx={{ backgroundColor: "#F4F4F4", minWidth: 150 }}
                value={selectedWeek}
                onChange={handleWeekChange}
              >
                {weeks.map((week, index) => (
                  <MenuItem
                    key={index}
                    value={format(week, "w", { locale: fr })}
                  >
                    {format(week, "w", { locale: fr }) + " Semaine"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <Select
                value={selectedYear}
                onChange={handleYearChange}
                sx={{ backgroundColor: "#F4F4F4", minWidth: 150 }}
              >
                {years.map((year, index) => (
                  <MenuItem key={index} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item flexDirection="row" display="flex" alignItems="center">
            <Typography mx={1}>Date</Typography>
            {selectedWeekDays.length > 0 ? (
              <>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={format(selectedWeekDays[0], "dd/MM/yyyy")}
                />
                <TextField
                  variant="outlined"
                  fullWidth
                  value={format(
                    selectedWeekDays[selectedWeekDays.length - 1],
                    "dd/MM/yyyy"
                  )}
                />
              </>
            ) : (
              <>
                <TextField variant="outlined" fullWidth value="" />
                <TextField variant="outlined" fullWidth value="" />
              </>
            )}
          </Grid>
        </Grid>
        {/* <Button onClick={handleButtonClick}>
          <Typography
            sx={{
              textTransform: "none",
              color: "rgba(0,0,0,0.4)",
              border: "1px solid rgba(0,0,0,0.4)",
            }}
            padding={1}
            borderRadius={1}
          >
            Filtres - Trie
          </Typography>
        </Button>
        <Dialog open={openDialog} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogContent>
            <Filter
              setSelectedValue={setSelectedValue}
              selectedValue={selectedValue}
              selectedDpts={selectedDpts}
              setSelectedDpts={setSelectedDpts}
              responsabilité={responsabilité}
              setResponsabilite={setResponsabilite}
              fromdate={fromdate}
              setFromdate={setFromdate}
              todate={todate}
              setTodate={setTodate}
            />
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center", marginBottom: 2 }}>
            <Button onClick={handleClose} color="primary">
              <Typography
                sx={{
                  backgroundColor: "#0B4B92",
                  color: "white",
                  textTransform: "none",
                }}
                paddingY={1}
                paddingX={2}
              >
                Recherche
              </Typography>
            </Button>
          </DialogActions>
        </Dialog> */}
        <Stack
          direction="row"
          sx={{ marginLeft: "148px", backgroundColor: "#cccccc" }}
          width={1281}
        >
          {selectedWeekDays.map((date, index) => (
            <DateDisplay key={index} date={date} />
          ))}
        </Stack>
        <Box minHeight={650}>
          {paginatedEmployees.map((employee: any) => (
            <Stack direction="row" my={1}>
              <Stack>
                <Typography
                  sx={{ backgroundColor: employee.couleurDuService }}
                  py={2}
                  px={3}
                  width={100}
                  height={26}
                >
                  {employee.nom}
                </Typography>
              </Stack>
              <Stack display="flex" flexDirection="row">
                {selectedWeekDays.map((date) => {
                  const filteredPlannings = employee.plannings.filter(
                    (planning: any) => {
                      const planningDate = new Date(planning.date);
                      return (
                        format(planningDate, "dd/MM/Y") ===
                        format(date, "dd/MM/Y")
                      );
                    }
                  );
                  return (
                    <>
                      <React.Fragment key={date.getTime()}>
                        {filteredPlannings.map((filteredPlanning: any) => (
                          <>
                            <Typography
                              sx={{ backgroundColor: filteredPlanning.color }}
                              py={1}
                              px={3}
                              width={135}
                              height={26}
                              textAlign='center'
                            >
                              {filteredPlanning.status
                                ? // Case 1: Display {filteredPlanning.status}
                                  filteredPlanning.status
                                : filteredPlanning.startTime_Un
                                ? // Case 2: Display {filteredPlanning.startTime_Un}-{filteredPlanning.endTime_Un}
                                  `${filteredPlanning.startTime_Un}-${filteredPlanning.endTime_Un}`
                                : // Case 3: Display {filteredPlanning.startTime_Deux}-{filteredPlanning.endTime_Deux}
                                  `${filteredPlanning.startTime_Deux}-${filteredPlanning.endTime_Deux}`}
                            </Typography>
                          </>
                        ))}
                      </React.Fragment>
                      {filteredPlannings.length === 0 && (
                        <>
                          <Button
                            sx={{
                              py: 2,
                              px: 3,
                              width: 183,
                            }}
                            variant="outlined"
                            onClick={() => handleClickOpen(employee.id)}
                          >
                            +
                          </Button>
                        </>
                      )}
                    </>
                  );
                })}
                <Dialog fullWidth onClose={handleClose1} open={open}>
                  {" "}
                  <DialogContent>
                    <NewPlanning employeeId={selectedEmployeeId} />
                  </DialogContent>
                </Dialog>
              </Stack>
            </Stack>
          ))}
        </Box>
        <Stack display="flex" flexDirection="row" paddingLeft="148px">
          {selectedWeekDays.map((date, index) => {
            const { cadreCount, nonCadreCount, offCount } = countResponsabilite(
              new Date(date)
            );

            return (
              <Box key={index} sx={{ border: "1px solid #CCCCCC" }} py={2}>
                <Typography
                  variant="body1"
                  sx={{ width: "181px!important" }}
                  textAlign="center"
                >
                  {cadreCount} Cadre
                  <br />
                  {nonCadreCount} Employé
                  <br />
                  {offCount} OFF
                </Typography>
              </Box>
            );
          })}
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <div>
            <Button disabled={currentPage === 1} onClick={handlePrevPage}>
              Previous
            </Button>
            <Button
              disabled={currentPage === totalPage}
              onClick={handleNextPage}
            >
              Next
            </Button>
          </div>
          <Typography>{`Page ${currentPage} / ${totalPage}`}</Typography>
        </Stack>
      </Paper>
    </Container>
  );
};

export default DateSelector;
