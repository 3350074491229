import './GnzPaper.css';

interface IGnzPaper {
  component: JSX.Element,
  marginBottom?: boolean,
  alignItems?: string
}

function GnzPaper(props: IGnzPaper) {
  return (
    <div className="GnzPaper" style={{
      marginBottom: props.marginBottom ? 20 : 0,
      alignItems: props.alignItems ? props.alignItems : 'center'
    }}>
      {props.component}
    </div>
  );
}

export default GnzPaper;
