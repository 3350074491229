import Modal from '@mui/material/Modal';
import './modalRestau.css';
import { useState } from 'react';
// import PolitiqueAnnulationForm from './politiqueAnnulationForm';
import { PolitiqueAnnulation as PolitiqueAnnulationType } from '../types/types';

interface PolitiqueAnnulationProps {
    onClose: () => void;
    initialData?: any;
    onUpdate?: (data: any) => void;
}

const PolitiqueAnnulation: React.FC<PolitiqueAnnulationProps> = ({ initialData, onUpdate, onClose }) => {

    const [localData, setLocalData] = useState<PolitiqueAnnulationType>({
        id: initialData?.id || 0,
        restaurantId: initialData?.restaurantId || 0,
        conditionAnnulation: initialData?.conditionAnnulation || '',
    });

    const handleSave = () => {
        onUpdate?.(localData);
        onClose();
    };

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setLocalData((prevData: PolitiqueAnnulationType) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // const [showPolitiqueFormModal, setPolitiqueFormModal] = useState(false);
    // const openPolitiqueFormModal = () => { setPolitiqueFormModal(true); };
    // const closePolitiqueFormModal = () => { setPolitiqueFormModal(false); };

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='modal-restau'>
                <h1>Politique d'annulation</h1>
                <button
                    className='modal-close-btn'
                    onClick={onClose}
                >
                    x
                </button>
                <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div>
                        <h2>Condition d'annulation</h2>
                        <h3>Veuillez saisir vos condition d'annulation :</h3>
                        <textarea
                            name="conditionAnnulation"
                            rows={2}
                            value={localData.conditionAnnulation}
                            onChange={handleChange}
                        ></textarea>
                    </div>

                    <button className='restau-btn restau-modal-btn' onClick={handleSave}>
                        VALIDER
                    </button>
                </div>

                {/* {
                    showPolitiqueFormModal &&
                    <PolitiqueAnnulationForm onClose={closePolitiqueFormModal} />
                } */}
            </div>
        </Modal>
    );
}

export default PolitiqueAnnulation;