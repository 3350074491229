import './calendar.css';
import DayStatus from './dayStatus';
import { useState } from 'react';
import TableTimeSlot from './tableTimeSlot';
// import HistoryIcon from '@mui/icons-material/History';
import { timeSlotsData } from '../../temp/data';

interface DayProps {
    day: number;
    status: string;
    tablesRemaining: number;
}

const Day: React.FC<DayProps> = ({ day, status, tablesRemaining }) => {

    const [showTableTimeSlotModal, setTableTimeSlotModal] = useState(false);

    const openTableTimeSlotModal = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setTableTimeSlotModal(true);
    };
    const closeTableTimeSlotModal = () => {
        setTableTimeSlotModal(false);
    };

    const displayText = status === 'available'
        ? (tablesRemaining <= 10
            ? `${tablesRemaining.toString()} Tables restantes`
            : 'Tables restantes')
        : status;

    const dayTimeSlotsData = {
        day,
        timeSlots: timeSlotsData
    };

    const [showDayStatusModal, setDayStatusModal] = useState(false);
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

    const openDayStatusModal = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        const position = {
            top: e.pageY,
            left: e.pageX
        };
        setModalPosition(position);
        setDayStatusModal(true);
    };
    const closeDayStatusModal = () => {
        setDayStatusModal(false);
    };

    return (
        <div className={`restau-calendar-day restau-calendar-day-status-${status}`}>
            <span className={`restau-calendar-day-span restau-calendar-day-span-${status}`} onClick={openDayStatusModal} ></span>
            <h1>{day.toString()}</h1>
            <span onClick={openTableTimeSlotModal}>{displayText}</span>

            {showDayStatusModal && <DayStatus onClose={closeDayStatusModal} position={modalPosition} />}

            {showTableTimeSlotModal && (
                <TableTimeSlot open={showTableTimeSlotModal} closeModal={closeTableTimeSlotModal} dayData={dayTimeSlotsData} />
            )}
        </div>
    );
}

export default Day;