import ClienteHeader from '../../shared/ClienteHeader/ClienteHeader';
import ProfilClientsDetailsTable from './ProfilClientsDetailsTable/ProfilClientsDetailsTable';
import './ProfilClientsDetails.css';

function ProfilClientsDetails() { 
  return (
    <div className="">
      <ClienteHeader src={""} title="Fiche Client" />
      <ProfilClientsDetailsTable/>
    </div>
  );
}
export default ProfilClientsDetails;