import { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import Tag from '../../../../models/Tag';
import Allergies from '../../../../models/Allergies';
import FoodPreferences from '../../../../models/Food_Preferences';
import axios from 'axios';
import './ProfilClientsDetailsForm.css';
import AllergiesCustomer from '../../../../models/AllergiesCustomer';
import FoodPreferencesCustomer from '../../../../models/FoodPreferencesCustomer';

interface IProfilClientsTagsForm { 
  myParam: string,
  allergiesCustomer: Array<AllergiesCustomer>,
  foodPreferencesCustomer: Array<FoodPreferencesCustomer>,
  closeForm: () => void,
}

function ProfilClientsTagsForm(props: IProfilClientsTagsForm) {  
  const [Tag, tag] = useState<Array<Tag>>([]);
  const [Allergies, allergies] = useState<Array<Allergies>>([]);
  const [FoodPreferences, foodPreferences] = useState<Array<FoodPreferences>>([]);
  const Url = 'https://www.mysharingsystem.com/api/';
   
  useEffect(() => {
    axios.get(Url + 'Tag')
      .then(res => tag(res.data));
  }, [])

  useEffect(() => {
    axios.get(Url + 'Allergy')
      .then(res => allergies(res.data));
  }, [])

  useEffect(() => {
    axios.get(Url + 'FoodPref')
      .then(res => foodPreferences(res.data));
  }, [])

  const handleChange = (event:any) => {     
    axios.post(Url + 'CustomerTag',{ 
      "CustomerId": props.myParam,
      "IdTag":event.target.value
    })
      .then();        
}; 

const handleChangeAllergiesCustomer = (event:any) => {     
  axios.post(Url + 'AllergiesCustomer',{ 
    "CustomerId": props.myParam,
    "allergiesId":event.target.value
  })
    .then();        
}; 

const handleChangeFoodPreferenceCustomer = (event:any) => {     
  axios.post(Url + 'FoodPreferenceCustomer',{ 
    "CustomerId": props.myParam,
    "food_PreferencesId":event.target.value
  })
    .then();        
}; 

  return (
    <div className="FeedbackForm">         
      <div className="moreContent">     
        <h1>Allergies</h1>
              {Allergies.map(r => (
              <p><Checkbox value={r.id} onChange={handleChangeAllergiesCustomer}  defaultChecked={ props.allergiesCustomer.some(item => r.id === item.allergiesId)}/> 
              {r.displayAs} {/* <img className="TagLogo" src={`data:image/jpeg;base64,${r.tagImage}`} alt="" />     */}
              </p>
            ))}

        <h1>FoodPreferences</h1>
              {FoodPreferences.map(r => (
              <p><Checkbox value={r.id} onChange={handleChangeFoodPreferenceCustomer}  defaultChecked={ props.foodPreferencesCustomer.some(item => r.id === item.food_PreferencesId)}/> 
              {/* <img className="TagLogo" src={`data:image/jpeg;base64,${r.tagImage}`} alt="" />     */}
              {r.displayAs} 
              </p>
            ))}
      </div>         

      <div className="PrimaryHeader">
        <div className="PrimaryHeaderSeparator"></div>
      </div>

      <div className="ProfilClientFormActions">
        <button className='ProfilClientButtonTout' onClick={props.closeForm}>Cancel</button>       
        <button onClick={props.closeForm}>Save</button>
      </div>
    </div>
  );
}

export default ProfilClientsTagsForm;
