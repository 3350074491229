import { useState } from 'react'
import { Stack, Card, CardContent, Grid, Button, TextField} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react'; 
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import './Provider.css';

const apiUrl = window.location.hostname === 'localhost' ? 'https://localhost:7142/api' : '/api';

const SharingButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  width: '259px',
  height: '50px',
  fontSize: 16,
  fontWeight:'bold',
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: '#1D62AF',
  borderColor: '#1D62AF',
  fontFamily: 'Montserrat',
  '&:hover': {
    backgroundColor: '#0069d9',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});


interface UpdateProviderProps {
  onCloseDrawerUpdate: () => void;
  providerId:any;
}
  
const UpdateProvider: React.FC<UpdateProviderProps> = ({ onCloseDrawerUpdate,providerId }) => {

const [formData, setFormData] = useState({ 
  ProviderID:'',
  Name: '',
  Contact: '',
  PhoneNumber: '',
  Email: '',
  StartHighSeason:'',
  EndHighSeason:'',
  HighSeasonCharge:'',
  HStartNight:'',
  HEndNight:'',
  NightRate:'',
  AdditionalInformation:'',
  CancelationPolicy:'',  
  DisposalCharge:'',
  Deleted:0 
});
const handleInputChange=(event:any) =>{
  const { name, value } = event.target;
  setFormData({ ...formData, [name]: value });
}

const [counterTrip, setCounterTrip] = useState(0);
const [counterDisposal, setCounterDisposal] = useState(0);
const [stateTrip, setStateTrip] = useState({ statut:true,})
const [stateDeleteTrip, setstateDeleteTrip] = useState({ statut:true,})
const [stateDisposal, setStateDisposal] = useState({ statut:true,})
const [stateDeleteDisposal, setStateDeleteDisposal] = useState({ statut:true,})


// Dynamic inputs for Trip 
const [promptsTrip, setpromptsTrip] = useState([{
  Departure:null,
  Arrival: null,
  Car1:null,
  Car2:null,
  Car3:null,
  Price1:null,
  Price2:null,
  Price3:null,
  CurrentProviderId:null,
  timestampTrip:new Date().getTime(),
}])

const handlePromptTrip = ( event:any , i:any) => {
  //une fois le trajet saisi, on active le bouton ajouter et supprimer un trajet
  if ( (promptsTrip[i].Departure &&  promptsTrip[i].Arrival  && promptsTrip[i].Price1 && promptsTrip[i].Price2 && promptsTrip[i].Price3) != null ) {
      stateTrip.statut= false
      stateDeleteTrip.statut= false
  }
  const{name, value} = event.target
  let newpromptsTrip = [... promptsTrip];
  (newpromptsTrip[i] as any)[name] = value;
  setpromptsTrip(newpromptsTrip);
  
} 

const  handleAddPromptTrip=() => {
  //Arrival la création d'un nouveau chemin vide, le bouton ajouté est desactivé
  stateTrip.statut= true

  setpromptsTrip([...promptsTrip,{
    Departure:null,
    Arrival: null,
    Car1:null,
    Car2:null,
    Car3:null,
    Price1:null,
    Price2:null,
    Price3:null,
    CurrentProviderId:null,
    timestampTrip:new Date().getTime()
  }])
  setCounterTrip(counterTrip => counterTrip + 1);
}

const HandleDeleteTrip = async (i:any) =>{
  console.log("delete trip: ",i, 'trip id: ',previousTrips[i].tripID)
  console.log('trip: ',previousTrips[i])
  try {
    await axios.put(`${apiUrl}/Trip/UpdateTrip/${previousTrips[i].tripID}`, {
      Deleted:1
    },{ headers: { "Content-Type": "application/json" }});
  }catch(error){console.error("Error submitting form delete trip:", error);}
  window.location.reload();
}

// Dynamic inputs for Disposition 
const [promptsDisposition, setpromptsDisposition] = useState ([{
  Departure:null,
  Arrival:null,
  NumberOfHours:null,
  Car1:null,
  Car2:null,
  Car3:null,
  Price1:null,
  Price2:null,
  Price3:null,
  CurrentProviderId:null,
  timestampDisposition: new Date().getTime()
}])

const handlePromptDisposition =(event:any, i:any) => {
  console.log('here',stateDisposal.statut)
   //once Disposition has been entered, activate the add button
   if ( (promptsDisposition[i].Departure &&  promptsDisposition[i].Arrival  
    && promptsDisposition[i].Price1 && promptsDisposition[i].Price2 && promptsDisposition[i].Price3 
    && promptsDisposition[i].NumberOfHours ) != null ) {
    stateDisposal.statut= false
    stateDeleteDisposal.statut= false
    }

  const {name, value}=event.target 
  let newpromptsDisposition = [... promptsDisposition];
 ( newpromptsDisposition[i] as any)[name]=value;
  setpromptsDisposition(newpromptsDisposition);
}

const handleAddPromptDisposition =() =>{
  // when a new empty Disposition is created, the added button is deactivated
  stateDisposal.statut= true
  setpromptsDisposition([...promptsDisposition,{
    Departure:null,
    Arrival:null,
    NumberOfHours:null,
    Car1:null,
    Car2:null,
    Car3:null,
    Price1:null,
    Price2:null,
    Price3:null,
    CurrentProviderId:null,
    timestampDisposition: new Date().getTime()
  }])
  setCounterDisposal(counterDisposal => counterDisposal + 1);
  
  }
const handleDeleteDisposition = async (i:any) =>{
   try {
     await axios.put(`${apiUrl}/Disposal/UpdateDisposal/${previousDisposal[i].disposalID}`, {
       Deleted:1
     },{ headers: { "Content-Type": "application/json" }});
   }catch(error){console.error("Error submitting form delete disposal:", error);}
   window.location.reload();  
}

//go back
const navigate = useNavigate();

//update old provider
const [previousData, setPreviousData] = useState<any[]>([]); 

//update old trips
const [previousTrips, setpreviousTrips] = useState([{
  car1:'',
  car2:'',
  car3:'',
  departure:'',
  arrival:'',
  tripID:'',
  price1:'',
  price2:'',
  price3:'',
}]);
const handlePreviousTrip = ( event:any , i:any) => {
const{name, value} = event.target
let newpromptsTrip = [... previousTrips];
(newpromptsTrip[i] as any)[name] = value;
setpreviousTrips(newpromptsTrip); 
} 

//update old Disposal
const [previousDisposal, setpreviousDisposal] = useState([{
  departure:'',
  arrival:'',
  car1:'',
  car2:'',
  car3:'',
  currentProviderId:'',
  numberOfHours:'',
  price1:'',
  price2:'',
  price3:'',
  disposalID:''
}]);
const handlePreviousDisposal = ( event:any , i:any) => {
  const{name, value} = event.target
  let newpromptsDisposal = [... previousDisposal];
  (newpromptsDisposal[i] as any)[name] = value;
  setpreviousDisposal(newpromptsDisposal); 
  } 

//get id for current provider
const {id}=useParams() 
//display old date
useEffect(() => {
    getData();
  }, []);
const getData = async () => {
try {
  await axios.get(`${apiUrl}/Provider/GetProvider/`+providerId,{ headers: { "Content-Type": "application/json" } } )
  .then(response => {
    console.log("response.data") 
    console.log(response.data)
    //Data to display in form :
    setPreviousData(response.data); 
    //Get data from Provider table
    formData.ProviderID=providerId ?? '',
    formData.Name=response.data[0].name
    formData.Contact=response.data[0].contact,
    formData.PhoneNumber=response.data[0].phoneNumber,
    formData.Email=response.data[0].email,
    formData.StartHighSeason=response.data[0].startHighSeason,
    formData.EndHighSeason=response.data[0].endHighSeason,
    formData.HighSeasonCharge=response.data[0].highSeasonCharge,
    formData.HStartNight=response.data[0].hStartNight,
    formData.HEndNight=response.data[0].hEndNight,
    formData.NightRate=response.data[0].nightRate,
    formData.AdditionalInformation=response.data[0].additionalInformation,
    formData.CancelationPolicy=response.data[0].cancelationPolicy,
    formData.DisposalCharge=response.data[0].disposalCharge
    // Store previous trips
    setpreviousTrips(response.data[0].trips); 
    //Store previsous Disposals
    setpreviousDisposal(response.data[0].disposals);
    });
} catch (error) {
  console.error("Error submitting form Add Provider:", error);
}   
}

// Submit an update: 
const onSubmit = async () => {
try {
  //Put provider
  await axios.put(`${apiUrl}/Provider/UpdateProvider`, formData, { headers: { "Content-Type": "application/json" }});
  //Put trip(s)
  //Case 1: there is at minimum one trip to update it
  if (previousTrips.length > 0){
    for (var i=0; i <= previousTrips.length-1; i++){
      await axios.put(`${apiUrl}/Trip/UpdateTrip`, {
        Departure:previousTrips[i].departure,
        Arrival: previousTrips[i].arrival,
        Car1:previousTrips[0].car1,
        Car2:previousTrips[0].car2,
        Car3:previousTrips[0].car3,
        Price1:previousTrips[i].price1,
        Price2:previousTrips[i].price2,
        Price3:previousTrips[i].price3,
        CurrentProviderId:providerId,
        TripID:previousTrips[i].tripID,
        Deleted:0
      },{ headers: { "Content-Type": "application/json" }});
    }
  }
  //Case 2: We add new trip(s)
  for (var i=0; i < counterTrip+1; i++){
    if ( promptsTrip[i].Price1 && promptsTrip[i].Price2 && promptsTrip[i].Price3 && promptsTrip[i].Departure && promptsTrip[i].Arrival) {
      await axios.post(`${apiUrl}/Trip/AddTrip`, {
      Departure: promptsTrip[i].Departure,
      Arrival: promptsTrip[i].Arrival,
      Car1: previousTrips[0].car1,
      Car2: previousTrips[0].car2,
      Car3: previousTrips[0].car3,
      Price1: promptsTrip[i].Price1,
      Price2:promptsTrip[i].Price2,
      Price3: promptsTrip[i].Price3,
      CurrentProviderId:providerId,
      Deleted:0
    },{ headers: { "Content-Type": "application/json" }}); 
    }
  }

  //put Disposal(s)
  //Case 1: there is at minimum one Disposal to update it
  if (previousDisposal.length > 0){
    for (var i=0; i <= previousDisposal.length-1; i++){
      await axios.put(`${apiUrl}/Disposal/UpdateDisposal`, {
        Departure:previousDisposal[i].departure,
        Arrival: previousDisposal[i].arrival,
        NumberOfHours: previousDisposal[i].numberOfHours,
        Price1: previousDisposal[i].price1,
        Price2: previousDisposal[i].price2,
        Price3: previousDisposal[i].price3,
        Car1: previousTrips[0].car1,
        Car2: previousTrips[0].car2,
        Car3: previousTrips[0].car3,
        CurrentProviderId:providerId,
        DisposalID:previousDisposal[i].disposalID,
        Deleted:0
      },{ headers: { "Content-Type": "application/json" }});
    }
  }
  //Case 2: We add new Disposal(s)
  for (var i=0; i < counterDisposal+1; i++){
    if ( promptsDisposition[i].Price1 && promptsDisposition[i].Price2 && promptsDisposition[i].Price3 && promptsDisposition[i].NumberOfHours && formData.DisposalCharge) {
      await axios.post(`${apiUrl}/Disposal/AddDisposal`, {
        Departure:promptsDisposition[i].Departure,
        Arrival: promptsDisposition[i].Arrival,
        NumberOfHours: promptsDisposition[i].NumberOfHours,
        Price1: promptsDisposition[i].Price1,
        Price2: promptsDisposition[i].Price2,
        Price3: promptsDisposition[i].Price3,
        Car1: previousTrips[0].car1,
        Car2: previousTrips[0].car2,
        Car3: previousTrips[0].car3,
        CurrentProviderId:providerId,
        Deleted:0
    },{ headers: { "Content-Type": "application/json" }}); 
    }
  }
} catch (error) {
console.error("Error submitting form Update Provider:", error);
}  

//go back
onCloseDrawerUpdate();
window.location.reload(); // Actualise la page

}

//form validation
const { handleSubmit } = useForm();

return (
<>
{previousData!.map((item:any,i:any) => (  

   <Card style={{ maxWidth: 450, padding: "20px 5px", margin: "0 auto" , overflowY: 'auto', flexGrow: 1}} key={i}>
  <label className='titleCard' style={{fontWeight:'bold'}}> &nbsp;&nbsp;Modifier prestataire </label>
    <CardContent>  
      <form onSubmit={handleSubmit(onSubmit)} >
          {/* Green Card */}
          <Card style={{backgroundColor: '#a0e29533', padding: 8,margin: 4}} variant="outlined" >
            <Stack  spacing={2} direction="row" sx={{marginBottom: 1}}>
              <Grid item xs={6} >
                <label className='FontLabel'  >Compagnie</label>
                <TextField required size='small' name="Name" defaultValue={item.name} onChange={handleInputChange} type="text" placeholder=""  className='StyleInput' />  
              </Grid>
              <Grid item xs={6}>
                <label className='FontLabel'>Contact</label>
                <TextField  required size='small' name="Contact" defaultValue={item.contact}  onChange={handleInputChange} type="text" placeholder="" className='StyleInput' />
              </Grid>
            </Stack > 
            <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
              <Grid item xs={6} >
                <label className='FontLabel'>Téléphone</label >
                <TextField required size='small'name="PhoneNumber" defaultValue={item.phoneNumber} onChange={handleInputChange} type="tel" placeholder=""  className='StyleInput'/>  
              </Grid>
              <Grid item xs={6}>
                <label className='FontLabel'>Email</label>
                <TextField required size='small'name="Email" defaultValue={item.email} onChange={handleInputChange} type="email" placeholder="" className='StyleInput'  />
              </Grid>
            </Stack> 
          </Card>

        {/* Blue Card */}
        <Card style={{backgroundColor: '#1d62af1a', padding: 8,margin: 4}} variant="outlined">
        <Grid className='SubTitleCard' >Trajets</Grid>
        <Grid className='line'></Grid>

        {/* Display Cars */}
        {/* {previousData[0].trips.slice(0,1).map((car:any,i:any) => (   */}
        {previousData[0].trips.slice(0,1).map((car:any,i:any) => (    
        <Stack spacing={2} direction="row" sx={{marginBottom: 1}} key={i}>
          <Grid item xs={4}>
            <label className='FontLabel'>Véhicule 1</label> 
            <TextField size='small' name="car1" defaultValue={car.car1} onChange={event=>handlePreviousTrip(event,i)} type="text" placeholder="" className="StyleInput" required={(previousTrips[i].arrival || previousTrips[i].departure || previousTrips[i].price1 || previousTrips[i].car2 || previousTrips[i].price2 || previousTrips[i].car3 || previousTrips[i].price3 || promptsDisposition[i].Price1 || promptsDisposition[i].Price2 || promptsDisposition[i].Price3 || promptsDisposition[i].NumberOfHours || formData.DisposalCharge) !=null}/>
          </Grid>
          <Grid item xs={4} >
            <label className='FontLabel'>Véhicule 2</label> 
            <TextField size='small' name="car2" defaultValue={car.car2} onChange={event=>handlePreviousTrip(event,i)} type="text" placeholder="" className="StyleInput"  required={(previousTrips[i].arrival || previousTrips[i].departure || previousTrips[i].car1 || previousTrips[i].price1  || previousTrips[i].price2 || previousTrips[i].car3 || previousTrips[i].price3 || promptsDisposition[i].Price1 || promptsDisposition[i].Price2 || promptsDisposition[i].Price3 || promptsDisposition[i].NumberOfHours || formData.DisposalCharge) !=null}/>
          </Grid>
          <Grid item xs={4} >
            <label className='FontLabel'>Véhicule 3</label> 
            <TextField size='small' name="car3" defaultValue={car.car3} onChange={event=>handlePreviousTrip(event,i)} type="text" placeholder="" className="StyleInput"  required={(previousTrips[i].arrival || previousTrips[i].departure || previousTrips[i].car1 || previousTrips[i].price1 || previousTrips[i].car2 || previousTrips[i].price2  || previousTrips[i].price3 || promptsDisposition[i].Price1 || promptsDisposition[i].Price2 || promptsDisposition[i].Price3 || promptsDisposition[i].NumberOfHours || formData.DisposalCharge) !=null}/>
          </Grid>
        </Stack>
         ))} 
  
        

        {/* Display old Trips */}
        {previousData[0].trips.map((trip:any,i:any) => (   
        <div key={trip.timestampTrip}> 

        <div style={{ textAlign: 'right' }}>
          <Stack className="FontLabel big-line" > Trajet: {i+1}</Stack>
        </div>
        
            <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
              <Grid item xs={6}>
                <label className='FontLabel'>Departure</label>
                <TextField size='small' name="departure" defaultValue={trip.departure} onChange={event=>handlePreviousTrip(event,i)} type="text" placeholder="" className="StyleInput" required={(previousTrips[i].arrival  || previousTrips[i].price1   || previousTrips[i].price2  || previousTrips[i].price3) !=null}/>  
              </Grid>
              <Grid item xs={6}>
                <label className='FontLabel'>Arrival</label>
                <TextField size='small' name="arrival" defaultValue={trip.arrival} onChange={event=>handlePreviousTrip(event,i)}  type="text" placeholder="" className="StyleInput" required={( previousTrips[i].departure || previousTrips[i].price1 || previousTrips[i].price2  || previousTrips[i].price3) !=null}/> 
              </Grid>
            </Stack> 
            <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
              <Grid  >
                <label className='FontLabel'>Prix 1</label>
                <TextField size='small' name="price1" defaultValue={trip.price1} onChange={event=>handlePreviousTrip(event,i)} type="number" placeholder="" className="StyleInput" required={(previousTrips[i].arrival || previousTrips[i].departure || previousTrips[i].price2 || previousTrips[i].price3) !=null}/>
              </Grid>
              <Grid>
                <label className='FontLabel'>Prix 2</label>
                <TextField size='small' name="price2" defaultValue={trip.price2} onChange={event=>handlePreviousTrip(event,i)} type="number" placeholder="" className="StyleInput" required={(previousTrips[i].arrival || previousTrips[i].departure || previousTrips[i].price1  || previousTrips[i].price3) !=null}/>
              </Grid>
              <Grid>
                <label className='FontLabel'>Prix 3</label>
                <TextField size='small' name="price3" defaultValue={trip.price3} onChange={event=>handlePreviousTrip(event,i)} type="number" placeholder="" className="StyleInput" required={(previousTrips[i].arrival || previousTrips[i].departure || previousTrips[i].price1  || previousTrips[i].price2) !=null}/>
              </Grid>
            </Stack>
          {/* <Button type="button" onClick={()=>DeleteTrip(i)} variant="outlined" color="error"> - Supprimer ce trajet </Button> */}
          <Button style={{ color: "black", textTransform: 'none'  }} size="small" startIcon={<DeleteOutlineOutlinedIcon style={{ color: "red" }} />} 
              onClick={()=>HandleDeleteTrip(i)} >
              Supprimer ce trajet 
          </Button>
        </div>
        ))} 
        <br/>
             
        {/* Add Trip */}
        {((previousTrips[0].car1 && previousTrips[0].car2 && previousTrips[0].car3 && previousTrips[0].departure && previousTrips[0].arrival && previousTrips[0].price1 && previousTrips[0].price2 && previousTrips[0].price3 ) == null) 
        ?
        (<>
        {/* no need to add trip if the 1st one still NULL */}
        </>)
        :
        (<>
        {promptsTrip.map((prompt,i) => ( 
          <div key={prompt.timestampTrip}>
            <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
              <Grid>
                <label className='FontLabel'>Departure____</label>
                <TextField size='small' name="Departure" onChange={event=>handlePromptTrip(event,i)}type="text" placeholder="" className="StyleInput" required={ (promptsTrip[i].Arrival) || (promptsTrip[i].Price1) || (promptsTrip[i].Price2) || (promptsTrip[i].Price3) !=null } />  
              </Grid>
              <Grid>
                <label className='FontLabel'>Arrival</label>
                <TextField size='small' name="Arrival" onChange={event=>handlePromptTrip(event,i)} type="text" placeholder="" className="StyleInput" required={(promptsTrip[i].Departure || promptsTrip[i].Price1|| promptsTrip[i].Price2 || promptsTrip[i].Price3) !=null} /> 
              </Grid>
            </Stack>
            <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
              <Grid>
                <label className='FontLabel'>Prix 1</label>
                <TextField size='small' name="Price1" onChange={event=>handlePromptTrip(event,i)} type="number" placeholder="" className="StyleInput" required={ (promptsTrip[i].Departure || promptsTrip[i].Arrival || promptsTrip[i].Price2 || promptsTrip[i].Price3)!=null}/>
              </Grid>
              <Grid>
                <label className='FontLabel'>Prix 2</label>
                <TextField size='small' name="Price2" onChange={event=>handlePromptTrip(event,i)} type="number" placeholder="" className="StyleInput" required={ (promptsTrip[i].Departure || promptsTrip[i].Arrival || promptsTrip[i].Price1  || promptsTrip[i].Price3)!=null}/>
              </Grid>
              <Grid>
                <label className='FontLabel'>Prix 3</label>
                <TextField size='small' name="Price3" onChange={event=>handlePromptTrip(event,i)} type="number" placeholder="" className="StyleInput" required={ (promptsTrip[i].Departure || promptsTrip[i].Arrival || promptsTrip[i].Price1  || promptsTrip[i].Price2)!=null}/>
              </Grid>
            </Stack>
          {/* <Button type="button" onClick={()=>DeleteTrip(i)} variant="outlined" color="error" disabled={stateDeleteTrip.statut}> - Supprimer ce trajet </Button> */}
          </div> 
        ))}
        </>)}
        
        <br/>
        {/* <Button type="button" onClick={handleAddPromptTrip} variant="outlined" color="success" 
        disabled={stateTrip.statut}> + Ajouter un trajet</Button>  */}

        <div style={{ textAlign: 'right' }}>
          <Button style={{ color: "black", textTransform: 'none' }} size="small" startIcon={<AddIcon style={{ color: "green" }} />} color="success" 
            onClick={handleAddPromptTrip} disabled={stateTrip.statut}>
            Ajouter un Trajet
          </Button>
        </div>
        </Card>
        {/* //end add trip  */}

          {/* Blue Card2 */}
          <Card style={{backgroundColor: '#1d62af1a', padding: 8,margin: 4}} variant="outlined">
          <Grid className='SubTitleCard' >Informations</Grid>
          <Grid className='line'></Grid>

          <Grid>Haute saison</Grid>
          <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
              <Grid>
                <label className='FontLabel'>Date Début</label>
                <TextField size='small'defaultValue={item.startHighSeason} name="StartHighSeason" onChange={handleInputChange} type='date' className="StyleInput"/>
              </Grid>
              <Grid>
                <label className='FontLabel'>Date Fin</label>
                <TextField size='small' name="EndHighSeason" defaultValue={item.endHighSeason} onChange={handleInputChange}type='date' className="StyleInput"/>
              </Grid>
          </Stack>
          <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
              <Grid>
                <label className='FontLabel'>Charge €</label><br/>
                <TextField size='small' name="HighSeasonCharge" defaultValue={item.highSeasonCharge} onChange={handleInputChange} type="number" placeholder="" className="StyleInput" />
              </Grid>
          </Stack>
          <Grid>Travail de nuit</Grid>
          <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
              <Grid>
                <label className='FontLabel'>Heure Début<br/></label>
                <TextField size='small' name="HStartNight" defaultValue={item.hStartNight} onChange={handleInputChange} type='time' className="StyleInput"/>
              </Grid>
              <Grid>
                <label className='FontLabel'>Heure Fin<br/></label>
                <TextField size='small' name="HEndNight" defaultValue={item.hEndNight} onChange={handleInputChange}type='time' className="StyleInput"/>
              </Grid>
          </Stack>
          <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
              <Grid>
              <label className='FontLabel'>Charge</label><br/>
                {/* <Input name="chargeTnuit" onChange={handleInputChange} type="number" placeholder="" className="StyleInput"  /> */}
                <TextField size='small' name="NightRate" defaultValue={item.nightRate} onChange={handleInputChange} className="StyleInput" type="number" />
                {/* startAdornment={<InputAdornment position="end">€</InputAdornment>} */}
              </Grid>
          </Stack>
          
        </Card>


        {/* Grey Card */}
        <Card style={{backgroundColor: '#F5F5F5', padding: 8,margin: 4}} variant="outlined">
          <Grid className='SubTitleCard'> Disposition </Grid>
          <Grid className='line'></Grid>
            {/* there is only one charge for all disposition */}
            <Grid>
              <label className='FontLabel'>Charge supplémentaire</label><br/>
              <TextField size='small' name="DisposalCharge" defaultValue={item.disposalCharge} onChange={handleInputChange} type="number" placeholder="" className="StyleInput"  required ={(previousDisposal[0].price1 || previousDisposal[0].price2 || previousDisposal[0].price3 || previousDisposal[0].numberOfHours) != null }/>
            </Grid> 

            {/* Display old Disposition */}
        {previousData[0].disposals.map((prompt:any,i:any) => (   
          <div key={prompt.timestampDisposition} >
          
          <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
            <Grid item xs={6}>
              <label className='FontLabel'>Departure</label>
              <TextField size='small' name="departure" defaultValue={prompt.departure} onChange={event=>handlePreviousDisposal(event,i)} type="text" placeholder="" className="StyleInput" required={(previousDisposal[i].arrival  || previousDisposal[i].price1   || previousDisposal[i].price2  || previousDisposal[i].price3) !=null}/>  
            </Grid>
            <Grid item xs={6}>
              <label className='FontLabel'>Arrival</label>
              <TextField size='small' name="arrival" defaultValue={prompt.arrival} onChange={event=>handlePreviousDisposal(event,i)}  type="text" placeholder="" className="StyleInput" required={( previousDisposal[i].departure || previousDisposal[i].price1 || previousDisposal[i].price2  || previousDisposal[i].price3) !=null}/> 
            </Grid>
          </Stack> 
          <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
            <Grid  >
              <label className='FontLabel'>Prix 1</label>
              <TextField size='small' name="price1" defaultValue={prompt.price1} onChange={event=>handlePreviousDisposal(event,i)} type="number" placeholder="" className="StyleInput" required ={(previousDisposal[i].price2 || previousDisposal[i].price3 || previousDisposal[i].numberOfHours || item.DisposalCharge || previousDisposal[i].departure || previousDisposal[i].arrival ) != null }/>
            </Grid>
            <Grid> 
              <label className='FontLabel'>Prix 2</label>
              <TextField size='small' name="price2" defaultValue={prompt.price2} onChange={event=>handlePreviousDisposal(event,i)} type="number" placeholder="" className="StyleInput" required ={(previousDisposal[i].price1 || previousDisposal[i].price3 || previousDisposal[i].numberOfHours || item.DisposalCharge || previousDisposal[i].departure || previousDisposal[i].arrival ) != null }/>
            </Grid>
            <Grid>
              <label className='FontLabel'>Prix 3</label>
              <TextField size='small' name="price3" defaultValue={prompt.price3} onChange={event=>handlePreviousDisposal(event,i)} type="number" placeholder="" className="StyleInput" required ={(previousDisposal[i].price1 || previousDisposal[i].price2 || previousDisposal[i].numberOfHours || item.DisposalCharge || previousDisposal[i].departure || previousDisposal[i].arrival ) != null }/>
            </Grid>
          </Stack> 
          <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
              <Grid>
                <label className='FontLabel'>Heure disposition</label><br/>
                <TextField size='small' name="numberOfHours" defaultValue={prompt.numberOfHours} onChange={event=>handlePreviousDisposal(event,i)} type='time' className="StyleInput" required ={(previousDisposal[i].price1 || previousDisposal[i].price2 || previousDisposal[i].price3 || item.DisposalCharge || previousDisposal[i].departure || previousDisposal[i].arrival ) != null }/>
              </Grid>
          </Stack>
          
          <Button style={{ color: "black", textTransform: 'none'  }} size="small" startIcon={<DeleteOutlineOutlinedIcon style={{ color: "red" }} />} 
              onClick={()=>handleDeleteDisposition(i)} >
              Supprimer cette disposition 
          </Button>
        </div>
        ))}

        {/* Add Disposition */}
        {(( previousDisposal[0].arrival && previousDisposal[0].departure && previousDisposal[0].price1 && previousDisposal[0].price2 && previousDisposal[0].price3 && previousDisposal[0].numberOfHours ) == null) 
        ?
        (<>
        </>)
        :
        (<>
        {promptsDisposition.map((prompt,i) => ( 
        <div key={prompt.timestampDisposition} >
          
          <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>  
          <Grid item xs={6}>
            <label className='FontLabel'>Departure___</label>
            <TextField size='small' name="Departure" onChange={event=>handlePromptDisposition(event,i)} type="text" placeholder="" className="StyleInput" required={(promptsDisposition[i].Arrival || promptsDisposition[i].Price1   || promptsDisposition[i].Price2 || promptsDisposition[i].Price3) !=null}/>  
          </Grid>
          <Grid item xs={6}>
            <label className='FontLabel'>Arrival</label>
            <TextField size='small' name="Arrival" onChange={event=>handlePromptDisposition(event,i)}  type="text" placeholder="" className="StyleInput" required={( promptsDisposition[i].Departure || promptsDisposition[i].Price1 || promptsDisposition[i].Price2  || promptsDisposition[i].Price3) !=null}/> 
          </Grid>
          </Stack>
          <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>  
            <Grid>
              <label className='FontLabel'>Prix 1</label>
              <TextField size='small' name="Price1" onChange={event=>handlePromptDisposition(event,i)} type="number" placeholder="" className="StyleInput" required={ promptsDisposition[i].Departure || promptsDisposition[i].Arrival || promptsDisposition[i].Price2 || promptsDisposition[i].Price3 || promptsDisposition[i].NumberOfHours !=null}/>
            </Grid>
            <Grid> 
              <label className='FontLabel'>Prix 2</label>
              <TextField size='small' name="Price2" onChange={event=>handlePromptDisposition(event,i)} type="number" placeholder="" className="StyleInput" required={ promptsDisposition[i].Departure || promptsDisposition[i].Arrival ||  promptsDisposition[i].Price1 || promptsDisposition[i].Price3 || promptsDisposition[i].NumberOfHours !=null}/>
            </Grid>
            <Grid>
              <label className='FontLabel'>Prix 3</label>
              <TextField size='small' name="Price3" onChange={event=>handlePromptDisposition(event,i)} type="number" placeholder="" className="StyleInput" required={ promptsDisposition[i].Departure || promptsDisposition[i].Arrival || promptsDisposition[i].Price1 || promptsDisposition[i].Price2 || promptsDisposition[i].NumberOfHours !=null}/>
            </Grid>
          </Stack> 
          <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
              <Grid>
                <label className='FontLabel'>Heure disposition</label><br/>
                <TextField size='small' name="NumberOfHours" onChange={event=>handlePromptDisposition(event,i)} type='time' className="StyleInput" required={(promptsDisposition[i].Price1 && promptsDisposition[i].Price2 && promptsDisposition[i].Price3 && promptsDisposition[i].Departure && promptsDisposition[i].Arrival) != null}/>
              </Grid>
          </Stack>
          
          {/* <Button style={{ color: "black", textTransform: 'none'  }} size="small" startIcon={<DeleteOutlineOutlinedIcon style={{ color: "red" }} />} 
              onClick={()=>handleDeleteDisposition(i)} disabled={stateDeleteDisposal.statut}>
              Supprimer cette disposition 
          </Button> */}
        </div>

        ))}
        </>)}

        <br/>
        <div style={{ textAlign: 'right' }}>
          <Button style={{ color: "black", textTransform: 'none' }} size="small" startIcon={<AddIcon style={{ color: "green" }} />} color="success" 
            onClick={handleAddPromptDisposition} disabled={stateDisposal.statut}>
            Ajouter une disposition
          </Button>
        </div>


        {/*  disabled={stateDisposal.statut}
        <Button type="button" onClick={handleAddPromptDisposition} disabled={stateDisposal.statut} variant="outlined" color="success"> + Ajouter une disposition </Button>  */}
       
        </Card>
        
        {/* White Card */}
        <Card style={{ padding: 8,margin: 4}} variant="outlined">
          <Grid>
            <label className='FontLabel2'>Informations</label><br/>
            <TextField size='small' name="AdditionalInformation" defaultValue={item.additionalInformation} onChange={handleInputChange} type="text" placeholder="" className="StyleInput" style={{width:'395px'}}/>
          </Grid>
          <Grid>
            <label className='FontLabel2'>Politique d'annulation</label><br/>
            <TextField multiline name="CancelationPolicy" defaultValue={item.cancelationPolicy} onChange={handleInputChange} type="text" placeholder="" className="StyleInput" style={{width:'395px'}}/>
          </Grid>
          <br/>
   
          {/* <label style={{fontSize:'10px',color:'grey'}} ><MuiModal/></label> */}
          </Card>
         <SharingButton type="submit" variant="contained" className='btn'>Modifier</SharingButton>
         </form>
    {/* </Grid> */}

  </CardContent> 
</Card>  
))} 
</>

);
}
export default UpdateProvider