import './BottomNav.css';

import to from '../../../../images/bottom-nav/to.png';
import toActive from '../../../../images/bottom-nav/to-active.png';
import check from '../../../../images/bottom-nav/check.png';
import checkActive from '../../../../images/bottom-nav/check-active.png';
import rooming from '../../../../images/bottom-nav/rooming.png';
import roomingActive from '../../../../images/bottom-nav/rooming-active.png';
import minibar from '../../../../images/bottom-nav/minibar.png';
import caisse from '../../../../images/bottom-nav/caisse.png';
import caisseActive from '../../../../images/bottom-nav/caisse-active.png';
import voiturier from '../../../../images/bottom-nav/voiturier.png';
import voiturierActive from '../../../../images/bottom-nav/voiturier-active.png';
import whatsapp from '../../../../images/bottom-nav/whatsapp.png';
import breakfast from '../../../../images/bottom-nav/breakfast.png';
import breakfastActive from '../../../../images/bottom-nav/breakfast-active.png';
import transferts from '../../../../images/bottom-nav/transferts.png';
import restaurants from '../../../../images/bottom-nav/restaurants.png';
import helicopter from '../../../../images/bottom-nav/helicopter.png';
import babysitter from '../../../../images/bottom-nav/babysitter.png';
import moniteurdeski from '../../../../images/bottom-nav/moniteurdeski.png';

import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

function BottomNav() {
  const location = useLocation();

  const [menus, setMenus] = useState(['To', 'Check list', 'Caisse', 'Voiturier', 'Rooming', 'Breakfast']);
  const [whatsappMenu, setWhatsappMenu] = useState(false);
  const [logos] = useState(new Map([
    ['To', to],
    ['Check list', check],
    ['Caisse', caisse],
    ['Voiturier', voiturier],
    ['Rooming', rooming],
    ['Minibar', minibar],
    ['Breakfast', breakfast],

    ['Transferts', transferts],
    ['Restaurants', restaurants],
    ['Helicopter', helicopter],
    ['Baby sitter', babysitter],
    ['Moniteur de ski', moniteurdeski],
  ]))
  const [activeLogos] = useState(new Map([
    ['To', toActive],
    ['Check list', checkActive],
    ['Caisse', caisseActive],
    ['Voiturier', voiturierActive],
    ['Rooming', roomingActive],
    ['Minibar', minibar],
    ['Breakfast', breakfastActive],
    ['Transferts', transferts],
    ['Restaurants', restaurants],
    ['Helicopter', helicopter],
    ['Baby sitter', babysitter],
    ['Moniteur de ski', moniteurdeski],
  ]))
  const [paths] = useState(new Map([
    ['To', '/to'],
    ['Check list', '/check-list'],
    ['Caisse', '/caisse'],
    ['Voiturier', '/voiturier'],
    ['Rooming', '/rooming'],
    ['Minibar', '/minibar'],
    ['Breakfast', '/breakfast'],
    ['Transferts', '/prestataire/transferts'],
    ['Restaurants', '/prestataire/restaurants'],
    ['Helicopter', '/prestataire/helicopter'],
    ['Baby sitter', '/prestataire/babysitter'],
    ['Moniteur de ski', '/prestataire/moniteurdeski'],
  ]))

  useEffect(() => {
    switch (location.pathname) {
      case '/welcome':
      case '/organigram':
      case '/profilClients':
      case '/to':
        setMenus(['To', 'Rooming']);
        setWhatsappMenu(false);
        break;
        /*
      case '/spa':
      case '/espace-prive':
        setMenus(['To', 'Rooming']);
        break;
      case '/restaurant-hotel':
      case '/breakfast':
      case '/bons-cadeaux':
        setMenus(['To', 'Check list', 'Rooming', 'Minibar', 'Breakfast']);
        break;
      case '/spa':
      case '/espace-prive':
        setMenus(['To', 'Rooming']);
        break;
      case '/prestataire':
      case '/prestataire/transferts':
      case '/prestataire/restaurants':
      case '/prestataire/helicopter':
      case '/prestataire/babysitter':
      case '/prestataire/moniteurdeski':
        setMenus(['Transferts', 'Restaurants', 'Helicopter', 'Baby sitter', 'Moniteur de ski']);
        break;
      default:
        setMenus(['To', 'Check list', 'Caisse', 'Voiturier', 'Rooming']);
        break;
        */
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="BottomNav">
      <div className="BottomNavLeftMenu"></div>
      <div className="BottomNavMainMenu">
        {menus.map(menu => (
          <Link to={'' + paths.get(menu)} className="BottomNavMenu" key={menu}>
            <div className="BottomNavMenuPicture">
              <img draggable="false" src={location.pathname === paths.get(menu) ? activeLogos.get(menu) : logos.get(menu)} alt={menu} className={'BottomNavMenuPictureImage' + menu.split(' ').join('')} />
            </div>
            <div className={location.pathname === paths.get(menu) ? 'BottomNavMenuNameActive' : 'BottomNavMenuName' }>
              {menu}
            </div>
          </Link>
        ))}
      </div>
      <div className="BottomNavRightMenu">
        {whatsappMenu ?  
          <Link to="whatsapp" className="BottomNavMenu" key="whatsapp">
              <div className="BottomNavMenuPicture">
                <img draggable="false" src={whatsapp} alt="whatsapp" className={'BottomNavMenuPictureImageWhatsapp'} />
              </div>
          </Link> : null
        }
      </div>
    </div>
  );
}

export default BottomNav;