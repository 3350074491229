import { useEffect, useRef, useState } from 'react';
import moment from 'moment';

import GnzButton from '../shared/gnz-button/GnzButton';
import FormMode from '../../enums/FormMode';
import GnzImage from '../shared/gnz-image/GnzImage';
import plus2 from '../../images/shared/plus2.png';
import more from '../../images/shared/more.png';
import FeedbackForm from './FeedbackForm/FeedbackForm';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import FeedbackTable from './FeedbackTable/FeedbackTable';
import { Menu, MenuItem } from '@mui/material';
import ConsigneStatus from '../../enums/ConsigneStatus';
import GnzDialog from '../shared/gnz-dialog/GnzDialog';
import CancelFeedbackForm from './CancelFeedbackForm/CancelFeedbackForm';
import ConsigneFilter from '../../enums/ConsigneFilter';
import _Feedback from '../../models/Feedback';
import GnzDrawer from '../shared/gnz-drawer/GnzDrawer';
import ConsigneEvent from '../../models/ConsigneEvent';
import ConsigneEventType from '../../enums/ConsigneEventType';
import { useSearchParams, useNavigate } from 'react-router-dom';

import './Feedback.css';

const status = new Map([
  [0, ''],
  [1, 'A faire'],
  [2, 'En attente'],
  [3, 'En cours'],
  [4, 'Fait'],
])

const state = new Map([
  [0, ''],
  [1, 'Urgent'],
  [2, 'Restante'],
  [3, 'Information'],
  [4, 'Terminé'],
  [5, 'Annulée'],
])

function Feedback() {
  const { userName } = useSelector((state: RootState) => state.user);
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0,10);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);

  const { currentDate } = useSelector((state: RootState) => state.app);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const drawerRef = useRef<HTMLDivElement>(null);
  const cancelDialogRef = useRef<HTMLDivElement>(null);
  const [mode, setMode] = useState<FormMode>(FormMode.None);
  const [filter, setFilter] = useState<ConsigneFilter>(ConsigneFilter.None);
  const [feedbacks, setFeedbacks] = useState<Array<_Feedback>>([]);
  const [currentFeedbacks, setCurrentFeedbacks] = useState<Array<_Feedback>>([]);
  const [orderedcurrentFeedbacks, setOrderedCurrentFeedbacks] = useState<Array<_Feedback>>([]);
  const [feedbackNew, setFeedbackNew] = useState<_Feedback>(new _Feedback());
  const [feedbackEdit, setFeedbackEdit] = useState<_Feedback>(new _Feedback());
  const [feedbackCancel, setFeedbackCancel] = useState<_Feedback>(new _Feedback());
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const myParam = searchParams.get('customerId');


  useEffect(() => {
    if (localStorage.getItem('feedbacks')) {
      setFeedbacks(JSON.parse(localStorage.getItem('feedbacks')!));
    }
  }, [])

  useEffect(() => {
    saveFeedbacks();
    // eslint-disable-next-line
  }, [feedbacks])

  useEffect(() => {
    if (localStorage.getItem('feedbacks')) {
      setCurrentFeedbacks(JSON.parse(localStorage.getItem('feedbacks')!).filter((c: _Feedback) => c.timestamp === currentDate));
    }
  }, [currentDate])

  const filterFeedbacks = () => {
    if (Number(filter) === ConsigneFilter.Status) {
      const newFeedbacks = [...currentFeedbacks].sort((c1: _Feedback, c2: _Feedback) => Number(c1.status) - Number(c2.status));
      setOrderedCurrentFeedbacks(newFeedbacks);
    } else if (Number(filter) === ConsigneFilter.State) {
      const newFeedbacks = [...currentFeedbacks].sort((c1: _Feedback, c2: _Feedback) => Number(c1.service) - Number(c2.service));
      setOrderedCurrentFeedbacks(newFeedbacks);
    } else {
      setOrderedCurrentFeedbacks(currentFeedbacks);
    }
  }

  useEffect(() => {
    filterFeedbacks();
    // eslint-disable-next-line
  }, [filter])

  useEffect(() => {
    filterFeedbacks();
    // eslint-disable-next-line
  }, [currentFeedbacks])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const newFeedback = () => {
    setMode(FormMode.New);
    setFeedbackNew(new _Feedback());
    openDrawer();
  };

  const openDrawer = () => {
    const drawerRefEl: any = drawerRef.current;
    drawerRefEl.open();
  }

  const closeDrawer = () => {
    const drawerRefEl: any = drawerRef.current;
    drawerRefEl.close();
  }

  const feedbackFormTitle = () => {
    switch (mode) {
      case FormMode.New:
        return 'Nouveau Feedback';
      case FormMode.Edit:
        return 'Edition de la feedback';
      default:
        return '';
    }
  }

  const feedbackFormFeedback = () => {
    switch (mode) {
      case FormMode.New:
        return feedbackNew;
      case FormMode.Edit:
        return feedbackEdit;
      default:
        return new _Feedback();
    }
  }

  const reset = () => {
    setMode(FormMode.None);
    setFeedbackNew(new _Feedback());
    setFeedbackEdit(new _Feedback());
  }

  const submitNew = (newFeedbacks: Array<_Feedback>) => {
    let feedbackId = feedbacks.length ? Math.max(...feedbacks.map(c => c.id)) + 1 : 1;
    const createdDate = Date.now();

    for (const newFeedbackIndex in newFeedbacks) {
      let newFeedbackEvent = new ConsigneEvent();

      if (Number(newFeedbacks[newFeedbackIndex].status) === ConsigneStatus.Todo) {
        newFeedbackEvent = new ConsigneEvent(ConsigneEventType.Create, userName, createdDate);
      } else if (Number(newFeedbacks[newFeedbackIndex].status) === ConsigneStatus.Waiting) {
        newFeedbackEvent = new ConsigneEvent(ConsigneEventType.Wait, userName, createdDate);
      } else if (Number(newFeedbacks[newFeedbackIndex].status) === ConsigneStatus.Processing) {
        newFeedbackEvent = new ConsigneEvent(ConsigneEventType.Process, userName, createdDate);
      } else if (Number(newFeedbacks[newFeedbackIndex].status) === ConsigneStatus.Done) {
        newFeedbackEvent = new ConsigneEvent(ConsigneEventType.Done, userName, createdDate);
      }

      newFeedbacks[newFeedbackIndex].id = feedbackId + Number(newFeedbackIndex);
      newFeedbacks[newFeedbackIndex].events = [newFeedbackEvent];
    }

    setFeedbacks([...feedbacks, ...newFeedbacks]);
    setCurrentFeedbacks([...currentFeedbacks, newFeedbacks[0]]);
    reset();
    closeDrawer();
  }

  const submitEdit = (newFeedbacks: Array<_Feedback>) => {
    const feedbackId = feedbacks.length ? Math.max(...feedbacks.map(c => c.id)) + 1 : 1;
    const nowDate = Date.now();
    const feedbacksCopy = [...feedbacks];

    let newConsigneCount = 0;
    for (const newConsigneIndex in newFeedbacks) {
      let newConsigneEvent = new ConsigneEvent();

      if (Number(newFeedbacks[newConsigneIndex].status) === ConsigneStatus.Todo) {
        newConsigneEvent = new ConsigneEvent(ConsigneEventType.Create, userName, nowDate);
      } else if (Number(newFeedbacks[newConsigneIndex].status) === ConsigneStatus.Waiting) {
        newConsigneEvent = new ConsigneEvent(ConsigneEventType.Wait, userName, nowDate);
      } else if (Number(newFeedbacks[newConsigneIndex].status) === ConsigneStatus.Processing) {
        newConsigneEvent = new ConsigneEvent(ConsigneEventType.Process, userName, nowDate);
      } else if (Number(newFeedbacks[newConsigneIndex].status) === ConsigneStatus.Done) {
        newConsigneEvent = new ConsigneEvent(ConsigneEventType.Done, userName, nowDate);
      }

      newFeedbacks[newConsigneIndex].events.push(newConsigneEvent);

      if (!newFeedbacks[newConsigneIndex].id) {
        newFeedbacks[newConsigneIndex].id = feedbackId + newConsigneCount;
        newConsigneCount++;
        feedbacksCopy.push(newFeedbacks[newConsigneIndex]);
      } else {
        const currentConsigne = feedbacks.find(c => c.id === newFeedbacks[newConsigneIndex].id);
        const currentConsignePos = feedbacks.indexOf(currentConsigne!);
        feedbacksCopy.splice(currentConsignePos, 1, newFeedbacks[newConsigneIndex]);
      }
    }

    setFeedbacks(feedbacksCopy);
    setCurrentFeedbacks(feedbacksCopy.filter((c: _Feedback) => c.timestamp === currentDate));
    reset();
    closeDrawer();
  }

  const saveFeedbacks = () => {
    if (feedbacks.length) {
      localStorage.setItem('feedbacks', JSON.stringify(feedbacks));
    }
  }

  const feedbackFormSubmit = () => {
    switch (mode) {
      case FormMode.New:
        return submitNew;
      case FormMode.Edit:
        return submitEdit;
      default:
        return () => { };
    }
  }

  const feedbackFormSubmitLabel = () => {
    switch (mode) {
      case FormMode.New:
        return 'Ajouter la feedback';
      case FormMode.Edit:
        return 'Modifier la feedback';
      default:
        return '';
    }
  }

  const editFeedback = (feedback: _Feedback) => {
    setMode(FormMode.Edit);
    setFeedbackEdit(feedback);
    openDrawer();
  }

  const cancelFeedback = (feedback: _Feedback) => {
    setFeedbackCancel(feedback);
    openCancelDialog();
  }

  const retablirFeedback = (feedback: _Feedback) => {
    const feedbacksCopy = [...feedbacks];
    const feedbackToUncancel = feedbacks.find(c => c.id === feedback.id);
    if (feedbackToUncancel) {
      const uncancelFeedbackPos = feedbacks.indexOf(feedbackToUncancel);

      feedback.events = feedback.events.filter(c => c.type !== ConsigneEventType.Cancel);

      feedbacksCopy.splice(uncancelFeedbackPos, 1, feedback);
      setFeedbacks(feedbacksCopy);
      setCurrentFeedbacks(feedbacksCopy.filter((c: _Feedback) => c.timestamp === currentDate));
    }
  }

  const cancelFeedbackConfirm = (feedback: _Feedback, reason: string) => {
    const feedbacksCopy = [...feedbacks];
    const feedbackToCancel = feedbacks.find(c => c.id === feedbackCancel.id);
    if (feedbackToCancel) {
      const cancelFeedbackPos = feedbacks.indexOf(feedbackToCancel);

      feedback.events.push(new ConsigneEvent(
        ConsigneEventType.Cancel,
        userName,
        Date.now(),
        reason
      ));

      feedbacksCopy.splice(cancelFeedbackPos, 1, feedback);
      setFeedbacks(feedbacksCopy);
      setCurrentFeedbacks(feedbacksCopy.filter((c: _Feedback) => c.timestamp === currentDate));
      reset();
      closeCancelDialog();
    }
  }

  const handlePrint = () => {
    setOpen(false);
    setAnchorEl(null);
    window.print();
  }

  const feedbackCreatedEvent = (feedback: _Feedback) => feedback.events.find(e => e.type === ConsigneEventType.Create) || new ConsigneEvent();

  const handleExport = () => {
    let csvContent = "data:text/csv;charset=utf-8,_Feedback,Status,Etat,Ecrite par,Date\r\n";

    orderedcurrentFeedbacks.forEach(function (c: _Feedback) {
      const createEvent = feedbackCreatedEvent(c);
      csvContent += `${c.title}: ${c.comment},${status.get(Number(c.status))},${state.get(Number(c.service))},${createEvent.user},le ${moment(createEvent.date).format('DD-MM-YYYY')} à ${moment(createEvent.date).format('HH')}h${moment(createEvent.date).format('mm')}\r\n`;
    });

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `feedbacks-${moment().format('DD-MM-YYYY-HH-mm')}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
    setOpen(false);
    setAnchorEl(null);
  }

  const handleImport = () => {
    console.log('handleImport');
    setOpen(false);
    setAnchorEl(null);
  }

  const openCancelDialog = () => {
    const cancelDialogRefEl: any = cancelDialogRef.current;
    cancelDialogRefEl.open();
  }

  const closeCancelDialog = () => {
    const cancelDialogRefEl: any = cancelDialogRef.current;
    cancelDialogRefEl.close();
  }

  const handleChange = (event:any) => {     
    // axios.get(Url +'Reservation/' + startDate +'/' + event.target.value)
    // .then(res => reservation(res.data));            
  }; 
  
  return (
    <div className="feed">
      <div className="FeedbackTopActions">
        <h3 className='FeedbackTopActionsh3'>Feedback Clients</h3>

        <div className='FeedbackSelectSyleDiv'>
          {/* <input className="FeedbackDatasyle" placeholder='Du'></input>
          <input className="FeedbackDatasyle" placeholder='Au'></input> */}
          <input type="date" className="DatasyleFeddback" defaultValue={date} onChange={(e) => setStartDate(e.target.value)} />
          <input type="date" className="DatasyleFeddback"  defaultValue={date} onChange={handleChange} />
         
          <select className='selectSyleFeed'>
            <option value={1}>Filter</option>
            <option value={2}>.....</option>
            <option value={3}>.....</option>
          </select>
         
          <button>Rechercher</button>
          <GnzButton leftIcon={<GnzImage src={plus2} />} label="Nouveau Feedback" onClick={newFeedback} color="#1D62AF" backgroundColor="#A0E295" />
        </div>
      </div>

      <FeedbackTable 
      feedbacks={orderedcurrentFeedbacks} 
      editFeedback={editFeedback} 
      cancelFeedback={cancelFeedback}
      retablirFeedback={retablirFeedback} 
      customerId={myParam}
      />

      <div className="">
        <GnzImage src={more} clickable onClick={handleClick} />
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem key="print" onClick={handlePrint}>
            Imprimer
          </MenuItem>
          <MenuItem key="export" onClick={handleExport}>
            Exporter
          </MenuItem>
          <MenuItem key="import" onClick={handleImport}>
            Importer
          </MenuItem>
        </Menu>
      </div>
      <GnzDrawer ref={drawerRef} component={
        <FeedbackForm
          mode={mode}
          currentDate={currentDate}
          title={feedbackFormTitle()}
          closeForm={closeDrawer}
          feedback={feedbackFormFeedback()}
          submit={feedbackFormSubmit()}
          submitLabel={feedbackFormSubmitLabel()}
          submitColor={"#003671"}
          customerId={myParam ?? ""}
        />
      } />
      <GnzDialog ref={cancelDialogRef} component={
        <CancelFeedbackForm
          feedback={feedbackCancel}
          confirmSubmit={cancelFeedbackConfirm}
          cancelSubmit={closeCancelDialog}
        />}
      />
    </div>
  );
}
export default Feedback;