import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import FormMode from '../../../enums/FormMode';
import Consigne from '../../../models/Consigne';
import ConsigneEventType from '../../../enums/ConsigneEventType';
import ConsigneEvent from '../../../models/ConsigneEvent';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import ItineraireItemsClients from './ItemsItinerarie/ItineraireItemsClients';
import './ItineraireClients.css';

function ItineraireClients() {

  const [mode, setMode] = useState<FormMode>(FormMode.None);
  const [orderedcurrentConsignes, setOrderedCurrentConsignes] = useState<Array<Consigne>>([]);
  const [consigneEdit, setConsigneEdit] = useState<Consigne>(new Consigne());
  const [consigneCancel, setConsigneCancel] = useState<Consigne>(new Consigne());
  const drawerRef = useRef<HTMLDivElement>(null);
  const cancelDialogRef = useRef<HTMLDivElement>(null);
  const [consignes, setConsignes] = useState<Array<Consigne>>([]);
  const [currentConsignes, setCurrentConsignes] = useState<Array<Consigne>>([]);
  const { currentDate } = useSelector((state: RootState) => state.app);

  const editConsigne = (consigne: Consigne) => {
    setMode(FormMode.Edit);
    setConsigneEdit(consigne);
    openDrawer();
  }

  const openDrawer = () => {
    const drawerRefEl: any = drawerRef.current;
    drawerRefEl.open();
  }
    const cancelConsigne = (consigne: Consigne) => {
      setConsigneCancel(consigne);
      openCancelDialog();
    }

    const openCancelDialog = () => {
      const cancelDialogRefEl: any = cancelDialogRef.current;
      cancelDialogRefEl.open();
    }

    const retablirConsigne = (consigne: Consigne) => {
      const consignesCopy = [...consignes];
      const consigneToUncancel = consignes.find(c => c.id === consigne.id);
      if (consigneToUncancel) {
        const uncancelConsignePos = consignes.indexOf(consigneToUncancel);
  
        consigne.events = consigne.events.filter(c => c.type !== ConsigneEventType.Cancel);
  
        consignesCopy.splice(uncancelConsignePos, 1, consigne);
        setConsignes(consignesCopy);
        setCurrentConsignes(consignesCopy.filter((c: Consigne) => c.timestamp === currentDate));
      }
    }

const consigneCreatedEvent = (consigne: Consigne) => consigne.events.find(e => e.type === ConsigneEventType.Create) || new ConsigneEvent();
const handleExport = () => {
orderedcurrentConsignes.forEach(function (c: Consigne) {
    const createEvent = consigneCreatedEvent(c);
   });
}

  return (
    <form className='ProfilClients'>
      <div className="ProfilClientsItineraireTopActions">
        <h3 className='ProfilClientsItineraireTopActionsh3'>Profil Clients </h3>
        <div className='selectItineraireSyleDiv'>
          <select className='selectItineraireSyle'>
            <option value={1}>Services</option>  {/* //only for hotels clients */}
            <option value={2}>........</option>
            <option value={3}>........</option>
          </select>
        </div>
      </div>

      <div className='itineraire_table'>
        <h3 className='booking_header'>Fiche Client</h3>
        <div className='userItineraireItineraire_content'>

          <div className="ProfilClientsItineraireTopActions">
            <select className='selectItineraireSyle'>
              <option value={1}>Itineraire</option>
              <option value={2}>Data1-Data2</option>
              <option value={3}>Data1-Data2</option>
              <option value={3}>Data1-Data2</option>
            </select>
          </div>
          <div className='LineaDiv'>
          </div>

          <ItineraireItemsClients consignes={orderedcurrentConsignes} editConsigne={editConsigne} cancelConsigne={cancelConsigne} retablirConsigne={retablirConsigne} />               

          <div className="ProfilClientsItinerarieActions">
            <button className="btnstyleItinerarie">Annulation séjour</button>
            <button className="btnstyleconfirmation">Envoyer la confirmation</button>
          </div>
        </div>


        <div>
        </div>
      </div>
    </form>
  );
}
export default ItineraireClients;