import { Container, Dialog, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Demander from "./demander";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
const apiUrl =
  window.location.hostname === "localhost"
    ? "https://localhost:7142/api"
    : "/api";
type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];
interface FormData {
  Date_out: Date;
  Date_in: Date;
  Motif: null | string;
  Status: null | string;
  Etat: string | null;
  EmployeeId: number;
  Id: number;
}
export default function MyApp() {
  const location = useLocation();
  const { id } = useParams();
  const Id =
    location.state && location.state.id !== undefined ? location.state.id : id;
  const [selectedRange, setSelectedRange] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [firstSelectedDate, setFirstSelectedDate] = useState<Date | null>(null);
  const [open, setOpen] = useState(false);
  const [employee, setEmployee] = useState<any>(null);
  const [formDataArray, setFormDataArray] = useState<FormData[]>([]);
  const [loading, setLoading] = useState(true);
  const [enAttenteArray, setEnAttenteArray] = useState<FormData[]>([]);
  const [rangeEnAttente, setRangeEnAttente] = useState<Date[]>([]);

  const [valideArray, setValideArray] = useState<FormData[]>([]);
  const [rejetéArray, setRejetéArray] = useState<FormData[]>([]);
  const [rangeValide, setRangeValide] = useState<Date[]>([]);
  const [rangeRejeté, setRangeRejeté] = useState<Date[]>([]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (value: string) => {
    setOpen(false);
  };
  const handleDate = (value: Date) => {
    setFirstSelectedDate(value);
  };
  const handleCalendarChange = (value: Date | Date[]) => {
    if (Array.isArray(value) && value.length === 2) {
      setSelectedRange([value[0], value[1]]);
    } else {
      setSelectedRange([null, null]);
    }
  };
  const formattedDate = (dateTimeString: any) => {
    if (!dateTimeString) return "";
    const dateOnlyString = dateTimeString.split("T")[0];
    return dateOnlyString;
  };

  const onChange = (
    value: Value,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    handleCalendarChange(value as Date | Date[]);
    handleClickOpen();
  };
  const onClick = (
    value: Value,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    handleDate(value as Date);
  };
  const tileClassName = ({ date }: { date: Date }) => {
    if (selectedRange[0] && selectedRange[1]) {
      if (date >= selectedRange[0] && date <= selectedRange[1]) {
        return "selectedDate";
      }
    }
    if (
      firstSelectedDate &&
      date.toDateString() === firstSelectedDate.toDateString()
    ) {
      return "selectedDate";
    }

    if (rangeEnAttente.some((d) => date.toDateString() === d.toDateString())) {
      return "enAttente";
    }
      if (rangeValide.some((d) => date.toDateString() === d.toDateString())) {
        return "valide";
      }
      if (rangeRejeté.some((d) => date.toDateString() === d.toDateString())) {
        return "rejeté";
      }
  };
  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await axios.get(`${apiUrl}/Employee/${Id}`);

        setEmployee(response.data);
        const newFormDataArray = response.data.demandes.map((absence: any) => {
          return {
            Date_out: formattedDate(absence.date_out),
            Date_in: formattedDate(absence.date_in),
            Motif: absence.motif,
            Status: absence.status,
            Etat: absence.etat,
            EmployeeId: Id,
            Id: absence.id,
          };
        });
        setFormDataArray(newFormDataArray);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching employee data:", error);

        setLoading(false);
      }
    };

    fetchEmployee();

    return () => {};
  }, [Id]);

  useEffect(() => {
    const enAttente = formDataArray.filter(
      (item) => item.Etat === "En Attente"
    );
    const valide = formDataArray.filter((item) => item.Etat === "Validée");
    const rejeté = formDataArray.filter((item) => item.Etat === "Refusée");

    setEnAttenteArray(enAttente);
    setValideArray(valide);
    setRejetéArray(rejeté);
  }, [formDataArray]);

  const generateDateRange = (startDate: Date, endDate: Date) => {
    const dateRange = [];
    const currentDate = new Date(startDate);
    const end = new Date(endDate);
    while (currentDate <= end) {
      dateRange.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateRange;
  };
  useEffect(() => {
    const newRangeEnAttente: Date[] = [];
    enAttenteArray.forEach((item) => {
      const dateOut = new Date(item.Date_out);
      const dateIn = new Date(item.Date_in);
      const datesInRange = generateDateRange(dateOut, dateIn);
      newRangeEnAttente.push(...datesInRange);
    });
    setRangeEnAttente(newRangeEnAttente);
  }, [enAttenteArray]);
  useEffect(() => {
    const newRangeValide: Date[] = [];
    valideArray.forEach((item) => {
      const dateOut = new Date(item.Date_out);
      const dateIn = new Date(item.Date_in);
      const datesInRange = generateDateRange(dateOut, dateIn);
      newRangeValide.push(...datesInRange);
    });
    setRangeValide(newRangeValide);
  }, [valideArray]);

  useEffect(() => {
    const newRangeRejeté: Date[] = [];
    rejetéArray.forEach((item) => {
      const dateOut = new Date(item.Date_out);
      const dateIn = new Date(item.Date_in);
      const datesInRange = generateDateRange(dateOut, dateIn);
      newRangeRejeté.push(...datesInRange);
    });
    setRangeRejeté(newRangeRejeté);
  }, [rejetéArray]);
  if (loading) {
    return <div>Loading...</div>;
  }

  if (!employee) {
    return <div>No employee found</div>;
  }


  return (
    <Container maxWidth="xl" sx={{ padding: 5 }}>
      <Paper sx={{ padding: 2, display: "flex", justifyContent: "center" }}>
        <div>
          <Typography
            variant="h6"
            fontWeight="bold"
            textAlign="center"
            my={2}
            sx={{ color: "#0B4B92" }}
          >
            Liste des demandes
          </Typography>
          <Calendar
            onChange={onChange}
            onClickDay={onClick}
            value={selectedRange as any}
            selectRange={true}
            tileClassName={tileClassName}
          />
          <Grid
            container
            display="flex"
            justifyContent="center"
            spacing={2}
            my={1}
          >
            <Grid item display="flex" flexDirection="row" alignItems="center">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="30" height="30" rx="10" fill="#FFAC4C" />
              </svg>
              <Typography mx={1}>En attente</Typography>
            </Grid>
            <Grid item display="flex" flexDirection="row" alignItems="center">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="30" height="30" rx="10" fill="#A0E295" />
              </svg>

              <Typography mx={1}>Validé</Typography>
            </Grid>
            <Grid item display="flex" flexDirection="row" alignItems="center">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="30" height="30" rx="10" fill="#95BDE2" />
              </svg>

              <Typography mx={1}>Refusé</Typography>
            </Grid>
          </Grid>
        </div>
      </Paper>
      <Dialog
        onClose={handleClose}
        open={open}
        sx={{ maxWidth: "100%" }}
        className="editDialog"
      >
        <Demander
          Date_out={selectedRange[0]}
          Date_in={selectedRange[1]}
          Id={Id}
        />
      </Dialog>
    </Container>
  );
}
