import { useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import customerImg1 from '../../../images/ProfilClients_img/Ale.png';
import Reservation from '../../../models/Reservation';
import axios from 'axios';

import './ProfilClientsTable.css';
import GnzDatePicker from '../../shared/gnz-date-picker/GnzDatePicker';
import GnzTimePicker from '../../shared/gnz-time-picker/GnzTimePicker';
import { TimeClock } from '@mui/x-date-pickers';

interface IProfilClientsTable {    
    search : string,
    Reservation : Array<Reservation>;
}

function ProfilClientsTable(props: IProfilClientsTable) {
    const navigate = useNavigate();  
    const customerImg = customerImg1; 
      
    return (
        <div className='user_payment'>
            <h3 className='user_header'>Trombinoscope</h3>
            <div className='user_content'>
            {/* const itemDate = new Date(item.startUtc);  && itemDate >= new Date(props.startDate) && itemDate <= new Date(props.endDate)*/}
                {props.Reservation.filter((item) => {                       
                        return (props.search.toLowerCase() === '' ? item : item.customers.lastName.toLowerCase().includes(props.search) 
                        || item.number.includes(props.search))  
                }).map((item) => (

                    <div className="user_element" key={item.idCustomer} onClick={() => navigate(`/clientsDetails?customerId=${item.idCustomer}`)}>
                        <div>
                            <img className="PrimaryHeaderLogo" alt="" src={customerImg} />
                            <p className='user_chambre'>Chamber {item.number}</p>                           
                            <p className='user_info'>{item.customers.lastName  ?? '---'} {item.customers.firstName  ?? '---'} </p>
                            <p className='user_info'> 16/01/24 au 23/01/24  </p>
                            <p className='user_nome'>VIP : {item.customers.classificationName ?? '---'}</p>
                            <p className='user_info'>{item.state}</p>
                            <p className='user_info'>Heure de arr :{item.startUtc}</p>
                            <p className='user_info'>Heure de départ : {item.endUtc}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ProfilClientsTable