import { ChangeEvent, useEffect, useRef, useState } from 'react';
import FormMode from '../../../enums/FormMode';
import Debour from '../../../models/Debour';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import moreVertical from '../../../images/shared/more-vertical.png';

import './CaisseConciergeSecondary.css';
import { Menu, MenuItem } from '@mui/material';
import { setAmount } from '../../../redux/caisseSlice';
import GnzButton from '../../shared/gnz-button/GnzButton';
import GnzDrawer from '../../shared/gnz-drawer/GnzDrawer';
import GnzImage from '../../shared/gnz-image/GnzImage';
import DebourForm from './debour-form/DebourForm';

const reglements = new Map([
  [0, 'Voir paiement'],
  [1, 'Sur chambre'],
  [2, 'Compte direction'],
  [3, 'Compte Hotel'],
  [4, 'Gratuité'],
])


function CaisseConciergeSecondary() {
  const dispatch = useDispatch();

  const [allowSaveDebour, setAllowSaveDebour] = useState(false);
  const [allowSaveRemboursement, setAllowSaveRemboursement] = useState(false);
  const { currentDate } = useSelector((state: RootState) => state.app);
  const { amount } = useSelector((state: RootState) => state.caisse);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement | any>(null);
  const [open, setOpen] = useState(false);
  const drawerRef = useRef<HTMLDivElement>(null);
  const [mode, setMode] = useState<FormMode>(FormMode.None);
  const [cashAmount, setCashAmount] = useState(6000);
  const [debours, setDebours] = useState<Array<Debour>>([]);
  const [currentDebours, setCurrentDebours] = useState<Array<Debour>>([]);
  const [debourNew, setDebourNew] = useState<Debour>(new Debour())
  const [debourEdit, setDebourEdit] = useState<Debour>(new Debour())
  const [debourFocus, setDebourFocus] = useState<Debour>(new Debour())
  const [remboursement, setRemboursement] = useState(0)
  const [cashMoney] = useState<Array<number>>([500, 200, 100, 50, 20, 10, 5, 2, 1, 0.50, 0.20, 0.10, 0.05, 0.02, 0.01])
  const [cashMorningMoney, setCashMorningMoney] = useState<Array<number>>(Array(cashMoney.length).fill(0))
  const [cashEveningMoney, setCashEveningMoney] = useState<Array<number>>(Array(cashMoney.length).fill(0))

  useEffect(() => {
    if (allowSaveDebour) {
      saveDebours();
    } else {
      setAllowSaveDebour(true);
    }
    // eslint-disable-next-line
  }, [debours])

  useEffect(() => {
    if (allowSaveRemboursement) {
      saveRemboursement();
    } else {
      setAllowSaveRemboursement(true);
    }
    // eslint-disable-next-line
  }, [remboursement])

  useEffect(() => {
    if (localStorage.getItem('remboursements')) {
      const remboursementOfTheDay = JSON.parse(localStorage.getItem('remboursements')!).find((remboursement: any) => remboursement.currentDate === currentDate);
      if (remboursementOfTheDay) {
        setRemboursement(remboursementOfTheDay.remboursement);
      } else {
        setRemboursement(0);
      }
    }

    if (localStorage.getItem('caisse')) {
      const caisseOfTheDay = JSON.parse(localStorage.getItem('caisse')!).find((caisse: any) => caisse.currentDate === currentDate);
      if (caisseOfTheDay) {
        setCashAmount(caisseOfTheDay.previousDayAmount);
        setCashMorningMoney(caisseOfTheDay.cashMorningMoney);
        setCashEveningMoney(caisseOfTheDay.cashEveningMoney);
      }
    }
    
    if (localStorage.getItem('debours')) {
      setDebours(JSON.parse(localStorage.getItem('debours')!));
      setCurrentDebours(JSON.parse(localStorage.getItem('debours')!).filter((d: Debour) => d.timestamp === currentDate));
    }
  }, [currentDate])

  useEffect(() => {
    setCaisseAmount();
    // eslint-disable-next-line
  }, [cashMorningMoney])

  useEffect(() => {
    setCaisseAmount();
    // eslint-disable-next-line
  }, [cashEveningMoney])

  const sumAmount = (cashMoneyToSum: Array<number>) => {
    return cashMoneyToSum.some(value => value) ? cashMoneyToSum.map((value, key) => ({ value, key })).reduce((previous, cash) => previous + cash.value * cashMoney[cash.key], 0).toFixed(2) : '';
  }

  const setCaisseAmount = () => dispatch(setAmount(Number(sumAmount(cashMorningMoney) + sumAmount(cashEveningMoney))))

  const newDebour = () => {
    setMode(FormMode.New);
    setDebourNew(new Debour());
    openDrawer();
  };
    
  const openDrawer = () => {
    const drawerRefEl: any = drawerRef.current;
    drawerRefEl.open();
  }

  const closeDrawer = () => {
    const drawerRefEl: any = drawerRef.current;
    drawerRefEl.close();
  }

  const debourFormTitle = () => {
    switch (mode) {
      case FormMode.New:
        return 'Nouvelle debour Concierge';
      case FormMode.Edit:
        return 'Modification debour Consigne';
      default:
        return '';
    }
  }

  const debourFormDebour = () => {
    switch (mode) {
      case FormMode.New:
        return debourNew;
      case FormMode.Edit:
        return debourEdit;
      default:
        return new Debour();
    }
  }

  const reset = () => {
    setMode(FormMode.None);
    setDebourNew(new Debour());
    setDebourEdit(new Debour());
    setDebourFocus(new Debour());
  }

  const saveDebours = () => {
    localStorage.setItem('debours', JSON.stringify(debours));
  }

  const saveRemboursement = () => {
    let previousRemboursement = [];
    if (localStorage.getItem('remboursements')) {
      previousRemboursement = JSON.parse(localStorage.getItem('remboursements')!);
    }
    previousRemboursement = previousRemboursement.filter((remboursement: any) => remboursement.currentDate !== currentDate);
    localStorage.setItem('remboursements', JSON.stringify([
      ...previousRemboursement,
      {
        currentDate,
        remboursement,
      }
    ]));
  }

  const submitNew = (newDebour: Debour) => {
    newDebour.id = debours.length ? Math.max(...debours.map(d => d.id)) + 1 : 1;

    setDebours([...debours, newDebour]);
    setCurrentDebours([...currentDebours, newDebour]);
    reset();
    closeDrawer();
  }

  const submitEdit = (newDebour: Debour) => {
    const deboursCopy = [...debours];
    const currentDebour = debours.find(d => d.id === newDebour.id);
    const currentDebourPos = debours.indexOf(currentDebour!);
    deboursCopy.splice(currentDebourPos, 1, newDebour);

    setDebours(deboursCopy);
    setCurrentDebours(deboursCopy.filter((d: Debour) => d.timestamp === currentDate));
    reset();
    closeDrawer();
  }

  const debourFormSubmit = () => {
    switch (mode) {
      case FormMode.New:
        return submitNew;
      case FormMode.Edit:
        return submitEdit;
      default:
        return () => {};
    }
  }

  const debourFormSubmitLabel = () => {
    switch (mode) {
      case FormMode.New:
        return 'Ajouter le débour';
      case FormMode.Edit:
        return 'Modifier le débour';
      default:
        return '';
    }
  }

  const amountDebourConcierge = () => currentDebours.reduce((previous, debour) => previous - debour.sortieCaisse, 0)

  const finalAmountConcierge = () => amount + amountDebourConcierge() + remboursement;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMoreVert = (event: Event, currentDebour: Debour) => {
    setAnchorEl(event.target);
    setDebourFocus(currentDebour);
    setOpen(true);
  }

  const handleEditDebour = () => {
    setMode(FormMode.Edit);
    setDebourEdit(debourFocus);
    openDrawer();
    setOpen(false);
  }

  const handleRemoveDebour = () => {
    setDebours(debours.filter(d => d.id !== debourFocus.id));
    setCurrentDebours(currentDebours.filter(d => d.id !== debourFocus.id));
    reset();
    setOpen(false);
  }

  const changeRemboursement = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    if (!isNaN(Number(input.value))) {
      setRemboursement(Number(input.value));
    }
  }

  return (
    <div className="CaisseConcierge">
      <div className="CaisseConciergeDebours">
        <div className="CaisseConciergeRow">
          <div className="CaisseConciergeRowColum">
            <div className="CaisseConciergeGreenCell">Montant caisse</div>
            <div className="CaisseConciergeGreyCell">{cashAmount}€</div>
          </div>
          <div className="CaisseConciergeRowColum">
            <div className="CaisseConciergeLargeNoCell"></div>
          </div>
          <div className="CaisseConciergeRowColum">
            <div className="CaisseConciergeLargeNoCell"></div>
            <div className="CaisseConciergeLargeNoCell"></div>
          </div>
        </div>
        <div className="CaisseConciergeRow">
          <div className="CaisseConciergeRowColum">
            <div className="CaisseConciergeBlueCell">N°de chambre</div>
            <div className="CaisseConciergeBlueCell">Nom du client</div>
          </div>
          <div className="CaisseConciergeRowColum">
            <div className="CaisseConciergeLargeBlueCell">Descriptif</div>
          </div>
          <div className="CaisseConciergeRowColum">
            <div className="CaisseConciergeBlueCell">Sortie de caisse</div>
            <div className="CaisseConciergeBlueCell">Reglement</div>
          </div>
        </div>
        {currentDebours.map((debour, key) => (
          <div className="CaisseConciergeRow" key={key}>
            <div className="CaisseConciergeRowColum">
              <div className="CaisseConciergeGreyCell">{debour.room > 0 ? debour.room : '/'}</div>
              <div className="CaisseConciergeGreyCell">{debour.lastName}</div>
            </div>
            <div className="CaisseConciergeRowColum">
              <div className="CaisseConciergeLargeGreyCell">{debour.description}</div>
            </div>
            <div className="CaisseConciergeRowColum">
              <div className="CaisseConciergeGreyCell">{debour.sortieCaisse}</div>
              <div className="CaisseConciergeGreyCell">{reglements.get(Number(debour.reglement))}</div>
            </div>
            <div className="CaisseConciergeOptionCell">
              <div className="CaisseConciergeOptionCellImage">
                <GnzImage src={moreVertical} width={32} height={32} clickable onClick={(e: MouseEvent) => handleMoreVert(e, debour)} />
              </div>
            </div>
          </div>
        ))}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem key="print" onClick={handleEditDebour}>
            Modifier débour
          </MenuItem>
          <MenuItem key="export" onClick={handleRemoveDebour}>
            Annuler débour
          </MenuItem>
        </Menu>
        <GnzButton small label="Débour concierge" onClick={newDebour} color="#fff" backgroundColor="#0B4B92" />
      </div>
      <div className="CaisseConciergeSummary">
        <div className="CaisseConciergeRow">
          <div className="CaisseConciergeRowColum">
            <div className="CaisseConciergeGreyCellLeft">Montant debour Concierge</div>
            <div className="CaisseConciergeGreyCell">{amountDebourConcierge()}</div>
          </div>
        </div>
        <div className="CaisseConciergeRow">
          <div className="CaisseConciergeRowColum">
            <div className="CaisseConciergeGreyCellLeft">Remboursement</div>
            <input type="text" className="CaisseConciergeGreyInputCell" value={remboursement} onChange={changeRemboursement} />
          </div>
        </div>
        <div className="CaisseConciergeRow">
          <div className="CaisseConciergeRowColum">
            <div className="CaisseConciergeGreyCellLeft">Montant finale</div>
            <div className="CaisseConciergeGreyCell">{finalAmountConcierge()}</div>
          </div>
        </div>
      </div>
      <GnzDrawer ref={drawerRef} component={
        <DebourForm
          mode={mode}
          title={debourFormTitle()} 
          closeForm={closeDrawer}
          debour={debourFormDebour()}
          submit={debourFormSubmit()}
          submitLabel={debourFormSubmitLabel()}
          submitColor="#003671"
          currentDate={currentDate}
        />
      } />
    </div>
  );
}

export default CaisseConciergeSecondary;
