import Modal from '@mui/material/Modal';
import LogoutIcon from '@mui/icons-material/Logout';
import { Menu } from '../types/types';
import { useState } from 'react';
import { menuService } from '../api/menuService';

interface AddMenuProps {
    onClose: () => void;
    restaurantId: number | undefined;
    onNewMenuAdded: (newMenu: Menu) => void;
}

const AddMenu: React.FC<AddMenuProps> = ({ onClose, restaurantId, onNewMenuAdded }) => {

    const initMenu: Menu = {
        id: 0,
        nom: "",
        prix: null,
        isPDJ: false,
        restaurantId: restaurantId || 0,
    };

    const [menu, setMenu] = useState < Menu > (initMenu);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setMenu({ ...menu, [name]: value });
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMenu({ ...menu, isPDJ: event.target.checked });
    };

    const handleSubmit = async () => {
        try {
            if(menu.nom === "" || menu.prix === null || menu.prix <= 0) {
                alert("Veuillez renseigner un nom et un prix valide");
                return;
            }
            
            const newMenu = await menuService.postMenu(menu);
            onNewMenuAdded(newMenu);
            onClose();
        } catch (error) {
            console.error("Error adding menu:", error);
        }
    };

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='modal-restau-add'>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h1>Ajouter un nouveau menu</h1>
                    <LogoutIcon
                        style={{ color: "darkgray", fontSize: "36px", cursor: "pointer" }}
                        onClick={onClose}
                    />
                </div>

                <div className='restau-column2'>
                    <input
                        type="text"
                        id='menu-restau'
                        className='text-restau'
                        placeholder='Demi - Pension'
                        style={{
                            marginRight: '20px'
                        }}
                        name='nom'
                        value={menu.nom}
                        onChange={handleInputChange}
                    />
                    <input
                        type="number"
                        className='text-restau'
                        placeholder='15 €'
                        style={{
                            width: '50px'
                        }}
                        name='prix'
                        value={menu.prix !== null ? menu.prix.toString() : ''}
                        onChange={handleInputChange}
                    />
                </div>
                <div className='restau-column2'>
                    <input
                        type="checkbox"
                        name="isPDJ"
                        id="PDJid"
                        checked={menu.isPDJ}
                        onChange={handleCheckboxChange}
                    />
                    <label style={{ color: "#7F7F7F" }} htmlFor="PDJid">PDJ</label>
                </div>
                <button className='restau-btn restau-modal-btn' onClick={handleSubmit}>
                    Ajouter
                </button>
            </div>
        </Modal>
    );
}

export default AddMenu;