import './AddCommentForm.css';

import { useState } from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';

import Mission from '../../../../models/Mission';
import MissionStatus from '../../../../enums/MissionStatus';
import GnzButton from '../../../shared/gnz-button/GnzButton';

interface IAddCommentForm {
  mission: Mission,
  confirmSubmit: any,
  cancelSubmit: any,
}

function AddCommentForm(props: IAddCommentForm) {
  const [comment, setComment] = useState('');
  const [status, setStatus] = useState(MissionStatus.None);

  const confirmSubmit = () => {
    if (comment.length && status !== MissionStatus.None) {
      props.confirmSubmit(props.mission, comment, status);
    }
  }

  return (
    <div className="AddCommentForm">
      <DialogTitle id="responsive-dialog-title">
        Nouveau commentaire
      </DialogTitle>
      <DialogContent>
        <div className="AddCommentFormContent">
          <div className="AddCommentFormInputTitle">Commentaire</div>
          <textarea placeholder="Commentaire" value={comment} onChange={(e) => setComment(e.target.value)} spellCheck={false}></textarea>
          <div className="AddCommentFormInputTitle">Mettre à jour le status</div>
          <select value={status} onChange={(e) => setStatus(e.target.value as unknown as MissionStatus)}>
            <option value={MissionStatus.None}></option>
            <option value={MissionStatus.Done}>Fait</option>
            <option value={MissionStatus.Waiting}>En attente</option>
          </select>
        </div>
      </DialogContent>
      <DialogActions>
        <GnzButton label="Confirmer" onClick={confirmSubmit} backgroundColor="#dc3545" />
        <GnzButton label="Annuler" onClick={props.cancelSubmit} />
      </DialogActions>
    </div>
  );
}

export default AddCommentForm;
