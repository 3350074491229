import { Dialog } from '@mui/material';
import { forwardRef, useImperativeHandle, useState } from 'react';

import './GnzDialog.css';

interface IGnzDialog {
  component: JSX.Element,
}

const GnzDialog = forwardRef(function GnzYesNoDialogNoRef(props: IGnzDialog, ref) {
  useImperativeHandle(ref, () => ({
    open() {
      setOpen(true);
    },
    close() {
      setOpen(false);
    }
  }));

  const [open, setOpen] = useState(false);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="responsive-dialog-title"
    >
      {props.component}
    </Dialog>
  );
})

export default GnzDialog;

