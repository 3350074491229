
export default class MissionReminder {
    public scedule: number = 0;
    public forMe: boolean = false;
    public forAllService: boolean = false;

    constructor(scedule: number = 0, forMe: boolean = false, forAllService: boolean = false) {
        this.scedule = scedule;
        this.forMe = forMe;
        this.forAllService = forAllService;
    }
}