import * as React from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import Created from "../../static/created.svg";
import { useNavigate } from "react-router-dom";

interface FicheProps {
  id: Number;
}

export default function Variants({id}:FicheProps) {
    const navigate = useNavigate();

   const handleButtonClick = () => {
     navigate(`/fiche/${id}`);
   };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div>
        <img src={Created} className="created" alt="adel" />
      </div>
      <Grid
        container
        xs={12}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        marginTop={2}
      >
        <Button
          variant="outlined"
          onClick={handleButtonClick}
          sx={{
            color: "black",
            border: "1px solid rgb(0, 0, 0)",
            fontWeight: "bold",
            fontSize: "0.6rem",
            paddingY: 1,
            borderRadius: "7px",
          }}
        >
          Voir la fiche d'employé
        </Button>
        <Button
          variant="contained"
          sx={{
            fontWeight: "bold",
            fontSize: "0.5rem",
            paddingY: 1,
            backgroundColor: "#0B4B92",
            borderRadius: "7px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "0.5rem",
              paddingY: 1,
              backgroundColor: "#0B4B92",
              display: "flex",
              alignItems: "center",
            }}
          >
            <svg
              width="15"
              height="15"
              style={{ marginRight: 3 }}
              viewBox="0 0 20 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 0.47998H2C0.9 0.47998 0.00976562 1.37998 0.00976562 2.47998L0 14.48C0 15.58 0.9 16.48 2 16.48H18C19.1 16.48 20 15.58 20 14.48V2.47998C20 1.37998 19.1 0.47998 18 0.47998ZM18 4.47998L10 9.47998L2 4.47998V2.47998L10 7.47998L18 2.47998V4.47998Z"
                fill="white"
              />
            </svg>
            ENVOYER EMAIL A L'EMPLOYE
          </Typography>
        </Button>
      </Grid>
    </Box>
  );
}
