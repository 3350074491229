import Modal from '@mui/material/Modal';
import LogoutIcon from '@mui/icons-material/Logout';
import { Menu } from '../types/types';
import { useEffect, useState } from 'react';
import { menuService } from '../api/menuService';

interface UpdateMenuProps {
    onClose: () => void;
    initialMenu: Menu | null;
    onUpdate: (updatedMenu: Menu) => void;
}

const UpdateMenu: React.FC<UpdateMenuProps> = ({ onClose, initialMenu, onUpdate }) => {

    const defaultMenu: Menu = {
        id: 0,
        nom: '',
        prix: 0,
        isPDJ: false,
        restaurantId: 0,
    };

    const [menu, setMenu] = useState < Menu > (initialMenu || defaultMenu);

    useEffect(() => {
        if (initialMenu) {
            setMenu(initialMenu);
        }
    }, [initialMenu]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setMenu({ ...menu, [name]: value });
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMenu({ ...menu, isPDJ: event.target.checked });
    };

    const handleSubmit = async () => {
        if (initialMenu) {
            try {

                if (menu.nom === "" || menu.prix === null || menu.prix <= 0) {
                    alert("Veuillez renseigner un nom et un prix valide");
                    return;
                }

                const updatedMenu = await menuService.putMenu(initialMenu.id, menu);
                onUpdate(updatedMenu);
                onClose();
            } catch (error) {
                console.error("Error updating menu:", error);
            }
        } else {
            console.error("Initial menu is null");
        }
    };

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='modal-restau-add'>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h1>Modifié le menu</h1>
                    <LogoutIcon
                        style={{ color: "darkgray", fontSize: "36px", cursor: "pointer" }}
                        onClick={onClose}
                    />
                </div>
                <div className='restau-column2'>
                    <input
                        type="text"
                        id='nom'
                        name='nom'
                        className='text-restau'
                        placeholder='Demi - Pension'
                        style={{
                            marginRight: '20px'
                        }}
                        value={menu.nom}
                        onChange={handleInputChange}
                    />
                    <input
                        type="number"
                        name='prix'
                        className='text-restau'
                        placeholder='15 €'
                        style={{
                            width: '50px'
                        }}
                        value={menu.prix?.toString()}
                        onChange={handleInputChange}
                    />
                </div>
                <div className='restau-column2'>
                    <input
                        type="checkbox"
                        name="isPDJ"
                        id="PDJid"
                        checked={menu.isPDJ}
                        onChange={handleCheckboxChange}
                    />
                    <label style={{ color: "#7F7F7F" }} htmlFor="PDJid">PDJ</label>
                </div>
                <button className='restau-btn restau-modal-btn' onClick={handleSubmit}>
                    Appliquer
                </button>
            </div>
        </Modal>
    );
}

export default UpdateMenu;