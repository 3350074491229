import './NoMatchInfo.css';

function NoMatchInfo() {
    return (
        <div className="NoMatchInfo">
            Oops, la page que vous cherchez n'est pas disponible !
        </div>
    );
}

export default NoMatchInfo;
