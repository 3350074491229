import './NoMatch.css';

import GnzPaper from '../shared/gnz-paper/GnzPaper';
import NoMatchInfo from './no-match-info/NoMatchInfo';

function NoMatch() {
    return (
        <div className="NoMatch">
            <GnzPaper component={<NoMatchInfo />}/>
        </div>
    );
}

export default NoMatch;
