import { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import Client from '../../../models/Client';
import FormMode from '../../../enums/FormMode';
import axios from 'axios';
import './ProfilClientsForm.css';
// import Tri from '../../../../models/Tri';
// import { Radio, RadioGroup } from '@mui/material';

interface IProfilClientsForm {
  mode: FormMode,
  currentDate: number,
  title: string,
  closeForm: () => void,
  submitLabel: string,
  submitColor: string,
}

function ProfilClientsForm(props: IProfilClientsForm) { 
  const [client, Client] = useState<Array<Client>>([]);
  const Url = 'https://www.mysharingsystem.com/api/';

  useEffect(() => {    
     axios.get(Url + 'ClientType')
      .then(res => Client(res.data));     
  }, [])

  return (
    <div className="FeedbackForm">
      <div className="FeedbackFormTitle">{props.title}</div>
      <div className="FeedbackFormClose" onClick={props.closeForm}>X</div>
      <div className="PrimaryHeader">
        <div className="PrimaryHeaderSeparator"></div>
      </div>      
      <div className="">Client</div>
      <br></br>     
      <div className="">
      {client.map(client => (
              <p><Checkbox value={client.id} /> {client.name}</p>
            ))}
      </div>   
      <div className="PrimaryHeader">
        <div className="PrimaryHeaderSeparator"></div>
      </div>

      <div className="ProfilClientFormActions">
        <button className='ProfilClientButtonTout'>Tout effacer</button>
        <button>Rechercher (36)</button>
      </div>
    </div>
  );
}

export default ProfilClientsForm;
