import FeddbackStatus from "../enums/ConsigneStatus";
import FeedbackServices from "../enums/FeedbackServices";
import FeddbackEvent from "./ConsigneEvent";

export default class Feedback {
    public id: number = 0;
    public title: string = '';     
    public comment: string = '';    
    public status: FeddbackStatus = FeddbackStatus.None;
    public service: FeedbackServices = FeedbackServices.None;
    public timestamp: number = 0;
    public events: Array<FeddbackEvent> = [];
    public statusClient: string = '';
    public solution: string = '';    
    public customerId: string = ''; 
    public idStatus: string = ''; 
    

    constructor(
        title: string = '',           
        status: FeddbackStatus = FeddbackStatus.None,
        service: FeedbackServices = FeedbackServices.None,
        timestamp: number = 0,
        events: Array<FeddbackEvent> = [],
        id: number = 0,
        comment: string = '',
        statusClient: string = '',
        solution: string = '',
        customerId: string = '',   
        idStatus: string = '',   
    ) {
        this.id = id;
        this.title = title;               
        this.status = status;
        this.service = service;
        this.timestamp = timestamp;
        this.events = events;      
        this.comment = comment;        
        this.statusClient = statusClient; 
        this.solution = solution; 
        this.customerId = customerId;
        this.idStatus = idStatus;
    }
}