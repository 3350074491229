import './CheckListTable.css';

import * as React from 'react';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Mission from '../../../models/Mission';
import FormMode from '../../../enums/FormMode';
import { RootState } from '../../../redux/store';
import user from '../../../images/shared/user.png';
import MissionEvent from '../../../models/MissionEvent';
import MissionEventType from '../../../enums/MissionEventType';
import MissionReminder from '../../../models/MissionReminder';
import MissionStatus from '../../../enums/MissionStatus';
import GnzImage from '../../shared/gnz-image/GnzImage';
import GnzButton from '../../shared/gnz-button/GnzButton';
import GnzDrawer from '../../shared/gnz-drawer/GnzDrawer';
import CheckListForm from './check-list-form/CheckListForm';
import GnzDialog from '../../shared/gnz-dialog/GnzDialog';
import AddReminderForm from './add-reminder-form/AddReminderForm';
import RemoveReminderForm from './remove-reminder-form/RemoveReminderForm';
import AddCommentForm from './add-comment-form/AddCommentForm';

const status = new Map([
  [0, ''],
  [1, 'A faire'],
  [2, 'En attente'],
  [3, 'En cours'],
  [4, 'Fait'],
])

interface IRow {
  mission: Mission,
  newReminder: any,
  removeReminder: any,
  addComment: any,
}

function Row(props: IRow) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row" className="RowCellSchedule">
          <div className="RowCellScheduleContent">
            {moment(props.mission.schedule).format('HH:mm')}
          </div>
        </TableCell>
        <TableCell component="th" scope="row" className="RowCell">
          <div className="RowCellContent">
            {props.mission.description}
          </div>
        </TableCell>
        <TableCell component="th" scope="row" className="RowCell">
          <div className="RowCellContent">
            {status.get(Number(props.mission.status))}
          </div>
        </TableCell>
        <TableCell component="th" scope="row" className="RowCellArrow">
          <div className="RowCellArrowContent">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6} className="HiddenTableCell">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {props.mission.events.filter(e => Number(e.type) === MissionEventType.Wait || Number(e.type) === MissionEventType.Done).map(event => (
                <div className="MissionRow">
                  <div className="MissionRowImage">
                    <GnzImage src={user} title="demo" />
                  </div>
                  <div className="MissionRowInfo">{event.type === MissionEventType.Wait ? 'En attente' : 'Fait'} le {moment(event.date).format('DD-MM-YYYY')} à {moment(event.date).format('HH')}h{moment(event.date).format('mm')} par {event.user} {event.info.length ? `(${event.info})` : ''}</div>
                </div>
              ))}
              <div className="MissionActions">
                <GnzButton small label="Ajouter un reminder" onClick={() => props.newReminder(props.mission)} color="#fff" backgroundColor="#E27918" borderRadius="100px" />
                <GnzButton small label="Retirer un reminder" onClick={()  => props.removeReminder(props.mission)} color="#fff" backgroundColor="#659EDE" borderRadius="100px" />
                <GnzButton small label="Laisser un commentaire" onClick={() => props.addComment(props.mission)} color="#fff" backgroundColor="#0B4B92" borderRadius="100px" />
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

interface ICheckListTable {
}

export default function CheckListTable(props: ICheckListTable) {
  const addReminderDialogRef = useRef<HTMLDivElement>(null);
  const removeReminderDialogRef = useRef<HTMLDivElement>(null);
  const addCommentDialogRef = useRef<HTMLDivElement>(null);

  const { userName } = useSelector((state: RootState) => state.user);
  const { currentDate } = useSelector((state: RootState) => state.app);
  const drawerRef = useRef<HTMLDivElement>(null);
  const [mode, setMode] = useState<FormMode>(FormMode.None);
  const [missionNew, setMissionNew] = useState<Mission>(new Mission());
  const [missions, setMissions] = useState<Array<Mission>>([]);
  const [mission, setMission] = useState<Mission>(new Mission());
  const [currentMissions, setCurrentMissions] = useState<Array<Mission>>([]);


  useEffect(() => {
    if (localStorage.getItem('missions')) {
      setMissions(JSON.parse(localStorage.getItem('missions')!));
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem('missions')) {
      setCurrentMissions(JSON.parse(localStorage.getItem('missions')!).filter((m: Mission) => m.timestamp === currentDate));
    }
  }, [currentDate])

  useEffect(() => {
    saveMissions();
    // eslint-disable-next-line
  }, [missions])

  const newMission = () => {
    setMode(FormMode.New);
    setMissionNew(new Mission());
    openDrawer();
  };

  const openDrawer = () => {
    const drawerRefEl: any = drawerRef.current;
    drawerRefEl.open();
  }

  const closeDrawer = () => {
    const drawerRefEl: any = drawerRef.current;
    drawerRefEl.close();
  }

  const reset = () => {
    setMissionNew(new Mission());
    setMission(new Mission());
  }

  const submitNew = (newMission: Mission) => {
    newMission.id = missions.length ? Math.max(...missions.map(c => c.id)) + 1 : 1;
    newMission.events = [new MissionEvent(
      MissionEventType.Create,
      userName,
      Date.now()
    )]

    setMissions([...missions, newMission]);
    setCurrentMissions([...currentMissions, newMission]);
    reset();
    closeDrawer();
  }

  const saveMissions = () => {
    if (missions.length) {
      localStorage.setItem('missions', JSON.stringify(missions));
    }
  }

  const newReminder = (mission: Mission) => {
    setMission(mission);
    openAddReminderDialog();
  }

  const removeReminder = (mission: Mission) => {
    setMission(mission);
    openRemoveReminderDialog();
  }

  const addComment = (mission: Mission) => {
    setMission(mission);
    openAddCommentDialog();
  }

  const openAddReminderDialog = () => {
    const addReminderDialogRefEl: any = addReminderDialogRef.current;
    addReminderDialogRefEl.open();
  }

  const closeAddReminderDialog = () => {
    const addReminderDialogRefEl: any = addReminderDialogRef.current;
    addReminderDialogRefEl.close();
  }

  const addReminderConfirm = (newMission: Mission, reminder: MissionReminder) => {
    const missionsCopy = [...missions];

    newMission.reminder = reminder;

    const currentMission = missions.find(c => c.id === newMission.id);
    const currentMissionPos = missions.indexOf(currentMission!);
    missionsCopy.splice(currentMissionPos, 1, newMission);

    setMissions(missionsCopy);
    setCurrentMissions(missionsCopy.filter((m: Mission) => m.timestamp === currentDate));
    reset();
    closeAddReminderDialog();
  }

  const openRemoveReminderDialog = () => {
    const removeReminderDialogRefEl: any = removeReminderDialogRef.current;
    removeReminderDialogRefEl.open();
  }

  const closeRemoveReminderDialog = () => {
    const removeReminderDialogRefEl: any = removeReminderDialogRef.current;
    removeReminderDialogRefEl.close();
  }

  const openAddCommentDialog = () => {
    const addCommentDialogRefEl: any = addCommentDialogRef.current;
    addCommentDialogRefEl.open();
  }

  const closeAddCommentDialog = () => {
    const addCommentDialogRefEl: any = addCommentDialogRef.current;
    addCommentDialogRefEl.close();
  }

  const removeReminderConfirm = (newMission: Mission) => {
    closeRemoveReminderDialog();

    setTimeout(() => {
      const missionsCopy = [...missions];

      newMission.reminder = new MissionReminder();
  
      const currentMission = missions.find(c => c.id === newMission.id);
      const currentMissionPos = missions.indexOf(currentMission!);
      missionsCopy.splice(currentMissionPos, 1, newMission);
  
      setMissions(missionsCopy);
      setCurrentMissions(missionsCopy.filter((m: Mission) => m.timestamp === currentDate));
      reset();
    }, 500);
  }

  const addCommentConfirm = (newMission: Mission, comment: string, status: MissionStatus) => {
    const missionsCopy = [...missions];

    let newType = MissionEventType.None;
    if (Number(status) === MissionStatus.Done) {
      newType = MissionEventType.Done;
    } else if (Number(status) === MissionStatus.Waiting) {
      newType = MissionEventType.Wait;
    }

    newMission.events.push(new MissionEvent(
      newType,
      userName,
      Date.now(),
      comment
    ));

    const currentMission = missions.find(c => c.id === newMission.id);
    const currentMissionPos = missions.indexOf(currentMission!);
    missionsCopy.splice(currentMissionPos, 1, newMission);

    setMissions(missionsCopy);
    setCurrentMissions(missionsCopy.filter((m: Mission) => m.timestamp === currentDate));
    reset();
    closeAddCommentDialog();
  }

  return (
    <div className="CheckListTable">
      <TableContainer component={Paper} className="CheckListTableContainer">
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell className="CheckListTableHeadCell">Horaire</TableCell>
              <TableCell className="CheckListTableHeadCellDescription">Description</TableCell>
              <TableCell className="CheckListTableHeadCell">Statut</TableCell>
              <TableCell className="CheckListTableHeadCellAction" />
            </TableRow>
          </TableHead>
          <TableBody>
            {currentMissions.map((mission, key) => (
              <Row key={key} mission={mission} newReminder={newReminder} removeReminder={removeReminder} addComment={addComment} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="CheckListTableBottomActions">
        <GnzButton label="Nouvelle mission" onClick={newMission} color="#fff" backgroundColor="#0B4B92" />
      </div>
      <GnzDrawer ref={drawerRef} component={
        <CheckListForm
          mode={mode}
          currentDate={currentDate}
          title="Nouvelle mission" 
          closeForm={closeDrawer}
          mission={missionNew}
          submit={submitNew}
          submitLabel="Ajouter la mission"
          submitColor="#003671"
        />
      } />
      <GnzDialog ref={addReminderDialogRef} component={
        <AddReminderForm
          mission={mission}
          confirmSubmit={addReminderConfirm}
          cancelSubmit={closeAddReminderDialog}
        />}
      />
      <GnzDialog ref={removeReminderDialogRef} component={
        <RemoveReminderForm
          mission={mission}
          confirmSubmit={removeReminderConfirm}
          cancelSubmit={closeRemoveReminderDialog}
        />}
      />
      <GnzDialog ref={addCommentDialogRef} component={
        <AddCommentForm
          mission={mission}
          confirmSubmit={addCommentConfirm}
          cancelSubmit={closeAddCommentDialog}
        />}
      />
    </div>
  );
}
