import MissionStatus from "../enums/MissionStatus";
import MissionEvent from "./MissionEvent";
import MissionReminder from "./MissionReminder";

export default class Mission {
    public id: number = 0;
    public schedule: number = 0;
    public description: string = '';
    public status: MissionStatus = MissionStatus.None;
    public timestamp: number = 0;
    public events: Array<MissionEvent> = [];
    public reminder: MissionReminder = new MissionReminder();

    constructor(
        schedule: number = 0,
        description: string = '',
        status: MissionStatus = MissionStatus.None,
        timestamp: number = 0,
        events: Array<MissionEvent> = [],
        reminder = new MissionReminder(),
        id: number = 0
    ) {
        this.schedule = schedule;
        this.description = description;
        this.status = status;
        this.timestamp = timestamp;
        this.events = events;
        this.reminder = reminder;
        this.id = id;
    }
}