import * as React from "react";
import Emp from "./emp";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Button, Typography, Box, Paper, Stack } from "@mui/material";
import axios from "axios";

const apiUrl =
  window.location.hostname === "localhost"
    ? "https://localhost:7142/api"
    : "/api";

interface MultiProps {
  selectedservice: string;
  respo: string;
  onCancel: () => void;
}

const Multi: React.FC<MultiProps> = ({ selectedservice, respo }) => {
  const [chef, setChef] = React.useState<any[]>([]);
  const [filteredEmployees, setFilteredEmployees] = React.useState<any[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/NvDpt/${selectedservice}`);

        if (response.data && response.data.employees) {
          setChef(
            response.data.employees.filter(
              (employee: any) => employee.createdByDpt === true
            )
          );
          setFilteredEmployees(
            response.data.employees.filter(
              (employee: any) => employee.responsabilite === respo
            )
          );
        }
      } catch (error) {
        console.error("Error fetching department data:", error);
      }
    };

    fetchData();
  }, [selectedservice, respo]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Emp
          poste={chef[0]?.poste}
          nom={chef[0]?.nom}
          prenom={chef[0]?.prenom}
          profilePicture={chef[0]?.profilePicture}
        />
        <svg
          width="4"
          height="119"
          viewBox="0 0 4 119"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 2L1.99999 117"
            stroke="white"
            stroke-width="4"
            stroke-linecap="round"
            stroke-dasharray="7 7"
          />
        </svg>

        <Paper
          sx={{ padding: 4, height: 430, overflow: "hidden" }}
          className="col"
        >
          <Typography variant="h5" fontWeight="bold" marginBottom={1}>
            {filteredEmployees.length} Collaborateurs
          </Typography>
          <PerfectScrollbar>
            <Stack display="flex" flexDirection="column">
              {filteredEmployees.map((employee: any) => (
                <Box
                  sx={{ backgroundColor: "#F0F4F8", width: "95%" }}
                  display="flex"
                  justifyContent="space-between"
                  marginY={1}
                  padding={1}
                  borderRadius={2}
                >
                  <div style={{ display: "flex" }}>
                    <img
                      src={employee.profilePicture}
                      alt="adel"
                      width={50}
                      height={50}
                      style={{
                        borderRadius: "50%",
                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
                      }}
                    />
                    <div>
                      <Typography
                        fontWeight="bold"
                        marginLeft={2}
                        width={{ xs: 100, sm: 150, md: 200 }}
                      >
                        {employee.nom} {employee.prenom}
                      </Typography>
                      <Typography
                        marginLeft={2}
                        color="rgba(0,0,0,0.5)"
                        width={{ xs: 100, sm: 150, md: 200 }}
                      >
                        {employee.poste}
                      </Typography>
                    </div>
                  </div>
                  <Button href={`fiche/${employee.id}`}>
                    <svg
                      width="4"
                      height="13"
                      viewBox="0 0 4 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse
                        cx="2.49349"
                        cy="11.2663"
                        rx="1.50651"
                        ry="1.48957"
                        transform="rotate(180 2.49349 11.2663)"
                        fill="#003671"
                      />
                      <ellipse
                        cx="2.49301"
                        cy="6.49969"
                        rx="1.50651"
                        ry="1.48957"
                        transform="rotate(180 2.49301 6.49969)"
                        fill="#003671"
                      />
                      <ellipse
                        cx="2.49301"
                        cy="1.73309"
                        rx="1.50651"
                        ry="1.48957"
                        transform="rotate(180 2.49301 1.73309)"
                        fill="#003671"
                      />
                    </svg>
                  </Button>
                </Box>
              ))}

              <div style={{ width: "5%", height: "100%" }}></div>
            </Stack>
          </PerfectScrollbar>
        </Paper>
      </Box>
    </>
  );
};

export default Multi;
