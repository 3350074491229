import './Checklist.css';

import GnzBreadcrumb from '../shared/gnz-breadcrumb/GnzBreadcrumb';
import GnzPaper from '../shared/gnz-paper/GnzPaper';
import CheckListTable from './check-list-table/CheckListTable';

function Checklist() {
    return (
        <div className="Checklist">
            <GnzBreadcrumb breadcrumbs={['Conciergerie', 'Check list']}/>
            <GnzPaper component={<CheckListTable />}/>
        </div>
    );
}

export default Checklist;
