import * as React from "react";
import {
  Button,
  Grid,
  Paper,
  Typography,
  Pagination,
  Container,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Box } from "@mui/material";
import Filter from "../liste/filterEmployee";
const formattedDate = (dateTimeString: any) => {
  if (!dateTimeString) return "";
  const dateOnlyString = dateTimeString.split("T")[0];
  return dateOnlyString;
};
const apiUrl =
  window.location.hostname === "localhost"
    ? "https://localhost:7142/api"
    : "/api";
const handleDownload = (pdfUrl: string) => {
  const decodedPdfData = atob(pdfUrl);
  const byteNumbers = new Array(decodedPdfData.length);
  for (let i = 0; i < decodedPdfData.length; i++) {
    byteNumbers[i] = decodedPdfData.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "filename.pdf";
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};
const EmployeeAbsenceComponent = ({
  absence,
  
}: any) => {
  return (
    <Box my={1} sx={{ backgroundColor: absence.couleurDuService }} p={2}>
      <Grid container>
        <Grid
          xs={6}
          sm={3}
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <img
            src={absence.profilePicture}
            alt={absence.name}
            width={30}
            height={30}
            style={{
              borderRadius: "50%",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
            }}
          />
          <Typography
            marginLeft={1}
            fontWeight="bold"
            sx={{ color: "rgba(0,0,0,0.6)" }}
          >
            {absence.nom} {absence.prenom}
          </Typography>
        </Grid>
        <Grid xs={6} sm={3} item justifyContent="center" alignItems="center" display="flex">
          <Typography
            textAlign="center"
            marginLeft={1}
            sx={{ color: "rgba(0,0,0,0.6)" }}
          >
            {absence.motif}
          </Typography>
        </Grid>
        <Grid
          xs={6}
          sm={3}
          item
          flexDirection="row"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography marginLeft={1} sx={{ color: "rgba(0,0,0,0.6)" }}>
            {formattedDate(absence.date_out)}
          </Typography>
        </Grid>
        <Grid
          xs={6}
          sm={3}
          item
          flexDirection="row"
          display="flex"
          justifyContent="center"
        >
          <Button
            onClick={() => handleDownload(absence.pdfFile)}
            aria-label="download"
            style={{
              border: "none",
              background: "none",
              cursor: "pointer",
            }}
          >
            <Typography color="black" sx={{ textTransform: "none" }}>
              Document à partager
            </Typography>
            <svg
              width="28"
              height="29"
              viewBox="0 0 28 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.1001 0.599609L27.6599 6.39966V28.4596H6.87988V28.5297H27.73V6.4696L22.1001 0.599609Z"
                fill="#909090"
              />
              <path
                d="M22.0305 0.530273H6.81055V28.4602H27.6604V6.40027L22.0305 0.530273Z"
                fill="#F4F4F4"
              />
              <path
                d="M6.65942 2.03027H0.269531V8.86023H20.3696V2.03027H6.65942Z"
                fill="#7A7B7C"
              />
              <path
                d="M20.4697 8.75018H0.389648V1.91016H20.4697V8.75018Z"
                fill="#DD2025"
              />
              <path
                d="M7.05029 3.05981H5.74023V7.8598H6.77026V6.23981L7.00024 6.25983C7.22024 6.24983 7.44015 6.20983 7.65015 6.13983C7.83015 6.07983 8.00014 5.97979 8.14014 5.84979C8.28014 5.71979 8.40021 5.56983 8.47021 5.38983C8.57021 5.09983 8.61032 4.78981 8.58032 4.48981C8.57032 4.26981 8.53021 4.04984 8.47021 3.83984C8.40021 3.68984 8.31019 3.55982 8.19019 3.43982C8.07019 3.32982 7.93027 3.24982 7.78027 3.18982C7.65027 3.13982 7.51036 3.10984 7.37036 3.08984C7.26036 3.06984 7.16029 3.05981 7.05029 3.05981ZM6.86035 5.3598H6.77026V3.87982H6.97021C7.05021 3.86982 7.14021 3.87979 7.22021 3.90979C7.29021 3.94979 7.37017 3.98981 7.42017 4.05981C7.54017 4.21981 7.61035 4.40981 7.61035 4.61981C7.61035 4.85981 7.61014 5.07981 7.39014 5.23981C7.23014 5.32981 7.04035 5.3698 6.86035 5.3598ZM10.5303 3.0498C10.4203 3.0498 10.3102 3.05981 10.2402 3.05981L10.0002 3.06982H9.22021V7.86981H10.1401C10.4901 7.87981 10.8402 7.81976 11.1702 7.68976C11.4302 7.58976 11.6603 7.41977 11.8503 7.19977C12.0203 6.97977 12.1502 6.71977 12.2202 6.44977C12.3002 6.13977 12.3403 5.81982 12.3303 5.49982C12.3503 5.11982 12.3202 4.74982 12.2502 4.37982C12.1702 4.09982 12.0303 3.84983 11.8503 3.63983C11.7003 3.46983 11.5103 3.33981 11.3103 3.23981C11.1303 3.15981 10.9503 3.09981 10.7603 3.05981C10.6903 3.04981 10.6103 3.0498 10.5303 3.0498ZM10.3503 6.9798H10.2502V3.9198H10.2603C10.4703 3.8998 10.6804 3.92985 10.8604 4.02985C11.0004 4.13985 11.1102 4.26981 11.1902 4.42981C11.2702 4.58981 11.3203 4.76983 11.3303 4.94983C11.3403 5.16983 11.3303 5.34982 11.3303 5.49982C11.3303 5.66982 11.3203 5.84984 11.3003 6.01984C11.2703 6.18984 11.2101 6.35984 11.1301 6.51984C11.0401 6.65984 10.9103 6.77982 10.7703 6.87982C10.6403 6.94982 10.5003 6.9898 10.3503 6.9798ZM15.4302 3.06982H13.0002V7.86981H14.0303V5.95978H15.3303V5.06976H14.0303V3.95984H15.4302V3.06982Z"
                fill="#464648"
              />
              <path
                d="M19.7799 18.7799C19.7799 18.7799 22.9698 18.2099 22.9698 19.2999C22.9698 20.3799 20.9899 19.9399 19.7799 18.7799ZM17.4198 18.8699C16.9198 18.9799 16.4198 19.1398 15.9498 19.3598L16.35 18.4598C16.75 17.5598 17.1698 16.3298 17.1698 16.3298C17.6398 17.1298 18.1999 17.8898 18.8199 18.5798C18.3499 18.6498 17.8798 18.7499 17.4198 18.8699ZM16.1598 12.3699C16.1598 11.4199 16.4698 11.1599 16.7098 11.1599C16.9498 11.1599 17.2198 11.2698 17.2198 12.0998C17.1498 12.9298 16.9698 13.7399 16.7098 14.5299C16.3398 13.8699 16.1598 13.1299 16.1598 12.3699ZM11.5099 22.8799C10.5299 22.2999 13.56 20.4998 14.11 20.4398C14.11 20.4398 12.5399 23.4999 11.5099 22.8799ZM23.8998 19.4199C23.8898 19.3199 23.7999 18.2199 21.8299 18.2599C21.0099 18.2499 20.1897 18.3098 19.3797 18.4398C18.5897 17.6498 17.91 16.7499 17.36 15.7799C17.71 14.7799 17.9199 13.7399 17.9899 12.6799C17.9599 11.4799 17.6699 10.7899 16.7499 10.7999C15.8299 10.8099 15.6999 11.6198 15.8199 12.8198C15.9399 13.6198 16.1598 14.4099 16.4798 15.1599C16.4798 15.1599 16.0599 16.4799 15.4999 17.7899C14.9299 19.1099 14.5499 19.7999 14.5499 19.7999C13.5699 20.1199 12.6499 20.5898 11.8299 21.1998C10.9999 21.9698 10.67 22.5599 11.1 23.1499C11.47 23.6499 12.7798 23.7699 13.9498 22.2399C14.5798 21.4399 15.1398 20.6099 15.6498 19.7399C15.6498 19.7399 17.4399 19.2499 17.9899 19.1199C18.5499 18.9899 19.2198 18.8799 19.2198 18.8799C19.2198 18.8799 20.8498 20.5198 22.4198 20.4598C23.9898 20.3998 23.9098 19.5199 23.8998 19.4299"
                fill="#DD2025"
              />
              <path
                d="M21.9502 0.610352V6.48035H27.5903L21.9502 0.610352Z"
                fill="#909090"
              />
              <path
                d="M22.0303 0.530273V6.40027H27.6602L22.0303 0.530273Z"
                fill="#F4F4F4"
              />
              <path
                d="M6.96997 2.99048H5.66992V7.79053H6.69995V6.17053L6.92993 6.19043C7.14993 6.18043 7.37007 6.14043 7.57007 6.07043C7.76007 6.01043 7.92007 5.91052 8.07007 5.78052C8.21007 5.66052 8.3299 5.50043 8.3999 5.32043C8.4999 5.03043 8.54 4.72047 8.5 4.42047C8.5 4.20047 8.45989 3.98051 8.38989 3.77051C8.32989 3.62051 8.23012 3.49049 8.12012 3.38049C8.00012 3.26049 7.85995 3.18048 7.69995 3.12048C7.56995 3.07048 7.43004 3.04051 7.29004 3.02051C7.19004 3.00051 7.07997 2.99048 6.96997 2.99048ZM6.78003 5.29053H6.69995V3.81049H6.88989C6.97989 3.80049 7.05989 3.81052 7.13989 3.84052C7.21989 3.88052 7.2901 3.92048 7.3501 3.99048C7.4701 4.15048 7.53003 4.34047 7.53003 4.54047C7.53003 4.79047 7.53006 5.01053 7.31006 5.17053C7.15006 5.26053 6.97004 5.30052 6.79004 5.28052M10.46 2.98047C10.34 2.98047 10.2399 2.99048 10.1599 2.99048L9.92993 3.00049H9.1499V7.80054H10.0601C10.4101 7.81054 10.7601 7.75048 11.0901 7.62048C11.3601 7.52048 11.59 7.35049 11.77 7.13049C11.95 6.91049 12.0799 6.65049 12.1399 6.38049C12.2199 6.07049 12.26 5.75054 12.26 5.43054C12.28 5.05054 12.2499 4.68048 12.1699 4.30048C12.0999 4.03048 11.96 3.7805 11.77 3.5705C11.62 3.4005 11.44 3.27047 11.24 3.17047C11.06 3.09047 10.8799 3.03048 10.6899 2.99048C10.6099 2.98048 10.54 2.98047 10.46 2.98047ZM10.28 6.91052H10.1799V3.85046H10.1899C10.3999 3.83046 10.61 3.86051 10.79 3.96051C10.93 4.07051 11.0401 4.20047 11.1101 4.36047C11.2001 4.52047 11.25 4.70049 11.26 4.88049C11.27 5.10049 11.26 5.28054 11.26 5.43054C11.26 5.60054 11.25 5.78043 11.22 5.94043C11.19 6.12043 11.14 6.29043 11.05 6.44043C10.96 6.59043 10.8399 6.71054 10.6899 6.80054C10.5699 6.88054 10.42 6.92052 10.28 6.91052ZM15.3501 3.00049H12.9199V7.80054H13.95V5.8905H15.25V5.00049H13.95V3.8905H15.3501V3.00049Z"
                fill="white"
              />
            </svg>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default function List() {
  const [employees, setEmployees] = React.useState([]);
  const [allAbsences, setAllAbsences] = React.useState([]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [selectedDpts, setSelectedDpts] = React.useState<String[]>([]);
  const [responsabilité, setResponsabilite] = React.useState<String[]>([]);
  const [fromdate, setFromdate] = React.useState(null);
  const [todate, setTodate] = React.useState(null);
  const [selectedValue, setSelectedValue] = React.useState("");

  const employeesPerPage = 10;

  React.useEffect(() => {
    const fetchEmployees = async () => {
      try {
        let url = `${apiUrl}/Employee`;

        const filters = [];
        if (selectedDpts.length > 0) {
          const selectedDptFilter = selectedDpts.map(
            (dpt) => `dpt=${encodeURIComponent(String(dpt))}`
          );
          filters.push(...selectedDptFilter);
        }
        if (responsabilité.length > 0) {
          const responsabilitéFilter = responsabilité.map(
            (respo) => `respo=${encodeURIComponent(String(respo))}`
          );
          filters.push(...responsabilitéFilter);
        }

        if (fromdate) {
          filters.push(`fromdate=${encodeURIComponent(fromdate)}`);
        }
        if (todate) {
          filters.push(`todate=${encodeURIComponent(todate)}`);
        }

        if (filters.length > 0) {
          url += `?${filters.join("&")}`;
        }

        const response = await fetch(url);
        const data = await response.json();
        const employeesWithAbsences = data.filter(
          (employee: any) => employee.absences.length > 0
        );
        const allAbsencesArray = employeesWithAbsences.reduce(
          (absences: any[], employee: any) => {
            const employeeAbsences = employee.absences.map((absence: any) => ({
              ...absence,
              nom: employee.nom,
              prenom: employee.prenom,
              profilePicture: employee.profilePicture,
              couleurDuService: employee.couleurDuService, // 
            }));
            return absences.concat(employeeAbsences);
          },
          []
        );
          allAbsencesArray.sort(
            (a: any, b: any) =>
              new Date(b.date_out).getTime() - new Date(a.date_out).getTime()
          );


        setEmployees(employeesWithAbsences);
        setAllAbsences(allAbsencesArray);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, [selectedDpts, responsabilité, fromdate, todate]);

  React.useEffect(() => {
    if (selectedValue === "asc" || selectedValue === "desc") {
      setEmployees((prevEmployees) =>
        [...prevEmployees].sort((a: any, b: any) =>
          selectedValue === "asc"
            ? a.nom.localeCompare(b.nom)
            : b.nom.localeCompare(a.nom)
        )
      );
    } else if (selectedValue === "newer" || selectedValue === "older") {
      setEmployees((prevEmployees) =>
        [...prevEmployees].sort((a: any, b: any) =>
          selectedValue === "newer"
            ? new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            : new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        )
      );
    } else {
      setEmployees((prevEmployees) =>
        [...prevEmployees].sort((a, b) => (a as any).id - (b as any).id)
      );
    }
  }, [selectedValue]);

  const indexOfLastEmployee = currentPage * employeesPerPage;
  const indexOfFirstEmployee = indexOfLastEmployee - employeesPerPage;
  const currentEmployees = allAbsences.slice(
    indexOfFirstEmployee,
    indexOfLastEmployee
  );

  const handlePageChange = (event: any, value: any) => {
    setCurrentPage(value);
  };
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleButtonClick = () => {
    setOpenDialog(!openDialog);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  return (
    <Container
      maxWidth="xl"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
        
        <Grid container sx={{ justifyContent: "end", display: "flex" }}>
          <Button onClick={handleButtonClick}>
            <Typography
              sx={{
                textTransform: "none",
                color: "rgba(0,0,0,0.4)",
                border: "1px solid rgba(0,0,0,0.4)",
              }}
              padding={1}
              borderRadius={1}
            >
              Filtres - Trie
            </Typography>
          </Button>
        <Dialog open={openDialog} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogContent>
            <Filter
              setSelectedValue={setSelectedValue}
              selectedValue={selectedValue}
              selectedDpts={selectedDpts}
              setSelectedDpts={setSelectedDpts}
              responsabilité={responsabilité}
              setResponsabilite={setResponsabilite}
              fromdate={fromdate}
              setFromdate={setFromdate}
              todate={todate}
              setTodate={setTodate}
            />
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center", marginBottom: 2 }}>
            <Button onClick={handleClose} color="primary">
              <Typography
                sx={{
                  backgroundColor: "#0B4B92",
                  color: "white",
                  textTransform: "none",
                }}
                paddingY={1}
                paddingX={2}
              >
                Recherche
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <Paper
        sx={{
          maxWidth: 1536,
          width: "100%",
          // minHeight: 700,
          paddingX: 2,
          paddingY: 6,
        }}
      >
        <Box
          sx={{
            maxWidth: 1536,
            minHeight: 750,
          }}
        >
          {currentEmployees.map((absence: any) => (
            <EmployeeAbsenceComponent key={absence.id} absence={absence} />
          ))}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <Pagination
            count={Math.ceil(allAbsences.length / employeesPerPage)}
            page={currentPage}
            onChange={handlePageChange}
          />
        </Box>
      </Paper>
    </Container>
  );
}
