import Modal from '@mui/material/Modal';
import './modalRestau.css';
import { useState } from 'react';
// import RappelsClientForm from './rappelsClientForm';
import { RappelsClient as RappelsClientType } from '../types/types';

interface RappelsClientProps {
    onClose: () => void;
    initialData?: any;
    onUpdate?: (data: any) => void;
}

const RappelsClient: React.FC<RappelsClientProps> = ({ initialData, onUpdate, onClose }) => {

    const [localData, setLocalData] = useState < RappelsClientType > ({
        id: initialData?.id || 0,
        restaurantId: initialData?.restaurantId || 0,
        codeVestimentaireHomme: initialData?.codeVestimentaireHomme || '',
        codeVestimentaireFemme: initialData?.codeVestimentaireFemme || '',
        animauxCompagnie: initialData?.animauxCompagnie || '',
        enfantsCondition: initialData?.enfantsCondition || '',
        autreCondition: initialData?.autreCondition || '',
    })

    const handleSave = () => {
        onUpdate?.(localData);
        onClose();
    };

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setLocalData((prevData: RappelsClientType) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // const [showRappelsClientFormModal, setRappelsClientFormModal] = useState(false);
    // const openRappelsClientFormModal = () => { setRappelsClientFormModal(true); };
    // const closeRappelsClientFormModal = () => { setRappelsClientFormModal(false); };

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='modal-restau'>
                <h1>Rappel Clients</h1>
                <button
                    className='modal-close-btn'
                    onClick={onClose}
                >
                    x
                </button>
                <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div>
                        <div>
                            <h2>Code vestimentaire</h2>
                            <h3>Pour homme :</h3>
                            <textarea
                                name="codeVestimentaireHomme"
                                rows={2}
                                value={localData.codeVestimentaireHomme}
                                onChange={handleChange}
                            ></textarea>

                            <h3>Pour femme :</h3>
                            <textarea
                                name="codeVestimentaireFemme"
                                rows={2}
                                value={localData.codeVestimentaireFemme}
                                onChange={handleChange}
                            ></textarea>
                        </div>

                        <div>
                            <h2>Animaux de compagnie :</h2>
                            <textarea
                                name="animauxCompagnie"
                                rows={2}
                                value={localData.animauxCompagnie}
                                onChange={handleChange}
                            ></textarea>
                        </div>

                        <div>
                            <h2>Enfants</h2>
                            <h3>Veuillez saisire vos conditions</h3>
                            <textarea
                                name="enfantsCondition"
                                rows={2}
                                value={localData.enfantsCondition}
                                onChange={handleChange}
                            ></textarea>
                        </div>

                        <div>
                            <h2>Autres :</h2>
                            <textarea
                                name="autreCondition"
                                rows={2}
                                value={localData.autreCondition}
                                onChange={handleChange}
                            ></textarea>
                        </div>
                    </div>

                    <button className='restau-btn restau-modal-btn' onClick={handleSave}>
                        VALIDER
                    </button>
                    {/* {
                        showRappelsClientFormModal &&
                        <RappelsClientForm onClose={closeRappelsClientFormModal} />
                    } */}
                </div>
            </div>
        </Modal>
    );
}

export default RappelsClient;