import Itineraire from '../../../../models/Itineraire';
import { useNavigate } from 'react-router-dom';
import ItineraireItems from './ItineraireItems';

import './../../ItineraireClients/ItineraireClients';

import './ItineraireItems.css';

interface IItineraireItemsClients {
    consignes: Array<Itineraire>,
    editConsigne: any,
    cancelConsigne: any,
    retablirConsigne: any,
}

interface ItineraireItemsClients {
    itemTitle: string;
    itemDetails: string;
}

interface ItineraireItemsClientsItems {
    itemData: string;
    imgItem: string;
    itemDetails: string;
}

function ItineraireItemsClients(props: IItineraireItemsClients) {
    const navigate = useNavigate();
    const _infoItineraireItems: ItineraireItemsClientsItems[] = [
        { itemData: "10/08/2022", imgItem: "", itemDetails: "Heure decollage à 10h00 - Heure atterrissage à 10h30  - (type de vol)" },
        { itemData: "10/08/2022", imgItem: "", itemDetails: "Heure decollage à 10h00 - Heure atterrissage à 11h30  - (type de vol)" },
        { itemData: "10/08/2022", imgItem: "", itemDetails: "Heure decollage à 10h00 - Heure atterrissage à 12h30  - (type de vol)" }
    ];
    const _infoItineraire: ItineraireItemsClients[] = [
        { itemTitle: "Compagnie", itemDetails: "Monoclair" },
        { itemTitle: "Type d’hélicoptère", itemDetails: "H705" },
        { itemTitle: "Depuis", itemDetails: "Altiport Mégève" }
    ];

    return (
        <div>
            {_infoItineraireItems.map((r, index) => (

                <div>

                    <div className='div1'>
                        <td className=''><input type="checkbox" readOnly={true} /></td>
                    </div>

                    <div className='div2'>
                        <div className='headertrSelect'>
                            <td className='tableItinereire_data'> 10/08/2022</td>
                            <td className='tableItinereire_data'>Heure decollage à 10h00 - Heure atterrissage à 10h30  - (type de vol)</td>
                            <td className='tableItinereire_data'> </td>

                        </div>
                        <div >
                            <ItineraireItems />
                        </div>

                    </div>

                </div>

            ))}
        </div>
    );
}
export default ItineraireItemsClients