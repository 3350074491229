import more from '../../images/shared/more.png';
import greenArrowLeft from '../../images/shared/greenarrowleft.png';
import greenArrowRight from '../../images/shared/greenarrowright.png';
import { useState } from 'react';

import './Caisse.css';
import { useNavigate } from 'react-router-dom';
import GnzBreadcrumb from '../shared/gnz-breadcrumb/GnzBreadcrumb';
import GnzPaper from '../shared/gnz-paper/GnzPaper';
import GnzImage from '../shared/gnz-image/GnzImage';
import CaisseConciergePrimary from './caisse-concierge-primary/CaisseConciergePrimary';
import CaisseConciergeSecondary from './caisse-concierge-secondary/CaisseConciergeSecondary';

function Caisse() {
  const navigate = useNavigate();
  const [primaryScreen, setPrimaryScreen] = useState(true);

  return (
    <div className="Caisse">
      <GnzBreadcrumb breadcrumbs={['Rapport', 'Caisse concierge']}/>
      <GnzPaper component={primaryScreen ? <CaisseConciergePrimary /> : <CaisseConciergeSecondary />}/>
      <div className="CaisseBottomActions">
        <div className="CaisseBottomCenterActions">
          <div className="CaisseBottomCenterActionsBlueCircle" onClick={() => setPrimaryScreen(true)}>
            <GnzImage src={greenArrowLeft} clickable />
          </div>
          <div className={primaryScreen ? 'CaisseBottomCenterActionsActiveDot' : 'CaisseBottomCenterActionsDot'}></div>
          <div className={!primaryScreen ? 'CaisseBottomCenterActionsActiveDot' : 'CaisseBottomCenterActionsDot'}></div>
          <div className="CaisseBottomCenterActionsDotOther" onClick={() => navigate('/caisse-room')}></div>
          <div className="CaisseBottomCenterActionsDotOther" onClick={() => navigate('/caisse-direction')}></div>
          <div className="CaisseBottomCenterActionsDotOther" onClick={() => navigate('/caisse-hotel')}></div>
          <div className="CaisseBottomCenterActionsDotOther" onClick={() => navigate('/caisse-free')}></div>
          <div className="CaisseBottomCenterActionsBlueCircle" onClick={() => setPrimaryScreen(false)}>
            <GnzImage src={greenArrowRight} clickable />
          </div>
        </div>
        <GnzImage src={more} clickable onClick={() => {}} />
      </div>
    </div>
  );
}

export default Caisse;
