import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Dialog, Stack, Typography } from "@mui/material";
import EditDpt from "./edit_dpt";
import Multi from "./multi";

export default function SimplePaper({ selectedservice }:any) {
  const [isDialogDpt, setIsDialogDpt] = React.useState(false);
  const [isDialogDpt1, setIsDialogDpt1] = React.useState(false);
  const [isDialogDpt2, setIsDialogDpt2] = React.useState(false);
  const OpenDpt = () => {

    setIsDialogDpt(true);
  };
  const OpenDpt1 = () => {

    setIsDialogDpt1(true);
  };
  const OpenDpt2 = () => {

    setIsDialogDpt2(true);
  };
  const CloseDpt = () => {
    setIsDialogDpt(false);
  };
  const CloseDpt1 = () => {
    setIsDialogDpt1(false);
  };
  const CloseDpt2 = () => {
    setIsDialogDpt2(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          m: 1,
          width: 250,
          //   height: 128,
        },
      }}
    >
      <Stack
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          flexDirection: "column",
        }}
      >
        <Button
          onClick={OpenDpt}
          variant="text"
          sx={{
            padding: 0,
            borderRadius: "20px",
            paddingBottom: 1,
            paddingTop: 1,
            paddingLeft: 0.5,
            paddingRight: 0.5,
            marginTop: 1,
            marginBottom: 0.5,
            marginLeft: 1,
          }}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.625 11.3333L7.79167 14.1666H14.875V11.3333H10.625ZM8.5425 5.09286L2.125 11.5104V14.1666H4.78125L11.1988 7.74911L8.5425 5.09286ZM13.2529 5.69495C13.5292 5.4187 13.5292 4.95828 13.2529 4.6962L11.5954 3.0387C11.4626 2.90694 11.2831 2.83301 11.096 2.83301C10.909 2.83301 10.7295 2.90694 10.5967 3.0387L9.30042 4.33495L11.9567 6.9912L13.2529 5.69495Z"
              fill="black"
            />
          </svg>

          <Typography
            fontSize={10}
            color="black"
            mx={1}
            sx={{ fontFamily: "'Poppins', sans-serif" }}
          >
            Rénommer
          </Typography>
        </Button>
        <Dialog
          className="editDialog"
          sx={{ maxWidth: "100%" }}
          open={isDialogDpt}
          onClose={CloseDpt}
        >
          <EditDpt selectedservice={selectedservice} onCancel={CloseDpt} />
        </Dialog>
        <Button
          onClick={OpenDpt1}
          variant="text"
          sx={{
            padding: 0,
            borderRadius: "20px",
            paddingBottom: 1,
            paddingTop: 1,
            paddingLeft: 0.5,
            paddingRight: 0.5,
            marginTop: 0.5,
            marginBottom: 1,
            marginLeft: 1,
          }}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 16 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.33366 1.51317L2.18699 0.666504L13.3337 11.8132L12.487 12.6665L10.4337 10.6132C9.66699 10.8665 8.85366 10.9998 8.00033 10.9998C4.66699 10.9998 1.82033 8.9265 0.666992 5.99984C1.12699 4.8265 1.86033 3.79317 2.79366 2.97317L1.33366 1.51317ZM8.00033 3.99984C8.53076 3.99984 9.03947 4.21055 9.41454 4.58562C9.78961 4.9607 10.0003 5.4694 10.0003 5.99984C10.0007 6.22688 9.96234 6.45233 9.88699 6.6665L7.33366 4.11317C7.54784 4.03783 7.77328 3.9995 8.00033 3.99984ZM8.00033 0.999837C11.3337 0.999837 14.1803 3.07317 15.3337 5.99984C14.7896 7.38197 13.865 8.58165 12.667 9.45984L11.7203 8.5065C12.6423 7.86876 13.3858 7.00587 13.8803 5.99984C13.3414 4.8998 12.5046 3.97302 11.4652 3.32484C10.4257 2.67666 9.2253 2.33309 8.00033 2.33317C7.27366 2.33317 6.56033 2.45317 5.89366 2.6665L4.86699 1.6465C5.82699 1.23317 6.88699 0.999837 8.00033 0.999837ZM2.12033 5.99984C2.65929 7.09988 3.49604 8.02666 4.53548 8.67483C5.57492 9.32301 6.77535 9.66658 8.00033 9.6665C8.46033 9.6665 8.91366 9.61984 9.33366 9.5265L7.81366 7.99984C7.34977 7.95011 6.91688 7.74309 6.58698 7.41319C6.25708 7.08329 6.05005 6.6504 6.00033 6.1865L3.73366 3.91317C3.07366 4.47984 2.52033 5.1865 2.12033 5.99984Z"
              fill="black"
            />
          </svg>

          <Typography
            fontSize={10}
            color="black"
            mx={1}
            sx={{ fontFamily: "'Poppins', sans-serif" }}
          >
            Collaborateur 1{" "}
          </Typography>
        </Button>
        <Dialog
        
          className="collaborateurs"
          open={isDialogDpt1}
          onClose={CloseDpt1}
        >
          <Multi selectedservice={selectedservice} respo="Collaborateur 1" onCancel={CloseDpt1} />
        </Dialog>
        <Button
          onClick={OpenDpt2}
          variant="text"
          sx={{
            padding: 0,
            borderRadius: "20px",
            paddingBottom: 1,
            paddingTop: 1,
            paddingLeft: 0.5,
            paddingRight: 0.5,
            marginTop: 0.5,
            marginBottom: 1,
            marginLeft: 1,
          }}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 16 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.33366 1.51317L2.18699 0.666504L13.3337 11.8132L12.487 12.6665L10.4337 10.6132C9.66699 10.8665 8.85366 10.9998 8.00033 10.9998C4.66699 10.9998 1.82033 8.9265 0.666992 5.99984C1.12699 4.8265 1.86033 3.79317 2.79366 2.97317L1.33366 1.51317ZM8.00033 3.99984C8.53076 3.99984 9.03947 4.21055 9.41454 4.58562C9.78961 4.9607 10.0003 5.4694 10.0003 5.99984C10.0007 6.22688 9.96234 6.45233 9.88699 6.6665L7.33366 4.11317C7.54784 4.03783 7.77328 3.9995 8.00033 3.99984ZM8.00033 0.999837C11.3337 0.999837 14.1803 3.07317 15.3337 5.99984C14.7896 7.38197 13.865 8.58165 12.667 9.45984L11.7203 8.5065C12.6423 7.86876 13.3858 7.00587 13.8803 5.99984C13.3414 4.8998 12.5046 3.97302 11.4652 3.32484C10.4257 2.67666 9.2253 2.33309 8.00033 2.33317C7.27366 2.33317 6.56033 2.45317 5.89366 2.6665L4.86699 1.6465C5.82699 1.23317 6.88699 0.999837 8.00033 0.999837ZM2.12033 5.99984C2.65929 7.09988 3.49604 8.02666 4.53548 8.67483C5.57492 9.32301 6.77535 9.66658 8.00033 9.6665C8.46033 9.6665 8.91366 9.61984 9.33366 9.5265L7.81366 7.99984C7.34977 7.95011 6.91688 7.74309 6.58698 7.41319C6.25708 7.08329 6.05005 6.6504 6.00033 6.1865L3.73366 3.91317C3.07366 4.47984 2.52033 5.1865 2.12033 5.99984Z"
              fill="black"
            />
          </svg>

          <Typography
            fontSize={10}
            color="black"
            mx={1}
            sx={{ fontFamily: "'Poppins', sans-serif" }}
          >
            Collaborateur 2{" "}
          </Typography>
        </Button>
        <Dialog
        
          className="collaborateurs"
          open={isDialogDpt2}
          onClose={CloseDpt2}
        >
          <Multi selectedservice={selectedservice} respo="Collaborateur 2" onCancel={CloseDpt2} />
        </Dialog>
      </Stack>
    </Box>
  );
}
