import './Layout.css';

import { Outlet, useNavigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import TopNav from './top-nav/TopNav';
import PrimaryNav from './primary-nav/PrimaryNav';
import BottomNav from './bottom-nav/BottomNav';
import { RootState } from '../../../redux/store';

function Layout() {
    const navigate = useNavigate();
    const { userLoggedIn } = useSelector((state: RootState) => state.user);

    useEffect(() => {
        navigate(userLoggedIn ? window.location.pathname : '/');
        // eslint-disable-next-line
    }, [])

    const TopNavAuthenticated = () =>  userLoggedIn ? <TopNav /> : null;

    const PrimaryNavAuthenticated = () =>  userLoggedIn ? <PrimaryNav /> : null;

    const BottomNavAuthenticated = () => userLoggedIn ? <BottomNav /> : null;

    return (
        <div className='Layout'>
            <Toaster />
            <TopNavAuthenticated />
            <PrimaryNavAuthenticated />
            <div className='Outlet'>
                <Outlet />
            </div>
            <BottomNavAuthenticated />
        </div>
    );
}

export default Layout;
/*

            <Toaster />
            <TopNavAuthenticated />
            <PrimaryNavAuthenticated />

        <div className='Layout'>
            <Toaster />
            <div className='navbar-section'>
                <TopNavAuthenticated />
                <PrimaryNavAuthenticated />
            </div>
            <div className='Outlet'>
                <Outlet />
            </div>
            <BottomNavAuthenticated />
        </div>
*/