import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/fr_FR';
import dayjs from 'dayjs';

import './GnzTimePicker.css';

interface IGnzTimePicker {
  currentDate?: number,
  className: string,
  onChange?: any,
  disabled?: boolean,
}

function GnzTimePicker(props: IGnzTimePicker) {
  return (
    <DatePicker picker="time" className={props.className} locale={locale} format="HH:mm" value={dayjs(props.currentDate || Date.now())} onChange={props.onChange} disabled={props.disabled} />
  );
}

export default GnzTimePicker;

