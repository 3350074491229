import './GnzImage.css';

interface IGnzImage {
  className?: string,
  src: string,
  clickable?: boolean,
  onClick?: any,
  title?: string,
  width?: number,
  height?: number
}

function GnzImage(props: IGnzImage) {
  return (
    <img className={props.className} src={props.src} alt={props.title} title={props.title} onClick={props.onClick} style={{
      cursor: props.clickable ? 'pointer' : 'default',
      width: props.width ? props.width : 'default'
    }}></img>
  );
}

export default GnzImage;
