import './TopNav.css';

import Badge from '@mui/material/Badge';
import { grey } from '@mui/material/colors';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePickerProps } from 'antd';
import NotificationsIcon from '@mui/icons-material/Notifications';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from 'react-router-dom';

import logo from '../../../../images/top-nav/logo.png';
import userLogo from '../../../../images/top-nav/user-logo.png';
import GnzDatePicker from '../../gnz-date-picker/GnzDatePicker';
import { RootState } from '../../../../redux/store';
import { decrementDate, incrementDate, setDate } from '../../../../redux/appSlice';
import TopNavMenu from '../../../../enums/TopNavMenu';
import { setUserLoggedIn } from '../../../../redux/userSlice';

function TopNav() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentMenu, setCurrentMenu] = useState<TopNavMenu>(TopNavMenu.Empty);
    const { currentDate, countNotifications } = useSelector((state: RootState) => state.app);

    const zeroNumber = (value: number) => value < 10 ? '0' + value : value; 

    const onChangeAppCurrentDate: DatePickerProps['onChange'] = (date: any) => {
        if (date) {
            date.$d = new Date(`${date.$d.getFullYear()}-${zeroNumber(date.$d.getMonth() + 1)}-${zeroNumber(date.$d.getDate())}`);
            date.$d.setHours(2);
            dispatch(setDate(date.$d.getTime()));
        }
    };

    const onLogout = () => {
        localStorage.removeItem('username');
        localStorage.removeItem('jwt');
        dispatch(setUserLoggedIn(false));
        navigate('/');
    }

    return (
        <div className="TopNav">
            <div className="TopNavWrapper">
                <div className="TopNavLogo">
                    <div className="TopNavLogoImage" style={{ backgroundImage: `url(${logo})` }} onClick={() => navigate('/welcome')}></div>
                    <div className="TopNavAppName" onClick={() => navigate('/')}>MySharingSystem</div>
                </div>
                <div className="TopNavDate">
                    <KeyboardArrowLeftIcon className="TopNavDateLeft" fontSize='large' color="secondary" onClick={() => dispatch(decrementDate())} />
                    <GnzDatePicker currentDate={currentDate} onChange={onChangeAppCurrentDate} />
                    <KeyboardArrowRightIcon className="TopNavDateRight" fontSize='large' color="secondary" onClick={() => dispatch(incrementDate())} />
                </div>
                <div className="TopNavUser">
                    <div className="TopNavUserNotification" onClick={() => setCurrentMenu(currentMenu !== TopNavMenu.Notification ? TopNavMenu.Notification : TopNavMenu.Empty)}>
                        <Badge badgeContent={countNotifications} color="secondary">
                            <NotificationsIcon fontSize='medium' sx={{ color: grey[100]}} />
                        </Badge>
                        <div className="TopNavUserNotificationMenuBackdrop" style={{ display: currentMenu === TopNavMenu.Notification ? 'block' : 'none' }}></div>
                        <div className="TopNavUserNotificationMenu" style={{ display: currentMenu === TopNavMenu.Notification ? 'block' : 'none' }}>
                            <div className="TopNavUserNotificationMenuItem">Notification 1</div>
                            <div className="TopNavUserNotificationMenuItem">Notification 2</div>
                            <div className="TopNavUserNotificationMenuItem">Notification 3</div>
                        </div>
                    </div>
                    <div className="TopNavUserProfile" onClick={() => setCurrentMenu(currentMenu !== TopNavMenu.User ? TopNavMenu.User : TopNavMenu.Empty)}>
                        <div className="TopNavUserProfileImage" style={{ backgroundImage: `url(${userLogo})` }}></div>
                        <div className="TopNavUserProfileInfo">
                            <div className="TopNavUserProfileInfoName">Bonnard Julie</div>
                            <div className="TopNavUserProfileInfoRole">Directrice</div>
                            <div className="TopNavUserProfileInfoMenuBackdrop" style={{ display: currentMenu === TopNavMenu.User ? 'block' : 'none' }}></div>
                            <div className="TopNavUserProfileInfoMenu" style={{ display: currentMenu === TopNavMenu.User ? 'block' : 'none' }}>
                                <div className="TopNavUserProfileInfoMenuItem">Espace privé</div>
                                <div className="TopNavUserProfileInfoMenuItem">Agenda</div>
                                <div className="TopNavUserProfileInfoMenuItem">Paramètres</div>
                                <div className="TopNavUserProfileInfoMenuItem" onClick={() => navigate('/admin')}>Espace admin</div>
                                <div className="TopNavUserProfileInfoMenuItem">En pause - Au travail</div>
                                <div className="TopNavUserProfileInfoMenuItem" onClick={onLogout}>Déconnexion</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopNav;
