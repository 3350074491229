import Stack from "@mui/material/Stack";
import { Button, Typography } from "@mui/material";

const apiUrl = window.location.hostname === 'localhost' ? 'https://localhost:7142/api' : '/api';

export default function Empty() {
  const handleSubmit = async () => {
    try {
      const response = await fetch(`${apiUrl}/NvDpt/AddDefaultDepartments`, {
        method: "POST",
      });

      if (response.ok) {
        window.location.reload();
        console.log("Department generation successful");
      } else {
        console.error("Department generation failed");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      height="30vh"
    >
      <Typography>Text de bienvenue + explication </Typography>
      <Button onClick={handleSubmit} variant="contained">
        Generation initiale par defaut
      </Button>
    </Stack>
  );
}
