import { useEffect, useRef, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import FormMode from '../../../../enums/FormMode';
import GnzDrawer from '../../../shared/gnz-drawer/GnzDrawer';
import StaysClienteTable from '../StaysClientsTable/StaysClientsTable';
import ProfilClientsCarousel from '../ProfilClientsCarousel/ProfilClientsCarousel';
import ProfilClientsTagsForm from '../ProfilClientsDetailsForm/ProfilClientsTagsForm'
import ProfilClientsEditForm from '../ProfilClientsDetailsForm/ProfilClientsEditForm'
import CustomerDetails from '../../../../models/CustomerDetails';
import AllergiesCustomer from '../../../../models/AllergiesCustomer';
import FoodPreferencesCustomer from '../../../../models/FoodPreferencesCustomer';
import reservation from '../../../../models/Reservation';
import axios from 'axios';
import './ProfilClientsDetailsTable.css';

interface IProfilClientsDetailsTable {
}

interface UserCivilite {
  CiviliteTxt: string;
  MandameTxt: string;
}

function ProfilClientsDetailsTable(props: IProfilClientsDetailsTable) {

  const [mode, setMode] = useState<FormMode>(FormMode.None);
  const drawerprofilClientsEdit = useRef<HTMLDivElement>(null);  
  const drawerTags = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const myParam = searchParams.get('customerId');
  const [CustomerDetails, customerDetails] = useState<CustomerDetails>();
  const [allergiesCustomer, AllergiesCustomer] = useState<Array<AllergiesCustomer>>([]);
  const [foodPreferencesCustomer, FoodPreferencesCustomer] = useState<Array<FoodPreferencesCustomer>>([]);
  const [Reservation, reservation] = useState<Array<reservation>>([]);
  const [ReservationCustomer, reservationCustomer] = useState<reservation>();
  const Url = 'https://www.mysharingsystem.com/api/';

  useEffect(() => {
    axios.get(Url + 'Reservation/' + myParam + '/0')
      .then(res => reservationCustomer(res.data));
  }, [])

  useEffect(() => {
    axios.get(Url + 'AllergiesCustomer/' + myParam)
      .then(res => AllergiesCustomer(res.data));
  }, [])

  useEffect(() => {
    axios.get(Url + 'FoodPreferenceCustomer/' + myParam)
      .then(res => FoodPreferencesCustomer(res.data));
  }, [])

  useEffect(() => {
    axios.get(Url + 'Reservation/' + myParam)
      .then(res => reservation(res.data));
  }, [])

  const Civilite: UserCivilite[] = [

    { CiviliteTxt: "Civilité", MandameTxt: ReservationCustomer?.customers?.sex ?? "------" },
    { CiviliteTxt: "Nom", MandameTxt: ReservationCustomer?.customers?.firstName ?? "------" },
    { CiviliteTxt: "Prénom", MandameTxt: ReservationCustomer?.customers?.lastName ?? "------" },
    { CiviliteTxt: "Date de naissance", MandameTxt: ReservationCustomer?.customers?.birthDate ?? "------" },
    { CiviliteTxt: "Adresse", MandameTxt: ReservationCustomer?.customers?.customerAddresss?.line1 ?? "------" },
    { CiviliteTxt: "Code postal", MandameTxt: ReservationCustomer?.customers?.nationalityCode ?? "------" },
    { CiviliteTxt: "Ville", MandameTxt: ReservationCustomer?.customers?.customerAddresss?.city ?? "------" },
    { CiviliteTxt: "N° de téléphone", MandameTxt: ReservationCustomer?.customers?.phone ?? "------" },
    { CiviliteTxt: "N° de téléphone 2", MandameTxt: ReservationCustomer?.customers?.phone2 ?? "------" },
    { CiviliteTxt: "Email", MandameTxt: ReservationCustomer?.customers?.email ?? "------" },
    { CiviliteTxt: "Email 2", MandameTxt: ReservationCustomer?.customers?.email2 ?? "------" },
    { CiviliteTxt: "Nationalité", MandameTxt: ReservationCustomer?.customers?.nationalityCode ?? "------" },
    { CiviliteTxt: "Langue(s)", MandameTxt: ReservationCustomer?.customers?.languageCode ?? "------" },
    { CiviliteTxt: "Date importante", MandameTxt: ReservationCustomer?.customers?.importantDate ?? "------" },
    { CiviliteTxt: "Heure d’arrivée", MandameTxt: ReservationCustomer?.startUtc ?? "------" },
    { CiviliteTxt: "Heure de départ", MandameTxt: ReservationCustomer?.endUtc ?? "------" },
    { CiviliteTxt: "CODE VIP", MandameTxt: ReservationCustomer?.customers?.classificationName ?? "------" },
  ];


  const ClientTagEdit = () => {
    setMode(FormMode.New);
    openTagEdit();
  };

  const openTagEdit = () => {
    const drawerRefEl: any = drawerTags.current;
    drawerRefEl.open();
  }

  const closeTagEdit = () => {
    const drawerRefEl: any = drawerTags.current;
    drawerRefEl.close();
  }
  
  const profilClientsEdit = () => {
    setMode(FormMode.New);
    openEditClientForm();
  }; 

  const openEditClientForm = () => {
    const drawerRefEl: any = drawerprofilClientsEdit.current;
    drawerRefEl.open();
  }

  const closeEditClientForm = () => {
    const drawerRefEl: any = drawerprofilClientsEdit.current;
    drawerRefEl.close();
  }

  return (
    <div className='userDetails_payment'>
      <h3 className='userDetails_header'>Fiche Client</h3>
      <div className='userDetails_content'>
        <div className="ProfilClientsDetailsTopActions">
          <select className='selectSyle'>
            <option value={1}>Profil</option>
            <option value={2}>Fiche client</option>
            <option value={3}>Email</option>
            <option value={4} key={myParam} onClick={() => navigate(`/feedback?customerId=${myParam}`)} >Feedback</option>
            <option value={5} key={myParam} onClick={() => navigate(`/clientsPreferences?customerId=${myParam}`)} >Préférences</option>
            <option value={6}>Whatsapp</option>
          </select>

          {/* <button className="btnstyle">Partager ficher client</button> */}

          <h4 className='ProfilClientsDetailsTopActionsh3'>Prochain séjour:</h4>

          <div className='selectSyleDiv'>

            <input className="DataDetailsSyle" placeholder='Du'></input>
            <input className="DataDetailsSyle" placeholder='Au'></input>
          </div>

        </div>
        <div className="userDetails_info">
          <div className="userDetails_photo">
            <ProfilClientsCarousel />
          </div>
          <div className="userDetails_details">
            {Civilite.map((r) => (
              <div className="userDetails_element">
                <a onClick={profilClientsEdit}>
                  <p className='user_chambre'>{r.CiviliteTxt}</p>
                  <p className='user_nome'>{r.MandameTxt}</p>
                </a>
              </div>
            ))}
          </div>
        </div>

        <div className="moreContent">
          <div className=''>
            {allergiesCustomer.map((r) => (
               <p>{r.allergies.displayAs}</p>
              // <img className="TagLogo" src={`data:image/jpeg;base64,${r.tags.tagImage}`} alt="" />
            ))}
            {foodPreferencesCustomer.map((r) => (
                <p>{r.food_Preferences.displayAs}</p>
              // <img className="TagLogo" src={`data:image/jpeg;base64,${r.foodPreferences.displayAs}`} alt="" />
            ))}

            <a className="TagLogo" onClick={ClientTagEdit}> ... </a>
          </div>
        </div>

        <GnzDrawer ref={drawerTags} component={
          <ProfilClientsTagsForm
            myParam={myParam ?? "0"}
            allergiesCustomer={allergiesCustomer}
            foodPreferencesCustomer={foodPreferencesCustomer}
            closeForm={closeTagEdit}
          />
        } />

        <GnzDrawer ref={drawerprofilClientsEdit} component={
          <ProfilClientsEditForm
            myParam={myParam ?? "0"}
            CustomerDetails={CustomerDetails}
            closeForm={closeEditClientForm}
          />
        } />

        <div className="moreContent">
          <div className="moreContent_style">
            <StaysClienteTable Reservation={Reservation} />
          </div>
        </div>
      </div>
      <div>
      </div>
    </div>
  );
}

export default ProfilClientsDetailsTable