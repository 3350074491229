import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";

export default function SizeAvatars({
  poste,
  nom,
  prenom,
  profilePicture,
}: any) {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      width={200}
      height={80}
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Avatar
        alt={nom}
        src={profilePicture}
        sx={{
          width: 60,
          height: 60,
        }}
      />
      <Box sx={{ width: 120 }}>
        <Typography
          fontWeight="bold"
          textAlign="center"
          fontSize="10px"
          sx={{ fontFamily: "'Poppins', sans-serif" }}
        >
          {prenom} {nom}
        </Typography>
        <Typography
          color="gray"
          textAlign="center"
          fontSize="8px"
          sx={{ fontFamily: "'Poppins', sans-serif" }}
        >
          {poste}
        </Typography>
      </Box>
    </Stack>
  );
}
