import { useState } from 'react';

import './ForgotPassword.css';
import { Link } from 'react-router-dom';

function ForgotPassword() {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState('')

  const forgot = async (event: any) => {
    event.preventDefault();

    setInfo('');
    setLoading(true);
    await new Promise(r => setTimeout(r, 200));

    if (email.length === 0) {
      setInfo('Le formulaire est incomplet');
    } else {
      setInfo('Email envoyé avec succès');
    }

    setLoading(false);
  };

  const ForgotPasswordLabel = () => {
    if (loading) {
      return <div className="spinner"></div>;
    }
    return <div>Envoyer</div>;
  }

  return (
    <div className="App">
      <div className="AppLogo">SHARING</div>
      <form className="AppForm">
        <div className="AppFormInputGroup">
          <label className="AppFormLabel">Adresse E-mail</label>
          <input type="email" placeholder="Adresse E-mail" className="AppFormInput" value={email} onChange={(e) => setEmail(e.target.value)}></input>
        </div>
        <div className="AppFormInputGroup">
          <Link to="/" className="AppFormLink">Retour</Link>
          <button onClick={forgot} className="AppFormButton"><ForgotPasswordLabel /></button>
        </div>
        <div className="AppFormInfo">
          {info}
        </div>
      </form>
    </div>
  );
}

export default ForgotPassword;
