import { useState } from 'react';
import { Stack, Card, CardContent, Link, Grid, Paper, Button, TextField} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useNavigate} from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';

import './Provider.css';

const apiUrl = window.location.hostname === 'localhost' ? 'https://localhost:7142/api' : '/api';

const SharingButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  width: '259px',
  height: '50px',
  fontSize: 16,
  fontWeight:'bold',
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: '#1D62AF',
  borderColor: '#1D62AF',
  fontFamily: 'Montserrat',
  '&:hover': {
    backgroundColor: '#0069d9',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});
const Item = styled(Paper)(() => ({
  padding: 8,
  margin: 4,
  
}));


interface AddProviderProps {
  onClose: () => void;
}
  
const AddProvider: React.FC<AddProviderProps> = ({ onClose }) => {

const navigate = useNavigate();

const [formData, setFormData] = useState({ 
    compagnie: null,
    perReference: null,
    telProvider: null,
    email: null,
    DHauteSaison:null,
    FHauteSaison:null,
    chargeHsaison:null,
    DHeureNuit:null,
    FHeureNuit:null,
    chargeTnuit:null,
    information:null,
    politiqueAnnulation:null,    
    chargeDisposition:null,
});
const [counterTrip, setCounterTrip] = useState(0);
const [counterDisposal, setCounterDisposal] = useState(0);

const [stateTrip, setStateTrip] = useState({statut:true,})
const [stateDeleteTrip, setstateDeleteTrip] = useState({ statut:true,})

const [stateDisposal, setStateDisposal] = useState({statut:true,})
const [stateDeleteDisposal, setStateDeleteDisposal] = useState({statut:true,})

function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
  const { name, value } = event.target;
  setFormData({ ...formData, [name]: value });
  
}

// Dynamic inputs for Trip 
const [promptsTrip, setpromptsTrip] = useState([{
  depuis:null,
  a: null,
  véhicule1:null,
  véhicule2:null,
  véhicule3:null,
  prix1:null,
  prix2:null,
  prix3:null,
  timestampTrip:new Date().getTime(),
}])

const handlePromptTrip = ( event : any, i:number) => {
  //une fois le trajet saisi avec 3 véhicules, on active le bouton ajouter un trajet
  if ( promptsTrip[i].depuis &&  promptsTrip[i].a 
    && promptsTrip[0].véhicule1 && promptsTrip[i].prix1 
    && promptsTrip[0].véhicule2 && promptsTrip[i].prix2
    && promptsTrip[0].véhicule3 && promptsTrip[i].prix3
    != null ) {
      stateTrip.statut= false
      stateDeleteTrip.statut= false
  }
  
  const{name, value} = event.target
  let newpromptsTrip = [... promptsTrip];
  (newpromptsTrip[i] as any)[name] = value;
  setpromptsTrip(newpromptsTrip);
  
} 

const  handleAddPromptTrip=() => {
  //a la création d'un nouveau chemin vide, le bouton ajouté est desactivé
  stateTrip.statut= true
  setpromptsTrip([...promptsTrip,{
    depuis:null,
    a: null,
    véhicule1:null,//promptsTrip[0].véhicule1
    véhicule2:null,
    véhicule3:null,
    prix1:null,
    prix2:null,
    prix3:null,
    timestampTrip:new Date().getTime()
  }])
  setCounterTrip(counterTrip => counterTrip + 1);
}

const handleDeleteTrip=(i:any)=>{
  let deletePromptsTrip = [...promptsTrip]
  deletePromptsTrip.splice(i,1);
  setpromptsTrip(deletePromptsTrip);
  //le nombre de chemin est réduit
  setCounterTrip(counterTrip => counterTrip - 1);
}

// Dynamic inputs for Disposition 
const [promptsDisposition, setpromptsDisposition] = useState ([{
  departure:null,
  arrival:null,
  heureDisposition:null,
  véhiculeD1:null,
  véhiculeD2:null,
  véhiculeD3:null,
  prixD1:null,
  prixD2:null,
  prixD3:null,
  timestampDisposition: new Date().getTime()
}])

const handlePromptDisposition =(event:any, i:any) => {
   //une fois la disposition saisi, on active le bouton ajouter une disposition
    if ( promptsDisposition[i].departure &&  promptsDisposition[i].arrival  
      && promptsDisposition[i].prixD1 && promptsDisposition[i].prixD2 && promptsDisposition[i].prixD3 
      && promptsDisposition[i].heureDisposition   != null ) {
      stateDisposal.statut= false
      stateDeleteDisposal.statut= false
  }
  const {name, value}=event.target 
  let newpromptsDisposition = [... promptsDisposition];
  (newpromptsDisposition[i] as any)[name]=value;
  setpromptsDisposition(newpromptsDisposition);
}

const handleAddPromptDisposition =() =>{
  //a la création d'un nouvelle disposition vide, le bouton ajouté est desactivé
  stateDisposal.statut= true
  setpromptsDisposition([...promptsDisposition,{
    departure:null,
    arrival:null,
    heureDisposition:null,
    véhiculeD1:null,
    véhiculeD2:null,
    véhiculeD3:null,
    prixD1:null,
    prixD2:null,
    prixD3:null,
    timestampDisposition: new Date().getTime()
  }])
  setCounterDisposal(counterDisposal => counterDisposal + 1);
  }
const handleDeleteDisposition =(i:any)=>{
    let deletePromptsDisposition=[...promptsDisposition]
    deletePromptsDisposition.splice(i,1);
    setpromptsDisposition(deletePromptsDisposition);
    //le nombre de disposition est réduit
    setCounterDisposal(counterDisposal => counterDisposal - 1);
    
}

// Submit: 
const onSubmit = async () => {

  //add provider
      try {
      const responseNvProvider = await axios.post(`${apiUrl}/Provider/addProvider`, {
          name: formData.compagnie,
          contact:formData.perReference ,
          phoneNumber:formData.telProvider,
          email: formData.email,
          startHighSeason:formData.DHauteSaison,
          endHighSeason:formData.FHauteSaison,
          highSeasonCharge:formData.chargeHsaison,
          hStartNight:formData.DHeureNuit,
          hEndNight:formData.FHeureNuit,
          nightRate:formData.chargeTnuit,
          additionalInformation:formData.information,
          cancelationPolicy:formData.politiqueAnnulation,
          DisposalCharge:formData.chargeDisposition,  
          deleted:0,
      }, 
    { headers: { "Content-Type": "application/json" } }
    );
      const NvProviderID = responseNvProvider.data.providerID;
      console.log('ID provider: '+NvProviderID)
      console.log('ResponseNewProvider'+responseNvProvider);

//add trips 
//if there is no Trip we create NULL trip
for (var i=0; i <= counterTrip; i++)
{   
  
    await axios.post(`${apiUrl}/Trip/AddTrip`, {
      Departure: promptsTrip[i].depuis,
      Arrival: promptsTrip[i].a,
      Car1: promptsTrip[0].véhicule1,
      Car2: promptsTrip[0].véhicule2,
      Car3: promptsTrip[0].véhicule3,
      Price1: promptsTrip[i].prix1,
      Price2:promptsTrip[i].prix2,
      Price3: promptsTrip[i].prix3,
      CurrentProviderId:NvProviderID,
      deleted:0,
      },
      { headers: { "Content-Type": "application/json" } }
    ); 
}
//add disposal
//if there is no Disposal we create NULL Disposal
  for (var i=0; i <= counterDisposal; i++)
{
    await axios.post(`${apiUrl}/Disposal/addDisposal`, {
      Departure: promptsDisposition[i].departure,
      Arrival: promptsDisposition[i].arrival,
      NumberOfHours:promptsDisposition[i].heureDisposition,
      Car1:promptsTrip[0].véhicule1,
      Car2:promptsTrip[0].véhicule2,
      Car3:promptsTrip[0].véhicule3,
      Price1:promptsDisposition[i].prixD1,
      Price2:promptsDisposition[i].prixD2,
      Price3:promptsDisposition[i].prixD3,
      CurrentProviderId:NvProviderID,
      deleted:0,
    },
    { headers: { "Content-Type": "application/json" } }
    );
} 
  //go back
  onClose(); // Ferme le drawer
  window.location.reload(); // Actualise la page
  //navigate('/ListProvider');
  } catch (error) {
    console.error("Error submitting form:", error);
  }   
//window.location.reload()
}


console.log("formData") 
console.log(formData)
console.log("promptsTrip")
console.log(promptsTrip)
console.log("promptsDisposition")
console.log(promptsDisposition)

const { handleSubmit } = useForm();
return (
  <>
   <Card style={{ maxWidth: 450, padding: '20px 5px', margin: '0 auto', overflowY: 'auto', flexGrow: 1 }}> 
  <label className='titleCard' style={{fontWeight:'bold'}}> &nbsp;&nbsp;Ajouter un nouveau prestataire </label>
    <CardContent>  
      <Grid container spacing={2} >
      <form onSubmit={handleSubmit(onSubmit)}>
          {/* Green Card */}
          <Card style={{backgroundColor: '#a0e29533', padding: 8,margin: 4}} variant="outlined">
            <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
              <Grid>
                <label className='FontLabel'  >Compagnie</label >
                <TextField required size='small' name="compagnie" onChange={handleInputChange} type="text" placeholder=""  className='StyleInput' />  
              </Grid>
              <Grid  >
                <label className='FontLabel'>Contact</label>
                <TextField  required size='small' name="perReference" onChange={handleInputChange} type="text" placeholder="" className='StyleInput' />
              </Grid>
            </Stack> 
            <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
              <Grid>
                <label className='FontLabel'>Téléphone</label >
                <TextField required size='small'name="telProvider" onChange={handleInputChange} type="tel" placeholder=""  className='StyleInput'/>  
              </Grid>
              <Grid>
                <label className='FontLabel'>Email</label>
                <TextField required size='small'name="email" onChange={handleInputChange} type="email" placeholder="" className='StyleInput'  />
              </Grid>
            </Stack> 
          </Card>

        {/* Blue Card */}
        <Card style={{backgroundColor: '#1d62af1a', padding: 8,margin: 4}} variant="outlined">
        <Grid className='SubTitleCard' >Trajets</Grid>
        <Grid className='line'></Grid>
        {/* Our three cars */}
        <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
          <Grid >
            <label className='FontLabel'>Véhicule 1</label>
            <TextField size='small' name="véhicule1" onChange={event=>handlePromptTrip(event,0)} type="text" placeholder="" className="StyleInput" required={ promptsTrip[0].depuis || promptsTrip[0].a  || promptsTrip[0].prix1 || promptsTrip[0].véhicule2 || promptsTrip[0].prix2 || promptsTrip[0].véhicule3 || promptsTrip[0].prix3 !=null} />
          </Grid>
          <Grid >
            <label className='FontLabel'>Véhicule 2</label>
            <TextField size='small' name="véhicule2" onChange={event=>handlePromptTrip(event,0)} type="text" placeholder="" className="StyleInput" required={ promptsTrip[0].prix2 || promptsTrip[0].véhicule3 || promptsTrip[0].prix3 !=null} />
          </Grid>
          <Grid >
            <label className='FontLabel'>Véhicule 3</label>
            <TextField size='small' name="véhicule3" onChange={event=>handlePromptTrip(event,0)} type="text" placeholder="" className="StyleInput" required={ promptsTrip[0].véhicule2 || promptsTrip[0].prix2 || promptsTrip[0].prix3 !=null} />
          </Grid>
        </Stack>

          {promptsTrip.map((prompt,i) => ( 
            <div key={prompt.timestampTrip}>
              <div>
                <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
                  <Grid >
                    <label className='FontLabel'>Depuis</label>
                    <TextField size='small' name="depuis" onChange={event=>handlePromptTrip(event,i)}type="text" placeholder="" className="StyleInput" required={ promptsTrip[i].a || promptsTrip[i].prix1 || promptsTrip[i].prix2 || promptsTrip[i].prix3 !=null} />  
                  </Grid>
                  <Grid >
                    <label className='FontLabel'>A</label>
                    <TextField size='small' name="a" onChange={event=>handlePromptTrip(event,i)} type="text" placeholder="" className="StyleInput" required={ promptsTrip[i].depuis || promptsTrip[i].prix1  || promptsTrip[i].prix2 || promptsTrip[i].prix3 !=null}  /> 
                  </Grid>
                </Stack> 

                <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
                  <Grid  >
                    <label className='FontLabel'>Prix 1</label>
                    <TextField size='small' name="prix1" onChange={event=>handlePromptTrip(event,i)} type="number" placeholder="" className="StyleInput" required={ promptsTrip[i].véhicule1 != null}/>
                  </Grid>
                  <Grid>
                    <label className='FontLabel'>Prix 2</label>
                    <TextField size='small' name="prix2" onChange={event=>handlePromptTrip(event,i)} type="number" placeholder="" className="StyleInput" required={ promptsTrip[i].véhicule2 != null}/>
                  </Grid>
                  <Grid>
                    <label className='FontLabel'>Prix 3</label>
                    <TextField size='small' name="prix3" onChange={event=>handlePromptTrip(event,i)} type="number" placeholder="" className="StyleInput" required={ promptsTrip[i].véhicule3 != null}/>
                  </Grid>
                </Stack>
              </div>

              <Button style={{ color: "black", textTransform: 'none'  }} size="small" startIcon={<DeleteOutlineOutlinedIcon style={{ color: "red" }} />} 
                onClick={()=>handleDeleteTrip(i)} disabled={stateDeleteTrip.statut}>
               Supprimer ce trajet 
              </Button>

            </div> 
          ))}
          <div style={{ textAlign: 'right' }}>
            <Button style={{ color: "black", textTransform: 'none' }} size="small" startIcon={<AddIcon style={{ color: "green" }} />} color="success" 
              onClick={handleAddPromptTrip} disabled={stateTrip.statut}>
              Ajouter un trajet
            </Button>
          </div>
          </Card>

          {/* Blue Card2 */}
          <Card style={{backgroundColor: '#1d62af1a', padding: 8,margin: 4}} variant="outlined">
          <Grid className='SubTitleCard' >Informations</Grid>
          <Grid className='line'></Grid>

          <Grid>Haute saison</Grid>
          <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
              <Grid>
                <label className='FontLabel'>Date Début</label>
                <TextField size='small' name="DHauteSaison" onChange={handleInputChange} type='date' className="StyleInput"/>
              </Grid>
              <Grid>
                <label className='FontLabel'>Date Fin</label>
                <TextField size='small' name="FHauteSaison" onChange={handleInputChange}type='date' className="StyleInput"/>
              </Grid>
          </Stack>
          <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
              <Grid>
                <label className='FontLabel'>Charge haute saison € </label><br/>
                <TextField size='small' name="chargeHsaison" onChange={handleInputChange} type="number" placeholder="" className="StyleInput" />
              </Grid>
          </Stack>
          
          <Grid>Travail de nuit</Grid>
          <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
              <Grid>
                <label className='FontLabel'>Heure Début</label>
                <TextField size='small' name="DHeureNuit" onChange={handleInputChange} type='time' className="StyleInput"/>
              </Grid>
              <Grid>
                <label className='FontLabel'>Heure Fin</label>
                <TextField size='small' name="FHeureNuit" onChange={handleInputChange}type='time' className="StyleInput"/>
              </Grid>
              <Grid>
              <label className='FontLabel'>Charge de nuit</label>
                {/* <Input name="chargeTnuit" onChange={handleInputChange} type="number" placeholder="" className="StyleInput"  /> */}
                <TextField size='small' name="chargeTnuit" onChange={handleInputChange} className="StyleInput" type="number" />
                {/* startAdornment={<InputAdornment position="end">€</InputAdornment>} */}
              </Grid>                      
          </Stack>
        </Card>


        {/* Grey Card */}
        <Card style={{backgroundColor: '#F5F5F5', padding: 8,margin: 4}} variant="outlined">
          <Grid className='SubTitleCard'> Disposition </Grid>
          <Grid className='line'></Grid>

          <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
            <Grid>
              <label className='FontLabel'>Charge supplémentaire</label><br/>
              <TextField size='small' name="chargeDisposition" onChange={handleInputChange} type="number" placeholder="" className="StyleInput" required={(promptsDisposition[0].heureDisposition || promptsDisposition[0].prixD1 || promptsDisposition[0].prixD2 || promptsDisposition[0].prixD3)!=null} />
            </Grid>
          </Stack>

          {promptsDisposition.map((prompt,i) => ( 
            
          <div key={prompt.timestampDisposition} >
          <div>
          <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
            <Grid >
              <label className='FontLabel'>Depuis</label>
              <TextField size='small' name="departure" onChange={event=>handlePromptDisposition(event,i)}type="text" placeholder="" className="StyleInput" required={ (promptsDisposition[i].arrival || promptsDisposition[i].prixD1 || promptsDisposition[i].prixD2 || promptsDisposition[i].prixD3 )!=null} />  
            </Grid>
            <Grid >
              <label className='FontLabel'>A</label>
              <TextField size='small' name="arrival" onChange={event=>handlePromptDisposition(event,i)} type="text" placeholder="" className="StyleInput" required={ (promptsDisposition[i].departure || promptsDisposition[i].prixD1  || promptsDisposition[i].prixD2 || promptsDisposition[i].prixD3) !=null}  /> 
            </Grid>
          </Stack>

          <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
            <Grid  >
              <label className='FontLabel'>Prix 1</label>
              <TextField size='small' name="prixD1" onChange={event=>handlePromptDisposition(event,i)} type="number" placeholder="" className="StyleInput" required={promptsDisposition[i].departure || promptsDisposition[i].arrival || promptsDisposition[i].heureDisposition || promptsDisposition[i].prixD2 || promptsDisposition[i].prixD3!=null}/>
            </Grid>
            <Grid> 
              <label className='FontLabel'>Prix 2</label>
              <TextField size='small' name="prixD2" onChange={event=>handlePromptDisposition(event,i)} type="number" placeholder="" className="StyleInput" required={promptsDisposition[i].departure || promptsDisposition[i].arrival || promptsDisposition[i].heureDisposition || promptsDisposition[i].prixD1  || promptsDisposition[i].prixD3!=null}/>
            </Grid>
            <Grid>
              <label className='FontLabel'>Prix 3</label>
              <TextField size='small' name="prixD3" onChange={event=>handlePromptDisposition(event,i)} type="number" placeholder="" className="StyleInput" required={promptsDisposition[i].departure || promptsDisposition[i].arrival || promptsDisposition[i].heureDisposition || promptsDisposition[i].prixD1 || promptsDisposition[i].prixD2!=null}/>
            </Grid>
          </Stack> 
          <Stack spacing={2} direction="row" sx={{marginBottom: 1}}>
          <Grid>
              <label className='FontLabel'>Heure disposition</label><br/>
              <TextField size='small' name="heureDisposition" onChange={event=>handlePromptDisposition(event,i)} type='time' className="StyleInput" required={promptsDisposition[i].departure || promptsDisposition[i].arrival || promptsDisposition[i].prixD1 || promptsDisposition[i].prixD2 || promptsDisposition[i].prixD3 !=null}/>
          </Grid>
          </Stack>
            </div>

            <Button style={{ color: "black", textTransform: 'none'  }} size="small" startIcon={<DeleteOutlineOutlinedIcon style={{ color: "red" }} />} 
              onClick={()=>handleDeleteDisposition(i)} disabled={stateDeleteDisposal.statut}>
              Supprimer cette disposition 
            </Button>

          </div>
          ))}
          <br/>

          <div style={{ textAlign: 'right' }}>
            <Button style={{ color: "black", textTransform: 'none' }} size="small" startIcon={<AddIcon style={{ color: "green" }} />} color="success" 
              onClick={handleAddPromptDisposition}  disabled={stateDisposal.statut}>
              Ajouter une disposition
            </Button>
          </div>

        </Card>
        {/* White Card */}
        <Card style={{ padding: 8,margin: 4}} variant="outlined">
          <Grid>
            <label className='FontLabel2'>Informations&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <TextField size='small' name="information" onChange={handleInputChange} type="text" placeholder="" className="StyleInput" style={{width:'410px'}}/>
          </Grid>
          <Grid>
            <label className='FontLabel2'>Politique d'annulation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <TextField multiline name="politiqueAnnulation" onChange={handleInputChange} type="text" placeholder="" className="StyleInput" style={{width:'410px'}}/>
          </Grid>
          <br/>
          {/* <label style={{fontSize:'10px',color:'grey'}} ><MuiModal/></label> */}
          </Card>
         <SharingButton type="submit" variant="contained" className='btn'>Ajouter</SharingButton>
         </form>
    </Grid>

  </CardContent> 
 </Card>  

</>

);
}
export default AddProvider

