import * as React from "react";
import {
  Button,
  Grid,
  Paper,
  Typography,
  Pagination,
  Container,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Box } from "@mui/material";
import Filter from "../liste/filterEmployee";
const apiUrl =
  window.location.hostname === "localhost"
    ? "https://localhost:7142/api"
    : "/api";

const formattedDate = (dateTimeString: any) => {
  if (!dateTimeString) return "";
  const dateOnlyString = dateTimeString.split("T")[0];
  return dateOnlyString;
}
const EmployeeAbsenceComponent = ({
  absence,
  
}: any) => {
  return (
    <Box my={1} sx={{ backgroundColor: absence.couleurDuService }} p={2}>
      <Grid container>
        <Grid
          md={3}
          xs={6}
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent={{xs:'center',md:'start'}}
        >
          <img
            src={absence.profilePicture}
            alt={absence.name}
            width={30}
            height={30}
            style={{
              borderRadius: "50%",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
            }}
          />
          <Typography
            marginLeft={1}
            fontWeight="bold"
            sx={{ color: "rgba(0,0,0,0.6)" }}
          >
            {absence.nom} {absence.prenom}
          </Typography>
        </Grid>
        <Grid md={1} xs={6} item>
          <Typography
            textAlign="center"
            marginLeft={1}
            sx={{ color: "rgba(0,0,0,0.6)" }}
          >
            {absence.status}
          </Typography>
        </Grid>
        <Grid md={2} xs={6} item>
          <Typography
            textAlign="center"
            marginLeft={1}
            sx={{ color: "rgba(0,0,0,0.6)" }}
          >
            {absence.motif}
          </Typography>
        </Grid>
        <Grid
          md={2}
          xs={6}
          item
          flexDirection="row"
          display="flex"
          justifyContent="center"
        >
          <Typography marginLeft={1} sx={{ color: "rgba(0,0,0,0.6)" }}>
            {formattedDate(absence.date_out)}
          </Typography>
        </Grid>
        <Grid
          md={2}
          xs={6}
          item
          flexDirection="row"
          display="flex"
          justifyContent="center"
        >
          <Typography marginLeft={1} sx={{ color: "rgba(0,0,0,0.6)" }}>
            {formattedDate(absence.date_in)}
          </Typography>
        </Grid>
        {absence.etat === "En Attente" && (
          <Grid
            md={2}
            xs={6}
            item
            flexDirection="row"
            display="flex"
            justifyContent="center"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.7998 19.95C7.5998 19.817 6.5 19.463 5.5 18.888C4.4 18.313 3.4998 17.58 2.7998 16.688C1.9998 15.796 1.40039 14.775 0.900391 13.625C0.500391 12.475 0.299805 11.258 0.299805 9.97498C0.299805 7.39198 1.1002 5.154 2.7002 3.263C4.4002 1.372 6.40039 0.284 8.90039 0V2C6.90039 2.283 5.29961 3.17899 4.09961 4.68799C2.79961 6.19699 2.2002 7.95898 2.2002 9.97498C2.2002 11.992 2.79961 13.754 4.09961 15.263C5.29961 16.772 6.8998 17.667 8.7998 17.95V19.95ZM9.7998 15L5 9.95001L6.40039 8.52502L8.7998 11.125V5H10.7998V11.125L13.2002 8.54999L14.5996 10L9.7998 15ZM10.7998 19.95V17.95C11.3998 17.85 12.1002 17.658 12.7002 17.375C13.4002 17.092 13.9 16.733 14.5 16.3L15.9004 17.75C15.1004 18.367 14.3 18.863 13.5 19.238C12.6 19.613 11.6998 19.851 10.7998 19.95ZM14.5 3.65002C14 3.21702 13.4002 2.85801 12.7002 2.57501C12.1002 2.29201 11.4998 2.1 10.7998 2V0C11.6998 0.1 12.6 0.338013 13.5 0.713013C14.3 1.08801 15.1004 1.58401 15.9004 2.20001L14.5 3.65002ZM17.2002 16.3L15.9004 14.875C16.3004 14.308 16.6004 13.696 16.9004 13.038C17.1004 12.38 17.3004 11.692 17.4004 10.975H19.4004C19.2004 11.958 18.9996 12.904 18.5996 13.813C18.2996 14.722 17.8002 15.551 17.2002 16.3ZM17.4004 8.97498C17.3004 8.25798 17.1004 7.57102 16.9004 6.91302C16.6004 6.25502 16.3004 5.64201 15.9004 5.07501L17.2002 3.65002C17.8002 4.40002 18.3002 5.229 18.7002 6.138C19.0002 7.047 19.3004 7.99198 19.4004 8.97498H17.4004Z"
                fill="#1D62AF"
              />
            </svg>
          </Grid>
        )}

        {absence.etat === "Refusée" && (
          <Grid
            md={2}
            xs={6}
            item
            flexDirection="row"
            display="flex"
            justifyContent="center"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.92982 18.0683C2.01982 17.1453 1.29017 16.0424 0.790172 14.8224C0.290172 13.6024 0.0296641 12.2903 0.0196641 10.9623C-0.000335932 9.63432 0.250602 8.31736 0.730602 7.08836C1.2106 5.85936 1.9197 4.74336 2.8097 3.80436C3.7097 2.86536 4.78033 2.12234 5.95033 1.62034C7.13033 1.11734 8.39052 0.864344 9.65052 0.875344C10.9205 0.887344 12.18 1.16336 13.34 1.68736C14.51 2.21136 15.5606 2.97332 16.4406 3.92832C18.1906 5.81432 19.15 8.34032 19.13 10.9623C19.1 13.5843 18.1002 16.0923 16.3302 17.9463C14.5602 19.8003 12.1605 20.8523 9.65052 20.8753C7.15052 20.8973 4.73982 19.8893 2.92982 18.0683ZM4.28041 16.6584C5.71041 18.1594 7.66056 19.0024 9.69056 19.0024C11.7206 19.0024 13.6597 18.1594 15.0997 16.6584C16.5297 15.1574 17.34 13.1213 17.34 10.9983C17.34 8.87533 16.5297 6.83936 15.0997 5.33836C13.6597 3.83736 11.7206 2.99332 9.69056 2.99332C7.66056 2.99332 5.71041 3.83736 4.28041 5.33836C2.85041 6.83936 2.04017 8.87533 2.04017 10.9983C2.04017 13.1213 2.85041 15.1574 4.28041 16.6584ZM13.7404 8.16837L11.0402 10.9983L13.7404 13.8283L12.3898 15.2383L9.69056 12.4084L6.9806 15.2383L5.63978 13.8283L8.33998 10.9983L5.63978 8.16837L6.9806 6.75834L9.69056 9.58836L12.3898 6.75834L13.7404 8.16837Z"
                fill="#DD2025"
              />
            </svg>
          </Grid>
        )}

        {absence.etat !== "En Attente" && absence.etat !== "Refusée" && (
          <Grid
            md={2}
            xs={6}
            item
            flexDirection="row"
            display="flex"
            justifyContent="center"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 12L9.90039 15L16.5996 8M19.5 15C18.5 16 20.7 18.75 19.5 20C18.3 21.25 15.6002 19 14.7002 20C13.7002 21 13.2998 23 11.7998 23C10.3998 23 9.9 21 9 20C8 19 5.4002 21.25 4.2002 20C3.0002 18.75 5.1002 16 4.2002 15C3.2002 14 1.2998 13.5 1.2998 12C1.2998 10.5 3.2002 10 4.2002 9C5.1002 8 3.0002 5.25 4.2002 4C5.4002 2.75 8 5 9 4C9.9 3 10.3998 1 11.7998 1C13.2998 1 13.7002 3 14.7002 4C15.6002 5 18.3 2.75 19.5 4C20.7 5.25 18.5 8 19.5 9C20.4 10 22.2998 10.5 22.2998 12C22.2998 13.5 20.4 14 19.5 15Z"
                stroke="#216824"
                stroke-width="2"
              />
            </svg>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default function List() {
  const [employees, setEmployees] = React.useState([]);
  const [allAbsences, setAllAbsences] = React.useState([]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [selectedDpts, setSelectedDpts] = React.useState<String[]>([]);
  const [responsabilité, setResponsabilite] = React.useState<String[]>([]);
  const [fromdate, setFromdate] = React.useState(null);
  const [todate, setTodate] = React.useState(null);
  const [selectedValue, setSelectedValue] = React.useState("");

  const employeesPerPage = 10;

  React.useEffect(() => {
    const fetchEmployees = async () => {
      try {
        let url = `${apiUrl}/Employee`;

        const filters = [];
        if (selectedDpts.length > 0) {
          const selectedDptFilter = selectedDpts.map(
            (dpt) => `dpt=${encodeURIComponent(String(dpt))}`
          );
          filters.push(...selectedDptFilter);
        }
        if (responsabilité.length > 0) {
          const responsabilitéFilter = responsabilité.map(
            (respo) => `respo=${encodeURIComponent(String(respo))}`
          );
          filters.push(...responsabilitéFilter);
        }

        if (fromdate) {
          filters.push(`fromdate=${encodeURIComponent(fromdate)}`);
        }
        if (todate) {
          filters.push(`todate=${encodeURIComponent(todate)}`);
        }

        if (filters.length > 0) {
          url += `?${filters.join("&")}`;
        }

        const response = await fetch(url);
        const data = await response.json();
        const employeesWithAbsences = data.filter(
          (employee: any) => employee.demandes.length > 0
        );
        const allAbsencesArray = employeesWithAbsences.reduce(
          (absences: any[], employee: any) => {
            const employeeAbsences = employee.demandes.map((absence: any) => ({
              ...absence,
              nom: employee.nom,
              prenom: employee.prenom,
              profilePicture: employee.profilePicture,
              couleurDuService: employee.couleurDuService, 
            }));
            return absences.concat(employeeAbsences);
          },
          []
        );
          allAbsencesArray.sort(
            (a: any, b: any) =>
              new Date(b.date_out).getTime() - new Date(a.date_out).getTime()
          );


        setEmployees(employeesWithAbsences);
        setAllAbsences(allAbsencesArray);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, [selectedDpts, responsabilité, fromdate, todate]);

  React.useEffect(() => {
    if (selectedValue === "asc" || selectedValue === "desc") {
      setEmployees((prevEmployees) =>
        [...prevEmployees].sort((a: any, b: any) =>
          selectedValue === "asc"
            ? a.nom.localeCompare(b.nom)
            : b.nom.localeCompare(a.nom)
        )
      );
    } else if (selectedValue === "newer" || selectedValue === "older") {
      setEmployees((prevEmployees) =>
        [...prevEmployees].sort((a: any, b: any) =>
          selectedValue === "newer"
            ? new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            : new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        )
      );
    } else {
      setEmployees((prevEmployees) =>
        [...prevEmployees].sort((a, b) => (a as any).id - (b as any).id)
      );
    }
  }, [selectedValue]);

  const indexOfLastEmployee = currentPage * employeesPerPage;
  const indexOfFirstEmployee = indexOfLastEmployee - employeesPerPage;
  const currentEmployees = allAbsences.slice(
    indexOfFirstEmployee,
    indexOfLastEmployee
  );

  const handlePageChange = (event: any, value: any) => {
    setCurrentPage(value);
  };
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleButtonClick = () => {
    setOpenDialog(!openDialog);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  return (
    <Container
      maxWidth="xl"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Grid container>
        <Grid container sx={{ justifyContent: "end", display: "flex" }}>
          <Button onClick={handleButtonClick}>
            <Typography
              sx={{
                textTransform: "none",
                color: "rgba(0,0,0,0.4)",
                border: "1px solid rgba(0,0,0,0.4)",
              }}
              padding={1}
              borderRadius={1}
            >
              Filtres - Trie
            </Typography>
          </Button>
        </Grid>
        <Dialog open={openDialog} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogContent>
            <Filter
              setSelectedValue={setSelectedValue}
              selectedValue={selectedValue}
              selectedDpts={selectedDpts}
              setSelectedDpts={setSelectedDpts}
              responsabilité={responsabilité}
              setResponsabilite={setResponsabilite}
              fromdate={fromdate}
              setFromdate={setFromdate}
              todate={todate}
              setTodate={setTodate}
            />
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center", marginBottom: 2 }}>
            <Button onClick={handleClose} color="primary">
              <Typography
                sx={{
                  backgroundColor: "#0B4B92",
                  color: "white",
                  textTransform: "none",
                }}
                paddingY={1}
                paddingX={2}
              >
                Recherche
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <Paper
        sx={{
          maxWidth: 1536,
          width: "100%",
          // minHeight: 700,
          paddingX: 2,
          paddingY: 6,
        }}
      >
        <Box
          sx={{
            maxWidth: 1536,
            minHeight: 750,
          }}
        >
          {currentEmployees.map((absence: any) => (
            <EmployeeAbsenceComponent key={absence.id} absence={absence} />
          ))}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <Pagination
            count={Math.ceil(allAbsences.length / employeesPerPage)}
            page={currentPage}
            onChange={handlePageChange}
          />
        </Box>
      </Paper>
    </Container>
  );
}
