import './Whatsapp.css';

import GnzBreadcrumb from '../shared/gnz-breadcrumb/GnzBreadcrumb';
import GnzPaper from '../shared/gnz-paper/GnzPaper';

function View() {
    return (
        <div style={{fontFamily: 'Montserrat', fontSize: 14, fontWeight: 600}}>
            Whatsapp
        </div>
    )
}

function Whatsapp() {
    return (
        <div className="Whatsapp">
            <GnzBreadcrumb breadcrumbs={['Whatsapp', 'Whatsapp']}/>
            <GnzPaper component={<View />}/>
        </div>
    );
}

export default Whatsapp;
