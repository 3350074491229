import './Welcome.css';
import LockIcon from '@mui/icons-material/Lock';

function Welcome() {
  return (
    <div className='welcome'>
        welcome
    </div>
  );
}

export default Welcome;
/*
      <div style={{ height: 80, margin: "0 5%", display: "flex", flexDirection: "row", alignItems: "center" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
              <LockIcon style={{ color: "#B3B3B3" }} />
              <h2 style={{ color: "#B3B3B3", fontWeight: 500 }}>
                  Sharing
              </h2>
          </div>
          <div className='home-hr'></div>
      </div>
      <div className="home-section">
          <div className='home-section-column'>
              <img
                  src="https://ik.imagekit.io/abdeelbrh/Sharing/Group.png?updatedAt=1705063749092"
                  alt="home img"
                  width={"100%"}
              />
          </div>
          <div className='home-section-column'>
              <h1>
                  Bonjour, Admin
              </h1>
              <p>
                  Bienvenue dans votre espace administrateur,
                  ici vous pouvez gérer vos données personnelles,
                  votre établissement ainsi que l'ensemble du personnel.
              </p>
          </div>
      </div>
*/