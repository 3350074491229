import './GnzButton.css';

interface IGnzButton {
  small?: boolean,
  leftIcon?: JSX.Element,
  label: string,
  onClick: any,
  color?: string,
  backgroundColor?: string,
  borderRadius?: string,
  id?: string
}

function GnzButton(props: IGnzButton) {
  return (
    <div className="GnzButton" onClick={props.onClick} style={{
      backgroundColor: props.backgroundColor ? props.backgroundColor : '#003671',
      height: props.small ? 20 : 38,
      borderRadius: props.borderRadius ? props.borderRadius : 5
    }}>
        {props.leftIcon}
        <div className="GnzButtonLabel" style={{
          marginLeft: props.leftIcon ? '10px' : 0,
          color: props.color ? props.color : '#fff'
        }}>{props.label}</div>
    </div>
  );
}

export default GnzButton;
