import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";

export default function SizeAvatars() {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      position="relative"
      width={150}
      height={50}
    >
      <Avatar
        alt="Remy Sharp"
        src=""
        sx={{
          width: 50,
          height: 50,
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 1,
        }}
      />
      <Box
        width={150}
        height={70}
        sx={{
          backgroundColor: "#1D62AF",
          position: "relative",
          zIndex: 0,
          marginTop: "65px !important",
          borderRadius: "20px",
        }}
      >
        <Typography
          fontWeight="bold"
          color="white"
          marginTop={2}
          textAlign="center"
          fontSize="10px"
          sx={{ fontFamily: "'Poppins', sans-serif" }}
        >
          Nom prénom
        </Typography>
        <Typography
          color="white"
          textAlign="center"
          fontSize="8px"
          sx={{ fontFamily: "'Poppins', sans-serif" }}
        >
          Poste occupé
        </Typography>
      </Box>
    </Stack>
  );
}
