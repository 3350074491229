import Modal from '@mui/material/Modal';
import LogoutIcon from '@mui/icons-material/Logout';
import { Table } from '../types/types';
import { useState } from 'react';
import { tableService } from '../api/tableService';

interface AddTableProps {
    onClose: () => void;
    restaurantId: number | undefined;
}

const AddTable: React.FC<AddTableProps> = ({ onClose, restaurantId }) => {

    const initTable: Table = {
        id: 0,
        numero: null,
        nbPersonne: null,
        restaurantId: restaurantId || 0,
    };

    const [table, setTable] = useState < Table > (initTable);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setTable({ ...table, [name]: value });
    };

    const handleSubmit = async () => {
        try {

            if (
                table.numero === null || table.nbPersonne === null
                || table.numero <= 0 || table.nbPersonne <= 0
            ) {
                alert("Veuillez renseigner un numéro et un nombre de personne valide");
                return;
            }

            await tableService.postTable(table);
            onClose();
        } catch (error) {
            console.error("Error adding table:", error);
        }
    }

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='modal-restau-add'>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h1>Ajouter une nouvelle table</h1>
                    <LogoutIcon
                        style={{ color: "darkgray", fontSize: "36px", cursor: "pointer" }}
                        onClick={onClose}
                    />
                </div>

                <div className='restau-column2'>
                    <input
                        type="number"
                        id='nbtable-restau'
                        name='numero'
                        className='text-restau'
                        placeholder='Nom'
                        style={{
                            marginRight: '20px'
                        }}
                        value={table.numero !== null ? table.numero.toString() : ''}
                        onChange={handleInputChange}
                    />
                    <input
                        type="number"
                        className='text-restau'
                        placeholder='Personne'
                        name='nbPersonne'
                        value={table.nbPersonne !== null ? table.nbPersonne.toString() : ''}
                        onChange={handleInputChange}
                    />
                </div>
                <button className='restau-btn restau-modal-btn' onClick={handleSubmit}>
                    Ajouter
                </button>
            </div>
        </Modal>
    );
}

export default AddTable;