import { Restaurant, UpdateRestaurantRequest } from '../types/types';
import axiosInstance from './axiosInstance';

const getRestaurants = async (): Promise<Restaurant[]> => {
    const response = await axiosInstance.get<Restaurant[]>('/Restaurant');
    return response.data;
};

const getRestaurant = async (restaurantId: number): Promise<Restaurant> => {
    const response = await axiosInstance.get<Restaurant>(`/Restaurant/${restaurantId}`);
    return response.data;
};

const addRestaurant = async (restaurant: Restaurant): Promise<Restaurant> => {
    const response = await axiosInstance.post<Restaurant>('/Restaurant', restaurant);
    return response.data;
};

const putRestaurant = async (restaurantId: number, restaurant: UpdateRestaurantRequest): Promise<Restaurant> => {
    const response = await axiosInstance.put<Restaurant>(`/Restaurant/${restaurantId}`, restaurant);
    return response.data;
};

const deleteRestaurant = async (restaurantId: number): Promise<void> => {
    await axiosInstance.delete(`/Restaurant/${restaurantId}`);
};

export const restaurantService = {
    getRestaurants,
    getRestaurant,
    addRestaurant,
    putRestaurant,
    deleteRestaurant,
};
