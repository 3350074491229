import ConsigneStatus from "../enums/ConsigneStatus";
import ConsigneState from "../enums/ConsigneState";
import ConsigneEvent from "./ConsigneEvent";

export default class Consigne {
    public id: number = 0;
    public title: string = '';
    public description: string = '';
    public status: ConsigneStatus = ConsigneStatus.None;
    public state: ConsigneState = ConsigneState.None;
    public timestamp: number = 0;
    public events: Array<ConsigneEvent> = [];

    constructor(
        title: string = '',
        description: string = '',
        status: ConsigneStatus = ConsigneStatus.None,
        state: ConsigneState = ConsigneState.None,
        timestamp: number = 0,
        events: Array<ConsigneEvent> = [],
        id: number = 0
    ) {
        this.title = title;
        this.description = description;
        this.status = status;
        this.state = state;
        this.timestamp = timestamp;
        this.events = events;
        this.id = id;
    }
}