import { forwardRef, useImperativeHandle, useState } from 'react';
import Drawer from '@mui/material/Drawer';

import './GnzDrawer.css';

interface IGnzDrawer {
  component: JSX.Element
}

const GnzDrawer = forwardRef(function GnzDrawerNoRef(props: IGnzDrawer, ref) {
  useImperativeHandle(ref, () => ({
    open() {
      setOpen(true);
    },
    close() {
      setOpen(false);
    }
  }));

  const [open, setOpen] = useState(false)

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
    >
      {props.component}
    </Drawer>
  );
})

export default GnzDrawer;
