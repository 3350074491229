import './MssAdminLicence.css';
import licenceKeyLogo from '../images/LicenceKey.png';

interface IMMssAdminLicence {
}

function MssAdminLicence(props: IMMssAdminLicence) {
  return (
    <div className="MssAdminLicence">
      <div className="MssAdminLicenceType">Licence - version basique</div>
      <div className="MssAdminLicenceUpdate">
        <div className="MssAdminLicenceUpdateLogo"><img src={licenceKeyLogo} alt="" draggable="false" /></div>
        <div className='MssAdminLicenceUpdateInfo'>Modifier la clé de produit</div>
      </div>
      <div className="MssAdminLicenceButton">Modifier</div>
    </div>
  );
}

export default MssAdminLicence;
