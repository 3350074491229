import './PrimaryNav.css';

import { useEffect, useState } from 'react';

import RoomServiceIcon from '@mui/icons-material/RoomService';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import SpaIcon from '@mui/icons-material/Spa';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import ForumIcon from '@mui/icons-material/Forum';
import HandshakeIcon from '@mui/icons-material/Handshake';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { restaurantService } from '../../../restaurant/api/restaurantService';

interface PrimaryMenu {
    name: string,
    path?: string
    icon?: JSX.Element | null,
    items?: Array<PrimaryMenu>,
}

function Primary() {

    const [restaurants, setRestaurants] = useState < any[] > ([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await restaurantService.getRestaurants();
            setRestaurants(data);
        };

        fetchData();
    }, []);

    const navigate = useNavigate();
    const [md1, setMd1] = useState('');
    const [md2, setMd2] = useState('');
    const [md3, setMd3] = useState('');
    const [menus, setMenus] = useState<Array<PrimaryMenu>>([
      {
        name: "Conciergerie",
        path: "dashboard",
        icon: <RoomServiceIcon />,
      },
      {
        name: "Profil clients",
        path: "profilClients",
        icon: <RoomServiceIcon />,
      },
      {
        name: "Restaurant Hotel",
        icon: <DinnerDiningIcon />,
        items: [
          {
            name: "Liste des restaurants",
            items: restaurants.map((rest) => ({
              name: rest.nom,
              path: `restaurant/${rest.id}`,
            })),
          },
          {
            name: "Reservation restaurant",
            items: [
              {
                name: "Restaurant 1",
                path: "reservation",
              },
              {
                name: "Restaurant 2",
                path: "reservation",
              },
              {
                name: "Restaurant 3",
                path: "reservation",
              },
            ],
          },
        ],
      },
      {
        name: "SPA",
        icon: <SpaIcon />,
      },
      {
        name: "Housekeeping",
        icon: <CleaningServicesIcon />,
      },
      {
        name: "Rapport",
        icon: <StickyNote2Icon />,
        items: [
          {
            name: "Fiche de fonction",
          },
          {
            name: "Espace concierge",
            items: [
              {
                name: "Caisse",
                path: "",
                items: [
                  {
                    name: "Règlement sur chambre",
                  },
                  {
                    name: "Règlement Compte Direction",
                  },
                  {
                    name: "Règlement compte Hôtel",
                  },
                  {
                    name: "Gratuité",
                  },
                ],
              },
              {
                name: "Best sellers",
              },
            ],
          },
          {
            name: "Espace restaurant",
          },
          {
            name: "Employée",
            items: [
              {
                name: "Organigramme",
                path: "organigram",
              },
              {
                name: "Liste Employée",
                path: "list",
              },
              {
                name: "Planning",
                path: "planning",
              },
              {
                name: "Liste demande",
                path: "list-demande",
              },
              {
                name: "Liste absence",
                path: "list-absence",
              },
              //   {
              //     name: "RH",
              //   },
            ],
          },
          {
            name: "Objets perdus",
          },
          {
            name: "To",
            path: "to",
          },
        ],
      },
      {
        name: "Feedback",
        path: "feedback",
        icon: <ForumIcon />,
      },
      {
        name: "Prestataires",
        path:"ListTransfer",
        icon: <HandshakeIcon />,
      },
      {
        name: "Whatsapp",
        path: "whatsapp",
        icon: <WhatsAppIcon />,
      },
    ]);

    useEffect(() => {
        // Update the menu when restaurant data changes
        setMenus((prevMenus) => prevMenus.map((menu) => {
            if (menu.name === 'Restaurant Hotel') {
                return {
                    ...menu,
                    items: [
                        {
                            name: 'Liste des restaurants',
                            items: restaurants.map((rest) => ({
                                name: rest.nom,
                                path: `restaurant/${rest.id}`,
                            }))
                        },
                        ...(menu.items?.slice(1) || [])  // Keep the other items in the 'Restaurant Hotel' menu
                    ]
                };
            }
            return menu;
        }));
    }, [restaurants]);

    const mKey = (menu: PrimaryMenu) => menu.name.toLowerCase().split(' ').join('-');

    const go = (event: any, menu: PrimaryMenu, depth: number) => {
        event.stopPropagation();
        setMd1(depth === 1 ? mKey(menu) : md1);
        setMd2(depth === 2 ? mKey(menu) : depth === 1 ? '' : md2);
        setMd3(depth === 3 ? mKey(menu) : depth === 1 || depth === 2 ? '' : md3);
        if (menu.path && menu.path.length) {
            setMd1('');
            setMd2('');
            setMd3('');
            navigate(menu.path);
        }
    }

    const reset = (event: any) => {
        event.stopPropagation();
        setMd1('');
        setMd2('');
        setMd3('');
    }

    const rKey = (menus: Array<PrimaryMenu>) => menus.map(menu => mKey(menu)).join('.');

    const mStyle = (name: string, menu: PrimaryMenu) => ({ display: name === mKey(menu) ? 'block' : 'none' });

    return (
        <div className="PrimaryNav">
            {menus.map(m1 => (
                <div className="PrimaryNavItem" key={rKey([m1])} onClick={(e) => go(e, m1, 1)}>
                    {m1.icon}
                    <div className="PrimaryNavItemLabel">{m1.name}</div>
                    {m1.items?.length ?
                        <div className="PrimaryNavDropdownMenu" style={mStyle(md1, m1)}>
                            <div className="PrimaryNavDropdownMenuBackDrop" onClick={(e) => reset(e)}></div>
                            <div className="PrimaryNavDropdownMenuHeader">
                                <CloseIcon onClick={(e) => reset(e)} />
                            </div>
                            {m1.items?.map(m2 => (
                                <div className="PrimaryNavDropdownMenuItem" key={rKey([m1, m2])} onClick={(e) => go(e, m2, 2)}>
                                    <div>{m2.name}</div>
                                    {m2.items?.length ?
                                        <div className="PrimaryNavDropdownSubMenu" style={mStyle(md2, m2)}>
                                            <div className="PrimaryNavDropdownSubMenuHeader">
                                                <ArrowBackIcon onClick={(e) => go(e, m1, 1)} />
                                                <CloseIcon onClick={(e) => reset(e)} />
                                            </div>
                                            {m2.items?.map(m3 => (
                                                <div className="PrimaryNavDropdownSubMenuItem" key={rKey([m1, m2, m3])} onClick={(e) => go(e, m3, 3)}>
                                                    {m3.name}
                                                    {m3.items?.length ?
                                                        <div className="PrimaryNavDropdownSubSubMenu" style={mStyle(md3, m3)}>
                                                            <div className="PrimaryNavDropdownSubSubMenuHeader">
                                                                <ArrowBackIcon onClick={(e) => go(e, m2, 2)} />
                                                                <CloseIcon onClick={(e) => reset(e)} />
                                                            </div>
                                                            {m3.items?.map(m4 => (
                                                                <div className="PrimaryNavDropdownSubSubMenuItem" key={rKey([m1, m2, m3, m4])} onClick={(e) => go(e, m3, 4)}>
                                                                    {m4.name}
                                                                </div>
                                                            ))}
                                                        </div> : null
                                                    }
                                                </div>
                                            ))}
                                        </div> : null
                                    }
                                </div>
                            ))}
                        </div> : null
                    }
                </div>
            ))}
        </div>
    );
}

export default Primary;
