import React, { useState } from 'react';

import './ItineraireItems.css';


interface ItineraireItemsClients {
    itemTitle: string;
    itemDetails: string;
}

function ItineraireItems() {
    const [show, setShow] = useState(true);

    const _infoItineraire: ItineraireItemsClients[] = [
        { itemTitle: "Compagnie", itemDetails: "Monoclair" },
        { itemTitle: "Type d’hélicoptère", itemDetails: "H705" },
        { itemTitle: "Depuis", itemDetails: "Altiport Mégève" }
    ];

    return (
        <>
            <a  className='itinerarieStyle'
                type="button"
                onClick={() => {
                    setShow(!show);
                }}
            >
                {show ? ' v ' : ' - '}
            </a>

            {show ? (
                <div>

                </div>
            ) : (
                <div className='tableStyle'>
                    <div>
                        <table>
                            <thead>
                                <tr>

                                </tr>
                            </thead>
                            <tbody >
                                {_infoItineraire.map((r, index) => (
                                    <tr>
                                        <td className='tableItinereire_data'><strong>{r.itemTitle}</strong></td>
                                        <td className='tableItinereire_data'>{r.itemDetails}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>

                    <div>
                        <a className='tableXAnnuler_data'><strong>X</strong>
                            <td className='tableAnnuler_data'>Annuler</td></a>
                    </div>
                </div>
            )}
        </>
    );
    // };
}
export default ItineraireItems;