import { useState } from "react";
import {
  MenuItem,
  Select,
  FormControl,
  Stack,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
} from "@mui/material";
import { differenceInDays } from "date-fns";
import { startOfDay } from "date-fns";
import axios from "axios";

interface FormData {
  Date_out: null | Date;
  Date_in: null | Date;
  Id: number;
}
const apiUrl =
  window.location.hostname === "localhost"
    ? "https://localhost:7142/api"
    : "/api";
const Absence = ({ Date_out, Date_in, Id }: FormData) => {
  const [formData, setFormData] = useState({
    Date_out: Date_out,
    Date_in: Date_in,
    Motif: null,
    Status: null,
    EmployeeId: Id,
    Etat: "En Attente",
  });
  const handlediff = (date1: any, date2: any) => {
    const dateOut = startOfDay(new Date(date1));
    const dateIn = startOfDay(new Date(date2));

    const dayDiff = differenceInDays(dateIn, dateOut) + 1;
    return dayDiff;
  };

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  function formattedDate(dateString: any): any {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    return date.toLocaleDateString("fr", options);
  }
  const handleFormSubmit = async () => {
    try {
      const adjustedDateOut = formData.Date_out
        ? new Date(formData.Date_out)
        : null;
      if (adjustedDateOut) {
        adjustedDateOut.setDate(adjustedDateOut.getDate() + 1);
      }

      await axios.post(`${apiUrl}/Demande`, {
        ...formData,
        Date_out: adjustedDateOut,
      });
      if (formData.Date_out && formData.Date_in) {
        const startDate = new Date(formData.Date_out);
        const endDate = new Date(formData.Date_in);
        const datesInRange = [];

        for (
          let date = startDate;
          date <= endDate;
          date.setDate(date.getDate() + 1)
        ) {
          datesInRange.push(new Date(date));
        }

        for (const dateInRange of datesInRange) {
          const adjustedDate = new Date(dateInRange);
          adjustedDate.setDate(adjustedDate.getDate() + 1);
          await axios.post(
            `${apiUrl}/Planning`,
            {
              Date: adjustedDate,
              Status: `En Attente ${formData.Motif}`,
              EmployeeId: Id,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        }
      } else {
        console.log("Date_out or Date_in is null, skipping this formDataItem");
      }

      window.location.reload();
    } catch (error) {
      // Handle error, perhaps by displaying an error message
      console.error("Error submitting demande:", error);
    }
  };

  return (
    <Stack
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      sx={{
        minHeight: 700,
        padding: 5,
      }}
    >
      <Grid
        container
        sx={{ backgroundColor: "rgba(160,226,149,0.1)" }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={1}
        my={0.5}
        spacing={2}
      >
        <Grid item lg={2}>
          <FormControl>
            <Select
              variant="outlined"
              value={formData.Status}
              name="Status"
              fullWidth
              onChange={handleChange}
            >
              <MenuItem value="OFF">OFF</MenuItem>
              <MenuItem value="Ferié">Ferié</MenuItem>
              <MenuItem value="Congé payé">Congé payé</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={2}>
          <TextField
            type="text"
            name="Motif"
            value={formData.Motif}
            onChange={handleChange}
          ></TextField>
        </Grid>
        <Grid item lg={1}>
          <Typography>
            {handlediff(formData.Date_out, formData.Date_in)} jours
          </Typography>
        </Grid>
        <Grid item lg={2}>
          <TextField value={formattedDate(formData.Date_out)} name="Date_out" />
        </Grid>
        <Grid item lg={2}>
          <TextField value={formattedDate(formData.Date_in)} name="Date_in" />
        </Grid>
        <Grid item lg={2}>
          <Box display="flex" alignItems="center">
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.97998 20.45C7.70998 20.317 6.52994 19.963 5.43994 19.388C4.34994 18.813 3.4001 18.08 2.6001 17.188C1.8001 16.296 1.16996 15.275 0.709961 14.125C0.259961 12.975 0.0297852 11.758 0.0297852 10.475C0.0297852 7.89198 0.879844 5.654 2.58984 3.763C4.29984 1.872 6.43 0.784 9 0.5V2.5C6.98 2.783 5.32 3.67899 4 5.18799C2.68 6.69699 2.02979 8.45898 2.02979 10.475C2.02979 12.492 2.68 14.254 4 15.763C5.32 17.272 6.97998 18.167 8.97998 18.45V20.45ZM9.97998 15.5L4.9502 10.45L6.37988 9.02502L8.97998 11.625V5.5H10.98V11.625L13.5498 9.04999L14.98 10.5L9.97998 15.5ZM10.98 20.45V18.45C11.69 18.35 12.38 18.158 13.04 17.875C13.7 17.592 14.3099 17.233 14.8799 16.8L16.3301 18.25C15.5401 18.867 14.6998 19.363 13.7998 19.738C12.8998 20.113 11.96 20.351 10.98 20.45ZM14.9302 4.15002C14.3402 3.71702 13.7201 3.35801 13.0601 3.07501C12.4001 2.79201 11.72 2.6 11 2.5V0.5C11.98 0.6 12.9301 0.838013 13.8301 1.21301C14.7301 1.58801 15.5601 2.08401 16.3301 2.70001L14.9302 4.15002ZM17.73 16.8L16.3301 15.375C16.7601 14.808 17.1099 14.196 17.3799 13.538C17.6399 12.88 17.8302 12.192 17.9302 11.475H19.98C19.84 12.458 19.59 13.404 19.23 14.313C18.86 15.222 18.36 16.051 17.73 16.8ZM17.9302 9.47498C17.8302 8.75798 17.6399 8.07102 17.3799 7.41302C17.1099 6.75502 16.7601 6.14201 16.3301 5.57501L17.73 4.15002C18.36 4.90002 18.87 5.729 19.25 6.638C19.63 7.547 19.88 8.49198 19.98 9.47498H17.9302Z"
                fill="#1D62AF"
              />
            </svg>
            <Typography
              textAlign="center"
              sx={{
                textTransform: "none",
                color: "rgba(0, 0, 0, 0.87)",
              }}
              mx={2}
            >
              EN ATTENTE
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        sx={{ width: 200, mx: "auto" }}
        onClick={handleFormSubmit}
      >
        Valider
      </Button>
    </Stack>
  );
};

export default Absence;
