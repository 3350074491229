import { useEffect, useState } from 'react';
import Allergies from '../../../../models/Allergies';
import FoodPreferences from '../../../../models/Food_Preferences';
import AllergiesCustomer from '../../../../models/AllergiesCustomer';
import FoodPreferencesCustomer from '../../../../models/FoodPreferencesCustomer';
import axios from 'axios';
import React from "react";
import GnzButton from '../../../shared/gnz-button/GnzButton';
import {
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Input,
} from "@mui/material";

import './ProfilClientsPreferenceForm.css';

interface IProfilClientsPreferenceForm {
  myParam: string,
  allergiesCustomer: Array<AllergiesCustomer>,
  foodPreferencesCustomer: Array<FoodPreferencesCustomer>,
  closeForm: () => void,
}

function ProfilClientsPreferenceForm(props: IProfilClientsPreferenceForm) {
  const [Allergies, allergies] = useState<Array<Allergies>>([]);
  const [FoodPreferences, foodPreferences] = useState<Array<FoodPreferences>>([]);
  const Url = 'https://www.mysharingsystem.com/api/';

  useEffect(() => {
    axios.get(Url + 'Allergy')
      .then(res => allergies(res.data));
  }, [])

  useEffect(() => {
    axios.get(Url + 'FoodPref')
      .then(res => foodPreferences(res.data));
  }, [])
     
  const handleChangeAllergiesCustomer = (event:any) => {     
    axios.post(Url + 'AllergiesCustomer',{ 
      "CustomerId": props.myParam,
      "allergiesId":event.target.value
    })
      .then();        
  }; 
  
  const handleChangeFoodPreferenceCustomer = (event:any) => {     
    axios.post(Url + 'FoodPreferenceCustomer',{ 
      "CustomerId": props.myParam,
      "food_PreferencesId":event.target.value
    })
      .then();        
  }; 

  return (
    <div className="PreferenceForm">
      <div className="PreferenceContent">
      <h1>Allergies</h1>

      {Allergies.map(r => (
      <p><Checkbox value={r.id} onChange={handleChangeAllergiesCustomer} defaultChecked={ props.allergiesCustomer.some(item => r.id === item.allergiesId)}/> 
      {r.displayAs} {/* <img className="TagLogo" src={`data:image/jpeg;base64,${r.tagImage}`} alt="" />     */}
      </p>
      ))}

      <h1>FoodPreferences</h1>
      {FoodPreferences.map(r => (
      <p><Checkbox value={r.id} onChange={handleChangeFoodPreferenceCustomer} defaultChecked={ props.foodPreferencesCustomer.some(item => r.id === item.food_PreferencesId)}/> 
      {/* <img className="TagLogo" src={`data:image/jpeg;base64,${r.tagImage}`} alt="" />     */}
      {r.displayAs} 
      </p>
      ))}
       
      </div>

      <div className="spaceTop">
        <hr style={{
          color: '#bababa',
          backgroundColor: '#bababa',
          borderColor: '#bababa'
        }} />
      </div>

      <div className="ProfilClientPreferenceFormActions">
        <div  className="FormActionsLefth">
          <GnzButton label="Cancel" onClick={props.closeForm} color="#ffffff" backgroundColor="#ee4d4d" />
        </div>
        <div  className="FormActionsRight">
          <GnzButton label="Save" onClick={props.closeForm} color="#ffffff" backgroundColor="#1D62AF" />
        </div>
      </div>
    </div>
  );
}

export default ProfilClientsPreferenceForm;
