import React, { useState } from 'react';

import './FeedbackTableItems.css';


interface FeedbackTableItemsClients {
    itemTitle: string;
    itemDetails: string;
}

function FeedbackTableItems() {
    const [show, setShow] = useState(true);

    const _infoItineraire: FeedbackTableItemsClients[] = [
        { itemTitle: "Dates", itemDetails: "17/01/2024" },
        { itemTitle: "Heure", itemDetails: "19h30" },
        { itemTitle: "Dates séjour", itemDetails: "16-1-24 24-1-24" },
        { itemTitle: "Statut", itemDetails: "E/C" },
        { itemTitle: "Commentaire", itemDetails: "La chambre n’a pas été néttoyé au moment de la recouche" },
        { itemTitle: "Service", itemDetails: "Réception" },
        { itemTitle: "Initiales", itemDetails: "AI" }
    ];

    return (
        <>
            <a  className='feedbackStyle'
                type="button"
                onClick={() => {
                    setShow(!show);
                }}
            >
                {show ? ' v ' : ' - '}
            </a>

            {show ? (
                <div>

                </div>
            ) : (
                <div className='tableStyle'>
                    <div>
                        <table>
                            <thead>
                                <tr>

                                </tr>
                            </thead>
                            <tbody >
                                {_infoItineraire.map((r, index) => (
                                    <tr>
                                        <td className='tableItinereire_data'><strong>{r.itemTitle}</strong></td>
                                        <td className='tableItinereire_data'>{r.itemDetails}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>

                    <div>

                    <a className='tableXAnnuler_data'><strong>/</strong>
                            <td className='tableAnnuler_data'>Modifier</td></a>

                        <a className='tableXAnnuler_data'><strong>X</strong>
                            <td className='tableAnnuler_data'>Annuler</td></a>
                    </div>
                </div>
            )}
        </>
    );
    // };
}
export default FeedbackTableItems;