import './Rooming.css';

import GnzBreadcrumb from '../shared/gnz-breadcrumb/GnzBreadcrumb';
import GnzPaper from '../shared/gnz-paper/GnzPaper';

function View() {
    return (
        <div style={{fontFamily: 'Montserrat', fontSize: 14, fontWeight: 600}}>
            Rooming
        </div>
    )
}

function Rooming() {
    return (
        <div className="Rooming">
            <GnzBreadcrumb breadcrumbs={['Rooming', 'Rooming']}/>
            <GnzPaper component={<View />}/>
        </div>
    );
}

export default Rooming;
