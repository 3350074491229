import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";

export default function SizeAvatars({
  poste,
  nom,
  prenom,
  profilePicture,
}: any) {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      width={150}
      height={50}
      sx={{
        backgroundColor: "#F0F4F8",
        borderRadius: "20px",
      }}
    >
      <Avatar
        alt={`${nom} ${prenom}`}
        src={profilePicture}
        sx={{
          width: 45,
          height: 45,
        }}
      />
      <Box sx={{width:90}}>
        {nom && prenom ? (
          <Typography
            fontWeight="bold"
            textAlign="center"
            fontSize="10px"
            sx={{ fontFamily: "'Poppins', sans-serif" }}
          >
            {`${nom} ${prenom}`}
          </Typography>
        ) : (
          ""
        )}

        <Typography
          color="gray"
          textAlign="center"
          fontSize="8px"
          sx={{ fontFamily: "'Poppins', sans-serif" }}
        >
          {poste}
        </Typography>
      </Box>
    </Stack>
  );
};

