import { Typography, Paper, Button, Drawer, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { useState, useEffect } from 'react';
import axios from 'axios';
import AddProvider from './AddProvider';
import UpdateProvider from './UpdateProvider';
import './Provider.css';

const apiUrl = window.location.hostname === 'localhost' ? 'https://localhost:7142/api' : '/api';

const ListProvider = () => {
    const [drawerState, setDrawerState] = useState(false);
    const [drawerStateUpdate, setDrawerStateUpdate] = useState<{ [key: number]: boolean }>({});
    const [currentProviderId, setCurrentProviderId] = useState<number | null>(null);
    const [data, setData] = useState([]);



    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/Provider/AllProvidersDetails`);
            setData(response.data);
        } catch (error) {
            console.error('Error getting data:', error);
        }
    };

    const toggleDrawer = (open:any) => () => {
        setDrawerState(open);
    };

    const toggleDrawerUpdate = (id:any, open:any) => () => {
        setDrawerStateUpdate((prevState) => ({
            ...prevState,
            [id]: open
        }));
        if (open) {
            setCurrentProviderId(id);
        }
    };

    const handleDeleteContact = (providerID:any) => {
        axios.put(`${apiUrl}/Provider/DeleteProvider/${providerID}`)
            .then(response => {
                console.log('Provider deleted successfully');
                getData(); // Refresh data instead of reloading the page
            })
            .catch(error => {
                console.error('Error deleting contact:', error);
            });
    };

    return (
        <>
            <div className='service-container'>
                <div className="service-filter">
                    <div className="service-filter-left"></div>
                    <div className="service-filter-right">
                        <button className='service-reservation-btn' onClick={toggleDrawer(true)}>
                            Nouveau prestataire
                        </button>
                        <Drawer
                            anchor="right"
                            open={drawerState}
                            onClose={toggleDrawer(false)}
                        >
                            <AddProvider onClose={toggleDrawer(false)} />
                        </Drawer>
                    </div>
                </div>
                <br />
                <div className="service-body">
                    <div className="service-body-header">
                        <h2>Prestataires</h2>
                    </div>
                    <div className="service-body-table">
                        <Grid container spacing={2}>
                            <Table sx={{ maxWidth: 700, padding: "20px 5px", margin: "0 auto" }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '25%', flexShrink: 0, fontWeight: 'bold' }}>Prestataire</TableCell>
                                        <TableCell sx={{ width: '25%', flexShrink: 0, fontWeight: 'bold' }}>Téléphone</TableCell>
                                        <TableCell sx={{ width: '25%', flexShrink: 0, fontWeight: 'bold' }}>Email</TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>

                            {data.map((prop:any, index:any) => (
                                <Grid item xs={12} key={prop.providerID} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Accordion sx={{ backgroundColor: index % 2 === 0 ? '#A0E2954D' : '#F5F5F5', width: '100%' }} style={{ maxWidth: 700 }}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography sx={{ width: '40%', flexShrink: 0, fontWeight: 'bold' }}>{prop.name}</Typography>
                                            <Typography sx={{ width: '40%', color: 'text.secondary', fontWeight: 'bold' }}>{prop.phoneNumber}</Typography>
                                            <Typography sx={{ color: 'text.secondary', fontWeight: 'bold' }}>{prop.email}</Typography>
                                        </AccordionSummary>

                                        <AccordionDetails style={{ maxWidth: 1000 }}>
                                            {prop.trips[0].departure == null ? (
                                                <></>
                                            ) : (
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 300 }} aria-label="simple table">
                                                        {prop.trips.slice(0, 1).map((list:any) => (
                                                            <TableHead key={list.tripID}>
                                                                <TableRow>
                                                                    <TableCell sx={{ fontWeight: 'bold' }}>Trajet</TableCell>
                                                                    <TableCell sx={{ fontWeight: 'bold' }} align="left">{list.car1}</TableCell>
                                                                    <TableCell sx={{ fontWeight: 'bold' }} align="left">{list.car2}</TableCell>
                                                                    <TableCell sx={{ fontWeight: 'bold' }} align="left">{list.car3}</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                        ))}

                                                        {prop.trips.map((list:any) => (
                                                            <TableBody key={list.tripID}>
                                                                <TableRow>
                                                                    <TableCell component="th" scope="row">{list.departure}-{list.arrival}</TableCell>
                                                                    <TableCell align="left">{list.price1}</TableCell>
                                                                    <TableCell align="left">{list.price2}</TableCell>
                                                                    <TableCell align="left">{list.price3}</TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        ))}
                                                    </Table>
                                                </TableContainer>
                                            )}
                                            <br />
                                            {prop.disposals[0].numberOfHours == null ? (
                                                <></>
                                            ) : (
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 300 }} aria-label="simple table">
                                                        {prop.disposals.slice(0, 1).map((list:any) => (
                                                            <TableHead key={list.disposalID}>
                                                                <TableRow>
                                                                    <TableCell sx={{ fontWeight: 'bold' }}>Disposition</TableCell>
                                                                    <TableCell sx={{ fontWeight: 'bold' }} align="left">{list.car1}</TableCell>
                                                                    <TableCell sx={{ fontWeight: 'bold' }} align="left">{list.car2}</TableCell>
                                                                    <TableCell sx={{ fontWeight: 'bold' }} align="left">{list.car3}</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                        ))}
                                                        {prop.disposals.map((list:any) => (
                                                            <TableBody key={list.disposalID}>
                                                                <TableRow>
                                                                    <TableCell align="left">{list.departure}-{list.arrival} : {list.numberOfHours} heure(s)</TableCell>
                                                                    <TableCell align="left">{list.price1}</TableCell>
                                                                    <TableCell align="left">{list.price2}</TableCell>
                                                                    <TableCell align="left">{list.price3}</TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        ))}
                                                    </Table>
                                                </TableContainer>
                                            )}
                                            <br />
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 300 }} aria-label="simple table">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="left">Charge supplémentaire pour la disposition</TableCell>
                                                            <TableCell align="left">{prop.disposalCharge}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="left">Charge supplementaire travail de nuit de ({prop.hStartNight} à {prop.hEndNight})</TableCell>
                                                            <TableCell align="left">{prop.nightRate}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="left">Charge supplementaire haute saison</TableCell>
                                                            <TableCell align="left">{prop.highSeasonCharge}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="left">Information</TableCell>
                                                            <TableCell align="left">{prop.additionalInformation}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <br />
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 300 }} aria-label="simple table">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: 'bold' }} align="left">Politique d'annulation</TableCell>
                                                            <TableCell align="left">{prop.cancelationPolicy}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <small>
                                                <Button style={{ color: "black" }} size="small" startIcon={<EditIcon />} onClick={toggleDrawerUpdate(prop.providerID, true)}>
                                                    Modifier le prestataire
                                                </Button>
                                                <Drawer
                                                    anchor="right"
                                                    open={!!drawerStateUpdate[prop.providerID]}
                                                    onClose={toggleDrawerUpdate(prop.providerID, false)}
                                                >
                                                    <UpdateProvider providerId={currentProviderId} onCloseDrawerUpdate={toggleDrawerUpdate(prop.providerID, false)} />
                                                </Drawer>
                                                <Button style={{ color: "black" }} size="small" startIcon={<DeleteOutlineOutlinedIcon style={{ color: "red" }} />} onClick={() => handleDeleteContact(prop.providerID)}>
                                                    Supprimer le prestataire
                                                </Button>
                                            </small>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </div>
                <div className='service-body-footer'>
                    <button style={{ backgroundColor: "#1D62AF", color: "#fff" }}>
                        Menu Prestataire
                    </button>
                    <button style={{ backgroundColor: "#A0E295", color: "rgba(0, 0, 0, 0.60)" }}>
                        Reminder globale
                    </button>
                </div>
            </div>
        </>
    );
};

export default ListProvider;
