import * as React from 'react';

import Grid from '@mui/material/Grid';
import { Card, CardContent, Button, TextField, Typography, InputLabel, Drawer} from '@mui/material';
import { Accordion, AccordionSummary,AccordionDetails} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import  Select,{ SelectChangeEvent }   from '@mui/material/Select';
import  MenuItem from '@mui/material/MenuItem'

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import axios from 'axios';

import './Transfer.css'


const apiUrl = window.location.hostname === 'localhost' ? 'https://localhost:7142/api' : '/api';

const { useState } = React;
const SharingButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  width: '259px',
  height: '50px',
  fontSize: 16,
  fontWeight:'bold',
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: '#1D62AF',
  borderColor: '#1D62AF',
  fontFamily: 'Montserrat',
  '&:hover': {
    backgroundColor: '#0069d9',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});

interface AddTransferProps {
  onCloseDrawer: () => void;
}

const AddTransfer: React.FC<AddTransferProps> = ({ onCloseDrawer }) => {

const [isModalOpen, setIsModalOpen] = useState(false);
const handleCloseModal = () => {
  setIsModalOpen(false);
};

// We daclare it to send it empty to Email
const [previousData, setPreviousData] = useState([{ 
  TransferDate: null,
  TransferTime: null,
  Paiement: null,
  TransfertNote: null,
  Car:null,
  NumberOfCars: null,
  NumberOfLuggage: null,
  NumberOfPassenger: null,
  SuppInformation: null,
  
}]);


// navigate after validation
//const navigate = useNavigate();
// Select type of transfer
const [selectedOption, setSelectedOption] = useState(null);
const [stateDispo, setstateDispo] = useState({statut:false,})
const [stateTrip, setstateTrip] = useState({statut:false,})
const handleCheckboxChange = (option:any) => {
  setSelectedOption(option);
  formData.TransferType=option;
  // Choose only one type: trip or disposition
  if (option == 'Départ' || 'Arrivée') stateDispo.statut=true
  if (option == 'Disposition') stateTrip.statut=true
};
///////////////////////////////////////////////////////////////////////////////
// Display preference data
///////////////////////////////////////////////////////////////////////////////
const [stateRadio, setStateRadio] = useState({statut:true,})
const [prefData, setPrefData]= useState({
  TransfertNote:null,
  NumberOfLuggage:null,
  NumberOfPassenger:null
})
const [display, setDisplay]=useState(0)

const handleRadioChange = async () => {
  //Retrieve IDClient:
  const Client = await fetch(`${apiUrl}/Client/IdClient/${formData.FirstName}&${formData.LastName}`);
  const response = await Client.json();
  //Retrieve preference data of the selected client:
  const pref = await fetch(`${apiUrl}/Transfer/PrefTransfer?IDCLient=${response[0]}`);
  const data = await pref.json();
  //To display preference data
  setDisplay(1)
  //No transfer for this client 
  if (data.length==0){
    prefData.TransfertNote=null
    prefData.NumberOfLuggage=null
    prefData.NumberOfPassenger=null
  }
  else{  
    prefData.TransfertNote=data[0].transfertNote
    prefData.NumberOfLuggage=data[0].numberOfLuggage
    prefData.NumberOfPassenger=data[0].numberOfPassenger
  }
  
  // console.log('prefData')
  // console.log(prefData)

}
//Calculate Price
// Define the type for price items
interface PriceItem {
  HighSeasonPrice: number;
  NightPrice: number;
  TotalPrice: number;
}

// Initialize state with the correct type
const [price, setPrice] = useState<PriceItem[]>([]);

// const [price, setPrice] = useState([{ 
//   HighSeasonPrice:null,
//   NightPrice:null,
//   TotalPrice:null
// }])

///////////////////////////////////////////////////////////////////////////////
// Data form
///////////////////////////////////////////////////////////////////////////////
const [formData, setFormData] = useState({
  LastName: null,
  FirstName: null,
  TransferDate: 0,
  TransferTime: 0,
  TransferType: null,
  NumberOfCars: null,
  ArrivalTime: null,
  EndTime: null,
  TransportDetail: null,
  Price: 0,
  Statut: null,
  Paiement: null,
  TransfertNote: null,
  NumberOfLuggage: null,
  NumberOfPassenger: null,
  SuppInformation: null,
  CurrentClientId: null,
  CurrentDisposalId: null,
  CurrentTripId: null,
  DuplicateDate:null,
  Statut_email:'demande de réservation',
  Departure:null,
  Arrival:null,
  Car:null,
});
const handleInputChange = async(event:any) => {
  // Store data
  const { name, value } = event.target;
  setFormData({ ...formData, [name]: value });
  // We must have client's firstname and lastname to share de preference data 
  if (formData.FirstName && formData.LastName != null){
    stateRadio.statut = false
  } 

}
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
// Dependent Drop Down 'Arrivée-Départ': Departure-Arrival-Car-Provider
//////////////////////////////////////////////////////////////////////////////
  const [providers, setProviders] = useState<any[]>([]);
  const [cars, setCars] = useState<any[]>([]);
  const [arrivals, setArrivals] = useState<any[]>([]);
  const [departures, setDepartures] = useState<any[]>([]);

  const [SelectedDeparture, setSelectedDeparture] = useState(null);
  const [SelectedArrival, setSelectedArrival] = useState(null);
  const [SelectedCar, setSelectedCar] = useState(null);
  const [selectedTrip, setSelectedTrip] = React.useState<string[]>([]);

  const handleDepartureChange = async (event:any) => {
    const selectedDeparture = event.target.value;
    setSelectedDeparture(selectedDeparture);
    const response = await fetch(`${apiUrl}/Trip/ArrivalsProvider/${selectedDeparture}`);
    const data = await response.json();
    setArrivals(data);
  };

  const handleArrivalChange =async(event:any) => {
    const SelectedArrival = event.target.value;
    setSelectedArrival(SelectedArrival);
    const response = await fetch(`${apiUrl}/Trip/Cars/${SelectedDeparture}&${SelectedArrival}`);
    const data = await response.json();
    setCars(data);
  }

  const handleCarChange = async (event:any) => {
    const car = event.target.value;
    setSelectedCar(car)
    const response = await fetch(`${apiUrl}/Trip/Providers/${SelectedDeparture}&${SelectedArrival}&${car}`);
    const data = await response.json();
    setProviders(data);
    console.log(data)
    // initialization
    for (var i=0; i <= 250; i++){
      setPrice([...price,{
        HighSeasonPrice:0,
        NightPrice:0,
        TotalPrice:0
      }])
    }
  };

  const handleProvidersChange = (event: SelectChangeEvent<typeof selectedTrip>) => {
    const {
      target: { value },
    } = event;
    setSelectedTrip(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  useEffect(() => {
      const fetchDepartures = async () => {
      const response = await fetch(`${apiUrl}/Trip/allDepartures`);
      const data = await response.json();
      setDepartures(data);
    };

    fetchDepartures();

  }, []);
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////
// Dependent Drop Down 'Disposition': Departure-Arrival-Car-Time-Provider
//////////////////////////////////////////////////////////////////////////////
const [departuresDispo, setDeparturesDispo] = useState<any[]>([]);
const [arrivalsDispo, setArrivalsDispo] = useState<any[]>([]);
const [carsDispo, setCarsDispo] = useState<any[]>([]);
const [timesDispo, setTimesDispo] = useState<any[]>([]);
const [providersDispo, setProvidersDispo] = useState<any[]>([]);

const [SelectedDepartureDispo, setSelectedDepartureDispo] = useState(null);
const [SelectedArrivalDispo, setSelectedArrivalDispo] = useState(null);
const [SelectedCarDispo, setSelectedCarDispo] = useState(null);
const [SelectedTimeDispo,setSelectedTimeDispo]=useState(null)
const [selectedDispo, setSelectedDispo] = React.useState<string[]>([]);

useEffect(() => {
  const fetchDeparturesDispo = async () => {
  const response = await fetch(`${apiUrl}/Disposal/allDepartures`);
  const data = await response.json();
  setDeparturesDispo(data);
};
fetchDeparturesDispo();
}, []);

const handleDepartureDispoChange = async (event:any) => {
  const SelectedDepartureDispo = event.target.value;
  setSelectedDepartureDispo(SelectedDepartureDispo);
  const response = await fetch(`${apiUrl}/Disposal/ArrivalsProvider/${SelectedDepartureDispo}`);
  const data = await response.json();
  //console.log(data);
  setArrivalsDispo(data);
};

const handleArrivalDispoChange = async(event:any) => {
  const SelectedArrivalDispo = event.target.value;
  setSelectedArrivalDispo(SelectedArrivalDispo);
  const response = await fetch(`${apiUrl}/Disposal/Cars/${SelectedDepartureDispo}&${SelectedArrivalDispo}`);
  const data = await response.json();
  setCarsDispo(data);
}

const handleCarDispoChange = async (event:any) => {
  const SelectedCarDispo = event.target.value;
  setSelectedCarDispo(SelectedCarDispo)
  const response = await fetch(`${apiUrl}/Disposal/Times/${SelectedDepartureDispo}&${SelectedArrivalDispo}&${SelectedCarDispo}`);
  const data = await response.json();
  setTimesDispo(data);
  // initialization
  for (var i=0; i <= 250; i++){
    setPrice([...price,{
      HighSeasonPrice:0,
      NightPrice:0,
      TotalPrice:0
    }])
  }

};

const handleTimeDispoChange = async (event:any) => {
  const SelectedTimeDispo = event.target.value;
  setSelectedTimeDispo(SelectedTimeDispo)
  const response = await fetch(`${apiUrl}/Disposal/Providers/${SelectedDepartureDispo}&${SelectedArrivalDispo}&${SelectedCarDispo}&${SelectedTimeDispo}`);
  const data = await response.json();
  setProvidersDispo(data)
  console.log(data)
};

const handleProvidersDispoChange = (event: SelectChangeEvent<typeof selectedDispo>) => {
  const {
    target: { value },
  } = event;
  setSelectedDispo(
    typeof value === 'string' ? value.split(',') : value,
  );
};


///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
// Submit: 
//Send email to the EMAIL template
const [email, setEmail] = useState<any[]>([]);
const handleSubmit = async (event:any) => {
// Prevent default form submission
event.preventDefault(); 

console.log('formData.TransfertNote1:', formData.TransfertNote)
console.log('formData.NumberOfLuggage1:', formData.NumberOfLuggage)
console.log('formData.NumberOfPassenger1:', formData.NumberOfPassenger)


//Store selected data in FormData to send it to Email
//-1 departure, arrival, car
  if ((formData.TransferType == "Départ") || (formData.TransferType == "Arrivée")){
    formData.Departure=SelectedDeparture
    formData.Arrival=SelectedArrival
    formData.Car=SelectedCar
  }
  if (formData.TransferType == "Disposition"){
    formData.Departure=SelectedDepartureDispo
    formData.Arrival=SelectedArrivalDispo
    formData.Car=SelectedCarDispo
  }
  //-2 email(s) of provider(s)
  if ((formData.TransferType == "Départ") || (formData.TransferType == "Arrivée")){
    for (var i=0; i < selectedTrip.length; i++){
      const response = await fetch(`${apiUrl}/Provider/Email/${selectedTrip[i]}`);
      const result = await response.json();  
      email[i]=result
    }
  }
  if (formData.TransferType == "Disposition"){
    for (var i=0; i < selectedDispo.length; i++){
      const response = await fetch(`${apiUrl}/Provider/Email/${selectedDispo[i]}`);
      const result = await response.json();  
      email[i]=result
    }
  }
  


  /////////////////////////////////////////////////////////////////////////////////////////////////////
   //Retrieve IDClient
   /////////////////////////////////////////////////////////////////////////////////////////////////////
   const response = await fetch(`${apiUrl}/Client/IdClient/${formData.FirstName}&${formData.LastName}`);
   const IDClient = await response.json();
  /////////////////////////////////////////////////////////////////////////////////////////////////////
  // Preference Data
  /////////////////////////////////////////////////////////////////////////////////////////////////////
     //Case 1: Data isn't displayed 
   if (display == 0){
    //No updated data
    if (formData.TransfertNote == null && formData.NumberOfLuggage == null && formData.NumberOfPassenger == null){
      const pref = await fetch(`${apiUrl}/Transfer/PrefTransfer?IDCLient=${IDClient[0]}`);
      const data = await pref.json();
      console.log('data:', data)
      //No prefrence data 
      if (data.length==0){
        formData.TransfertNote=null
        formData.NumberOfLuggage=null
        formData.NumberOfPassenger=null
      }
      else{  
        formData.TransfertNote=data[0].transfertNote
        formData.NumberOfLuggage=data[0].numberOfLuggage
        formData.NumberOfPassenger=data[0].numberOfPassenger
      }
    }
    //Updated data
    else if (formData.TransfertNote != null && formData.NumberOfLuggage != null && formData.NumberOfPassenger != null){
      //
      formData.TransfertNote
      formData.NumberOfLuggage
      formData.NumberOfPassenger
    }
   }
   //Case 2: Data is displayed
   if (display == 1){
    //No updated data
    if (formData.TransfertNote == null && formData.NumberOfLuggage == null && formData.NumberOfPassenger == null){
      formData.TransfertNote=prefData.TransfertNote
      formData.NumberOfLuggage=prefData.NumberOfLuggage
      formData.NumberOfPassenger=prefData.NumberOfPassenger
    }
    //Updated data
    else if (formData.TransfertNote != null && formData.NumberOfLuggage != null && formData.NumberOfPassenger != null){
      //
      formData.TransfertNote
      formData.NumberOfLuggage
      formData.NumberOfPassenger
    }
   }
   /////////////////////////////////////////////////////////////////////////////////////////////////////
   // Case Price written
   /////////////////////////////////////////////////////////////////////////////////////////////////////
   //TRIP 
    if (formData.Paiement == "Compte direction"){
      for (var i=0; i < selectedTrip.length; i++){
        price[i].TotalPrice=formData.Price
      }
    }
  //DISPOSITION
  if (formData.Paiement == "Compte direction"){
    for (var i=0; i < selectedDispo.length; i++){
      price[i].TotalPrice=formData.Price
    }
  }

    console.log('formData.TransfertNote2:', formData.TransfertNote)
    console.log('formData.NumberOfLuggage2:', formData.NumberOfLuggage)
    console.log('formData.NumberOfPassenger2:', formData.NumberOfPassenger)

   /////////////////////////////////////////////////////////////////////////////////////////////
   //Case 0 : No Trip - No Disposition
   /////////////////////////////////////////////////////////////////////////////////////////////


   /////////////////////////////////////////////////////////////////////////////////////////////
   //Case 1 : Trip
   /////////////////////////////////////////////////////////////////////////////////////////////
  if (selectedOption == 'Arrivée' || 'Départ'){
     for (var i=0; i < selectedTrip.length; i++)
     { 
       try {
        await axios.post(`${apiUrl}/Transfer/addTransfer`,{
          TransferDate:formData.TransferDate,
          TransferTime:formData.TransferTime,
          TransferType:formData.TransferType,
          NumberOfCars:formData.NumberOfCars,
          ArrivalTime:formData.ArrivalTime,
          EndTime:formData.EndTime,
          TransportDetail:formData.TransportDetail,
          Price:String(price[i].TotalPrice),
          Statut:formData.Statut,
          Paiement:formData.Paiement,
          TransfertNote:formData.TransfertNote,
          NumberOfLuggage:formData.NumberOfLuggage,
          NumberOfPassenger:formData.NumberOfPassenger,
          SuppInformation:formData.SuppInformation,
          CurrentClientId:IDClient[0],
          CurrentProviderId:String(selectedTrip[i]),
          Departure:SelectedDeparture,
          Arrival:SelectedArrival,
          Car:SelectedCar,
          deleted:0,
        } , 
      { headers: { "Content-Type": "application/json" } }
      );
      }catch (error) {
      console.error("Error submitting form trip:", error);
      }
    }//end for 1

//     // Here, we duplicate ...
    if (formData.DuplicateDate != null)
    {
    for (var i=0; i < selectedTrip.length; i++)
    { 
      try {
        await axios.post(`${apiUrl}/Transfer/addTransfer`,{
          TransferDate:formData.DuplicateDate,
          TransferTime:formData.TransferTime,
          TransferType:formData.TransferType,
          NumberOfCars:formData.NumberOfCars,
          ArrivalTime:formData.ArrivalTime,
          EndTime:formData.EndTime,
          TransportDetail:formData.TransportDetail,
          Price:String(price[i].TotalPrice),
          Statut:formData.Statut,
          Paiement:formData.Paiement,
          TransfertNote:formData.TransfertNote,
          NumberOfLuggage:formData.NumberOfLuggage,
          NumberOfPassenger:formData.NumberOfPassenger,
          SuppInformation:formData.SuppInformation,
          CurrentClientId:IDClient[0],
          CurrentProviderId:String(selectedTrip[i]),
          Departure:SelectedDeparture,
          Arrival:SelectedArrival,
          Car:SelectedCar,
          deleted:0,
        } , 
      { headers: { "Content-Type": "application/json" } }
      );
      }catch (error) {
      console.error("Error submitting form trip:", error);
      }
    }//end for 2
  }//end if duplicate
}//end if

//   /////////////////////////////////////////////////////////////////////////////////////////////
//   //Case 2 : Disposal
//   /////////////////////////////////////////////////////////////////////////////////////////////
  if (selectedOption == 'Disposition'){
    for (var i=0; i < selectedDispo.length; i++)
    { 
      try {
        await axios.post(`${apiUrl}/Transfer/addTransfer`,{
          TransferDate:formData.TransferDate,
          TransferTime:formData.TransferTime,
          TransferType:formData.TransferType,
          NumberOfCars:formData.NumberOfCars,
          ArrivalTime:formData.ArrivalTime,
          EndTime:formData.EndTime,
          TransportDetail:formData.TransportDetail,
          Price:String(price[i].TotalPrice),
          Statut:formData.Statut,
          Paiement:formData.Paiement,
          TransfertNote:formData.TransfertNote,
          NumberOfLuggage:formData.NumberOfLuggage,
          NumberOfPassenger:formData.NumberOfPassenger,
          SuppInformation:formData.SuppInformation,
          CurrentClientId:IDClient[0],
          Company:String(selectedDispo[i]),
          CurrentProviderId:String(selectedDispo[i]),
          Departure:SelectedDepartureDispo,
          Arrival:SelectedArrivalDispo,
          Car:SelectedCarDispo,
          NumberOfHours:SelectedTimeDispo,
          deleted:0,
        } , 
      { headers: { "Content-Type": "application/json" } }
      );
      }catch (error) {
      console.error("Error submitting form disposition :", error);
      }
    }// end for 1

    // Here, we duplicate again ...
    if (formData.DuplicateDate != null)
    {
      for (var i=0; i < selectedDispo.length; i++)
      { 
        try {
          await axios.post(`${apiUrl}/Transfer/addTransfer`,{
            TransferDate:formData.DuplicateDate,
            TransferTime:formData.TransferTime,
            TransferType:formData.TransferType,
            NumberOfCars:formData.NumberOfCars,
            ArrivalTime:formData.ArrivalTime,
            EndTime:formData.EndTime,
            TransportDetail:formData.TransportDetail,
            Price:String(price[i].TotalPrice),
            Statut:formData.Statut,
            Paiement:formData.Paiement,
            TransfertNote:formData.TransfertNote,
            NumberOfLuggage:formData.NumberOfLuggage,
            NumberOfPassenger:formData.NumberOfPassenger,
            SuppInformation:formData.SuppInformation,
            CurrentClientId:IDClient[0],
            Company:String(selectedDispo[i]),
            CurrentProviderId:String(selectedDispo[i]),
            Departure:SelectedDepartureDispo,
            Arrival:SelectedArrivalDispo,
            Car:SelectedCarDispo,
            NumberOfHours:SelectedTimeDispo,
            deleted:0,
          } , 
        { headers: { "Content-Type": "application/json" } }
        );
        }catch (error) {
        console.error("Error submitting form disposition duplication:", error);
        }
      }// end for 2
    }//end if duplicate
  }//end if


  //go back 
  onCloseDrawer(); // Ferme le drawer
  window.location.reload(); // Actualise la page
  // Ouvrir le modal
 // setIsModalOpen(true);
 }
 

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
return (
<>

<Card style={{ maxWidth: 450, padding: '20px 5px', margin: '0 auto', overflowY: 'auto', flexGrow: 1 }}>
  <label className='titleCard' style={{fontWeight:'bold'}}> &nbsp;&nbsp;Ajouter une nouvelle réservation</label> 
  <form onSubmit={handleSubmit}> 
    <CardContent> 
     {/*Blue Card: Transfert  */}
    <Accordion  sx={{ backgroundColor: '#1D62AF1A'}}>
      <AccordionSummary  expandIcon={<ExpandMoreIcon />}>  
        <Typography sx={{ width: '40%', flexShrink:0,fontWeight:'bold'}}>Transfert</Typography>
        <Grid className='line' ></Grid>
      </AccordionSummary>
      
      <AccordionDetails style={{ maxWidth: 650, padding: "20px 5px", margin: "0 auto" }}>
        <Grid container spacing={1}>
          <Grid item xs={4} md={4}>
            <label className='FontLabel'  >Nom*</label >
            <TextField required size='small' name="LastName"  onChange={handleInputChange} type="text" placeholder="" className='StyleInput'/>
          </Grid>
          <Grid item xs={4} md={4}>
            <label className='FontLabel'  >Prénom*</label >
            <TextField required size='small' name="FirstName"  onChange={handleInputChange} type="text" placeholder=""  className='StyleInput'/>
          </Grid>
          <Grid item xs={4} md={4}>
            <label className='FontLabel'  >Chambre</label >
            <TextField size='small' name="Room"  onChange={handleInputChange} type="text" placeholder=""  className='StyleInput' />
          </Grid>
          <Grid item xs={12} md={12} className="center-left">
            <FormControl >
              <RadioGroup row aria-labelledby="demo-form-control-label-placement" className="radio-input" name="pref" >
                <FormControlLabel value="pref" control={<Radio />} label="Visualiser s'il y a des préférences préenregistrées" onChange={() => handleRadioChange()} disabled={stateRadio.statut}/>
              </RadioGroup>
            </FormControl>
          </Grid>
          {(display == 1 )
          &&
          <Grid item xs={12} md={12}>
            <p>Note: {prefData.TransfertNote} Nombre de bagage: {prefData.NumberOfLuggage} Nombre de passager: {prefData.NumberOfPassenger}</p>
          </Grid>
          }
          <Grid item xs={12} md={12} className='line'></Grid>
          <Grid item xs={4} md={4}>
              <label className='FontLabel'  >Téléphone Client</label >
              <TextField  size='small' name="PhoneNumber" onChange={handleInputChange}  type="number" placeholder=""  className='StyleInput' />
          </Grid>
          <Grid item xs={4} md={4}>
              <label className='FontLabel'  >Date*</label >
              <TextField required size='small' name="TransferDate" onChange={handleInputChange}  type="date" placeholder=""  className='StyleInput' fullWidth/>
          </Grid>
          <Grid item xs={4} md={4}>
              <label className='FontLabel'  >Heure*</label ><br/>
              <TextField required size='small' name="TransferTime" onChange={handleInputChange}  type="time" placeholder=""  className='StyleInput' fullWidth/>
          </Grid>
          <Grid item xs={12} md={12} className="center-horizonally"> 
            <label>
              <input type="checkbox"  checked={selectedOption == 'Arrivée'} onChange={() => handleCheckboxChange('Arrivée')} disabled={stateTrip.statut}/> Arrivée&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </label>
            <label>
              <input type="checkbox"  checked={selectedOption == 'Départ'} onChange={() => handleCheckboxChange('Départ')} disabled={stateTrip.statut}/> Départ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </label>
            <label>
              <input type="checkbox"  checked={selectedOption == 'Disposition'} onChange={() => handleCheckboxChange('Disposition')} disabled={stateDispo.statut}/> Disposition
            </label>
          </Grid>

          {/* IF 'Arrivée' or 'Départ' checked */}
          {(selectedOption == 'Départ' || selectedOption == 'Arrivée' )
          &&
          <>
             {/* IF 'Arrivée' or 'Départ' checked */}
             <Grid item xs={4} md={4}>
              <label className='FontLabel' >Paiement</label ><br/>
              <Select  variant="outlined" name="Paiement" onChange={handleInputChange} className='StyleInput' size='small' defaultValue="" fullWidth >
                <MenuItem value={"En direct"}>En direct</MenuItem>
                <MenuItem value={"Sur chambre"}>Sur chambre</MenuItem>
                <MenuItem value={"Compte direction"}>Compte direction</MenuItem>
                <MenuItem value={"Gratuité"}>Gratuité</MenuItem>
                <MenuItem value={"Hôtel transfert"}>Hôtel transfert</MenuItem>
              </Select>
            </Grid>
             <Grid item xs={4} md={4}>
              <label className='FontLabel'  >Depuis</label >
              <Select variant="outlined" className='StyleInput' size='small' onChange={handleDepartureChange} defaultValue="" fullWidth>
                {
                  departures.map((departure:any, index:any) => (
                    <MenuItem key = {index} value={departure}>{departure}</MenuItem>
                  ))
                }
              </Select>
            </Grid>
            <Grid item xs={4} md={4} >
              <label className='FontLabel'  >A&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label >
              <Select variant="outlined" name='idTrip' className='StyleInput' size='small' onChange={handleArrivalChange} defaultValue="" fullWidth >
                  {
                    arrivals.map((arrival:any, index:any) => (
                      <MenuItem key = {index} value={arrival}>{arrival}</MenuItem>
                    ))
                  }
              </Select>
            </Grid>
            <Grid item xs={4} md={4}>
              <label className='FontLabel'  >Véhicule</label ><br/>        
              <Select variant="outlined" className='StyleInput' size='small' onChange={handleCarChange} defaultValue="" fullWidth>
                  {
                    cars.map((car:any, index:any) => (
                      <MenuItem key = {index} value={car}>{car}</MenuItem>
                    ))
                  }
              </Select>
            </Grid> 
            <Grid item xs={4} md={4}  >
              <label className='FontLabel'  >Compagnie</label ><br/>
              <Select variant="outlined" className='StyleInput' size='small'  multiple value={selectedTrip} onChange={handleProvidersChange} fullWidth>
                  {/* Get NightRate Price */}
                  {
                    providers.map((provider:any, index:any) => (
                      (
                        (
                        (formData.TransferTime<=provider.hEndNight && formData.TransferTime>=provider.hStartNight) && (formData.Paiement != "Gratuité") && (formData.Paiement != "Hôtel transfert")
                        )
                        ?
                        (price[index].NightPrice=provider.nightRate )
                        :
                        (price[index].NightPrice=0)
                      )
                  ))
                  }
                  {/* Get HighSeason Price */}
                  
                  {
                    providers.map((provider:any, index:any) => (
                      ((new Date(provider.startHighSeason).getFullYear())==(new Date(provider.endHighSeason).getFullYear()))
                      ?
                      /* Get HighSeason Price without YEAR if startDate and endDate are in the same year*/
                      (
                        (
                        (
                        (`${new Date(formData.TransferDate).getMonth()}-${new Date(formData.TransferDate).getDate()}`) 
                        >= (`${new Date(provider.startHighSeason).getMonth()}-${new Date (provider.startHighSeason).getDate()}`) 
                        && 
                        (`${new Date (formData.TransferDate).getMonth()}-${new Date(formData.TransferDate).getDate()}`)
                        <= (`${new Date(provider.endHighSeason).getMonth()}-${new Date(provider.endHighSeason).getDate()}`)
                        ) 
                        && 
                        (
                          (formData.Paiement != "Gratuité")  && (formData.Paiement != "Hôtel transfert")
                        )
                        )
                        ?
                        (price[index].HighSeasonPrice=provider.highSeasonCharge)
                        :
                        (price[index].HighSeasonPrice=0)
                      ) 
                      :
                      /* Get HighSeason Price with YEAR if startDate and endDate aren't in the same year*/
                      (
                        (
                        (
                        (formData.TransferDate >= provider.startHighSeason) 
                        && 
                        (formData.TransferDate <= provider.endHighSeason)
                        ) 
                        && 
                        (
                          (formData.Paiement != "Gratuité")  && (formData.Paiement != "Hôtel transfert")
                        )
                        )
                        ?
                        (price[index].HighSeasonPrice=provider.highSeasonCharge)
                        :
                        (price[index].HighSeasonPrice=0)
                        
                      )
                  ))
                  }

                  {/* Free price */}
                  {
                    providers.map((provider:any) => (
                      (
                        (
                        (formData.Paiement == "Gratuité") || (formData.Paiement == "Hôtel transfert")
                        )
                        ?
                        (provider.price=0)
                        :
                        (provider.price=provider.price)
                      ) 
                  ))
                  }

                  {/* Display Data */}
                  {
                    providers.map((provider:any, index:any) => (
                      <MenuItem key={index} value={provider.currentProviderId}>
                        {provider.name} | 
                        {provider.price}+{price[index].NightPrice}+{price[index].HighSeasonPrice} =
                        {price[index].TotalPrice=(Number(provider.price)+Number(price[index].NightPrice)+Number(price[index].HighSeasonPrice))}
                      </MenuItem>                 
                      ))
                  } 
                  
              </Select>
            </Grid>
            {(formData.Paiement == "Compte direction" )
            ?
            <Grid item xs={4} md={4}>
              <label className='FontLabel'  >Prix* </label ><br/>
              <TextField required size='small' name="Price" onChange={handleInputChange}  type="text" placeholder=""  className='StyleInput' />
            </Grid>
            :
            <Grid item xs={4} md={4}>
              
            </Grid>
            }
          {/* End IF 'Arrivée' or 'Départ' checked */}
          </>}

          {(selectedOption == 'Disposition')
          &&
          <>
          {/* IF 'Disposition' checked  */}
          <Grid item xs={4} md={4}>
            <label className='FontLabel' >Paiement</label ><br/>
            <Select  variant="outlined" name="Paiement" onChange={handleInputChange} className='StyleInput' size='small' defaultValue="" fullWidth >
              <MenuItem value={"En direct"}>En direct</MenuItem>
              <MenuItem value={"Sur chambre"}>Sur chambre</MenuItem>
              <MenuItem value={"Compte direction"}>Compte direction</MenuItem>
              <MenuItem value={"Gratuité"}>Gratuité</MenuItem>
              <MenuItem value={"Hôtel transfert"}>Hôtel transfert</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={4} md={4}>
            <label className='FontLabel'  >Depuis</label >
            <Select variant="outlined" className='StyleInput' size='small' onChange={handleDepartureDispoChange} defaultValue="" fullWidth>
              {
                departuresDispo.map((departure:any, index:any) => (
                  <MenuItem key = {index} value={departure}>{departure}</MenuItem>
                ))
              }
            </Select>
          </Grid>
          <Grid item xs={4} md={4} >
            <label className='FontLabel'  >A&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label >
            <Select variant="outlined" name='idTrip' className='StyleInput' size='small' onChange={handleArrivalDispoChange} defaultValue="" fullWidth >
                {
                  arrivalsDispo.map((arrival:any, index:any) => (
                    <MenuItem key = {index} value={arrival}>{arrival}</MenuItem>
                  ))
                }
            </Select>
          </Grid>
          <Grid item xs={3} md={3}>
            <label className='FontLabel'  >Véhicule</label ><br/>        
            <Select variant="outlined" className='StyleInput' size='small' onChange={handleCarDispoChange} defaultValue="" fullWidth>
                {
                  carsDispo.map((car:any, index:any) => (
                    <MenuItem key = {index} value={car}>{car}</MenuItem>
                  ))
                }
            </Select>
          </Grid>
          <Grid item xs={3} md={3}>
            <label className='FontLabel'  >Heure de disposition</label ><br/>        
            <Select variant="outlined" className='StyleInput' size='small' onChange={handleTimeDispoChange} defaultValue="" fullWidth>
                {
                  timesDispo.map((time:any, index:any) => (
                    <MenuItem key = {index} value={time}>{time}</MenuItem>
                  ))
                }
            </Select>
          </Grid>
          <Grid item xs={3} md={3}  >
            <label className='FontLabel'  >Compagnie</label ><br/>
            <Select variant="outlined" className='StyleInput' size='small'  multiple value={selectedDispo} onChange={handleProvidersDispoChange} fullWidth>
                 {/* Free price */}
                 {
                  providersDispo.map((provider:any) => (
                    (
                      (
                      (formData.Paiement == "Gratuité") || (formData.Paiement == "Hôtel transfert")
                      )
                      ?
                      (provider.price=0 )
                      :
                      (provider.price=provider.price)
                    ) 
                  ))
                  }
                  {
                  providersDispo.map((provider:any) => (
                    (
                      (
                      (formData.Paiement == "Gratuité") || (formData.Paiement == "Hôtel transfert")
                      )
                      ?
                      (provider.disposalCharge=0 )
                      :
                      (provider.disposalCharge=provider.disposalCharge)
                    ) 
                  ))
                  }
                  {
                    providersDispo.map((provider:any, index:any) => (
                      <MenuItem key={index} value={provider.currentProviderId}>
                        {provider.name} | 
                        {provider.price}+{provider.disposalCharge} =  
                        {price[index].TotalPrice=Number(provider.price)+Number(provider.disposalCharge)}
                      </MenuItem>
                    ))
                  }
            </Select>
          </Grid>
          {(formData.Paiement == "Compte direction" )
          ?
          <Grid item xs={3} md={3}>
            <label className='FontLabel'  >Prix* </label ><br/>
            <TextField required size='small' name="Price" onChange={handleInputChange}  type="text" placeholder=""  className='StyleInput' />
          </Grid>
          :
          <></>
          }
          {/* End IF 'Disposition' checked */}
          </> 
          }
          <Grid item xs={4} md={4}>
              <label className='FontLabel'  >Nombre de véhicule*</label >
              <TextField required size='small' name="NumberOfCars" onChange={handleInputChange} type="number" placeholder=""  className='StyleInput' />
          </Grid>
          <Grid item xs={4} md={4}>
              <label className='FontLabel'  >Heure arrivée à l'hôtel</label >
              <TextField  size='small' name="ArrivalTime" onChange={handleInputChange} type="time" placeholder=""  className='StyleInput' fullWidth/>
          </Grid>
          <Grid item xs={4} md={4}>
              <label className='FontLabel'  >Heure de fin</label >
              <TextField  size='small' name="EndTime" onChange={handleInputChange}  type="time" placeholder=""  className='StyleInput' fullWidth/>
          </Grid>
          <Grid item xs={6} md={6}>
              <label className='FontLabel'  >N° du vol - train / bateau / bus</label >
              <TextField  size='small' name="TransportDetail" onChange={handleInputChange}  type="text" placeholder=""  className='StyleInput' fullWidth/>
          </Grid>
          <Grid item xs={6} md={6}>
            <label className='FontLabel' >Statut*</label ><br/>
            <Select required variant="outlined" name="Statut" onChange={handleInputChange} className='StyleInput' size='small' defaultValue="" fullWidth>
              <MenuItem value={"Confirmée"}>Confirmée</MenuItem>
              <MenuItem value={"Annulée"}>Annulée</MenuItem>
              <MenuItem value={"En attente de confirmation"}>En attente de confirmation</MenuItem>
              <MenuItem value={"Attente de carte bancaire"}>Attente de carte bancaire</MenuItem>
              <MenuItem value={"A faire"}>A faire</MenuItem>
            </Select>
          </Grid>
      </Grid>
      </AccordionDetails>
    </Accordion>

    <br/>

    {/*Blue Card: Préference Transfert  */}
    <Accordion  sx={{ backgroundColor: '#CD11110D'}}>
      <AccordionSummary  expandIcon={<ExpandMoreIcon />}>  
        <Typography sx={{ width: '40%', flexShrink:0,fontWeight:'bold'}}>Préference Transfert</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ maxWidth: 650, padding: "20px 5px", margin: "0 auto" }}>
      <Grid container spacing={1}> 
        <Grid item xs={12} md={12}> 
          <TextField  size='small' name="TransfertNote" onChange={handleInputChange} type="text" placeholder=""  className='StyleInput' fullWidth/>
        </Grid>
        <Grid item xs={6} md={6}>  
          {/* <label className='FontLabel'  >Nbr. de bagages</label > */}
          <label className='FontLabel'>Nbr. de bagage</label>
          <TextField  size='small' name="NumberOfLuggage"  onChange={handleInputChange} type="number" placeholder=""  className='StyleInput'/> 
        </Grid>
        <Grid item xs={6} md={6}>
          <label className='FontLabel'  >Nbr. de passagers</label>
          <TextField  size='small' name="NumberOfPassenger"  onChange={handleInputChange}  type="number" placeholder=""  className='StyleInput' fullWidth />
        </Grid>
      </Grid>
      </AccordionDetails>
    </Accordion>

    <br/>

    {/*Blue Card: Information supplémentaire  */}
    <Accordion  sx={{ backgroundColor: '#A0E29533'}}>
      <AccordionSummary  expandIcon={<ExpandMoreIcon />}>  
        <Typography sx={{ width: '50%', flexShrink:0,fontWeight:'bold'}}>Information supplémentaire</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ maxWidth: 650, padding: "20px 5px", margin: "0 auto" }}>
      <TextField size='small' name="SuppInformation" onChange={handleInputChange} type="text" placeholder=""  className='StyleInput' fullWidth />
      </AccordionDetails>
    </Accordion>

    <br/>

    <Card>
      <CardContent>
        <Grid item xs={12} md={12} >
          <InputLabel>Dupliquer la réservation pour une autre date</InputLabel>             
        </Grid>
        <Grid item xs={12} md={12} style={{display:'flex'}} >
          <TextField size='small' name="DuplicateDate" onChange={handleInputChange}  type="date" className='StyleInput' />
        </Grid>
      </CardContent>
    </Card>

    </CardContent>

  <SharingButton type="submit" variant="contained" className='btn'>Ajouter</SharingButton>
  </form>
  {/* {isModalOpen && <Email formData={formData} previousData={previousData} email={email} isOpen={isOpen} onClose={handleCloseModal} />} */}
 {/* <Email formData={formData} previousData={previousData} email={email} 
 isOpen={isModalOpen} onCloseModal={handleCloseModal} /> */}


</Card>

</>
);
}
export default AddTransfer
