import './SignIn.css';

import { useState } from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';
import preval from 'preval.macro';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserName, setUserLoggedIn } from '../../redux/userSlice';

function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const apiUrl = window.location.hostname === 'localhost' ? 'https://localhost:7142/api' : '/api';

  const handleSubmit = (event: any) => {
    event.preventDefault();
    
    if (!loading && username.length && password.length) {
      login();
    } else {
      toast.error('Formulaire incomplet');
    }
  };

  const login = () => {
    setLoading(true);
    axios.post(apiUrl + '/login', { username, password }).then(loginSuccess).catch(loginFailed).finally(() => setLoading(false));
  }

  const loginSuccess = (res: any) => {
    setUsername('');
    setPassword('');
    localStorage.setItem('username', username);
    localStorage.setItem('jwt', res.data);
    dispatch(setUserName(username))
    dispatch(setUserLoggedIn(true));
    navigate('/welcome');
    toast.success('Connexion réussie', { duration: 4000 });
  }

  const loginFailed = () => {
    toast.error('Erreur de connexion');
  }

  const loginButton = () => loading ? 'Chargement...'  : 'Connexion';

  return (
    <div className='SignIn'>
      <div className='SignInLogo'>SHARING</div>
      <form className='SignInForm' onSubmit={handleSubmit}>
        <div className='SignInFormVersion'>
          <div>Version 1.0.0</div>
          <div>{preval`module.exports = new Date().toLocaleString();`}</div>
        </div>
        <input className='SignInFormTextInput mt25' placeholder='Identifiant' spellCheck={false} value={username} onChange={(e) => setUsername(e.target.value)}></input>
        <input className='SignInFormPasswordInput mt25' placeholder='Mot de passe' type='password' spellCheck={false} value={password} onChange={(e) => setPassword(e.target.value)}></input>
        <button className='SignInFormButton'>{loginButton()}</button>
      </form>
    </div>
  );
}

export default SignIn;
