import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RestoreIcon from '@mui/icons-material/Restore';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import './RestauDetails.css';
import './addRestau.css';
import React, { useEffect, useState } from 'react';
import RappelsClient from '../modal/rappelsClient';
import PolitiqueAnnulation from '../modal/politiqueAnnulation';
import AddDate from '../modal/addDate';
import UpdateDate from '../modal/updateDate';
import AddTime from '../modal/addTime';
import TablesList from '../modal/tablesList';
import AddTable from '../modal/addTable';
import AddMenu from '../modal/addMenu';
import UpdateMenu from '../modal/updateMenu';
import Historique from '../historique/historique';
import UpdateTime from '../modal/updateTime';
import {
    Cuisine, Menu, Periode,
    Restaurant, Salle, Service,
    UpdateRestaurantRequest,
    PolitiqueAnnulation as PolitiqueAnnulationType,
    RappelsClient as RappelsClientType,

} from '../types/types';
import { restaurantService } from '../api/restaurantService';
import { periodeService } from '../api/periodeService';
import { serviceRestauService } from '../api/serviceRestauService';
import { menuService } from '../api/menuService';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useParams } from 'react-router-dom';

const Alert = React.forwardRef < HTMLDivElement, AlertProps> (function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function RestauDetails() {
    const [restaurantData, setRestaurantData] = useState < Restaurant | null > (null);
    const [services, setServices] = useState < Service[] > ([]);

    const { id: restauId } = useParams();
    const restaurantId = parseInt(restauId || '', 10);

    useEffect(() => {
        const fetchRestaurantData = async () => {
            try {
                const data = await restaurantService.getRestaurant(restaurantId);
                setRestaurantData(data);
                setShowAnnuelDatePicker(data?.type ?? true);

                const servicesData = await serviceRestauService.getServicesByRestaurant(restaurantId);
                setServices(servicesData);
            } catch (error) {
                console.error('Error fetching restaurant data:', error);
            }
        };

        fetchRestaurantData();
    }, [restaurantId]);

    const [showRappelsClientModal, setShowRappelsClientModal] = useState(false);
    const openRappelsClientModal = () => { setShowRappelsClientModal(true); };
    const closeRappelsClientModal = () => { setShowRappelsClientModal(false); };

    const [showPolitiqueAnnulationModal, setPolitiqueAnnulationModal] = useState(false);
    const openPolitiqueAnnulationModal = () => { setPolitiqueAnnulationModal(true); };
    const closePolitiqueAnnulationModal = () => { setPolitiqueAnnulationModal(false); };

    const handleTypeUpdate = (type: boolean) => {
        if (restaurantData) {
            const updatedRestaurant: Restaurant = {
                ...restaurantData,
                type: type
            };
            setRestaurantData(updatedRestaurant);
        }
    };

    const handleUpdatePeriode = (updatedPeriode: Periode) => {
        if (restaurantData) {
            const updatedPeriodes = restaurantData.periodes.map(periode =>
                periode.id === updatedPeriode.id ? updatedPeriode : periode
            );

            const updatedRestaurantData = {
                ...restaurantData,
                periodes: updatedPeriodes
            };

            setRestaurantData(updatedRestaurantData);
        }
    };

    const handleUpdateMenu = (updatedMenu: Menu) => {
        if (restaurantData) {
            const updatedMenus = restaurantData.menus.map(menu =>
                menu.id === updatedMenu.id ? updatedMenu : menu
            );

            const updatedRestaurantData = {
                ...restaurantData,
                menus: updatedMenus
            };

            setRestaurantData(updatedRestaurantData);
        }
    };

    const handleDeleteMenu = async (menuId: number) => {
        try {
            await menuService.deleteMenu(menuId);
            if (restaurantData && restaurantData.menus) {
                const updatedMenus = restaurantData.menus.filter(menu => menu.id !== menuId);
                setRestaurantData({
                    ...restaurantData,
                    menus: updatedMenus
                });
            }
        } catch (error) {
            console.error("Error deleting menu:", error);
        }
    };

    const [showAnnuelDatePicker, setShowAnnuelDatePicker] = useState(restaurantData?.type ?? true);
    const handleAnnuelClick = () => {
        setShowAnnuelDatePicker(false);
        handleTypeUpdate(false);
    };
    const handleSaisonnierClick = () => {
        setShowAnnuelDatePicker(true);
        handleTypeUpdate(true);
    };

    const handleNewPeriodeAdded = (newPeriode: Periode) => {
        if (restaurantData) {
            const updatedPeriodes = [...restaurantData.periodes, newPeriode];

            setRestaurantData({
                ...restaurantData,
                periodes: updatedPeriodes
            });
        }
    };

    const handleDeletePeriode = async (periodeId: number) => {
        try {
            await periodeService.deletePeriode(periodeId);
            if (restaurantData && restaurantData.periodes) {
                const updatedPeriodes = restaurantData.periodes.filter(periode => periode.id !== periodeId);
                setRestaurantData({
                    ...restaurantData,
                    periodes: updatedPeriodes
                });
            }
        } catch (error) {
            console.error("Error deleting periode:", error);
        }
    };

    const fetchServicesData = async () => {
        try {
            const servicesData = await serviceRestauService.getServicesByRestaurant(restaurantId);
            setServices(servicesData);
        } catch (error) {
            console.error('Error fetching services data:', error);
        }
    };

    const handleUpdateService = async (updatedService: Service) => {
        if (services) {
            await fetchServicesData();
        }
    };

    const handleNewServiceAdded = async (newService: Service) => {
        if (services) {
            await fetchServicesData();
        }
    };

    const handleDeleteService = async (serviceId: number) => {
        try {
            await serviceRestauService.deleteService(serviceId);
            if (services) {
                await fetchServicesData();
            }
        } catch (error) {
            console.error("Error deleting service:", error);
        }
    };

    const handleNewMenuAdded = (newMenu: Menu) => {
        if (restaurantData) {
            const updatedMenus = [...restaurantData.menus, newMenu];

            setRestaurantData({
                ...restaurantData,
                menus: updatedMenus
            });
        }
    };

    const [showAddDateModal, setAddDateModal] = useState(false);
    const openAddDateModal = () => { setAddDateModal(true); };
    const closeAddDateModal = () => { setAddDateModal(false); };

    const [selectedDate, setSelectedDate] = useState < Periode | null > (null);
    const [showUpdateDateModal, setUpdateDateModal] = useState(false);
    const openUpdateDateModal = (periode: Periode) => {
        setSelectedDate(periode);
        setUpdateDateModal(true);
    };
    const closeUpdateDateModal = () => {
        setUpdateDateModal(false);
        setSelectedDate(null);
    };

    const [showAddTimeModal, setAddTimeModal] = useState(false);
    const openAddTimeModal = () => { setAddTimeModal(true); };
    const closeAddTimeModal = () => { setAddTimeModal(false); };

    const [showUpdateTimeModal, setUpdateTimeModal] = useState(false);
    const [selectedService, setSelectedService] = useState < Service | null > (null);
    const openUpdateTimeModal = (service: Service) => {
        setUpdateTimeModal(true);
        setSelectedService(service);
    };
    const closeUpdateTimeModal = () => {
        setUpdateTimeModal(false);
        setSelectedService(null);
    };

    const [showTablesListModal, setTablesListModal] = useState(false);
    const openTablesListModal = () => { setTablesListModal(true); };
    const closeTablesListModal = () => { setTablesListModal(false); };

    const [showAddTableModal, setAddTableModal] = useState(false);
    const openAddTableModal = () => { setAddTableModal(true); };
    const closeAddTableModal = () => { setAddTableModal(false); };

    const [showAddMenuModal, setAddMenuModal] = useState(false);
    const openAddMenuModal = () => { setAddMenuModal(true); };
    const closeAddMenuModal = () => { setAddMenuModal(false); };


    const [showUpdateMenuModal, setUpdateMenuModal] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState < Menu | null > (null);

    const openUpdateMenuModal = (menu: Menu) => {
        setUpdateMenuModal(true);
        setSelectedMenu(menu);
    };
    const closeUpdateMenuModal = () => {
        setUpdateMenuModal(false);
        setSelectedMenu(null);
    };

    const [showHistoriqueModal, setHistoriqueModal] = useState(false);
    const openHistoriqueModal = () => { setHistoriqueModal(true); };
    const closeHistoriqueModal = () => { setHistoriqueModal(false); };

    const handleSalleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, field: keyof Salle) => {
        const updatedValue = event.target.checked;
        if (restaurantData) {
            setRestaurantData({
                ...restaurantData,
                salle: { ...restaurantData.salle, [field]: updatedValue }
            });
        }
    };

    const handleSalleColorChange = (colorValue: string) => {
        if (restaurantData) {
            setRestaurantData({
                ...restaurantData,
                salle: { ...restaurantData.salle, couleur: colorValue }
            });
        }
    };

    const handleCuisineSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, field: keyof Cuisine) => {
        const updatedValue = event.target.checked;
        if (restaurantData) {
            setRestaurantData({
                ...restaurantData,
                cuisine: { ...restaurantData.cuisine, [field]: updatedValue }
            });
        }
    };

    const handleCuisineColorChange = (colorValue: string) => {
        if (restaurantData) {
            setRestaurantData({
                ...restaurantData,
                cuisine: { ...restaurantData.cuisine, couleur: colorValue }
            });
        }
    };

    const colorOptions = [
        { value: "rgba(164, 226, 31, 0.63)", name: "Light Green" },
        { value: "#70DBEB", name: "Light Blue" },
        { value: "#5E5EAF", name: "Light Purple" },
        { value: "#8E6FAD", name: "Purple" },
        { value: "#D67AB1", name: "Pinkish" },
        { value: "#E78482", name: "Salmon" },
        { value: "#E05D5D", name: "Light Red" },
        { value: "#C34A36", name: "Dark Red" },
    ];

    const handleRappelsClientUpdate = (updatedRappelsClient: RappelsClientType) => {
        setRestaurantData(prevState => {
            if (!prevState) return null;
            return {
                ...prevState,
                rappelsClient: updatedRappelsClient
            };
        });
    };

    const handlePolitiqueAnnulationUpdate = (updatedPolitiqueAnnulation: PolitiqueAnnulationType) => {
        setRestaurantData(prevState => {
            if (!prevState) return null;
            return {
                ...prevState,
                politiqueAnnulation: updatedPolitiqueAnnulation
            };
        });
    };

    const handleInputChange = (field: keyof Restaurant, value: string) => {
        setRestaurantData(prev => {
            if (!prev) return null;
            return {
                ...prev,
                [field]: value,
            };
        });
    };

    const handleNestedInputChange = <T extends keyof Restaurant>(
        nestedField: T,
        field: keyof Restaurant[T],
        value: string
    ) => {
        setRestaurantData(prev => {
            if (!prev) return null;

            const nestedObject = prev[nestedField] || {};
            if (typeof nestedObject !== 'object' || nestedObject === null) {
                console.error(`Expected an object at '${nestedField}', but got:`, nestedObject);
                return prev;
            }

            return {
                ...prev,
                [nestedField]: {
                    ...nestedObject,
                    [field]: value,
                },
            };
        });
    };

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
        setOpenErrorSnackbar(false);
    };

    const handleSubmit = async () => {
        if (restaurantData) {
            try {
                const updateRequest: UpdateRestaurantRequest = {
                    nom: restaurantData.nom,
                    email: restaurantData.email,
                    localisation: restaurantData.localisation,
                    webSite: restaurantData.webSite,
                    type: restaurantData.type,
                    hotelId: restaurantData.hotelId,
                    cuisine: restaurantData.cuisine,
                    salle: restaurantData.salle,
                    politiqueAnnulation: restaurantData.politiqueAnnulation,
                    rappelsClient: restaurantData.rappelsClient
                };

                await restaurantService.putRestaurant(restaurantId, updateRequest);

                setRestaurantData(prev => {
                    if (!prev) return null; // If there's no previous data, return null

                    return {
                        ...prev,
                        nom: updateRequest.nom,
                        email: updateRequest.email,
                        localisation: updateRequest.localisation,
                        webSite: updateRequest.webSite,
                        type: updateRequest.type,
                        hotelId: updateRequest.hotelId,
                        cuisine: updateRequest.cuisine,
                        salle: updateRequest.salle
                    };
                });

                setOpenSnackbar(true);
            } catch (error) {
                setOpenErrorSnackbar(true);
                console.error("Error updating restaurant:", error);
            }
        }
    };

    return (
        <div className='restau-section'>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackbar} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Restaurant mis à jour avec succès !
                </Alert>
            </Snackbar>

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openErrorSnackbar} autoHideDuration={6000} onClose={() => setOpenErrorSnackbar(false)}>
                <Alert onClose={() => setOpenErrorSnackbar(false)} severity="error" sx={{ width: '100%' }}>
                    Échec de la mise à jour du restaurant
                </Alert>
            </Snackbar>
            <form onSubmit={handleSubmit}>
                <div className='restau-form'>
                    <div className='restau-form-header'>
                        <h1>Restaurant {restaurantData?.nom}</h1>
                        <h1
                            style={{ color: "black", fontWeight: "500", textDecoration: "underline", cursor: "pointer" }}
                            onClick={openHistoriqueModal}
                        >
                            Historique <RestoreIcon style={{ fontSize: "25px", marginLeft: "5px", marginBottom: "-5px" }} />
                        </h1>
                        {
                            showHistoriqueModal && (
                                <Historique onClose={closeHistoriqueModal} restaurantId={restaurantId} />
                            )
                        }
                    </div>

                    <div className='restau-main-container'>

                        <div className="restau-column">
                            <div style={{ marginTop: "20px", width: "100%" }}>
                                <label htmlFor="email-restau" className='label-restau'>Email du restaurant</label>
                                <input type="email" id='email-restau' className='text-restau' required
                                    value={restaurantData?.email}
                                    onChange={(e) => handleInputChange('email', e.target.value)}
                                />
                            </div>

                            <div id='btnDiv' style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <button
                                    type='button'
                                    onClick={handleAnnuelClick}
                                    className={showAnnuelDatePicker ? '' : 'restau-btn-clicked'}
                                >
                                    Annuel
                                </button>

                                <button
                                    type='button'
                                    onClick={handleSaisonnierClick}
                                    className={showAnnuelDatePicker ? 'restau-btn-clicked' : ''}
                                >
                                    Saisonnier
                                </button>
                            </div>

                            <div style={{ marginTop: "20px", width: "100%" }}>
                                <label htmlFor="date-debut">Date d'ouverture</label>
                                <div className='restau-periode-container'>
                                    {
                                        restaurantData?.periodes.map((periode, index) => (
                                            <div key={index} className='restau-service-time'>
                                                <h4> Date {periode.dateDebutString} {showAnnuelDatePicker ? ' - ' + periode.dateFinString : ''}</h4>
                                                <div>
                                                    <EditNoteIcon
                                                        style={{ fontSize: "25px", marginRight: "3px", cursor: "pointer", color: "#134563" }}
                                                        onClick={() => openUpdateDateModal(periode)}
                                                    />
                                                    <DeleteForeverIcon
                                                        style={{ fontSize: "25px", cursor: "pointer", color: "#134563" }}
                                                        onClick={() => handleDeletePeriode(periode.id)}
                                                    />

                                                    {
                                                        showUpdateDateModal && (
                                                            <UpdateDate
                                                                onClose={closeUpdateDateModal}
                                                                initialPeriode={selectedDate}
                                                                onUpdate={handleUpdatePeriode}
                                                                type={restaurantData?.type}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>

                                <div className='restau-add-date'>
                                    <h4 onClick={openAddDateModal}>
                                        <AddCircleOutlineIcon style={{ marginBottom: "-5px" }} />
                                        Ajouter une nouvelle période
                                    </h4>
                                    {
                                        showAddDateModal && (
                                            <AddDate
                                                onClose={closeAddDateModal}
                                                type={showAnnuelDatePicker}
                                                restaurantId={restaurantData?.id}
                                                onNewPeriodeAdded={handleNewPeriodeAdded}
                                            />
                                        )
                                    }
                                </div>
                            </div>

                            <div style={{ marginTop: "20px", width: "100%" }}>
                                <label className='label-restau'>Horaires de service</label>
                                <div className='restau-service-container'>
                                    {
                                        services ?
                                            services.map((service) => (
                                                <div key={`${service.type}-${service.index}`} className='restau-service-time'>
                                                    <h4>
                                                        {service.type}
                                                        {service.index !== -1 && ` - ${service.index === 1 ? '1er' : `${service.index}ème`} Service`}
                                                        {' -'} {service.heureDebutString}/{service.heureFinString}
                                                    </h4>
                                                    <div>
                                                        <EditNoteIcon
                                                            style={{ fontSize: "25px", marginRight: "3px", cursor: "pointer", color: "#134563" }}
                                                            onClick={() => openUpdateTimeModal(service)}
                                                        />
                                                        <DeleteForeverIcon
                                                            style={{ fontSize: "25px", cursor: "pointer", color: "#134563" }}
                                                            onClick={() => handleDeleteService(service.id)}
                                                        />

                                                        {
                                                            showUpdateTimeModal && (
                                                                <UpdateTime
                                                                    onClose={closeUpdateTimeModal}
                                                                    initialService={selectedService}
                                                                    onUpdate={handleUpdateService}
                                                                />
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )) :
                                            <p>No services available</p>
                                    }
                                </div>
                                <div className='restau-add-date'>
                                    <h4 onClick={openAddTimeModal}>
                                        <AddCircleOutlineIcon style={{ marginBottom: "-5px" }} />
                                        Ajouter un nouvel horaire
                                    </h4>
                                    {
                                        showAddTimeModal && (
                                            <AddTime
                                                onClose={closeAddTimeModal}
                                                restaurantId={restaurantData?.id}
                                                onNewServiceAdded={handleNewServiceAdded}
                                            />
                                        )
                                    }
                                </div>

                            </div>

                        </div>

                        <div className="restau-column">

                            {/* Table Section */}

                            <div style={{ marginTop: "20px", width: "100%" }}>
                                <label htmlFor="date-debut">Nombre de table</label>
                                <div className='restau-service-time restau-table-container'>
                                    <h4 style={{ cursor: "pointer" }} onClick={openTablesListModal}>
                                        Afficher toutes les tables
                                    </h4>
                                    {
                                        showTablesListModal && (
                                            <TablesList
                                                onClose={closeTablesListModal}
                                                restaurantId={restaurantData?.id}
                                            />
                                        )
                                    }
                                </div>

                                <div className='restau-add-date'>
                                    <h4 onClick={openAddTableModal}>
                                        <AddCircleOutlineIcon style={{ marginBottom: "-5px" }} />
                                        Ajouter une nouvelle table
                                    </h4>
                                    {
                                        showAddTableModal && (
                                            <AddTable
                                                onClose={closeAddTableModal}
                                                restaurantId={restaurantData?.id}
                                            />
                                        )
                                    }
                                </div>
                            </div>

                            {/* Menu Section */}

                            <div style={{ marginTop: "10px", width: "100%" }}>
                                <label htmlFor="date-debut">Menu</label>
                                <div className="restau-menu-container">
                                    {
                                        restaurantData?.menus.map((menu, index) => (
                                            <div key={index} className='restau-service-time'>
                                                <h4 style={{ cursor: "pointer" }}>
                                                    {menu.nom} - {menu.prix}€
                                                </h4>
                                                <div>
                                                    <EditNoteIcon
                                                        style={{ fontSize: "25px", marginRight: "3px", cursor: "pointer", color: "#134563" }}
                                                        onClick={() => openUpdateMenuModal(menu)}
                                                    />
                                                    <DeleteForeverIcon
                                                        style={{ fontSize: "25px", cursor: "pointer", color: "#134563" }}
                                                        onClick={() => handleDeleteMenu(menu.id)}
                                                    />

                                                    {
                                                        showUpdateMenuModal && (
                                                            <UpdateMenu
                                                                onClose={closeUpdateMenuModal}
                                                                initialMenu={selectedMenu}
                                                                onUpdate={handleUpdateMenu}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className='restau-add-date'>
                                    <h4 onClick={openAddMenuModal}>
                                        <AddCircleOutlineIcon style={{ marginBottom: "-5px" }} />
                                        Ajouter un nouveau menu
                                    </h4>
                                    {
                                        showAddMenuModal && (
                                            <AddMenu
                                                onClose={closeAddMenuModal}
                                                restaurantId={restaurantData?.id}
                                                onNewMenuAdded={handleNewMenuAdded}
                                            />
                                        )
                                    }
                                </div>
                            </div>

                            {/* Rappels Clients & Politiques d'annulation Section */}

                            <div style={{ marginTop: "0px", width: "100%" }}>
                                <button
                                    type='button'
                                    className='restau-btn'
                                    onClick={openRappelsClientModal}
                                >
                                    Rappels clients
                                </button>

                                {showRappelsClientModal && (
                                    <RappelsClient
                                        onClose={closeRappelsClientModal}
                                        initialData={restaurantData?.rappelsClient}
                                        onUpdate={handleRappelsClientUpdate}
                                    />
                                )}

                                <button
                                    type='button'
                                    className='restau-btn'
                                    style={{ marginTop: "10px" }}
                                    onClick={openPolitiqueAnnulationModal}
                                >
                                    Politiques d'annulation
                                </button>
                                {showPolitiqueAnnulationModal && (
                                    <PolitiqueAnnulation
                                        onClose={closePolitiqueAnnulationModal}
                                        initialData={restaurantData?.politiqueAnnulation}
                                        onUpdate={handlePolitiqueAnnulationUpdate}
                                    />
                                )}
                            </div>

                            {/* Restau Data [ Web Site & Location ] */}
                            <div style={{ marginTop: "10px", width: "100%" }}>
                                <div>
                                    <label htmlFor="site-restau" className='label-restau'>Site Internet du restaurant</label>
                                    <input
                                        type="text"
                                        id='site-restau'
                                        className='text-restau'
                                        value={restaurantData?.webSite}
                                        onChange={(e) => handleInputChange('webSite', e.target.value)}
                                    />
                                </div>

                                <div style={{ marginTop: "5px", width: "100%" }}>
                                    <label htmlFor="addresse-restau" className='label-restau'>Addresse du restaurant</label>
                                    <input
                                        type="text"
                                        id='addresse-restau'
                                        className='text-restau'
                                        value={restaurantData?.localisation}
                                        onChange={(e) => handleInputChange('localisation', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="restau-column">
                            <div className='restau-column3' style={{ marginTop: "20px" }}>
                                <div className="row">
                                    <label className='label-restau column'></label>
                                    <label className='label-restau column' style={{ textAlign: "center" }}>Salle</label>
                                    <label className='label-restau column' style={{ textAlign: "center" }}>Cuisine</label>
                                </div>
                                <div className="row">
                                    <label className='label-restau column'>Création espace</label>
                                    <div className='switch-column'>
                                        {/* for Salle */}
                                        <IOSSwitch
                                            sx={{ m: 1 }}
                                            checked={restaurantData?.salle.espace}
                                            onChange={(e) => handleSalleSwitchChange(e, 'espace')}
                                        />
                                    </div>
                                    <div className='switch-column'>
                                        {/* for Cuisine */}
                                        <IOSSwitch
                                            sx={{ m: 1 }}
                                            checked={restaurantData?.cuisine.espace}
                                            onChange={(e) => handleCuisineSwitchChange(e, 'espace')}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <label className='label-restau column'>Création planning</label>
                                    <div className='switch-column'>
                                        {/* for Salle */}
                                        <IOSSwitch
                                            sx={{ m: 1 }}
                                            checked={restaurantData?.salle.planning}
                                            onChange={(e) => handleSalleSwitchChange(e, 'planning')}
                                        />
                                    </div>
                                    <div className='switch-column'>
                                        {/* for Cuisine */}
                                        <IOSSwitch
                                            sx={{ m: 1 }}
                                            checked={restaurantData?.cuisine.planning}
                                            onChange={(e) => handleCuisineSwitchChange(e, 'planning')}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <label className='label-restau column'>Couleur</label>
                                    <div style={{ width: "30%", display: "flex", justifyContent: "center" }}>
                                        <div className="color-select-container">
                                            <div className="color-display" style={{ backgroundColor: restaurantData?.salle.couleur }}></div>
                                            <select
                                                className="color-select"
                                                value={restaurantData?.salle.couleur}
                                                onChange={(e) => handleSalleColorChange(e.target.value)}
                                            >
                                                {colorOptions.map((option) => (
                                                    <option className='restau-color-option' key={option.value} value={option.value} style={{ backgroundColor: option.value }}>

                                                    </option>
                                                ))}
                                            </select>
                                            <div className="color-select-arrow">{'>'}</div>
                                        </div>
                                    </div>

                                    <div style={{ width: "30%", display: "flex", justifyContent: "center" }}>
                                        <div className="color-select-container">
                                            <div className="color-display" style={{ backgroundColor: restaurantData?.cuisine.couleur }}></div>
                                            <select
                                                className="color-select"
                                                value={restaurantData?.cuisine.couleur}
                                                onChange={(e) => handleCuisineColorChange(e.target.value)}
                                            >
                                                {colorOptions.map((option) => (
                                                    <option className='restau-color-option' key={option.value} value={option.value} style={{ backgroundColor: option.value }}>

                                                    </option>
                                                ))}
                                            </select>
                                            <div className="color-select-arrow">{'>'}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginTop: "20px", width: "100%" }}>
                                <label htmlFor="posts-restau" className='label-restau'>Poste du chef de service salle</label>
                                <input
                                    type="text"
                                    id='posts-restau'
                                    className='text-restau'
                                    placeholder='Nom'
                                    value={restaurantData?.salle.posteService}
                                    onChange={(e) => handleNestedInputChange('salle', 'posteService', e.target.value)}
                                />
                            </div>

                            <div style={{ marginTop: "20px", width: "100%" }}>
                                <label htmlFor="chefs-restau" className='label-restau'>Chef de service</label>
                                <div className='restau-column2'>
                                    <input
                                        type="text"
                                        id='chefs-restau'
                                        className='text-restau'
                                        placeholder='Nom'
                                        style={{
                                            marginRight: '20px'
                                        }}
                                        value={restaurantData?.salle.nomChefService}
                                        onChange={(e) => handleNestedInputChange('salle', 'nomChefService', e.target.value)}
                                    />
                                    <input
                                        type="text"
                                        className='text-restau'
                                        placeholder='Prenom'
                                        value={restaurantData?.salle.prenomChefService}
                                        onChange={(e) => handleNestedInputChange('salle', 'prenomChefService', e.target.value)}
                                    />
                                </div>
                            </div>

                            <div style={{ marginTop: "20px", width: "100%" }}>
                                <label htmlFor="postc-restau" className='label-restau'>Poste du chef de service cuisine</label>
                                <input
                                    type="text"
                                    id='postc-restau'
                                    className='text-restau'
                                    placeholder='Nom'
                                    value={restaurantData?.cuisine.posteService}
                                    onChange={(e) => handleNestedInputChange('cuisine', 'posteService', e.target.value)}
                                />
                            </div>

                            <div style={{ marginTop: "20px", width: "100%" }}>
                                <label htmlFor="chefc-restau" className='label-restau'>Chef de cuisine</label>
                                <div className='restau-column2'>
                                    <input
                                        type="text"
                                        id='chefc-restau'
                                        className='text-restau'
                                        placeholder='Nom'
                                        style={{
                                            marginRight: '20px'
                                        }}
                                        value={restaurantData?.cuisine.nomChefCuisine}
                                        onChange={(e) => handleNestedInputChange('cuisine', 'nomChefCuisine', e.target.value)}
                                    />
                                    <input
                                        type="text"
                                        className='text-restau'
                                        placeholder='Prenom'
                                        value={restaurantData?.cuisine.prenomChefCuisine}
                                        onChange={(e) => handleNestedInputChange('cuisine', 'prenomChefCuisine', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='restau-submit-div'>
                    <button type='submit' className='restau-btn restau-btn-submit'>
                        Validé
                    </button>
                </div>
            </form>
        </div>
    );
}

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#1DC9A0',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#1DC9A0',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export default RestauDetails