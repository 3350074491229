import './RemoveReminderForm.css';

import { DialogActions, DialogContent, DialogTitle } from '@mui/material';

import Mission from '../../../../models/Mission';
import GnzTimePicker from '../../../shared/gnz-time-picker/GnzTimePicker';
import GnzButton from '../../../shared/gnz-button/GnzButton';

interface IRemoveReminderForm {
  mission: Mission,
  confirmSubmit: any,
  cancelSubmit: any,
}

function RemoveReminderForm(props: IRemoveReminderForm) {
  const confirmSubmit = () => {
    props.confirmSubmit(props.mission);
  }

  return (
    <div className="AddReminderForm">
      <DialogTitle id="responsive-dialog-title">
        Retirer un reminder
      </DialogTitle>
      <DialogContent>
        <div className="AddReminderFormLabel">{props.mission.reminder.scedule ? 'Êtes-vous sur de vouloir retirer le reminder ?' : 'Aucun reminder enregistré'}</div>
        {props.mission.reminder.scedule ? (
          <div>
            <GnzTimePicker className="SecondaryDatePicker" currentDate={props.mission.reminder.scedule} disabled />
            <div className="AddReminderCheckboxs">
              <div className="AddReminderCheckbox">
                <input type="checkbox" id="forMe" checked={props.mission.reminder.forMe} disabled />
                <label htmlFor="forMe">Pour moi même</label>
              </div>
              <div className="AddReminderCheckbox">
                <input type="checkbox" id="forAllService" checked={props.mission.reminder.forAllService} disabled />
                <label htmlFor="forAllService">Pour service entier</label>
              </div>
            </div>
          </div>
        ) : null}
      </DialogContent>
      <DialogActions>
        {props.mission.reminder.scedule ? (<GnzButton label="Confirmer" onClick={confirmSubmit} backgroundColor="#dc3545" />) : null}
        <GnzButton label="Annuler" onClick={props.cancelSubmit} />
      </DialogActions>
    </div>
  );
}

export default RemoveReminderForm;
