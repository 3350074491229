import { Service } from "../types/types";
import axiosInstance from "./axiosInstance";

const getServices = async (): Promise<Service[]> => {
    const response = await axiosInstance.get<Service[]>('/Service');
    return response.data;
};

const getServicesByRestaurant = async (id: number): Promise<Service[]> => {
    const response = await axiosInstance.get<Service[]>(`/Service/restaurant/${id}`);
    return response.data;
};

const postService = async (service: Service): Promise<Service> => {
    const response = await axiosInstance.post<Service>('/Service', service);
    return response.data;
};

const putService = async (id: number, service: Service): Promise<Service> => {
    const response = await axiosInstance.put<Service>(`/Service/${id}`, service);
    return response.data;
};

const deleteService = async (id: number): Promise<Service> => {
    const response = await axiosInstance.delete<Service>(`/Service/${id}`);
    return response.data;
};

export const serviceRestauService = {
    getServices,
    getServicesByRestaurant,
    postService,
    putService,
    deleteService
};