import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { FormControl, InputLabel, MenuItem, Select, CircularProgress } from '@mui/material';
import { useState } from 'react';

const HistoriqueMobile = ({ filter, handleFilterChange, handleChangeEntity, handleChangeUser, handleFilterButtonClick, loading, historiques }) => {

    const [expandedId, setExpandedId] = useState(null);

    const toggleDescription = (id) => {
        if (expandedId === id) {
            setExpandedId(null);
        } else {
            setExpandedId(id);
        }
    };

    const renderDescription = (historique) => {
        if (expandedId === historique.id) {
            return historique.description;
        }
        if (historique.description.length > 40) {
            return `${historique.description.substring(0, 40)}...`;
        }

        return historique.description;
    };

    const [showFilterOptions, setShowFilterOptions] = useState(false);

    const toggleFilterOptions = () => {
        setShowFilterOptions(!showFilterOptions);
    }

    return (
        <>
            <div className="historique-mobile-filter" style={{ marginBottom: "10px" }}>
                <div className="historique-mobile-filter-row1">
                    <div style={{ margin: "8px", width: "30%" }}>
                        <input
                            type="date"
                            name="dateDebutString"
                            value={filter.dateDebutString === '1000-01-01' ? '' : filter.dateDebutString}
                            onChange={handleFilterChange}
                        />
                    </div>

                    <div style={{ margin: "8px", width: "30%" }}>
                        <input
                            type="date"
                            name="dateFinString"
                            value={filter.dateFinString === '9999-01-01' ? '' : filter.dateFinString}
                            onChange={handleFilterChange}
                        />
                    </div>

                    <button type='button' className='restau-add-btn' onClick={handleFilterButtonClick}>
                        ✓
                    </button>
                </div>

                <div className="historique-mobile-filter-row2">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {filter.dateDebutString !== '1000-01-01' && filter.dateDebutString !== '' && (
                            <h3>
                                {filter.dateDebutString}
                            </h3>
                        )}

                        {filter.dateDebutString !== '1000-01-01' && filter.dateDebutString !== '' && filter.dateFinString !== '9999-01-01' && filter.dateFinString !== '' && (
                            <h3>
                                &nbsp; au &nbsp;
                            </h3>
                        )}

                        {filter.dateFinString !== '9999-01-01' && filter.dateFinString !== '' && (
                            <h3>
                                {filter.dateFinString}
                            </h3>
                        )}
                    </div>
                    <div className="home-hr"></div>
                    <button onClick={toggleFilterOptions}>
                        Filtres
                        <FilterAltIcon style={{ color: "#003671", cursor: "pointer" }} />
                    </button>
                </div>

                {showFilterOptions && (
                    <div className="historique-mobile-filter-row3">
                        <FormControl sx={{ m: 1, width: "50%" }} size="small">
                            <InputLabel id="demo-select-small-label" style={{ fontFamily: "Poppins !important" }}>Type de modification</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                name='entity'
                                value={filter.entity}
                                label="Type de modification"
                                onChange={handleChangeEntity}
                            >
                                <MenuItem value="">
                                    <em>Tous</em>
                                </MenuItem>
                                <MenuItem value={"Horaire de service"}>Horaire de service</MenuItem>
                                <MenuItem value={"Date d'ouverture"}>Date d'ouverture</MenuItem>
                                <MenuItem value={"Table"}>Tables</MenuItem>
                                <MenuItem value={"Menu"}>Menu</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1 }} size="small">
                            <InputLabel id="demo-select-small-label">Utilisateur</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                name='utilisateur'
                                value={filter.utilisateur}
                                label="Utilisateur"
                                onChange={handleChangeUser}
                            >
                                <MenuItem value="">
                                    <em>Tous</em>
                                </MenuItem>
                                <MenuItem value={"Alessandra"}>Alessandra</MenuItem>
                                <MenuItem value={"John"}>John</MenuItem>
                                <MenuItem value={"Doe"}>Doe</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                )}
            </div>

            <div className="historique-body">
                {
                    loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress />
                        </div>
                    ) : (historiques.map(historique => (
                        <div
                            className={`historique-mobile-body-row historique-${historique.method}`}
                            key={historique.id}
                            onClick={() => toggleDescription(historique.id)}
                        >
                            <div>
                                <div
                                    style={{
                                        width: "50%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <h4>{historique.dateString}</h4>
                                    .
                                    <h4>{historique.heureString}</h4>
                                </div>
                                <h4>
                                    {historique.utilisateur}
                                </h4>
                            </div>

                            <div>
                                <h2>
                                    {historique.typeModification}
                                </h2>
                                <h3 style={{ transform: "rotate(90deg)", color: "#1D62AF", fontSize: "20px" }}>
                                    {">"}
                                </h3>
                            </div>
                            <div>
                                <h3>
                                    {renderDescription(historique)}
                                </h3>
                            </div>
                        </div>
                    )))
                }

            </div>
        </>
    );
};

export default HistoriqueMobile;