import { createSlice } from '@reduxjs/toolkit';

export interface CaisseState {
  amount: number,
}

const initialState: CaisseState = {
  amount: 0,
};

export const caisseSlice = createSlice({
  name: 'caisse',
  initialState,
  reducers: {
    setAmount: (state, data): void => {
      state.amount = data.payload;
    },
  }
});

export const { setAmount } = caisseSlice.actions;

export default caisseSlice.reducer;