import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

import './CaisseConciergePrimary.css';
import { setAmount } from '../../../redux/caisseSlice';

function CaisseConciergePrimary() {
  const dispatch = useDispatch();
  const { currentDate } = useSelector((state: RootState) => state.app);
  const [allowSaveMorning, setAllowSaveMorning] = useState(false);
  const [allowSaveEvening, setAllowSaveEvening] = useState(false);
  const [previousDayAmount, setPreviousDayAmount] = useState(6000);
  const [cashMoney] = useState<Array<number>>([500, 200, 100, 50, 20, 10, 5, 2, 1, 0.50, 0.20, 0.10, 0.05, 0.02, 0.01])
  const [cashMorningMoney, setCashMorningMoney] = useState<Array<number>>(Array(cashMoney.length).fill(0))
  const [cashEveningMoney, setCashEveningMoney] = useState<Array<number>>(Array(cashMoney.length).fill(0))

  useEffect(() => {
    if (localStorage.getItem('caisse')) {
      const caisseOfTheDay = JSON.parse(localStorage.getItem('caisse')!).find((caisse: any) => caisse.currentDate === currentDate);
      if (caisseOfTheDay) {
        setPreviousDayAmount(caisseOfTheDay.previousDayAmount);
        setCashMorningMoney(caisseOfTheDay.cashMorningMoney);
        setCashEveningMoney(caisseOfTheDay.cashEveningMoney);
      } else {
        setPreviousDayAmount(6000);
        setCashMorningMoney(Array(cashMoney.length).fill(0));
        setCashEveningMoney(Array(cashMoney.length).fill(0));
      }
    }
    // eslint-disable-next-line
  }, [currentDate])

  const setCaisseAmount = () => dispatch(setAmount(Number(sumAmount(cashMorningMoney) + sumAmount(cashEveningMoney))))

  useEffect(() => {
    if (allowSaveMorning) {
      saveCaisse();
    } else {
      setAllowSaveMorning(true);
    }
    setCaisseAmount();
    // eslint-disable-next-line
  }, [cashMorningMoney])

  useEffect(() => {
    if (allowSaveEvening) {
      saveCaisse();
    } else {
      setAllowSaveEvening(true);
    }
    setCaisseAmount();
    // eslint-disable-next-line
  }, [cashEveningMoney])

  const saveCaisse = () => {
    let previousCaisse = [];
    if (localStorage.getItem('caisse')) {
      previousCaisse = JSON.parse(localStorage.getItem('caisse')!);
    }
    previousCaisse = previousCaisse.filter((caisse: any) => caisse.currentDate !== currentDate);
    localStorage.setItem('caisse', JSON.stringify([
      ...previousCaisse,
      {
        currentDate,
        previousDayAmount,
        cashMorningMoney,
        cashEveningMoney
      }
    ]));
  }

  const sumAmount = (cashMoneyToSum: Array<number>) => {
    return cashMoneyToSum.some(value => value) ? cashMoneyToSum.map((value, key) => ({ value, key })).reduce((previous, cash) => previous + cash.value * cashMoney[cash.key], 0).toFixed(2) : '';
  }

  const sumValue = (cashMoneyToSum: Array<number>) => {
    return cashMoneyToSum.some(value => value) ? cashMoneyToSum.reduce((a, b) => a + b) : '';
  }

  const cashMoneyAmount = (value: number, amount: number) => {
    return !!value ? (value * amount).toFixed(2) : '';
  }

  const cashMoneyValue = (value: number) => {
    return !!value ? value : '';
  }

  const changeMorningCount = (e: ChangeEvent<HTMLInputElement>, key: number) => {
    const input = e.target as HTMLInputElement;
    if (!isNaN(Number(input.value)) && Number(input.value) > 0) {
      const cashMorningMoneyCopy = [...cashMorningMoney];
      cashMorningMoneyCopy[key] = Number(input.value);
      setCashMorningMoney(cashMorningMoneyCopy);
    }
  }

  const changeEveningCount = (e: ChangeEvent<HTMLInputElement>, key: number) => {
    const input = e.target as HTMLInputElement;
    if (!isNaN(Number(input.value)) && Number(input.value) > 0) {
      const cashEveningMoneyCopy = [...cashEveningMoney];
      cashEveningMoneyCopy[key] = Number(input.value);
      setCashEveningMoney(cashEveningMoneyCopy);
    }
  }

  return (
    <div className="CaisseConcierge">
      <div className="CaisseConciergeRow">
        <div className="CaisseConciergeRowColum">
          <div className="CaisseConciergeLargeGreyCell">Check du matin</div>
        </div>
        <div className="CaisseConciergeRowColum">
          <div className="CaisseConciergeGreenCell">Montant de la veille</div>
          <div className="CaisseConciergeGreyCell">{previousDayAmount}€</div>
        </div>
        <div className="CaisseConciergeRowColum">
          <div className="CaisseConciergeLargeYellowCell">Check du soir</div>
        </div>
      </div>
      <div className="CaisseConciergeRow">
        <div className="CaisseConciergeRowColum">
          <div className="CaisseConciergeBlueCell">Montant</div>
          <div className="CaisseConciergeBlueCell">Nombre</div>
        </div>
        <div className="CaisseConciergeRowColum">
          <div className="CaisseConciergeLargeBlueCell">Valeur</div>
        </div>
        <div className="CaisseConciergeRowColum">
          <div className="CaisseConciergeBlueCell">Montant</div>
          <div className="CaisseConciergeBlueCell">Nombre</div>
        </div>
      </div>
      {cashMoney.map((amount, key) => (
        <div className="CaisseConciergeRow" key={key}>
          <div className="CaisseConciergeRowColum">
            <div className="CaisseConciergeGreyCell">{cashMoneyAmount(cashMorningMoney[key], amount)}</div>
            <input type="text" className="CaisseConciergeGreyInputCell" value={cashMoneyValue(cashMorningMoney[key])} onChange={(e) => changeMorningCount(e, key)} />
          </div>
          <div className="CaisseConciergeRowColum">
            <div className="CaisseConciergeLargeGreyCell">{amount}€</div>
          </div>
          <div className="CaisseConciergeRowColum">
            <div className="CaisseConciergeGreyCell">{cashMoneyAmount(cashEveningMoney[key], amount)}</div>
            <input type="text" className="CaisseConciergeGreyInputCell" value={cashMoneyValue(cashEveningMoney[key])} onChange={(e) => changeEveningCount(e, key)} />
          </div>
        </div>
      ))}
      <div className="CaisseConciergeRow">
        <div className="CaisseConciergeRowColum">
          <div className="CaisseConciergeGreyCell">{sumAmount(cashMorningMoney)}</div>
          <div className="CaisseConciergeGreyCell">{sumValue(cashMorningMoney)}</div>
        </div>
        <div className="CaisseConciergeRowColum">
          <div className="CaisseConciergeLargeGreyCell">Montant de la caisse</div>
        </div>
        <div className="CaisseConciergeRowColum">
          <div className="CaisseConciergeGreyCell">{sumAmount(cashEveningMoney)}</div>
          <div className="CaisseConciergeGreyCell">{sumValue(cashEveningMoney)}</div>
        </div>
      </div>
    </div>
  );
}

export default CaisseConciergePrimary;


