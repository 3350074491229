import './Voiturier.css';

import GnzBreadcrumb from '../shared/gnz-breadcrumb/GnzBreadcrumb';
import GnzPaper from '../shared/gnz-paper/GnzPaper';

function View() {
    return (
        <div style={{fontFamily: 'Montserrat', fontSize: 14, fontWeight: 600}}>
            Voiturier
        </div>
    )
}

function Voiturier() {
    return (
        <div className="Voiturier">
            <GnzBreadcrumb breadcrumbs={['Voiturier', 'Voiturier']}/>
            <GnzPaper component={<View />}/>
        </div>
    );
}

export default Voiturier;
