import { useEffect, useState } from "react";
import { Stack, Typography, Button, Grid } from "@mui/material";
import { differenceInDays } from "date-fns";
import { startOfDay } from "date-fns";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";

interface FormData {
  Date_out: null | Date;
  Date_in: null | Date;
  Motif: null | string;
  Detail: null | string;
  pdfFile: File | null;
  EmployeeId: number;
  Id: number;
}
const apiUrl =
  window.location.hostname === "localhost"
    ? "https://localhost:7142/api"
    : "/api";
const Absence = () => {
  const location = useLocation();
  const { id } = useParams();
  const [employee, setEmployee] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const Id =
    location.state && location.state.id !== undefined ? location.state.id : id;
  const [formDataArray, setFormDataArray] = useState<FormData[]>([]);

  const handlediff = (date1: any, date2: any) => {
    const dateOut = startOfDay(new Date(date1));
    const dateIn = startOfDay(new Date(date2));

    const dayDiff = differenceInDays(dateIn, dateOut) + 1;
    return dayDiff;
  };

  const handleDelete = async (id: number) => {
    try {
      const response = await axios.delete(`${apiUrl}/Absence/${id}`);

      setFormDataArray((prevFormDataArray) =>
        prevFormDataArray.filter((item) => item.Id !== id)
      );

      const startDate = new Date(response.data.date_out);
      const endDate = new Date(response.data.date_in);
      const datesInRange = [];
      for (
        let date = startDate;
        date <= endDate;
        date.setDate(date.getDate() + 1)
      ) {
        const formattedDate = `${date.getFullYear()}-${String(
          date.getMonth() + 1
        ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
        datesInRange.push(formattedDate);
      }

      for (const dateInRange of datesInRange) {
        const responsePlanning = await axios.delete(
          `${apiUrl}/Planning/${Id}/${dateInRange}`,
          {} // Provide an empty object as the request body
        );

        console.log("Response from Planning:", responsePlanning.data);
      }

      // window.location.reload();
    } catch (error) {
      console.error(error);
      // Handle error, display message to user, etc.
    }
  };

  const formattedDate = (dateTimeString: any) => {
    if (!dateTimeString) return "";
    const dateOnlyString = dateTimeString.split("T")[0];
    return dateOnlyString;
  };
  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await axios.get(`${apiUrl}/Employee/${Id}`);
        setEmployee(response.data);
        const newFormDataArray = response.data.absences.map((absence: any) => ({
          Date_out: formattedDate(absence.date_out),
          Date_in: formattedDate(absence.date_in),
          Motif: absence.motif,
          Detail: absence.detail,
          pdfFile: absence.pdfFile,
          EmployeeId: Id,
          Id: absence.id,
        }));
        setFormDataArray(newFormDataArray);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchEmployee();

    return () => {};
  }, [Id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!employee) {
    return <div>No employee found</div>;
  }
  return (
    <Stack
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      sx={{
        minHeight: 700,
        padding: 2,
      }}
    >
      <Grid container>
        {formDataArray.map((absence: any, index: number) => (
          <Grid
            container
            key={index}
            sx={{ backgroundColor: "rgba(160,226,149,0.1)" }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding={1}
            my={0.5}
          >
            <Grid item lg={2}>
              <Typography textAlign="center">{absence.Motif}</Typography>
            </Grid>
            <Grid item lg={2}>
              <Typography textAlign="center">{absence.Detail}</Typography>
            </Grid>
            <Grid item lg={2}>
              <Typography textAlign="center">
                {handlediff(absence.Date_out, absence.Date_in)} jours
              </Typography>
            </Grid>
            <Grid item lg={2}>
              <Typography textAlign="center">{absence.Date_out}</Typography>
            </Grid>
            <Grid item lg={2}>
              <Typography textAlign="center">{absence.Date_in}</Typography>
            </Grid>
            <Grid item>
              <Button onClick={() => handleDelete(absence.Id)}>
                <Typography
                  textAlign="end"
                  sx={{
                    textTransform: "none",
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                  mx={2}
                >
                  Supprimer
                </Typography>
              </Button>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default Absence;
