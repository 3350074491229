import { FormControl, InputLabel, MenuItem, Select, CircularProgress } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const HistoriqueDesktop = ({ filter, handleFilterChange, handleChangeEntity, handleChangeUser, handleFilterButtonClick, loading, historiques }) => {
    return (
        <>
            <div className='historique-filter'>
                <div style={{ margin: "8px" }}>
                    <input
                        type="date"
                        name="dateDebutString"
                        value={filter.dateDebutString === '1000-01-01' ? '' : filter.dateDebutString}
                        onChange={handleFilterChange}
                    />
                </div>

                <div style={{ margin: "8px" }}>
                    <input
                        type="date"
                        name="dateFinString"
                        value={filter.dateFinString === '9999-01-01' ? '' : filter.dateFinString}
                        onChange={handleFilterChange}
                    />
                </div>
                <FormControl sx={{ m: 1, minWidth: 120, width: 220 }} size="small">
                    <InputLabel id="demo-select-small-label" style={{ fontFamily: "Poppins !important" }}>Type de modification</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        name='entity'
                        value={filter.entity}
                        label="Type de modification"
                        onChange={handleChangeEntity}
                    >
                        <MenuItem value="">
                            <em>Tous</em>
                        </MenuItem>
                        <MenuItem value={"Horaire de service"}>Horaire de service</MenuItem>
                        <MenuItem value={"Date d'ouverture"}>Date d'ouverture</MenuItem>
                        <MenuItem value={"Table"}>Tables</MenuItem>
                        <MenuItem value={"Menu"}>Menu</MenuItem>
                    </Select>
                </FormControl>

                <div style={{ display: "flex", alignItems: "center" }}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-select-small-label">Utilisateur</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            name='utilisateur'
                            value={filter.utilisateur}
                            label="Utilisateur"
                            onChange={handleChangeUser}
                        >
                            <MenuItem value="">
                                <em>Tous</em>
                            </MenuItem>
                            <MenuItem value={"Alessandra"}>Alessandra</MenuItem>
                            <MenuItem value={"John"}>John</MenuItem>
                            <MenuItem value={"Doe"}>Doe</MenuItem>
                        </Select>
                    </FormControl>

                    <FilterAltIcon
                        style={{ color: "#003671", fontSize: "36px", cursor: "pointer" }}
                        onClick={handleFilterButtonClick}
                    />
                </div>

            </div>

            <div className='historique-body'>
                <div className='historique-body-header'>
                    <h2 style={{ width: "12%" }}>
                        Date
                    </h2>
                    <h2 style={{ width: "12%" }}>
                        Heure
                    </h2>
                    <h2 style={{ width: "23%" }}>
                        Type de modification
                    </h2>
                    <h2 style={{ width: "40%", textAlign: "center" }}>
                        Description
                    </h2>
                    <h2 style={{ width: "13%" }}>
                        Utilisateur
                    </h2>
                </div>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </div>

                ) : (historiques.map(historique => (
                    <div className={`historique-body-row historique-${historique.method}`} key={historique.id}>
                        <h2 style={{ width: "12%" }}>
                            {historique.dateString}
                        </h2>
                        <h2 style={{ width: "12%" }}>
                            {historique.heureString}
                        </h2>
                        <h2 style={{ width: "23%" }}>
                            {historique.typeModification}
                        </h2>
                        <h2 style={{ width: "40%" }}>
                            {historique.description}
                        </h2>
                        <h2 style={{ width: "13%" }}>
                            {historique.utilisateur}
                        </h2>
                    </div>
                ))
                )}
            </div>
        </>
    );
};

export default HistoriqueDesktop;