import './AddReminderForm.css';

import { useEffect, useState } from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { TimePickerProps } from 'antd';

import Mission from '../../../../models/Mission';
import MissionReminder from '../../../../models/MissionReminder';
import GnzButton from '../../../shared/gnz-button/GnzButton';
import GnzTimePicker from '../../../shared/gnz-time-picker/GnzTimePicker';

interface IAddReminderForm {
  mission: Mission,
  confirmSubmit: any,
  cancelSubmit: any,
}

function AddReminderForm(props: IAddReminderForm) {
  const [schedule, setSchedule] = useState(Date.now());
  const [forMe, setForMe] = useState(false);
  const [forAllService, setForAllService] = useState(false);

  useEffect(() => {
    if (props.mission.reminder.scedule) {
      setSchedule(props.mission.reminder.scedule);
      setForMe(props.mission.reminder.forMe);
      setForAllService(props.mission.reminder.forAllService);
    }
    // eslint-disable-next-line
  }, [])

  const confirmSubmit = () => {
    if (schedule && (forMe || forAllService)) {
      props.confirmSubmit(props.mission, new MissionReminder(schedule, forMe, forAllService));
    }
  }

  const onChangeTimePicker: TimePickerProps['onChange'] = (date: any) => {
    date.$d = new Date(`${date.$d.getFullYear()}-${date.$d.getMonth() + 1}-${date.$d.getDate()} ${date.$H}:${date.$m}`);
    setSchedule(date.$d.getTime());
  };

  return (
    <div className="AddReminderForm">
      <DialogTitle id="responsive-dialog-title">
        Ajouter un reminder
      </DialogTitle>
      <DialogContent>
        <div className="AddReminderFormLabel">A quelle heure souhaitez vous mettre le reminder ?</div>
        <GnzTimePicker className="SecondaryDatePicker" currentDate={schedule} onChange={onChangeTimePicker} />
        <div className="AddReminderCheckboxs">
          <div className="AddReminderCheckbox">
            <input type="checkbox" id="forMe" checked={forMe} onChange={() => setForMe(!forMe)}/>
            <label htmlFor="forMe">Pour moi même</label>
          </div>
          <div className="AddReminderCheckbox">
            <input type="checkbox" id="forAllService" checked={forAllService} onChange={() => setForAllService(!forAllService)}/>
            <label htmlFor="forAllService">Pour service entier</label>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <GnzButton label="Confirmer" onClick={confirmSubmit} backgroundColor="#dc3545" />
        <GnzButton label="Annuler" onClick={props.cancelSubmit} />
      </DialogActions>
    </div>
  );
}

export default AddReminderForm;
