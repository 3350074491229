import { useEffect, useRef, useState } from 'react';
import ClienteHeader from '../../shared/ClienteHeader/ClienteHeader';
import ProfilClientPreferencesDetails from './ProfilClientPreferencesDetails/ProfilClientPreferencesDetails';

function ProfilClientPreferences() {
  const [search, setSearch] = useState('');  

  return (
     <div className="">
      <div className="ProfilClientsTopActions">
        <ClienteHeader src={""} title="Preference" />     

          <div className='inputDivSyle'>
          <input className="inputsyle" onChange={(e) => setSearch(e.target.value)} placeholder='Que recherchez-vous ?'></input>
        </div> 
      </div>
      <ProfilClientPreferencesDetails />     
    </div>
  );
}
export default ProfilClientPreferences;