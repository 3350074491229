import * as React from "react";
import {
  Button,
  Grid,
  Paper,
  Typography,
  Pagination,
  Container,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Box } from "@mui/material";
import Filter from "./filterEmployee";
import NewEmployee from "./newEmployee";
const apiUrl =
  window.location.hostname === "localhost"
    ? "https://localhost:7142/api"
    : "/api";
const EmployeeComponent = ({ employee }: any) => {
  return (
    <Box my={1} sx={{ backgroundColor: employee.couleurDuService }} p={2}>
      <Grid container>
        <Grid
          xs={6}
          sm={4}
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <img
            src={employee.profilePicture}
            alt={employee.nom}
            width={30}
            height={30}
            style={{
              borderRadius: "50%",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
            }}
          />
          <Typography
            marginLeft={1}
            fontWeight="bold"
            sx={{ color: "rgba(0,0,0,0.6)" }}
          >
            {employee.nom} {employee.prenom}
          </Typography>
        </Grid>
        <Grid xs={6} sm={4} item>
          <Typography
            textAlign="center"
            marginLeft={1}
            sx={{ color: "rgba(0,0,0,0.6)" }}
          >
            {employee.poste}
          </Typography>
        </Grid>
        <Grid
          xs={12}
          sm={4}
          item
          flexDirection="row"
          display="flex"
          justifyContent="space-between"
          mt={{ xs: 2, sm: 0 }}
        >
          <Typography marginLeft={1} sx={{ color: "rgba(0,0,0,0.6)" }}>
            {employee.responsabilite}
          </Typography>
          <Button href={`/fiche/${employee.id}`}>
            <svg
              width="3"
              height="15"
              viewBox="0 0 3 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 0 1.5 0C0.671573 0 0 0.671573 0 1.5C0 2.32843 0.671573 3 1.5 3Z"
                fill="#1D62AF"
              />
              <path
                d="M1.5 9C2.32843 9 3 8.32843 3 7.5C3 6.67157 2.32843 6 1.5 6C0.671573 6 0 6.67157 0 7.5C0 8.32843 0.671573 9 1.5 9Z"
                fill="#1D62AF"
              />
              <path
                d="M1.5 15C2.32843 15 3 14.3284 3 13.5C3 12.6716 2.32843 12 1.5 12C0.671573 12 0 12.6716 0 13.5C0 14.3284 0.671573 15 1.5 15Z"
                fill="#1D62AF"
              />
            </svg>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default function List() {
  const [employees, setEmployees] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [selectedDpts, setSelectedDpts] = React.useState<String[]>([]);
  const [responsabilité, setResponsabilite] = React.useState<String[]>([]);
  const [fromdate, setFromdate] = React.useState(null);
  const [todate, setTodate] = React.useState(null);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const OpenEmployee = () => {
    setIsDialogOpen(true);
  };
  const CloseEmployee = () => {
    setIsDialogOpen(false);
  };
  const employeesPerPage = 10;

  React.useEffect(() => {
    const fetchEmployees = async () => {
      try {
        let url = `${apiUrl}/Employee`;

        const filters = [];
        if (selectedDpts.length > 0) {
          const selectedDptFilter = selectedDpts.map(
            (dpt) => `dpt=${encodeURIComponent(String(dpt))}`
          );
          filters.push(...selectedDptFilter);
        }
        if (responsabilité.length > 0) {
          const responsabilitéFilter = responsabilité.map(
            (respo) => `respo=${encodeURIComponent(String(respo))}`
          );
          filters.push(...responsabilitéFilter);
        }

        if (fromdate) {
          filters.push(`fromdate=${encodeURIComponent(fromdate)}`);
        }
        if (todate) {
          filters.push(`todate=${encodeURIComponent(todate)}`);
        }

        if (filters.length > 0) {
          url += `?${filters.join("&")}`;
        }

        const response = await fetch(url);
        const data = await response.json();

        setEmployees(data);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, [selectedDpts, responsabilité, fromdate, todate]);

  React.useEffect(() => {
    if (selectedValue === "asc" || selectedValue === "desc") {
      setEmployees((prevEmployees) =>
        [...prevEmployees].sort((a: any, b: any) =>
          selectedValue === "asc"
            ? a.nom.localeCompare(b.nom)
            : b.nom.localeCompare(a.nom)
        )
      );
    } else if (selectedValue === "newer" || selectedValue === "older") {
      setEmployees((prevEmployees) =>
        [...prevEmployees].sort((a: any, b: any) =>
          selectedValue === "newer"
            ? new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            : new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        )
      );
    } else {
      setEmployees((prevEmployees) =>
        [...prevEmployees].sort((a, b) => (a as any).id - (b as any).id)
      );
    }
  }, [selectedValue]);

  const indexOfLastEmployee = currentPage * employeesPerPage;
  const indexOfFirstEmployee = indexOfLastEmployee - employeesPerPage;
  const currentEmployees = employees.slice(
    indexOfFirstEmployee,
    indexOfLastEmployee
  );

  const handlePageChange = (event: any, value: any) => {
    setCurrentPage(value);
  };
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleButtonClick = () => {
    setOpenDialog(!openDialog);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Grid container sx={{ justifyContent: "end", display: "flex" }}>
        <Button onClick={handleButtonClick}>
          <Typography
            sx={{
              textTransform: "none",
              color: "rgba(0,0,0,0.4)",
              border: "1px solid rgba(0,0,0,0.4)",
            }}
            padding={1}
            borderRadius={1}
          >
            Filtres - Trie
          </Typography>
        </Button>
      </Grid>
      <Dialog
        open={openDialog}
        // PaperProps={{
        //   style: {
        //     marginLeft: "auto", // Adjust the left margin as needed
        //     marginRight: "0px",
        //     width: 500,
        //     height:"100%"
        //   },
        // }}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <Filter
            setSelectedValue={setSelectedValue}
            selectedValue={selectedValue}
            selectedDpts={selectedDpts}
            setSelectedDpts={setSelectedDpts}
            responsabilité={responsabilité}
            setResponsabilite={setResponsabilite}
            fromdate={fromdate}
            setFromdate={setFromdate}
            todate={todate}
            setTodate={setTodate}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", marginBottom: 2 }}>
          <Button onClick={handleClose} color="primary">
            <Typography
              sx={{
                backgroundColor: "#0B4B92",
                color: "white",
                textTransform: "none",
              }}
              paddingY={1}
              paddingX={2}
            >
              Recherche
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Paper
        sx={{
          maxWidth: 1536,
          width: "100%",
          // minHeight: 700,
          paddingX: 2,
          paddingY: 6,
        }}
      >
        <Box
          sx={{
            maxWidth: 1536,
            // minHeight: 750,
          }}
        >
          {currentEmployees.map((employee: any) => (
            <EmployeeComponent key={employee.id} employee={employee} />
          ))}
        </Box>
        <Box>
          <Button variant="outlined" onClick={OpenEmployee}>
            <Typography sx={{ textTransform: "none" }}>
              Ajouter un employé
            </Typography>
          </Button>
          <Dialog open={isDialogOpen} onClose={CloseEmployee}>
            <NewEmployee onCancel={CloseEmployee} />
          </Dialog>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <Pagination
            count={Math.ceil(employees.length / employeesPerPage)}
            page={currentPage}
            onChange={handlePageChange}
          />
        </Box>
      </Paper>
    </Container>
  );
}
