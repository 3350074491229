import Modal from '@mui/material/Modal';
import LogoutIcon from '@mui/icons-material/Logout';
import { Periode } from '../types/types';
import { periodeService } from '../api/periodeService';
import { useEffect, useState } from 'react';

interface UpdateDateProps {
    onClose: () => void;
    initialPeriode: Periode | null;
    onUpdate: (updatedPeriode: Periode) => void;
    type: boolean;
}

const UpdateDate: React.FC<UpdateDateProps> = ({ onClose, initialPeriode, onUpdate, type }) => {

    const convertDateFormat = (dateString: string) => {
        if (!dateString) return '';

        const parts = dateString.split('/');
        if (parts.length !== 3) return dateString;

        return `${parts[2]}-${parts[1].padStart(2, '0')}-${parts[0].padStart(2, '0')}`;
    };

    const defaultPeriode: Periode = {
        id: 0,
        dateDebutString: '',
        dateFinString: '',
        restaurantId: 0,
    };

    // Initialize with converted or default data
    const initialData = initialPeriode ? {
        ...initialPeriode,
        dateDebutString: convertDateFormat(initialPeriode.dateDebutString),
        dateFinString: initialPeriode.dateFinString ? convertDateFormat(initialPeriode.dateFinString) : ''
    } : defaultPeriode;

    const [periode, setPeriode] = useState < Periode > (initialData);

    useEffect(() => {
        if (initialPeriode) {
            setPeriode({
                ...initialPeriode,
                dateDebutString: convertDateFormat(initialPeriode.dateDebutString),
                dateFinString: initialPeriode.dateFinString ? convertDateFormat(initialPeriode.dateFinString) : ''
            });
        }
    }, [initialPeriode]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setPeriode({ ...periode, [name]: value });
    };

    const handleSubmit = async () => {
        if (initialPeriode) {
            try {
                if (periode.dateDebutString === "") {
                    alert("Veuillez renseigner une date de début");
                    return;
                }
                if (periode.dateFinString === "" && type) {
                    alert("Veuillez renseigner une date de fin");
                    return;
                }
                if (type && periode.dateDebutString && periode.dateFinString) {
                    if (periode.dateDebutString > periode.dateFinString) {
                        alert("La date de début doit être inférieure à la date de fin");
                        return;
                    } else {
                        const updatedData = await periodeService.putPeriode(initialPeriode.id, periode);
                        onUpdate(updatedData);
                        onClose();
                    }
                }

            } catch (error) {
                console.error("Error updating periode:", error);
            }
        } else {
            console.error("initialPeriode is null or undefined");
        }
    };

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='modal-restau-add'>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h1>Modifié la date d'ouverture</h1>
                    <LogoutIcon
                        style={{ color: "darkgray", fontSize: "36px", cursor: "pointer" }}
                        onClick={onClose}
                    />
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                    <div style={{ width: "48%", display: "flex", flexDirection: "column" }}>
                        <label htmlFor="dateDebut">Du</label>
                        <input type="date" name="dateDebutString" id="dateDebut" value={periode.dateDebutString} onChange={handleInputChange} />
                    </div>

                    {type && (
                        <div style={{ width: "48%", display: "flex", flexDirection: "column" }}>
                            <label htmlFor="dateFin">Au</label>
                            <input type="date" name="dateFinString" id="dateFin" value={periode.dateFinString || ""} onChange={handleInputChange} />
                        </div>
                    )}
                </div>
                <button className='restau-btn restau-modal-btn' onClick={handleSubmit}>
                    Appliquer
                </button>
            </div>
        </Modal>
    );
}

export default UpdateDate;