import { useState } from 'react';
import './MssAdminDepartment.css';
import classNames from 'classnames';

interface IMssAdminDepartment {
  name: string;
  children?: React.ReactNode;
  visible: boolean;
}

function MssAdminDepartment(props: IMssAdminDepartment) {
  const [visible, setVisible] = useState<boolean>(props.visible);

  return (
    <div className="MssAdminDepartment">
      <div className="MssAdminDepartmentName" onClick={() => setVisible(!visible)}>{props.name}</div>
      <div className={classNames({'MssAdminDepartmentUsers': true, 'MssAdminDepartmentUsersVisible': visible})}>
        {props.children}
      </div>
    </div>
  );
}

export default MssAdminDepartment;
