import './ToDay.css';

import { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
    FormControl,
    MenuItem,
    Select,
} from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import ToChart from '../../../enums/ToChart';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const legendMargin = {
  id: 'legendMargin',
  beforeInit(chart: any) {
    const fitValue = chart.legend.fit;
    chart.legend.fit = function fit() {
      fitValue.bind(chart.legend)();
      return this.height += 40;
    }
  }
}

const options = {
  responsive: true,
  chartArea: {
    backgroundColor: 'red'
  },
  plugins: {
    legend: {
      position: 'top' as const,
      align: 'start' as const,
      labels: {
        boxWidth: 20,
        boxHeight: 3,
      }
    },
  },
  elements: {
    point:{
        radius: 0
    },
    line: {
      tension: 0.4,
    },

  },
  scales: {
    y: {
        suggestedMin: 0,
        suggestedMax: 800,
        grid: {
          display: false
        },
        border: {
          display: false,
        },
    },
    x: {
      display: true
    },
  },
};
const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
const data = {
  labels,
  datasets: [
    {
      label: 'Décembre',
      data: [100, 0, 200, 0, 400, 0, 800, 0, 400, 0, 200, 0, 100, 0],
      borderColor: '#E9A800',
      backgroundColor: '#E9A800',
    },
    {
      label: 'Décembre',
      data: [50, 150, 0, 300, 0, 600, 0, 600, 0, 300, 0, 150, 0, 50],
      borderColor: '#95E2AF',
      backgroundColor: '#95E2AF',
    },
  ],
};

function ToDay() {
    const [toChart, setToChart] = useState(ToChart.Month);

    return (
        <div className="ToDay">
            <div className="ToDayTop">
                <div className="ToDayTopChart">
                    <div className="ToDayTopChartSelect">
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <Select
                            className="ToDayGraphChartHeaderSelect"
                            variant="outlined"
                            sx={{ backgroundColor: "#F4F4F4" }}
                            value={toChart}
                            onChange={(e) => setToChart(e.target.value as ToChart)}
                        >
                            <MenuItem key={ToChart.Month} value={ToChart.Month}>
                                Ce mois
                            </MenuItem>
                            <MenuItem key={ToChart.Year} value={ToChart.Year}>
                                Cette année
                            </MenuItem>
                        </Select>
                        </FormControl>
                    </div>
                    <Line options={options} data={data} plugins={[legendMargin]} />
                </div>
                <div className="ToDayTopStats">
                    <div className="ToDayTopStatsItem">
                        <div className="ToDayTopStatsItemTitle">
                        Check-in du jour
                        </div>
                        <div className="ToDayTopStatsItemValue">
                            23
                        </div>
                    </div>
                    <div className="ToDayTopStatsItem">
                        <div className="ToDayTopStatsItemTitle">
                            Check-in restant
                        </div>
                        <div className="ToDayTopStatsItemValue">
                            12
                        </div>
                    </div>
                    <div className="ToDayTopStatsItem">
                        <div className="ToDayTopStatsItemTitle">
                            Check-out du jour
                        </div>
                        <div className="ToDayTopStatsItemValue">
                            13
                        </div>
                    </div>
                    <div className="ToDayTopStatsItem">
                        <div className="ToDayTopStatsItemTitle">
                            Check-out restant
                        </div>
                        <div className="ToDayTopStatsItemValue">
                            1
                        </div>
                    </div>
                    <div className="ToDayTopStatsItem">
                        <div className="ToDayTopStatsItemTitle">
                            To du jour
                        </div>
                        <div className="ToDayTopStatsItemValue">
                            50%
                        </div>
                    </div>
                </div>
            </div>
            <div className="ToDayBottom">
                <div className="ToDayBottomTitle">
                    Réservations du jour restaurant
                </div>
                <div className="ToDayStatsTable">
                    <div className="ToDayStatsTableHeadRow">
                        <div className="ToDayStatsTableCell">
                        </div>
                        <div className="ToDayStatsTableHeadCell">
                        Confirmées
                        </div>
                        <div className="ToDayStatsTableHeadCell">
                        En attente
                        </div>
                        <div className="ToDayStatsTableHeadCell">
                        Waiting List
                        </div>
                        <div className="ToDayStatsTableHeadCell">
                        Tables restantes
                        </div>
                    </div>
                    <div className="ToDayStatsTableRow">
                        <div className="ToDayStatsTableLeftHeadCell">
                            Bar
                        </div>
                        <div className="ToDayStatsTableHeadCell">
                            56
                        </div>
                        <div className="ToDayStatsTableHeadCell">
                            56
                        </div>
                        <div className="ToDayStatsTableHeadCell">
                            56
                        </div>
                        <div className="ToDayStatsTableHeadCell">
                            56
                        </div>
                    </div>
                    <div className="ToDayStatsTableRow">
                        <div className="ToDayStatsTableLeftHeadCell">
                            Restaurant “Il Sogno”
                        </div>
                        <div className="ToDayStatsTableHeadCell">
                            56
                        </div>
                        <div className="ToDayStatsTableHeadCell">
                            56
                        </div>
                        <div className="ToDayStatsTableHeadCell">
                            56
                        </div>
                        <div className="ToDayStatsTableHeadCell">
                            56
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ToDay;