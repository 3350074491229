import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import greenArrowLeft from '../../images/shared/greenarrowleft.png';
import greenArrowRight from '../../images/shared/greenarrowright.png';
import _FiltersProfilClient from '../../models/Tri';
import FormMode from '../../enums/FormMode';
import ProfilClientsTable from './ProfilClientsTable/ProfilClientsTable';
import { RootState } from '../../redux/store';
import GnzDialog from '../shared/gnz-dialog/GnzDialog';
import GnzDrawer from '../shared/gnz-drawer/GnzDrawer';
import CancelProfilClientsFilter from './CancelProfilClientsFilter/CancelProfilClientsFilter';
import ProfilClientsForm from './ProfilClientsForm/ProfilClientsForm'
import Reservation from '../../models/Reservation';
import GnzImage from '../shared/gnz-image/GnzImage';
import axios from 'axios';
import './ProfilClients.css';

function ProfilClients() {
  const navigate = useNavigate();
  const { currentDate } = useSelector((state: RootState) => state.app);
  const [mode, setMode] = useState<FormMode>(FormMode.None);
  const drawerRef = useRef<HTMLDivElement>(null);
  const cancelDialogRef = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState('');
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);
  const [Reservation, reservation] = useState<Array<Reservation>>([]);
  const [primaryScreen, setPrimaryScreen] = useState(true);
  const Url = 'https://www.mysharingsystem.com/api/';

  const profilClientsFilter = () => {
    setMode(FormMode.New);
    openDrawer();
  };

  const openDrawer = () => {
    const drawerRefEl: any = drawerRef.current;
    drawerRefEl.open();
  }

  const closeDrawer = () => {
    const drawerRefEl: any = drawerRef.current;
    drawerRefEl.close();
  }

  const feedbackFormTitle = () => {
    switch (mode) {
      case FormMode.New:
        return 'Tous les filtres';
      default:
        return '';
    }
  }

  const feedbackFormSubmitLabel = () => {
    switch (mode) {
      case FormMode.New:
        return 'Ajouter la feedback';
      case FormMode.Edit:
        return 'Modifier la feedback';
      default:
        return '';
    }
  }

  const cancelFeedbackConfirm = (feedback: _FiltersProfilClient, reason: string) => {
  }

  const closeCancelDialog = () => {
    const cancelDialogRefEl: any = cancelDialogRef.current;
    cancelDialogRefEl.close();
  }

  useEffect(() => {
    axios.get(Url + 'Reservation/' + startDate + '/' + endDate) // POST
      .then(res => reservation(res.data));

  }, [])

  const handleChangeDate = (event: any) => {
    setStartDate(event.target.value);
    axios.get(Url + 'Reservation/' + event.target.value + '/' + endDate) // POST
      .then(res => reservation(res.data));
  };

  const handleChange = (event: any) => {
    setEndDate(event.target.value);
    axios.get(Url + 'Reservation/' + startDate + '/' + event.target.value) // POST
      .then(res => reservation(res.data));
  };

  const GetCheckInReservation = () => {
    axios.get(Url + 'Reservation/GetCheckInReservation/' + startDate + '/' + endDate) // POST
      .then(res => reservation(res.data));
  };
  
  return (
    <div className="ProfilClients">
      <div className="ProfilClientsTopActions">
        <h3 className='ProfilClientsTopActionsh3'>Profil Clients</h3>

        <div className='inputDivSyle'>
          <input className="inputsyle" onChange={(e) => setSearch(e.target.value)} placeholder='Que recherchez-vous ?'></input>
        </div>
        <div className='selectTimeDiv'>
          <input type="date" className="Datasyle" defaultValue={startDate} onChange={handleChangeDate} />
          <input type="date" className="Datasyle" defaultValue={endDate} onChange={handleChange} />
        </div>
        <div className='selectSyleDiv'>
          <select className='selectSyleClient'>
            <option value={0}>tri</option>
            <option value={1}>Acs</option>
            <option value={2}>Desc</option>

          </select>
          <select className='selectSyleClient' onClick={profilClientsFilter}>
            <option value={1}>Filters</option>
          </select>
        </div>
      </div>

      <ProfilClientsTable search={search} Reservation={Reservation} />
      <div className="Caisse">
        <div className="CaisseBottomActions">
          <div className="CaisseBottomCenterActionsBlueCircle" onClick={() => setPrimaryScreen(true)}>
            <GnzImage src={greenArrowLeft} clickable />
          </div>
          <div className={primaryScreen ? 'CaisseBottomCenterActionsActiveDot' : 'CaisseBottomCenterActionsDot'}></div>
          <div className={!primaryScreen ? 'CaisseBottomCenterActionsActiveDot' : 'CaisseBottomCenterActionsDot'} onClick={GetCheckInReservation}></div>
          <div className="CaisseBottomCenterActionsDotOther" onClick={() => navigate('/caisse-room')}></div>
          <div className="CaisseBottomCenterActionsBlueCircle" onClick={() => setPrimaryScreen(false)}>
            <GnzImage src={greenArrowRight} clickable />
          </div>
        </div>
      </div>
      <GnzDrawer ref={drawerRef} component={
        <ProfilClientsForm
          mode={mode}
          currentDate={currentDate}
          title={feedbackFormTitle()}
          closeForm={closeDrawer}
          submitLabel={feedbackFormSubmitLabel()}
          submitColor="#003671"
        />
      } />
      <GnzDialog ref={cancelDialogRef} component={
        <CancelProfilClientsFilter
          confirmSubmit={cancelFeedbackConfirm}
          cancelSubmit={closeCancelDialog}
        />}
      />

    </div>
  );
}
export default ProfilClients;