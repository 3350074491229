import  {useState } from "react";
import {
  FormControl,
  Paper,
  Container,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import CreationAbs from './creationAbs'
import ModifAbs from './modifAbs'
import DeleteAbs from './deleteAbs'

const Absence = () => {
  const [selectedValue, setSelectedValue] = useState("Création");

    const handleChange2 = (event:any) => {
      setSelectedValue(event.target.value);
    };

  return (
    <Container maxWidth="xl" sx={{ padding: 5 }}>
      <Paper sx={{ padding: 2 }}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={handleChange2}
            value={selectedValue}
          >
            <FormControlLabel
              value="Modifications"
              control={<Radio />}
              label="Modifications"
            />
            <FormControlLabel
              value="Création"
              control={<Radio />}
              label="Création"
            />
            <FormControlLabel
              value="Suppression"
              control={<Radio />}
              label="Suppression"
            />
          </RadioGroup>
        </FormControl>
        {selectedValue === "Création" && <CreationAbs />}
        {selectedValue === "Modifications" && <ModifAbs />}
        {selectedValue === "Suppression" && <DeleteAbs />}
      </Paper>
    </Container>
  );
};

export default Absence;
