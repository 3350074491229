import ReglementType from "../enums/ReglementType";

export default class Debour {
    public id: number = 0;
    public room: number = 0;
    public firstName: string = ''
    public lastName: string = '';
    public description: string = '';
    public prestataire: string = '';
    public sortieCaisse: number = 0;
    public reglement: ReglementType = ReglementType.SEE_PAYMENT;
    public timestamp: number = 0;

    constructor(
        room: number = 0,
        firstName: string = '',
        lastName: string = '',
        description: string = '',
        prestataire: string = '',
        sortieCaisse: number = 0,
        reglement: ReglementType = ReglementType.SEE_PAYMENT,
        timestamp: number = 0,
        id: number = 0
    ) {
        this.room = room;
        this.firstName = firstName;
        this.lastName = lastName;
        this.description = description;
        this.prestataire = prestataire;
        this.sortieCaisse = sortieCaisse;
        this.reglement = reglement;
        this.timestamp = timestamp;
        this.id = id;
    }
}