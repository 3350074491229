const monthStatus = [
  { day: 1, status: "available", tablesRemaining: 7 },
  { day: 2, status: "available", tablesRemaining: 12 },
  { day: 3, status: "Complet", tablesRemaining: 0 },
  { day: 4, status: "available", tablesRemaining: 10 },
  { day: 5, status: "Privatisé", tablesRemaining: -1 },
  { day: 6, status: "available", tablesRemaining: 12 },
  { day: 7, status: "available", tablesRemaining: 15 },
  { day: 8, status: "Fermé", tablesRemaining: -1 },
  { day: 9, status: "available", tablesRemaining: 15 },
  { day: 10, status: "available", tablesRemaining: 2 },
  { day: 11, status: "available", tablesRemaining: 5 },
  { day: 12, status: "Privatisé", tablesRemaining: -1 },
  { day: 13, status: "Groupe", tablesRemaining: -1 },
  { day: 14, status: "available", tablesRemaining: 12 },
  { day: 15, status: "Complet", tablesRemaining: 0 },
  { day: 16, status: "available", tablesRemaining: 12 },
  { day: 17, status: "Groupe", tablesRemaining: -1 },
  { day: 18, status: "Fermé", tablesRemaining: -1 },
  { day: 19, status: "available", tablesRemaining: 15 },

  { day: 23, status: "available", tablesRemaining: 15 },
  { day: 24, status: "Privatisé", tablesRemaining: -1 },
  { day: 25, status: "available", tablesRemaining: 2 },
  { day: 26, status: "available", tablesRemaining: 12 },
  { day: 27, status: "available", tablesRemaining: 15 },
  { day: 28, status: "Complet", tablesRemaining: 0 },
  { day: 29, status: "available", tablesRemaining: 15 },
  { day: 30, status: "available", tablesRemaining: 5 },
  { day: 31, status: "Fermé", tablesRemaining: -1 },
];

const timeSlotsData = [
  { time: "10:00", status: "available", tables: 10 },
  { time: "10:30", status: "available", tables: 10 },
  { time: "11:00", status: "full", tables: 0 },
  { time: "11:30", status: "few", tables: 2 },
  { time: "12:00", status: "available", tables: 10 },
  { time: "12:30", status: "few", tables: 10 },
  { time: "13:00", status: "available", tables: 10 },
  { time: "13:30", status: "available", tables: 10 },
  { time: "14:00", status: "full", tables: 10 },
  { time: "14:30", status: "few", tables: 10 },
];

const mailData = [
  {
    id: 1,
    name: "Alessandra Carminati",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla...",
    date: "14 Jan 2024",
    subject: "Objet",
    img: "https://img.freepik.com/free-vector/illustration-businesswoman_53876-5857.jpg?t=st=1705237563~exp=1705238163~hmac=78eac29a40d266c295ec0bb2a91d8e4e10ada67c32d59c3715f17ea8396b4a9c",
  },
  {
    id: 1,
    name: "Alessandra Carminati",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla......",
    date: "14 Jan 2024",
    subject: "Objet",
    img: "https://img.freepik.com/free-vector/illustration-businesswoman_53876-5857.jpg?t=st=1705237563~exp=1705238163~hmac=78eac29a40d266c295ec0bb2a91d8e4e10ada67c32d59c3715f17ea8396b4a9c",
  },
  {
    id: 1,
    name: "Alessandra Carminati",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla......",
    date: "14 Jan 2024",
    subject: "Objet",
    img: "https://img.freepik.com/free-vector/illustration-businesswoman_53876-5857.jpg?t=st=1705237563~exp=1705238163~hmac=78eac29a40d266c295ec0bb2a91d8e4e10ada67c32d59c3715f17ea8396b4a9c",
  },
  {
    id: 1,
    name: "Alessandra Carminati",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla......",
    date: "14 Jan 2024",
    subject: "Objet",
    img: "https://img.freepik.com/free-vector/illustration-businesswoman_53876-5857.jpg?t=st=1705237563~exp=1705238163~hmac=78eac29a40d266c295ec0bb2a91d8e4e10ada67c32d59c3715f17ea8396b4a9c",
  },
  {
    id: 1,
    name: "Alessandra Carminati",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla......",
    date: "14 Jan 2024",
    subject: "Objet",
    img: "https://img.freepik.com/free-vector/illustration-businesswoman_53876-5857.jpg?t=st=1705237563~exp=1705238163~hmac=78eac29a40d266c295ec0bb2a91d8e4e10ada67c32d59c3715f17ea8396b4a9c",
  },
  {
    id: 1,
    name: "Alessandra Carminati",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla......",
    date: "14 Jan 2024",
    subject: "Objet",
    img: "https://img.freepik.com/free-vector/illustration-businesswoman_53876-5857.jpg?t=st=1705237563~exp=1705238163~hmac=78eac29a40d266c295ec0bb2a91d8e4e10ada67c32d59c3715f17ea8396b4a9c",
  },
  {
    id: 1,
    name: "Alessandra Carminati",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla......",
    date: "14 Jan 2024",
    subject: "Objet",
    img: "https://img.freepik.com/free-vector/illustration-businesswoman_53876-5857.jpg?t=st=1705237563~exp=1705238163~hmac=78eac29a40d266c295ec0bb2a91d8e4e10ada67c32d59c3715f17ea8396b4a9c",
  },
  {
    id: 1,
    name: "Alessandra Carminati",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla......",
    date: "14 Jan 2024",
    subject: "Objet",
    img: "https://img.freepik.com/free-vector/illustration-businesswoman_53876-5857.jpg?t=st=1705237563~exp=1705238163~hmac=78eac29a40d266c295ec0bb2a91d8e4e10ada67c32d59c3715f17ea8396b4a9c",
  },
  {
    id: 1,
    name: "Alessandra Carminati",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla......",
    date: "14 Jan 2024",
    subject: "Objet",
    img: "https://img.freepik.com/free-vector/illustration-businesswoman_53876-5857.jpg?t=st=1705237563~exp=1705238163~hmac=78eac29a40d266c295ec0bb2a91d8e4e10ada67c32d59c3715f17ea8396b4a9c",
  },
  {
    id: 1,
    name: "Alessandra Carminati",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla......",
    date: "14 Jan 2024",
    subject: "Objet",
    img: "https://img.freepik.com/free-vector/illustration-businesswoman_53876-5857.jpg?t=st=1705237563~exp=1705238163~hmac=78eac29a40d266c295ec0bb2a91d8e4e10ada67c32d59c3715f17ea8396b4a9c",
  },
  {
    id: 1,
    name: "Alessandra Carminati",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla......",
    date: "14 Jan 2024",
    subject: "Objet",
    img: "https://img.freepik.com/free-vector/illustration-businesswoman_53876-5857.jpg?t=st=1705237563~exp=1705238163~hmac=78eac29a40d266c295ec0bb2a91d8e4e10ada67c32d59c3715f17ea8396b4a9c",
  },
];

export { monthStatus, timeSlotsData, mailData };
