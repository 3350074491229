import './Dashboard.css';

import GnzBreadcrumb from '../shared/gnz-breadcrumb/GnzBreadcrumb';
import GnzPaper from '../shared/gnz-paper/GnzPaper';

function View() {
    return (
        <div style={{fontFamily: 'Montserrat', fontSize: 14, fontWeight: 600}}>
            Dashboard
        </div>
    )
}

function Dashboard() {
    return (
        <div className="Dashboard">
            <GnzBreadcrumb breadcrumbs={['Conciergerie', 'Dasboard']}/>
            <GnzPaper component={<View />}/>
        </div>
    );
}

export default Dashboard;
