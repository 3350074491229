import './calendar.css';
import Modal from '@mui/material/Modal';

interface DayStatusProps {
    onClose: () => void;
    position: { top: number; left: number };
}

const DayStatus: React.FC<DayStatusProps> = ({ onClose, position }) => {
    const modalSx = {
        position: 'absolute',
        top: `${position.top}px`,
        left: `${position.left}px`,
        // transform: 'translate(-50%, -50%)',
    };

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={modalSx}
        >
            <div className='calendar-day-status'>
                <div className="calendar-day-status-row">
                    <span className='day-status-span' style={{ backgroundColor: "red" }}></span>
                    <h3>Fermé</h3>
                </div>
                <div className="calendar-day-status-row">
                    <span className='day-status-span' style={{ backgroundColor: "#f9951f" }}></span>
                    <h3>Complet</h3>
                </div>
                <div className="calendar-day-status-row">
                    <span className='day-status-span' style={{ backgroundColor: "#00d1ff" }}></span>
                    <h3>Privatisé</h3>
                </div>
                <div className="calendar-day-status-row" style={{ borderBottom: "none" }}>
                    <span className='day-status-span' style={{ backgroundColor: "#95e2af" }}></span>
                    <h3>Groupe</h3>
                </div>
            </div>
        </Modal>
    );
}

export default DayStatus;