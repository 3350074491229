import Modal from '@mui/material/Modal';
import LogoutIcon from '@mui/icons-material/Logout';
import { Periode } from '../types/types';
import { periodeService } from '../api/periodeService';
import { useState } from 'react';

interface AddDateProps {
    onClose: () => void;
    type: boolean;
    restaurantId: number | undefined;
    onNewPeriodeAdded: (newPeriode: Periode) => void;
}

const AddDate: React.FC<AddDateProps> = ({ onClose, type, restaurantId, onNewPeriodeAdded }) => {

    const initPeriode: Periode = {
        id: 0,
        dateDebutString: "",
        dateFinString: "",
        restaurantId: restaurantId || 0,
    };

    const [periode, setPeriode] = useState < Periode > (initPeriode);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setPeriode({ ...periode, [name]: value });
    };

    const handleSubmit = async () => {
        try {
            if (periode.dateDebutString === "") {
                alert("Veuillez renseigner une date de début");
                return;
            }
            if (periode.dateFinString === "" && type) {
                alert("Veuillez renseigner une date de fin");
                return;
            }
            if (type && periode.dateDebutString && periode.dateFinString) {
                if (periode.dateDebutString > periode.dateFinString) {
                    alert("La date de début doit être inférieure à la date de fin");
                    return;
                } else {
                    const newPeriode = await periodeService.postPeriode(periode);
                    onNewPeriodeAdded(newPeriode);
                    onClose();
                }
            }
        } catch (error) {
            console.error("Error adding periode:", error);
        }
    };

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='modal-restau-add'>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h1>Ajouter une nouvelle période</h1>
                    <LogoutIcon
                        style={{ color: "darkgray", fontSize: "36px", cursor: "pointer" }}
                        onClick={onClose}
                    />
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                    <div style={{ width: "48%", display: "flex", flexDirection: "column" }}>
                        <label htmlFor="dateDebut">Du</label>
                        <input type="date" name="dateDebutString" id="dateDebut" value={periode.dateDebutString} onChange={handleInputChange} />
                    </div>

                    {type && (
                        <div style={{ width: "48%", display: "flex", flexDirection: "column" }}>
                            <label htmlFor="dateFin">Au</label>
                            <input type="date" name="dateFinString" id="dateFin" value={periode.dateFinString || ""} onChange={handleInputChange} />
                        </div>
                    )}
                </div>
                <button className='restau-btn restau-modal-btn' onClick={handleSubmit}>
                    Ajouter
                </button>
            </div>
        </Modal>
    );
}

export default AddDate;