import MissionEventType from "../enums/MissionEventType";

export default class MissionEvent {
    public type: MissionEventType = MissionEventType.None;
    public user: string = '';
    public date: number = 0;
    public info: string = '';

    constructor(type: MissionEventType = MissionEventType.None, user: string = '', date: number = 0, info: string = '') {
        this.type = type;
        this.user = user;
        this.date = date;
        this.info = info;
    }
}