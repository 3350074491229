import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import LogoutIcon from '@mui/icons-material/Logout';
import { useState } from 'react';
import { Service } from '../types/types';
import { serviceRestauService } from '../api/serviceRestauService';

interface AddTimeProps {
    onClose: () => void;
    restaurantId: number | undefined;
    onNewServiceAdded: (newPeriode: Service) => void;
}

const AddTime: React.FC<AddTimeProps> = ({ onClose, restaurantId, onNewServiceAdded }) => {

    const initService: Service = {
        id: 0,
        type: "Non-Stop",
        index: 0,
        heureDebutString: "",
        heureFinString: "",
        restaurantId: restaurantId || 0,
    };

    const [service, setService] = useState < Service > (initService);

    const handleServiceTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setService({ ...service, type: event.target.value });
    };

    const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>, isStartTime: boolean) => {
        if (isStartTime) {
            const heureDebut = event.target.value;
            setService({...service, heureDebutString: heureDebut});
        } else {
            const heureFin = event.target.value;
            setService({...service, heureFinString: heureFin});
        }
    };

    const handleSubmit = async () => {
        try {
            if (service.heureDebutString === "" || service.heureFinString === "" || service.heureDebutString >= service.heureFinString) {
                alert("Veuillez renseigner une heure de début et de fin valide");
                return;
            }
            
            const newService = await serviceRestauService.postService(service);
            onNewServiceAdded(newService);
            onClose();
        } catch (error) {
            console.error("Error adding service:", error);
        }
    };

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='modal-restau-add'>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h1>Ajouter un nouvel horaire</h1>
                    <LogoutIcon
                        style={{ color: "darkgray", fontSize: "36px", cursor: "pointer" }}
                        onClick={onClose}
                    />
                </div>

                <RadioGroup
                    row
                    className='radio-restau'
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={service.type} 
                    onChange={handleServiceTypeChange}
                >
                    <FormControlLabel value="Non-Stop" control={<Radio className="custom-radio" />} label="Non-Stop" />
                    <FormControlLabel value="Déjeuner" control={<Radio className="custom-radio" />} label="Déjeuner" />
                    <FormControlLabel value="Dîner" control={<Radio className="custom-radio" />} label="Dîner" />
                </RadioGroup>

                <div style={{ display: "flex", justifyContent: "space-around", marginTop: "20px" }}>
                    <div style={{ width: "30%", display: "flex", flexDirection: "column" }}>
                        <label htmlFor="startTime">Du</label>
                        <input type="time" id="startTime" onChange={(e) => handleTimeChange(e, true)} />
                    </div>

                    <div style={{ width: "30%", display: "flex", flexDirection: "column" }}>
                        <label htmlFor="endTime">Au</label>
                        <input type="time" id="endTime" onChange={(e) => handleTimeChange(e, false)} />
                    </div>
                </div>
                <button className='restau-btn restau-modal-btn' onClick={handleSubmit}>
                    Ajouter
                </button>
            </div>
        </Modal>
    );
}

export default AddTime;