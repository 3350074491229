import Modal from '@mui/material/Modal';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LogoutIcon from '@mui/icons-material/Logout';
import { useEffect, useState } from 'react';
import UpdateTable from './updateTable';
import { Table } from '../types/types';
import { tableService } from '../api/tableService';

interface TablesListProps {
    onClose: () => void;
    restaurantId: number | undefined;
}

const TablesList: React.FC<TablesListProps> = ({ onClose, restaurantId }) => {

    const [tables, setTables] = useState < Table[] > ([]);

    useEffect(() => {
        const fetchTables = async () => {
            try {
                const tables = await tableService.getTables(restaurantId || 0);
                setTables(tables);
            }
            catch (error) {
                console.error("Error fetching tables:", error);
            }
        };
        fetchTables();
    }, [restaurantId]);

    const handleUpdateTable = (updatedTable: Table) => {
        if (tables) {
            const updatedTables = tables.map((table) => {
                if (table.id === updatedTable.id) {
                    return updatedTable;
                }
                return table;
            });

            setTables(updatedTables);
        }
    };

    const [selectedTable, setSelectedTable] = useState < Table | null > (null);
    const [showUpdateTableModal, setUpdateTableModal] = useState(false);

    const openUpdateTableModal = (table: Table) => {
        setSelectedTable(table);
        setUpdateTableModal(true);
    };

    const closeUpdateTableModal = () => {
        setUpdateTableModal(false);
        setSelectedTable(null);
    };

    const handleDeleteTable = async (tableId: number) => {
        try {
            await tableService.deleteTable(tableId);
            const updatedTables = tables.filter((table) => table.id !== tableId);
            setTables(updatedTables);
        }
        catch (error) {
            console.error("Error deleting table:", error);
        }
    };

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='modal-restau-tables'>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h1>Toute les tables</h1>
                    <LogoutIcon
                        style={{ color: "darkgray", fontSize: "36px", cursor: "pointer" }}
                        onClick={onClose}
                    />
                </div>

                <div className='restau-table-container'>
                    {
                        tables.map((table, index) => (
                            <div className="restau-table-row" key={index} style={{ padding: "2px 5px" }}>
                                <h4>Table {table.numero} - {table.nbPersonne} Personnes</h4>
                                <div>
                                    <EditNoteIcon
                                        style={{ fontSize: "25px", marginRight: "3px", cursor: "pointer", color: "#134563" }}
                                        onClick={() => openUpdateTableModal(table)}
                                    />
                                    <DeleteForeverIcon
                                        style={{ fontSize: "25px", cursor: "pointer", color: "#134563" }}
                                        onClick={() => handleDeleteTable(table.id)}
                                    />

                                    {
                                        showUpdateTableModal &&
                                        <UpdateTable
                                            onClose={closeUpdateTableModal}
                                            initTable={selectedTable}
                                            onUpdate={handleUpdateTable}
                                        />
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </Modal>
    );
}

export default TablesList;