import * as React from 'react';

import Grid from '@mui/material/Grid';
import { Card, CardContent,Link, Input, Button, InputAdornment, TextField, Typography, InputLabel} from '@mui/material';
import {Paper, Accordion, AccordionSummary,AccordionDetails} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import  Select,{ SelectChangeEvent }   from '@mui/material/Select';
import  MenuItem from '@mui/material/MenuItem'
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import './Transfer.css'
import Email from '../Email/Email';

const apiUrl = window.location.hostname === 'localhost' ? 'https://localhost:7142/api' : '/api';

const { useState } = React;
const SharingButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  width: '259px',
  height: '50px',
  fontSize: 16,
  fontWeight:'bold',
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: '#1D62AF',
  borderColor: '#1D62AF',
  fontFamily: 'Montserrat',
  '&:hover': {
    backgroundColor: '#0069d9',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});

///////////////////////////////////////////////////////////////////////////////
// Some Data
///////////////////////////////////////////////////////////////////////////////

interface UpdateTransferProps {
  onCloseDrawerUpdate: () => void;
  transferId:any;
}

const UpdateTransfer: React.FC<UpdateTransferProps> = ({ onCloseDrawerUpdate,transferId }) => {

//get id for current provider
const {id}=useParams() 
// Select type of transfer
const [selectedOption, setSelectedOption] = useState(null);
//Calculate Price
const [price, setPrice] = useState({ 
    HighSeasonPrice:0,
    NightPrice:0,
    TotalPrice:0
})
const [statePaiement, setStatePaiement]= useState(false);


///////////////////////////////////////////////////////////////////////////////
// Data form
///////////////////////////////////////////////////////////////////////////////
const [formData, setFormData] = useState({ 
    LastName: null,
    FirstName: null,
    Room:null,
    PhoneNumber:null,
    Email:null,
    TransferDate: 0,
    TransferTime: 0,
    TransferType: null,
    NumberOfCars: null,
    ArrivalTime: null,
    EndTime: null,
    TransportDetail: null,
    Price: 0 as number | string,
    Statut: null,
    Paiement: null,
    TransfertNote: null,
    NumberOfLuggage: null,
    NumberOfPassenger: null,
    SuppInformation: null,
    Arrival:null,
    Car:null,
    Name:null,
    Departure: null,
    NumberOfHours:null,
    CurrentClientId:null,
    CurrentProviderId:null,
    id:'',
    Statut_email:'modification',
    Deleted:0,

  });
  const handleInputChange = async(event:any) => {
    // Store data
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  }

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//Get data transfer ///////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const [previousData, setPreviousData] = useState<any[]>([]); 
const [cars, setCars] = useState([]);
const [hoursDispo, sethoursDispo] = useState<any[]>([]); 

//display stored data
useEffect(() => {
  const getData = async () => {
    try {
      await axios.get(`${apiUrl}/Transfer/GetTransfer/`+transferId,{ headers: { "Content-Type": "application/json" } } )
      .then(response => {
        console.log("response.data") 
        console.log(response.data)
        //Get data from Transfer Table
        formData.LastName=response.data[0].lastName,
        formData.FirstName=response.data[0].firstName ,
        formData.Room=response.data[0].room,
        formData.PhoneNumber=response.data[0].phoneNumber,
        formData.TransferDate=response.data[0].transferDate,
        formData.TransferTime=response.data[0].transferTime,
        formData.TransferType=response.data[0].transferType,
        formData.NumberOfCars=response.data[0].numberOfCars,
        formData.ArrivalTime=response.data[0].arrivalTime,
        formData.EndTime=response.data[0].endTime,
        formData.TransportDetail=response.data[0].transportDetail,
        formData.Price=response.data[0].price,
        formData.Statut=response.data[0].statut,
        formData.Paiement=response.data[0].paiement,
        formData.TransfertNote=response.data[0].transfertNote,
        formData.NumberOfLuggage=response.data[0].numberOfLuggage,
        formData.NumberOfPassenger=response.data[0].numberOfPassenger,
        formData.SuppInformation=response.data[0].suppInformation,
        formData.Arrival=response.data[0].arrival,
        formData.Car=response.data[0].car,
        formData.Name=response.data[0].name,
        formData.Departure= response.data[0].departure,
        formData.NumberOfHours=response.data[0].numberOfHours,
        formData.CurrentClientId=response.data[0].currentClientId,
        formData.CurrentProviderId=response.data[0].currentProviderId,
        formData.id=transferId?? '',
        email[0]=response.data[0].email,
        //Data to display in form :
        setPreviousData(response.data); 
        });
    } catch (error) {
      console.error("Error submitting form :", error);
    }   
    }
  getData();
}, []);

useEffect(() => {
  const fetchCars = async () => {
    if (previousData.length != 0){
      try {
      const response = await axios.get(`${apiUrl}/Transfer/GetCars/${previousData[0].name}`);
      //cars to dispaly in dropdown:
      setCars(response.data)
      console.log("response.data cars")
      console.log(response.data)
      }catch (error) {
        console.error("Error submitting form :", error);
      }  
    }
  }
fetchCars();
}, [previousData]);


useEffect(()=>{
  const fetchHoursDispo = async () => {
    if (formData.TransferType == 'Disposition') {
      if (previousData.length != 0){
        try {
        const response = await axios.get(`${apiUrl}/Transfer/GetHoursDispo/${previousData[0].departure}&${previousData[0].arrival}&${previousData[0].name}&${previousData[0].car}`);
        //Available hours dispo to display in dropdown
        sethoursDispo(response.data)
        console.log("response.data hours")
        console.log(response.data)
        }catch (error){
          console.error("Error submitting form: ",error)
        }
      }
    }
  }
  fetchHoursDispo();
},[cars])

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dependent Drop Down 'Arrivée-Départ': Departure-Arrival-Car-Provider
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const handleCarChange = async (event:any) => {
  const car = event.target.value;
  formData.Car=car;

};

const handleHourDispoChange = async (event:any) => {
  const hour = event.target.value;
  formData.NumberOfHours=hour;

}

const handlePaiementChange = async (event:any) => {
  // get the 'paiement' type
  const paiement=event.target.value;
  formData.Paiement=paiement
  //If the paiement is changed to compte direction
  if (paiement == "Compte direction") setStatePaiement(true) 
    else setStatePaiement(false); 

}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//Popup
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
const [selectedTransfer, setSelectedTransfer] = useState(null);
const handleOpenEmailModal = () => {
  setIsEmailModalOpen(true);

};

const handleCloseEmailModal = () => {
  setIsEmailModalOpen(false);
  setSelectedTransfer(null);
  onCloseDrawerUpdate();
};
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Submit: 
const [email, setEmail] = useState<any[]>([]);

const handleSubmit = async (event:any) => {
 // Prevent default form submission
 event.preventDefault(); 
///////////////////////////////////////////////  
//Calculate the new price after the updates:
///////////////////////////////////////////////
if ((formData.Paiement =='En direct') || (formData.Paiement =='Sur chambre')){
  ////////////////////////////////
  // -----> 'Arrivée' or 'Départ'
  ////////////////////////////////
  if ((formData.TransferType == "Départ" ) || (formData.TransferType == "Arrivée")) 
  {
    //Get Required Data
    const response = await axios.get(`${apiUrl}/Transfer/UpdatePriceTrip/${formData.Departure}&${formData.Arrival}&${formData.Car}&${formData.Name}`);
    //Highsaison
    if 
    ((`${new Date(formData.TransferDate).getMonth()}-${new Date(formData.TransferDate).getDate()}`) >= (`${new Date(response.data[0].startHighSeason).getMonth()+1}-${new Date (response.data[0].startHighSeason).getDate()}`) 
      && 
      (`${new Date(formData.TransferDate).getMonth()}-${new Date(formData.TransferDate).getDate()}`) <= (`${new Date(response.data[0].endHighSeason).getMonth()+1}-${new Date(response.data[0].endHighSeason).getDate()}`))
    {
      price.HighSeasonPrice=response.data[0].highSeasonCharge
    }
    else price.HighSeasonPrice=0
    //Night price
    if (formData.TransferTime >= response.data[0].hStartNight && formData.TransferTime <= response.data[0].hEndNight)
    {
      price.NightPrice=response.data[0].nightRate
    }
    else price.NightPrice=0
    //Total price
    formData.Price=Number(response.data[0].price)+Number(price.HighSeasonPrice)+Number(price.NightPrice)

  }
  ////////////////////////////////
  // -----> 'Disposition'
  ///////////////////////////////
  if (previousData[0].TransferType == "Disposition" )
  {
    //Get Required Data
    const response = await axios.get(`${apiUrl}/Transfer/UpdatePriceDispo/${formData.Departure}&${formData.Arrival}&${formData.Car}&${formData.Name}&${formData.NumberOfHours}`);
    console.log(response.data)
    //Total price
    formData.Price=Number(response.data[0].disposalCharge)+Number(response.data[0].price)
    console.log('formData.Price')
    console.log(formData.Price)
  }
}
//The other paiement cases: 
if ( (formData.Paiement == 'Gratuité') || ( formData.Paiement == 'Hôtel transfert') ){formData.Price=0}
if (formData.Paiement == 'Compte direction') {formData.Price=formData.Price}

//Conversion
formData.Price=String(formData.Price)

try{
  //Put transfer
  await axios.put(`${apiUrl}/transfer/UpdateTransfer`, formData, { headers: { "Content-Type": "application/json" }});
}catch (error) {
  console.error("Error submitting form Update Transfer:", error);
}
// After successfully submitting the form
handleOpenEmailModal();

}

return(
<>
{previousData.map((item,i) => (  

<Card style={{ maxWidth: 550, padding: "20px 5px", margin: "0 auto", overflowY: 'auto', flexGrow: 1  }} key={i}>
  <label className='titleCard' style={{fontWeight:'bold'}}> &nbsp;&nbsp;Modifier une réservation -{item.transferType}- </label>
  <form onSubmit={handleSubmit}> 
    <CardContent> 
     {/*Blue Card: Transfert  */}
    <Accordion  sx={{ backgroundColor: '#1D62AF1A'}}>
      <AccordionSummary  expandIcon={<ExpandMoreIcon />}>  
        <Typography sx={{ width: '40%', flexShrink:0,fontWeight:'bold'}}>Transfert</Typography>
        <Grid className='line' ></Grid>
      </AccordionSummary>
      
      <AccordionDetails style={{ maxWidth: 650, padding: "20px 5px", margin: "0 auto" }}>
        <Grid container spacing={1}>
          <Grid item xs={4} md={4}>
            <label className='FontLabel'  >Nom*</label >
            <TextField required size='small' value={item.firstName} type="text" className='StyleInput' disabled/>
          </Grid>
          <Grid item xs={4} md={4}>
            <label className='FontLabel'  >Prénom*</label >
            <TextField required size='small' value={item.lastName}   type="text" className='StyleInput' disabled/>
          </Grid>
          <Grid item xs={4} md={4}>
            <label className='FontLabel'  >Chambre</label >
            <TextField size='small' value={item.room}   type="text"  className='StyleInput' disabled/>
          </Grid>

          <Grid item xs={12} md={12} className='line'></Grid>
          <Grid item xs={4} md={4}>
              <label className='FontLabel'  >Téléphone Client</label >
              <TextField  size='small' name="PhoneNumber" defaultValue={item.phoneNumber} onChange={handleInputChange}  type="text" placeholder=""  className='StyleInput' disabled/>
          </Grid>
          <Grid item xs={4} md={4}>
              <label className='FontLabel'  >Date*</label >
              <TextField required size='small' name="TransferDate" defaultValue={item.transferDate} onChange={handleInputChange}  type="date" placeholder=""  className='StyleInput' fullWidth/>
          </Grid>
          <Grid item xs={4} md={4}>
              <label className='FontLabel'  >Heure*</label ><br/>
              <TextField required size='small' name="TransferTime" defaultValue={item.transferTime}  onChange={handleInputChange}  type="time" placeholder=""  className='StyleInput' fullWidth/>
          </Grid>


          {/* IF 'Arrivée' or 'Départ' checked */}
          {(item.transferType == 'Départ' || item.transferType == 'Arrivée' )
          &&
          <>
             <Grid item xs={4} md={4}>
              <label className='FontLabel' >Paiement</label ><br/>
              <Select  variant="outlined" name="Paiement" defaultValue={item.paiement}   onChange={handlePaiementChange} className='StyleInput' size='small' fullWidth >
                <MenuItem value={"En direct"}>En direct</MenuItem>
                <MenuItem value={"Sur chambre"}>Sur chambre</MenuItem>
                <MenuItem value={"Compte direction"}>Compte direction</MenuItem>
                <MenuItem value={"Gratuité"}>Gratuité</MenuItem>
                <MenuItem value={"Hôtel transfert"}>Hôtel transfert</MenuItem>
              </Select>
            </Grid>
             <Grid item xs={4} md={4}>
              <label className='FontLabel'  >Depuis</label >
              <TextField required size='small'  value={item.departure}  type="text" className='StyleInput' fullWidth disabled/>
            </Grid>
            <Grid item xs={4} md={4} >
              <label className='FontLabel'  >A&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label >
              <TextField required size='small'  value={item.arrival}  type="text" className='StyleInput' fullWidth disabled/>
            </Grid>
            <Grid item xs={4} md={4}>
              <label className='FontLabel'  >Véhicule</label ><br/>        
              <Select variant="outlined" className='StyleInput' defaultValue={item.car}  size='small' onChange={handleCarChange}  fullWidth>
                {
                  (cars != null )
                  ? 
                  (
                    cars.map((car, index) => (
                      <MenuItem key = {index} value={car}>{car}</MenuItem>
                    ))
                  ) 
                  : 
                  (
                    <MenuItem></MenuItem>
                  )
                }
              </Select>
            </Grid> 
            <Grid item xs={4} md={4}  >
              <label className='FontLabel'  >Compagnie</label ><br/>
              <TextField required size='small'  value={item.name}  type="text" className='StyleInput' fullWidth disabled/>
            </Grid>
            { ((formData.Paiement == "Compte direction") || (statePaiement == true))
            ?
            <Grid item xs={4} md={4}>
              <label className='FontLabel'  >Prix* </label ><br/>
              <TextField required size='small' name="Price" onChange={handleInputChange} defaultValue={item.price} type="text"  className='StyleInput' />
            </Grid>
            :
            <Grid item xs={4} md={4}>

            </Grid>
            }

          {/* End IF 'Arrivée' or 'Départ' checked */}
          </>}

          {(item.TransferType == 'Disposition')
          &&
          <>
          {/* IF 'Disposition' checked  */}
          <Grid item xs={4} md={4}>
            <label className='FontLabel' >Paiement</label ><br/>
            <Select  variant="outlined" name="Paiement" defaultValue={item.paiement}  onChange={handlePaiementChange} className='StyleInput' size='small' fullWidth >
              <MenuItem value={"En direct"}>En direct</MenuItem>
              <MenuItem value={"Sur chambre"}>Sur chambre</MenuItem>
              <MenuItem value={"Compte direction"}>Compte direction</MenuItem>
              <MenuItem value={"Gratuité"}>Gratuité</MenuItem>
              <MenuItem value={"Hôtel transfert"}>Hôtel transfert</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={4} md={4}>
            <label className='FontLabel'  >Depuis</label >
            <TextField required size='small'  value={item.departure}  type="text" className='StyleInput' fullWidth disabled/>
          </Grid>
          <Grid item xs={4} md={4} >
            <label className='FontLabel'  >A&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label >
            <TextField required size='small'  value={item.arrival}  type="text"   className='StyleInput' fullWidth disabled/>
          </Grid>
          <Grid item xs={3} md={3}>
            <label className='FontLabel'  >Véhicule</label ><br/>   
            <Select variant="outlined" className='StyleInput' size='small' defaultValue={item.car || " "}  onChange={handleCarChange}  fullWidth>
                {
                  (cars != null )
                  ? 
                  (
                    cars.map((car, index) => (
                      <MenuItem key = {index} value={car}>{car}</MenuItem>
                    ))
                  ) 
                  : 
                  (
                    <MenuItem></MenuItem>
                  )
                }
            </Select>
          </Grid>
          <Grid item xs={3} md={3}>
            <label className='FontLabel'  >Heure de disposition</label >
            {/* <TextField required size='small'  value={item.NumberOfHours}  type="text"   className='StyleInput' fullWidth/> */}
            <Select variant="outlined" className='StyleInput' size='small' defaultValue={item.numberOfHours || " "} onChange={handleHourDispoChange}  fullWidth>
                {
                  (hoursDispo != null )
                  ? 
                  (
                    hoursDispo.map((hour, index) => (
                      <MenuItem key = {index} value={hour.numberOfHours}>{hour.numberOfHours}</MenuItem>
                    ))
                  ) 
                  : 
                  (
                    <MenuItem></MenuItem>
                  )
                }
            </Select>     
          </Grid>
          <Grid item xs={4} md={4}  >
            <label className='FontLabel'  >Compagnie</label >
            <TextField required size='small'  value={item.name}  type="text"   className='StyleInput' fullWidth disabled/>
          </Grid>
          {((formData.Paiement == "Compte direction") || (statePaiement == true ))
          ?
          <Grid item xs={2} md={2}>
            <label className='FontLabel'  >Prix* </label ><br/>
            <TextField required size='small' name="Price" onChange={handleInputChange} defaultValue={item.price} type="text" className='StyleInput' />
          </Grid>
          :
          <></>
          }
          {/* End IF 'Disposition' checked */}
          </> 
          }
          <Grid item xs={4} md={4}>
              <label className='FontLabel'  >Nombre de véhicule*</label >
              <TextField required size='small' name="NumberOfCars" defaultValue={item.numberOfCars}  onChange={handleInputChange} type="number" placeholder=""  className='StyleInput' />
          </Grid>
          <Grid item xs={4} md={4}>
              <label className='FontLabel'  >Heure arrivée à l'hôtel</label >
              <TextField  size='small' name="ArrivalTime" defaultValue={item.arrivalTime} onChange={handleInputChange} type="time" placeholder=""  className='StyleInput' fullWidth/>
          </Grid>
          <Grid item xs={4} md={4}>
              <label className='FontLabel'  >Heure de fin</label >
              <TextField  size='small' name="EndTime" defaultValue={item.endTime} onChange={handleInputChange}  type="time" placeholder=""  className='StyleInput' fullWidth/>
          </Grid>
          <Grid item xs={6} md={6}>
              <label className='FontLabel'  >N° du vol - train / bateau / bus</label >
              <TextField  size='small' name="TransportDetail" defaultValue={item.transportDetail} onChange={handleInputChange}  type="text" placeholder=""  className='StyleInput' fullWidth/>
          </Grid>
          <Grid item xs={6} md={6}>
            <label className='FontLabel' >Statut*</label ><br/>
            <Select required variant="outlined" name="Statut" defaultValue={item.statut} onChange={handleInputChange} className='StyleInput' size='small' fullWidth>
              <MenuItem value={"Confirmée"}>Confirmée</MenuItem>
              <MenuItem value={"Annulée"}>Annulée</MenuItem>
              <MenuItem value={"En attente de confirmation"}>En attente de confirmation</MenuItem>
              <MenuItem value={"Attente de carte bancaire"}>Attente de carte bancaire</MenuItem>
              <MenuItem value={"A faire"}>A faire</MenuItem>
            </Select>
          </Grid>
      </Grid>
      </AccordionDetails>
    </Accordion>

    <br/>

    {/*Blue Card: Préference Transfert  */}
    <Accordion  sx={{ backgroundColor: '#CD11110D'}}>
      <AccordionSummary  expandIcon={<ExpandMoreIcon />}>  
        <Typography sx={{ width: '40%', flexShrink:0,fontWeight:'bold'}}>Préference Transfert</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ maxWidth: 650, padding: "20px 5px", margin: "0 auto" }}>
      <Grid container spacing={1}> 
        <Grid item xs={12} md={12}> 
          <TextField  size='small' name="TransfertNote" defaultValue={item.transfertNote} onChange={handleInputChange} type="text" placeholder=""  className='StyleInput' fullWidth/>
        </Grid>
        <Grid item xs={6} md={6}>  
          {/* <label className='FontLabel'  >Nbr. de bagages</label > */}
          <label className='FontLabel'>Nbr. de bagage</label>
          <TextField  size='small' name="NumberOfLuggage"  defaultValue={item.numberOfLuggage}  onChange={handleInputChange} type="number" placeholder=""  className='StyleInput'/> 
        </Grid>
        <Grid item xs={6} md={6}>
          <label className='FontLabel'  >Nbr. de passagers</label>
          <TextField  size='small' name="NumberOfPassenger"  defaultValue={item.numberOfPassenger}  onChange={handleInputChange}  type="number" placeholder=""  className='StyleInput' fullWidth />
        </Grid>
      </Grid>
      </AccordionDetails>
    </Accordion>

    <br/>

    {/*Blue Card: Information supplémentaire  */}
    <Accordion  sx={{ backgroundColor: '#A0E29533'}}>
      <AccordionSummary  expandIcon={<ExpandMoreIcon />}>  
        <Typography sx={{ width: '50%', flexShrink:0,fontWeight:'bold'}}>Information supplémentaire</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ maxWidth: 650, padding: "20px 5px", margin: "0 auto" }}>
      <TextField size='small' name="SuppInformation" defaultValue={item.suppInformation} onChange={handleInputChange} type="text" placeholder=""  className='StyleInput' fullWidth />
      </AccordionDetails>
    </Accordion>
    </CardContent>

  <SharingButton type="submit" variant="contained" className='btn'>Modifier</SharingButton>
  </form>
 <Email formData={formData} email={email} previousData={previousData} onClose={handleCloseEmailModal} isOpen={isEmailModalOpen} />
</Card>

))} 
</>
);
}
export default UpdateTransfer

