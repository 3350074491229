import './To.css';

import GnzBreadcrumb from '../shared/gnz-breadcrumb/GnzBreadcrumb';
import GnzPaper from '../shared/gnz-paper/GnzPaper';
import ToDay from './to-day/ToDay';

function To() {
  return (
    <div className="To">
      <GnzBreadcrumb breadcrumbs={['Rapport', 'To du jour']}/>
      <GnzPaper component={<ToDay />}/>
    </div>
  );
}

export default To;
