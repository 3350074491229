import { useEffect, useState } from 'react';
import GnzButton from '../../shared/gnz-button/GnzButton';
import { DatePickerProps } from 'antd';
import Feedback from '../../../models/Feedback';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Checkbox } from 'antd';
import './FeedbackForm.css';
import FeedbackStatus from '../../../enums/ConsigneStatus';
import FeedbackServices from '../../../enums/FeedbackServices';
import Status from '../../../models/Status';
import ServicesItems from '../../../models/ServicesItems';
import StatusClient from '../../../models/StatusClient';
import DuplicateDay from '../../../enums/DuplicateDay';
import FormMode from '../../../enums/FormMode';
import moment from 'moment';
import FeedbackEventType from '../../../enums/ConsigneEventType';
import FeedbackEvent from '../../../models/ConsigneEvent';
import axios from 'axios';

interface IFeedbackForm {
  mode: FormMode,
  currentDate: number,
  title: string,
  closeForm: () => void,
  feedback: Feedback,
  submit: (feedback: Array<Feedback>) => void
  submitLabel: string,
  submitColor: string,
  customerId: string,
}

function FeedbackForm(props: IFeedbackForm) {
  const [id, setId] = useState(0);
  const [title, setTitle] = useState('');
  const [comment, setComment] = useState('');
  const [resolution, setResolution] = useState('');
  const [status, setStatus] = useState(FeedbackStatus.None);
  const [service, setservice] = useState(FeedbackServices.None);
  const [timestamp, setTimestamp] = useState(0);
  const [events, setEvents] = useState<Array<FeedbackEvent>>([]);
  const [history, setHistory] = useState('');
  const [duplicate1, setDuplicate1] = useState(false);
  const [duplicate1Timestamp, setDuplicate1Timestamp] = useState(0);
  const [duplicate2, setDuplicate2] = useState(false);
  const [duplicate2Day, setDuplicate2Day] = useState(DuplicateDay.One);
  const [StatusFilter, Status] = useState<Array<Status>>([]);
  const [ServicesItemsFilter, ServicesItems] = useState<Array<ServicesItems>>([]);
  const [StatusClientFilter, StatusClient] = useState<Array<StatusClient>>([]);
  const [StatusClientImageID, StatusClientIDImage] = useState('');
  const Url = 'https://www.mysharingsystem.com/api/';

  useEffect(() => {
    setDuplicate1Timestamp(props.currentDate);
    if (props.feedback.id) {
      setId(props.feedback.id);
      setTitle(props.feedback.title);
      setComment(props.feedback.comment);      
      setStatus(props.feedback.status);
      setservice(props.feedback.service);
      setResolution(props.feedback.solution);
      setTimestamp(props.feedback.timestamp);
      setEvents(props.feedback.events);
      StatusClientIDImage(props.feedback.statusClient);
    }
    buildHistory();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    axios.get(Url + 'Status') //https://localhost:7142/api
      .then(res => Status(res.data));
  }, [])

  useEffect(() => {
    axios.get(Url + 'ServicesItems')
      .then(res => ServicesItems(res.data));
  }, [])

  useEffect(() => {
    axios.get(Url + 'StatusClient')
      .then(res => StatusClient(res.data));
  }, [])

  const buildHistory = () => {
    let newHistory = '';
    let newHistoryPrefix = '';

    for (const event of props.feedback.events.sort((e1, e2) => e1.date - e2.date)) {
      switch (event.type) {
        case FeedbackEventType.Create:
          newHistoryPrefix = `Ecrite par `;
          break;
        case FeedbackEventType.Wait:
          newHistoryPrefix = `En attente par `;
          break;
        case FeedbackEventType.Process:
          newHistoryPrefix = `En cours par `;
          break;
        case FeedbackEventType.Cancel:
          newHistoryPrefix = `Annulée par `;
          break;
        case FeedbackEventType.Done:
          newHistoryPrefix = `Terminée par `;
          break;
        default:
          newHistoryPrefix = '';
          break;
      }

      newHistory += newHistoryPrefix + `${event.user} le ${moment(event.date).format('DD-MM-YYYY')} à ${moment(event.date).format('HH')}h${moment(event.date).format('mm')}\n\n`;
    }

    setHistory(newHistory);
  }

  const submit = () => {
    if (title.length && comment.length && status !== FeedbackStatus.None && service !== FeedbackServices.None) {
      const newFeedbacks: Array<Feedback> = [];

      newFeedbacks.push(new Feedback(
        title,
        status,
        service,
        timestamp || props.currentDate,
        events,
        id
      ));

      if (duplicate1 && duplicate1Timestamp !== props.currentDate) {
        newFeedbacks.push(new Feedback(title, status, service, duplicate1Timestamp))
      }

      if (duplicate2) {
        for (let day = 1; day <= Number(duplicate2Day); day++) {
          const newDate = new Date(props.currentDate);
          newDate.setDate(newDate.getDate() + day);
          if (!newFeedbacks.find(c => c.timestamp === newDate.getTime())) {
            newFeedbacks.push(new Feedback(
              title,
              status,
              service,
              newDate.getTime(),
              events
            ));
          }
        }
      }
      props.submit(newFeedbacks);
    }
  }

  const onChangeDuplicate1Timestamp: DatePickerProps['onChange'] = (date: any) => {
    date.$d = new Date(`${date.$d.getFullYear()}-${date.$d.getMonth() + 1}-${date.$d.getDate()}`)
    date.$d.setHours(2);
    setDuplicate1Timestamp(date.$d.getTime());
  };

  const handleChange = () => {
    axios.post('https://localhost:7142/api/Feedback', {
      "customerId":  props.customerId,
      "comment": comment,
      "idStatus": 1,
      "idServicesItems": 1,
      "solution": resolution,
      "idStatusClient": 1,
      "createDate": "2023-12-28T12:12:13.931Z",
      "updateDate": "2023-12-28T12:12:13.931Z"
    })
      .then();
  };

  return (
    <div className="FeedbackForm">
      <div className="FeedbackFormTitle">{props.title}</div>
      <div className="FeedbackFormClose" onClick={props.closeForm}>X</div>
      <div className="PrimaryHeader">
        <div className="PrimaryHeaderSeparator"></div>
      </div>
      <div className="FeedbackFormInputTitle">Commentaire</div>
      <textarea className='FeedbackFormTextarea' value={comment} onChange={(e) => setComment(e.target.value)} spellCheck={false}></textarea>
      <div className="FeedbackFormInputTitle">Status</div>
      <select className="FeedbackFormSelect" value={status} onChange={(e) => setStatus(e.target.value as unknown as FeedbackStatus)}>
        <option value={0}> </option>
            {StatusFilter.map(items => (
              <option value={items.idStatus}>{items.statusName}</option>
            ))}
      </select>
      <div className="FeedbackFormInputTitle">Service</div>
      <select className="FeedbackFormSelect" value={service} onChange={(e) => setservice(e.target.value as unknown as FeedbackServices)}>
        <option value={0}> </option>
        {ServicesItemsFilter.map(items => (
              <option value={items.idServicesItems}>{items.servicesName}</option>
            ))}
      </select>
      <div className="FeedbackFormInputTitle">Résolution</div>
      <textarea className='FeedbackFormTextarea' value={resolution} onChange={(e) => setResolution(e.target.value)} spellCheck={false}></textarea>
      <div className="FeedbackFormInputTitle">Status Client</div>
      {/* <select className="FeedbackFormSelect" value={service} onChange={(e) => StatusClientIDImage(e.target.value)}>
        <option value={FeedbackServices.None}></option>     
        {StatusClientFilter.map(items => (
              <option value={items.idStatusClient}>              
              <img className="TagLogo" src={`data:image/jpeg;base64,${items.statusClientImage}`} alt="" /> src={`data:image/jpeg;base64,${items.statusClientImage}`}
              </option>              
            ))}
      </select> */}

      {/* <div>
      {StatusClientFilter.map(items => (
                      
                      <img className="TagLogo" src={`data:image/jpeg;base64,${items.statusClientImage}`} alt="" />       
                    ))}
      </div> */}

      <div>
      {StatusClientFilter.map(items => (
              <p><Checkbox value={items.idStatusClient}/> 
              <img className="TagLogo" src={`data:image/jpeg;base64,${items.statusClientImage}`} alt="" />    
              </p>
            ))}
      </div>   
     

      <div className="FeedbackFormActions">
        <DialogActions>
          <GnzButton label="Confirmer" onClick={handleChange}  />        
        </DialogActions>
      </div>
    </div>
  );
}

export default FeedbackForm;
