import './Consignes.css';

import GnzBreadcrumb from '../shared/gnz-breadcrumb/GnzBreadcrumb';
import GnzPaper from '../shared/gnz-paper/GnzPaper';

function View() {
    return (
        <div style={{fontFamily: 'Montserrat', fontSize: 14, fontWeight: 600}}>
            Consignes
        </div>
    )
}

function Consignes() {
    return (
        <div className="Consignes">
            <GnzBreadcrumb breadcrumbs={['Consignes', 'Consignes']}/>
            <GnzPaper component={<View />}/>
        </div>
    );
}

export default Consignes;
