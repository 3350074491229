import { useEffect, useRef, useState } from 'react';
import FeedbackTableItems from './FeedbackTableItems';
import Feedback from '../../../models/Feedback';
import axios from 'axios';

import './FeedbackTable.css';

interface IFeedbackTable {
  feedbacks: Array<Feedback>,
  editFeedback: any,
  cancelFeedback: any,
  retablirFeedback: any,
  customerId: any
}

export default function FeedbackTable(props: IFeedbackTable) {
  const [feedback, Feedback] = useState<Array<Feedback>>([]);
  var url = 'https://www.mysharingsystem.com/api/Feedback';

  if (props.customerId != null && props.customerId != "") {
    url = url + "/" + props.customerId;
  }

  useEffect(() => {
    axios.get(url)
      .then(res => Feedback(res.data));
  }, [])

  return (
    <form>

      <div className='itineraire_table'>
        <h3 className='booking_header'>Fiche Client</h3>
        <div className='userItineraireItineraire_content'>
          {feedback.map((r, index) => (
            <div>
              <div className='divFeedBack2'>
                <div className='headertrFeedbackSelect'>
                  <td className='tableFeedback_data'>{r.solution}</td>
                  <td className='tableFeedback_data'>{r.comment}</td>
                  <td className='tableFeedback_data'>{r.idStatus} </td>
                </div>
                <div >
                  <FeedbackTableItems />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
        </div>
      </div>
    </form>
  );
}