import { Periode } from "../types/types";
import axiosInstance from "./axiosInstance";

const getPeriodes = async (): Promise<Periode[]> => {
    const response = await axiosInstance.get<Periode[]>('/Periode');
    return response.data;
};

const getPeriode = async (id: number): Promise<Periode> => {
    const response = await axiosInstance.get<Periode>(`/Periode/${id}`);
    return response.data;
};

const postPeriode = async (periode: Periode): Promise<Periode> => {
    const response = await axiosInstance.post<Periode>('/Periode', periode);
    return response.data;
};

const putPeriode = async (id: number, periode: Periode): Promise<Periode> => {
    const response = await axiosInstance.put<Periode>(`/Periode/${id}`, periode);
    return response.data;
};

const deletePeriode = async (id: number): Promise<Periode> => {
    const response = await axiosInstance.delete<Periode>(`/Periode/${id}`);
    return response.data;
};

export const periodeService = {
    getPeriodes,
    getPeriode,
    postPeriode,
    putPeriode,
    deletePeriode
};