import Modal from '@mui/material/Modal';
import LogoutIcon from '@mui/icons-material/Logout';
import './calendar.css';

interface TableTimeSlotProps {
    open: boolean;
    closeModal: () => void;
    dayData: {
        day: number;
        timeSlots: { time: string; status: string; tables: number }[];
    };
}

const TableTimeSlot: React.FC<TableTimeSlotProps> = ({ open, closeModal, dayData }) => {

    return (
        <Modal
            open={open}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="table-time-slots-modal">
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <h2>Nombre de tables disponible</h2>
                    <LogoutIcon
                        style={{ color: "darkgray", fontSize: "36px", cursor: "pointer" }}
                        onClick={() => closeModal()}
                    />
                </div>
                <p>{dayData.day}</p>
                <div className="time-slots-container">
                    {dayData.timeSlots.map(slot => (
                        <div key={slot.time} className={`table-time-slot table-time-slot-${slot.status}`}>
                            <h3>{slot.time}</h3>
                            <span></span>
                        </div>
                    ))}
                </div>
                <div className="time-slots-container-key">
                    <div className='time-slots-container-key-column'>
                        <span></span>
                        <h3>Tables restantes 10</h3>
                    </div>
                    <div className='time-slots-container-key-column'>
                        <span style={{ backgroundColor: "#f91f1f" }}></span>
                        <h3>Complet</h3>
                    </div>
                    <div className='time-slots-container-key-column'>
                        <span style={{ backgroundColor: "#f9951f" }}></span>
                        <h3>Plus que 2 tables</h3>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default TableTimeSlot;