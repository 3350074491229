import * as React from "react";
import Stack from "@mui/material/Stack";
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
const apiUrl =
  window.location.hostname === "localhost"
    ? "https://localhost:7142/api"
    : "/api";
export default function Variants({ employeeId }: any) {
  const [formData, setFormData] = React.useState({
    Date: null,
    Status: "",
    StartTime_Un: null,
    StartTime_Deux: null,
    EndTime_Un: null,
    EndTime_Deux: null,
    Couleur: "",
    EmployeeId: employeeId,
    Dcolor: "",
  });
  const [formData1, setFormData1] = React.useState({
    range: "",
  });
  const [days, setDays] = React.useState(0);
  const [wdays, setWdays] = React.useState(0);
  const [selectedDays, setSelectedDays] = React.useState<Date[]>([]);
  const [selectedDays1, setSelectedDays1] = React.useState<String[]>([]);

  React.useEffect(() => {
    const calculateDates = () => {
      const startDate = formData.Date ? new Date(formData.Date) : new Date();
      startDate.setDate(startDate.getDate() + 1);
      const calculatedDates = [];

      for (let i = 0; i < days; i++) {
        const nextDate = new Date(startDate);
        nextDate.setDate(startDate.getDate() + i);

        const formattedDate = nextDate.toISOString().split("T")[0];

        calculatedDates.push(formattedDate);
      }

      return calculatedDates;
    };

    const generatedDates = calculateDates();
    setSelectedDays1(generatedDates);
  }, [formData.Date, days]);

  const [buttonSelectedStates, setButtonSelectedStates] = React.useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const handleDecrease = () => {
    if (days > 0) {
      setDays(days - 1);
    }
  };
  const handleDecrease1 = () => {
    if (wdays > 0) {
      setWdays(wdays - 1);
    }
  };

  const handleIncrease = () => {
    setDays(days + 1);
  };
  const handleIncrease1 = () => {
    setWdays(wdays + 1);
  };
  const [isChecked, setIsChecked] = React.useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setIsChecked1(false);
  };
  const [isChecked1, setIsChecked1] = React.useState(false);
  const [isChecked2, setIsChecked2] = React.useState(false);
  const handleCheckboxChange1 = () => {
    setIsChecked1(!isChecked1);
    setIsChecked(false);
  };
  const handleCheckboxChange2 = () => {
    setIsChecked2(!isChecked2);
  };
  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleChange1 = (e: any) => {
    setFormData1({
      ...formData1,
      range: e.target.value,
    });
  };
  React.useEffect(() => {
    if (isChecked2) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        Dcolor: prevFormData.Couleur,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        Dcolor: "",
      }));
    }
  }, [isChecked2, formData.Couleur]);

  const handlePosts = async (dates: any) => {
    try {
      for (const selectedDate of dates) {
        await axios.post(
          `${apiUrl}/Planning`,
          {
            Date: selectedDate,
            Status: formData.Status,
            StartTime_Un: formData.StartTime_Un,
            StartTime_Deux: formData.StartTime_Deux,
            EndTime_Un: formData.EndTime_Un,
            EndTime_Deux: formData.EndTime_Deux,
            EmployeeId: formData.EmployeeId,
            Color: formData.Couleur,
            Dcolor: formData.Dcolor,
          },
          { headers: { "Content-Type": "application/json" } }
        );
      }
    } catch (error) {
      console.error("Error submitting posts:", error);
    }
  };
  const handleCantineDays = async (dates: any) => {
    try {
      for (const selectedDate of dates) {
        await axios.post(
          `${apiUrl}/Cantine`,
          {
            Date: selectedDate,
            StartTime_Un: formData.StartTime_Un,
            StartTime_Deux: formData.StartTime_Deux,
            EndTime_Un: formData.EndTime_Un,
            EndTime_Deux: formData.EndTime_Deux,
            EmployeeId: formData.EmployeeId,
          },
          { headers: { "Content-Type": "application/json" } }
        );
      }
    } catch (error) {
      console.error("Error submitting cantine days:", error);
    }
  };

  const handleSubmit = async () => {
    try {
      await axios.post(
        `${apiUrl}/Planning`,
        {
          Date: formData.Date,
          Status: formData.Status,
          StartTime_Un: formData.StartTime_Un,
          StartTime_Deux: formData.StartTime_Deux,
          EndTime_Un: formData.EndTime_Un,
          EndTime_Deux: formData.EndTime_Deux,
          EmployeeId: formData.EmployeeId,
          Color: formData.Couleur,
          Dcolor: formData.Dcolor,
        },
        { headers: { "Content-Type": "application/json" } }
      );

      if (selectedDays.length > 0) {
        await handlePosts(selectedDays);
      }

      if (selectedDays1.length > 0) {
        await handlePosts(selectedDays1);
      }
      if (formData.StartTime_Un || formData.StartTime_Deux) {
        await axios.post(
          `${apiUrl}/Cantine`,
          {
            Date: formData.Date,
            StartTime_Un: formData.StartTime_Un,
            StartTime_Deux: formData.StartTime_Deux,
            EndTime_Un: formData.EndTime_Un,
            EndTime_Deux: formData.EndTime_Deux,
            EmployeeId: formData.EmployeeId,
          },
          { headers: { "Content-Type": "application/json" } }
        );
        if (selectedDays.length > 0) {
          await handleCantineDays(selectedDays);
        }
        if (selectedDays1.length > 0) {
          await handleCantineDays(selectedDays1);
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const [selected, setSelected] = React.useState<number[]>([]);
  const handleToggle = (dayIndex: any) => {
    setButtonSelectedStates((prevSelectedStates) => {
      const updatedSelectedStates = [...prevSelectedStates];
      const buttonIndex = dayIndex - 1;
      updatedSelectedStates[buttonIndex] = !updatedSelectedStates[buttonIndex];
      return updatedSelectedStates;
    });

    const index = selected.indexOf(dayIndex);

    if (index === -1) {
      // If not selected, add it to the array
      setSelected((prevSelected) => [...prevSelected, dayIndex]);
    } else {
      // If already selected, remove it from the array
      setSelected((prevSelected) =>
        prevSelected.filter((day) => day !== dayIndex)
      );
    }
  };
  const findNextDays = (dayIndex: any, occurrences: number = 1) => {
    const selectedDate = formData.Date ? new Date(formData.Date) : new Date();
    const nextDays = [];

    if (isNaN(selectedDate.getTime())) {
      return ["Invalid Date"];
    }

    const dayOfWeek = selectedDate.getDay();

    for (let i = 0; i < occurrences; i++) {
      const daysUntilNextDay =
        dayIndex - dayOfWeek + (dayIndex <= dayOfWeek ? 7 : 0) + 7 * i;

      const nextDay = new Date(selectedDate);
      nextDay.setDate(selectedDate.getDate() + daysUntilNextDay);

      // Format the date as 'YYYY-MM-DD'
      const formattedNextDay = nextDay.toISOString().split("T")[0];

      nextDays.push(formattedNextDay);
    }

    return nextDays;
  };

  const handleButtonClick = (day: any, occurrences: number = 1) => {
    setSelectedDays((prevSelectedDays: any) => {
      const nextDays = findNextDays(day, occurrences);

      let updatedSelectedDays;

      if (buttonSelectedStates[day - 1]) {
        updatedSelectedDays = prevSelectedDays.filter(
          (selectedDay: string) => !nextDays.includes(selectedDay)
        );
      } else {
        updatedSelectedDays = [...prevSelectedDays, ...nextDays];
      }

      return updatedSelectedDays;
    });
  };

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Typography sx={{ color: "#003671" }} fontWeight="bold">
          Nom
        </Typography>

        <Grid container>
          <Grid item xs={6}>
            <Typography fontWeight="bold" marginBottom={2}>
              Date
            </Typography>

            <TextField
              type="date"
              variant="outlined"
              fullWidth
              name="Date"
              value={formData.Date}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography fontWeight="bold" marginBottom={2}>
              Statut
            </Typography>
            <Select
              variant="outlined"
              value={formData.Status}
              name="Status"
              fullWidth
              onChange={handleChange}
            >
              <MenuItem value="OFF">OFF</MenuItem>
              <MenuItem value="Congé payé">Congé payé</MenuItem>
              <MenuItem value="Ferié">Ferié</MenuItem>
              <MenuItem value="Maladie">Maladie</MenuItem>
              <MenuItem value="Absence">Absence</MenuItem>
              <MenuItem value="Congé Maternité">Congé Maternité</MenuItem>
              <MenuItem value="Congé Paternité">Congé Paternité</MenuItem>
              <MenuItem value="Formation">Formation</MenuItem>
              <MenuItem value="Syndicat">Syndicat</MenuItem>
            </Select>
          </Grid>
        </Grid>

        <Grid container my={1}>
          <RadioGroup
            name="use-radio-group"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              width: "100%",
            }}
            onChange={handleChange1}
          >
            <FormControlLabel value="Cadre" label="Cadre" control={<Radio />} />
            <FormControlLabel
              value="Continue"
              label="Continue"
              control={<Radio />}
            />
            <FormControlLabel
              value="Coupure"
              label="Coupure"
              control={<Radio />}
            />
          </RadioGroup>
        </Grid>
        <Grid container>
          <Grid item xs={5} mb={1} mx={2}>
            <Typography fontWeight="bold" textAlign="center" m={1}>
              Heure de début
            </Typography>
            <TextField
              type="time"
              variant="outlined"
              fullWidth
              value={formData.StartTime_Un}
              onChange={handleChange}
              name="StartTime_Un"
            />
          </Grid>
          {formData1.range === "Continue" || formData1.range === "Coupure" ? (
            <>
              <Grid item xs={5} mb={1} mx={2}>
                <Typography fontWeight="bold" textAlign="center" m={1}>
                  Heure de fin
                </Typography>
                <TextField
                  type="time"
                  variant="outlined"
                  fullWidth
                  value={formData.EndTime_Un}
                  onChange={handleChange}
                  name="EndTime_Un"
                />
              </Grid>
            </>
          ) : null}
          {formData1.range === "Coupure" ? (
            <>
              <Grid item xs={5} my={1} mx={2}>
                <TextField
                  type="time"
                  variant="outlined"
                  fullWidth
                  value={formData.StartTime_Deux}
                  onChange={handleChange}
                  name="StartTime_Deux"
                />
              </Grid>
              <Grid item xs={5} my={1} mx={2}>
                <TextField
                  type="time"
                  variant="outlined"
                  fullWidth
                  value={formData.EndTime_Deux}
                  onChange={handleChange}
                  name="EndTime_Deux"
                />
              </Grid>
            </>
          ) : null}
          <Grid
            container
            item
            mx="auto"
            flexDirection="row"
            display="flex"
            alignItems="center"
            xs={12}
          >
            <Grid item xs={12} lg={9}>
              <InputLabel
                sx={{
                  overflow: "visible",
                  color: "black",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                htmlFor="Couleur"
              >
                Couleur de la tranche horaire dans le planning
              </InputLabel>
            </Grid>
            <Grid item xs={12} lg={3} justifyContent="center" display="flex">
              <Select
                variant="outlined"
                fullWidth
                value={formData.Couleur}
                onChange={handleChange}
                name="Couleur"
                sx={{ width: "100px" }}
              >
                <MenuItem
                  value="#05FFC3"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <svg
                    width="25"
                    height="22"
                    viewBox="0 0 25 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5 -1.74846e-07C22.7091 -7.8281e-08 24.5 1.79086 24.5 4L24.5 18C24.5 20.2091 22.7091 22 20.5 22L4.5 22C2.29086 22 0.499999 20.2091 0.499999 18L0.5 4C0.5 1.79086 2.29086 -9.70792e-07 4.5 -8.74228e-07L20.5 -1.74846e-07Z"
                      fill="#05FFC3"
                      fill-opacity="0.63"
                    />
                  </svg>
                </MenuItem>
                <MenuItem
                  value="#12F1FF"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <svg
                    width="25"
                    height="22"
                    viewBox="0 0 25 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5 -1.74846e-07C22.7091 -7.8281e-08 24.5 1.79086 24.5 4L24.5 18C24.5 20.2091 22.7091 22 20.5 22L4.5 22C2.29086 22 0.499999 20.2091 0.499999 18L0.5 4C0.5 1.79086 2.29086 -9.70792e-07 4.5 -8.74228e-07L20.5 -1.74846e-07Z"
                      fill="#12F1FF"
                    />
                  </svg>
                </MenuItem>
                <MenuItem
                  value="#9ED4E8"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <svg
                    width="25"
                    height="22"
                    viewBox="0 0 25 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5 -1.74846e-07C22.7091 -7.8281e-08 24.5 1.79086 24.5 4L24.5 18C24.5 20.2091 22.7091 22 20.5 22L4.5 22C2.29086 22 0.499999 20.2091 0.499999 18L0.5 4C0.5 1.79086 2.29086 -9.70792e-07 4.5 -8.74228e-07L20.5 -1.74846e-07Z"
                      fill="#9ED4E8"
                    />
                  </svg>
                </MenuItem>
                <MenuItem
                  value="#AACBF0"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <svg
                    width="25"
                    height="22"
                    viewBox="0 0 25 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5 -1.74846e-07C22.7091 -7.8281e-08 24.5 1.79086 24.5 4L24.5 18C24.5 20.2091 22.7091 22 20.5 22L4.5 22C2.29086 22 0.499999 20.2091 0.499999 18L0.5 4C0.5 1.79086 2.29086 -9.70792e-07 4.5 -8.74228e-07L20.5 -1.74846e-07Z"
                      fill="#AACBF0"
                    />
                  </svg>
                </MenuItem>
                <MenuItem
                  value="#BCD0F1"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <svg
                    width="25"
                    height="22"
                    viewBox="0 0 25 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5 -1.74846e-07C22.7091 -7.8281e-08 24.5 1.79086 24.5 4L24.5 18C24.5 20.2091 22.7091 22 20.5 22L4.5 22C2.29086 22 0.499999 20.2091 0.499999 18L0.5 4C0.5 1.79086 2.29086 -9.70792e-07 4.5 -8.74228e-07L20.5 -1.74846e-07Z"
                      fill="#BCD0F1"
                    />
                  </svg>
                </MenuItem>
                <MenuItem
                  value="#BDBBF2"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <svg
                    width="25"
                    height="22"
                    viewBox="0 0 25 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5 -1.74846e-07C22.7091 -7.8281e-08 24.5 1.79086 24.5 4L24.5 18C24.5 20.2091 22.7091 22 20.5 22L4.5 22C2.29086 22 0.499999 20.2091 0.499999 18L0.5 4C0.5 1.79086 2.29086 -9.70792e-07 4.5 -8.74228e-07L20.5 -1.74846e-07Z"
                      fill="#BDBBF2"
                    />
                  </svg>
                </MenuItem>
                <MenuItem
                  value="#CA91CF"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <svg
                    width="25"
                    height="22"
                    viewBox="0 0 25 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5 -1.74846e-07C22.7091 -7.8281e-08 24.5 1.79086 24.5 4L24.5 18C24.5 20.2091 22.7091 22 20.5 22L4.5 22C2.29086 22 0.499999 20.2091 0.499999 18L0.5 4C0.5 1.79086 2.29086 -9.70792e-07 4.5 -8.74228e-07L20.5 -1.74846e-07Z"
                      fill="#CA91CF"
                    />
                  </svg>
                </MenuItem>
                <MenuItem
                  value="#D17BAA"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <svg
                    width="25"
                    height="22"
                    viewBox="0 0 25 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5 -1.74846e-07C22.7091 -7.8281e-08 24.5 1.79086 24.5 4L24.5 18C24.5 20.2091 22.7091 22 20.5 22L4.5 22C2.29086 22 0.499999 20.2091 0.499999 18L0.5 4C0.5 1.79086 2.29086 -9.70792e-07 4.5 -8.74228e-07L20.5 -1.74846e-07Z"
                      fill="#D17BAA"
                    />
                  </svg>
                </MenuItem>
                <MenuItem
                  value="#D17B82"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <svg
                    width="25"
                    height="22"
                    viewBox="0 0 25 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5 -1.74846e-07C22.7091 -7.8281e-08 24.5 1.79086 24.5 4L24.5 18C24.5 20.2091 22.7091 22 20.5 22L4.5 22C2.29086 22 0.499999 20.2091 0.499999 18L0.5 4C0.5 1.79086 2.29086 -9.70792e-07 4.5 -8.74228e-07L20.5 -1.74846e-07Z"
                      fill="#D17B82"
                    />
                  </svg>
                </MenuItem>
                <MenuItem
                  value="#D05158"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <svg
                    width="25"
                    height="22"
                    viewBox="0 0 25 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5 -1.74846e-07C22.7091 -7.8281e-08 24.5 1.79086 24.5 4L24.5 18C24.5 20.2091 22.7091 22 20.5 22L4.5 22C2.29086 22 0.499999 20.2091 0.499999 18L0.5 4C0.5 1.79086 2.29086 -9.70792e-07 4.5 -8.74228e-07L20.5 -1.74846e-07Z"
                      fill="#D05158"
                    />
                  </svg>
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>
        <FormGroup>
          <Grid container display="flex" justifyContent="space-between">
            <Grid item xs={12} lg={8}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Dupliquer pour une série de jours"
              />
            </Grid>

            {isChecked && (
              <Grid item xs={12} lg={4}>
                <Button onClick={handleDecrease}>-</Button>
                {days}
                <Button onClick={handleIncrease}>+</Button>
              </Grid>
            )}
          </Grid>
        </FormGroup>
        <FormGroup>
          <Grid container display="flex" justifyContent="space-between">
            <Grid item xs={12} lg={8}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked1}
                    onChange={handleCheckboxChange1}
                  />
                }
                label="Dupliquer pour des jours specifitque des semaines à venir"
              />
            </Grid>

            {isChecked1 && (
              <>
                <Grid item xs={12} lg={4}>
                  <Button onClick={handleDecrease1}>-</Button>
                  {wdays}
                  <Button onClick={handleIncrease1}>+</Button>
                </Grid>
                <Grid container justifyContent="center" marginTop={2}>
                  <Grid>
                    <ButtonGroup
                      variant="contained"
                      aria-label="outlined primary button group"
                    >
                      <Button
                        sx={{
                          color: "black",
                          backgroundColor: selected.includes(1)
                            ? "rgba(111,175,29,0.84) !important"
                            : "#ffffff !important",
                        }}
                        onClick={() => {
                          handleToggle(1);
                          handleButtonClick(1, wdays);
                        }}
                      >
                        L
                      </Button>

                      <Button
                        sx={{
                          color: "black",
                          backgroundColor: selected.includes(2)
                            ? "rgba(111,175,29,0.84) !important"
                            : "#ffffff !important",
                        }}
                        onClick={() => {
                          handleToggle(2);
                          handleButtonClick(2, wdays);
                        }}
                      >
                        M
                      </Button>
                      <Button
                        sx={{
                          color: "black",
                          backgroundColor: selected.includes(3)
                            ? "rgba(111,175,29,0.84) !important"
                            : "#ffffff !important",
                        }}
                        onClick={() => {
                          handleToggle(3);
                          handleButtonClick(3, wdays);
                        }}
                      >
                        M
                      </Button>
                      <Button
                        sx={{
                          backgroundColor: selected.includes(4)
                            ? "rgba(111,175,29,0.62) !important"
                            : "#ffffff !important",
                          color: "black",
                        }}
                        onClick={() => {
                          handleToggle(4);
                          handleButtonClick(4, wdays);
                        }}
                      >
                        M
                      </Button>
                      <Button
                        sx={{
                          backgroundColor: selected.includes(5)
                            ? "rgba(111,175,29,0.51) !important"
                            : "#ffffff !important",
                          color: "black",
                        }}
                        onClick={() => {
                          handleToggle(5);
                          handleButtonClick(5, wdays);
                        }}
                      >
                        J
                      </Button>
                      <Button
                        sx={{
                          backgroundColor: selected.includes(6)
                            ? "rgba(111,175,29,0.33) !important"
                            : "#ffffff !important",
                          color: "black",
                        }}
                        onClick={() => {
                          handleToggle(6);
                          handleButtonClick(6, wdays);
                        }}
                      >
                        V
                      </Button>
                      <Button
                        sx={{
                          backgroundColor: selected.includes(7)
                            ? "rgba(111,175,29,0.19) !important"
                            : "#ffffff !important",
                          color: "black",
                        }}
                        onClick={() => {
                          handleToggle(7);
                          handleButtonClick(7, wdays);
                        }}
                      >
                        S
                      </Button>
                      <Button
                        sx={{
                          backgroundColor: selected.includes(0)
                            ? "rgba(111,175,29,0.05) !important"
                            : "#ffffff !important",
                          color: "black",
                        }}
                        onClick={() => {
                          handleToggle(0);
                          handleButtonClick(0, wdays);
                        }}
                      >
                        D
                      </Button>
                    </ButtonGroup>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </FormGroup>
        <Grid item xs={12} lg={8}>
          <FormControlLabel
            control={
              <Checkbox checked={isChecked2} onChange={handleCheckboxChange2} />
            }
            label="Couleur par défaut pour cette future tranche horraire"
          />
        </Grid>
        <Stack flexDirection="row" justifyContent="space-between" mt={10}>
          <Button sx={{ py: 2, px: 3 }} color="secondary" variant="outlined">
            Annuler
          </Button>
          <Button
            onClick={handleSubmit}
            sx={{ py: 2, px: 3 }}
            variant="contained"
          >
            Valider
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
