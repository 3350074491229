import Carousel from 'react-material-ui-carousel';
import consigne from '../../../../images/ProfilClients_img/Ale.png';

import {
    Paper,
} from '@mui/material'


const ProfilClientsCarousel = () => {   
    return (       
            <Carousel>
                {
                    items.map((item) => {
                        return <Project item={item} />
                    })
                }
            </Carousel>   
    )
}

type Item = {
    imgprofilo: string;
}

interface ProjectProps
{
    item: Item
}

function Project({item}: ProjectProps) {
    return (
        <Paper>           
            <img className="PrimaryHeaderLogo" src={item.imgprofilo} alt="" />
        </Paper>
    )
}

const items: Item[] = [
    { imgprofilo: consigne },  
    // { imgprofilo: consigne },  
    // { imgprofilo: consigne },
]

export default ProfilClientsCarousel;