import './ClienteHeader.css';

interface IClienteHeader {
  src: string,
  title: string
}

function ClienteHeader(props: IClienteHeader) {
  return (
    <div className="ClienteHeader">
        <img className="ClienteHeaderLogo" src={props.src} alt=""></img>
        <div className="ClienteHeaderTitle">{props.title}</div>
        <div className="ClienteHeaderSeparator"></div>
    </div>
  );
}

export default ClienteHeader;
