import { Menu } from "../types/types";
import axiosInstance from "./axiosInstance";

const getMenus = async (): Promise<Menu[]> => {
    const response = await axiosInstance.get<Menu[]>('/menu');
    return response.data;
};

const postMenu = async (menu: Menu): Promise<Menu> => {
    const response = await axiosInstance.post<Menu>('/menu', menu);
    return response.data;
};

const putMenu = async (id: number, menu: Menu): Promise<Menu> => {
    const response = await axiosInstance.put<Menu>(`/menu/${id}`, menu);
    return response.data;
};

const deleteMenu = async (id: number): Promise<void> => {
    await axiosInstance.delete<void>(`/menu/${id}`);
};

export const menuService = {
    getMenus,
    postMenu,
    putMenu,
    deleteMenu
};