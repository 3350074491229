import ConsigneEventType from "../enums/ConsigneEventType";

export default class ConsigneEvent {
    public type: ConsigneEventType = ConsigneEventType.None;
    public user: string = '';
    public date: number = 0;
    public info: string = '';


    constructor(type: ConsigneEventType = ConsigneEventType.None, user: string = '', date: number = 0, info: string = '') {
        this.type = type;
        this.user = user;
        this.date = date;
        this.info = info;
    }
}