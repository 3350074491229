import Modal from '@mui/material/Modal';
import LogoutIcon from '@mui/icons-material/Logout';
import { Table } from '../types/types';
import { tableService } from '../api/tableService';
import { useState } from 'react';

interface UpdateTableProps {
    onClose: () => void;
    initTable: Table | null;
    onUpdate: (updatedTable: Table) => void;
}

const UpdateTable: React.FC<UpdateTableProps> = ({ onClose, initTable, onUpdate }) => {

    const defaultTable: Table = {
        id: 0,
        numero: 0,
        nbPersonne: 0,
        restaurantId: 0,
    };

    const [table, setTable] = useState < Table > (initTable ?? defaultTable);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setTable({ ...table, [name]: value });
    };

    const handleSubmit = async () => {
        if (initTable) {
            try {

                if (table.numero === null || table.nbPersonne === null 
                    || table.numero <= 0 || table.nbPersonne <= 0) {
                    alert("Veuillez renseigner un numéro et un nombre de personne valide");
                    return;
                }

                const updatedTable = await tableService.putTable(table.id, table);
                onUpdate(updatedTable);
                onClose();
            } catch (error) {
                console.error("Error updating table:", error);
            }
        } else {
            console.error("initTable is undefined");
        }
    }

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='modal-restau-add'>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h1>Modifier la table</h1>
                    <LogoutIcon
                        style={{ color: "darkgray", fontSize: "36px", cursor: "pointer" }}
                        onClick={onClose}
                    />
                </div>

                <div className='restau-column2'>
                    <input
                        type="number"
                        id='nbtable-restau'
                        name='numero'
                        className='text-restau'
                        placeholder='Nom'
                        style={{
                            marginRight: '20px'
                        }}
                        value={table.numero?.toString() || ""}
                        onChange={handleInputChange}
                    />
                    <input
                        type="number"
                        className='text-restau'
                        placeholder='Personne'
                        name='nbPersonne'
                        value={table.nbPersonne?.toString() || ""}
                        onChange={handleInputChange}
                    />
                </div>
                <button className='restau-btn restau-modal-btn' onClick={handleSubmit}>
                    Appliquer
                </button>
            </div>
        </Modal>
    );
}

export default UpdateTable;