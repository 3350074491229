import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField, Backdrop, Grid, FormControlLabel, Select, MenuItem, Checkbox, CardContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';

import './Email.css';

interface EmailProps {
    isOpen: boolean;
    onClose: any;
    formData: any;
    email: string[];
    previousData: any;
}

const Email: React.FC<EmailProps> = ({ isOpen, onClose, formData, email, previousData }) => {
    /////////////////////////////////////////////////////////////////
    // for editing
    ////////////////////////////////////////////////////////////////
    const initialText = "Nous vous souhaitons une agréable journée. \nBien à vous,\nMateo C";
    const [isEditing, setIsEditing] = useState(false);
    const [text, setText] = useState(initialText);
    const [editLinkText, setEditLinkText] = useState('Modifier');

    const handleDoubleClick = () => {
        setIsEditing(true);
        setEditLinkText('Fermer');
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target.value);
    };

    const handleBlur = () => {
        setIsEditing(false);
        setEditLinkText('Modifier');
    };

    const handleToggleEdit = () => {
        setIsEditing(!isEditing);
        if (isEditing) {
            setEditLinkText('Modifier');
        } else {
            setEditLinkText('Fermer');
        }
    };

    /////////////////////////////////////////////////////////////////
    // for submitting
    ////////////////////////////////////////////////////////////////
    // navigate after validation
    const navigate = useNavigate();
    const handleSubmit = (e: any) => {
        navigate('/ListTransfer');
    };

    /////////////////////////////////////////////////////////////////
    // option oui or non
    ////////////////////////////////////////////////////////////////
    const handleCheckboxChange = (option: any) => {
        setSelectedOption(option);
    }
    const [selectedOption, setSelectedOption] = useState(null);
    const displayedValue = email.join(', ');

    /////////////////////////////////////////////////////////////////
    // View
    /////////////////////////////////////////////////////////////////

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            style={{ zIndex: 1500 }}
        >
            <Box 
                sx={{ 
                    position: 'absolute', 
                    top: '50%', 
                    left: '50%', 
                    transform: 'translate(-50%, -50%)', 
                    width: { xs: '90%', md: '70%', lg: '50%' }, // Adjusting width for different screen sizes
                    maxHeight: '90vh', // Setting maximum height
                    overflowY: 'auto', // Adding vertical scroll
                    bgcolor: 'background.paper', 
                    border: '1px solid #000', 
                    boxShadow: 24, 
                    p: 4 
                }}
            >
                <form onSubmit={handleSubmit}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid container alignItems="flex-end" spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography className='FontLabel'>Destinataires:</Typography>
                                    <TextField
                                        required
                                        size='small'
                                        name="email"
                                        value={displayedValue}
                                        type="text"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography className='FontLabel'>Envoyer par:</Typography>
                                    <Select variant="outlined" name="Paiement" className='StyleInput' size='small' defaultValue="Email" fullWidth>
                                        <MenuItem value={"Email"}>Email</MenuItem>
                                        <MenuItem value={"Whatsup"}>Whatsup</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    Bonjour,<br />
                                    Nous vous contactons pour une {formData.Statut_email} de transfert:
                                </Typography>

                                <Grid className="grey-card">
                                    <Grid container spacing={2}>
                                        {/* Grey content */}
                                        <Grid item xs={12} md={6} className="card-header">
                                            <Typography className="card-title">Nom:</Typography>
                                            <Typography className="card-content">{formData.LastName}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} className="card-header">
                                            <Typography className="card-title">Prénom:</Typography>
                                            <Typography className="card-content">{formData.FirstName}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} className="card-header">
                                            <Typography className="card-title">Chambre:</Typography>
                                            <Typography className="card-content">{formData.Room}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} className="card-header">
                                            <Typography className="card-title">Téléphone client:</Typography>
                                            <Typography className="card-content">{formData.PhoneNumber}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} className="card-header">
                                            <Typography className="card-title">Départ depuis:</Typography>
                                            <Typography className="card-content">{formData.Departure}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} className="card-header">
                                            <Typography className="card-title">A:</Typography>
                                            <Typography className="card-content">{formData.Arrival}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} className="card-header">
                                            <Typography className="card-title">Date & heure:</Typography>
                                            <Typography className="card-content">
                                                {formData.TransferDate !== previousData[0].TransferDate ? (
                                                    <span style={{ color: 'red', fontWeight: 'bold' }}>{formData.TransferDate}</span>
                                                ) : (
                                                    formData.TransferDate
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} className="card-header">
                                            <Typography className="card-title">à:</Typography>
                                            <Typography className="card-content">
                                                {formData.TransferTime !== previousData[0].TransferTime ? (
                                                    <span style={{ color: 'red', fontWeight: 'bold' }}>{formData.TransferTime}</span>
                                                ) : (
                                                    formData.TransferTime
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} className="card-header">
                                            <Typography className="card-title">Détails:</Typography>
                                            <Typography className="card-content">{formData.TransportDetail}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} className="card-header">
                                            <Typography className="card-title">Paiement:</Typography>
                                            <Typography className="card-content">
                                                {formData.Paiement !== previousData[0].Paiement ? (
                                                    <span style={{ color: 'red', fontWeight: 'bold' }}>{formData.Paiement}</span>
                                                ) : (
                                                    formData.Paiement
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} className="card-header">
                                            <Typography className="card-title">Préference client:</Typography>
                                            <Typography className="card-content">
                                                {formData.TransfertNote !== previousData[0].TransfertNote ? (
                                                    <span style={{ color: 'red', fontWeight: 'bold' }}>{formData.TransfertNote}</span>
                                                ) : (
                                                    formData.TransfertNote
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} className="card-header">
                                            <Typography className="card-title">Véhicule:</Typography>
                                            <Typography className="card-content">
                                                {formData.Car !== previousData[0].Car ? (
                                                    <span style={{ color: 'red', fontWeight: 'bold' }}>{formData.Car}</span>
                                                ) : (
                                                    formData.Car
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} className="card-header">
                                            <Typography className="card-title">Nombre de véhicule:</Typography>
                                            <Typography className="card-content">
                                                {formData.NumberOfCars !== previousData[0].NumberOfCars ? (
                                                    <span style={{ color: 'red', fontWeight: 'bold' }}>{formData.NumberOfCars}</span>
                                                ) : (
                                                    formData.NumberOfCars
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} className="card-header">
                                            <Typography className="card-title">Nombre de bagage:</Typography>
                                            <Typography className="card-content">
                                                {formData.NumberOfLuggage !== previousData[0].NumberOfLuggage ? (
                                                    <span style={{ color: 'red', fontWeight: 'bold' }}>{formData.NumberOfLuggage}</span>
                                                ) : (
                                                    formData.NumberOfLuggage
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} className="card-header">
                                            <Typography className="card-title">Nombre de passager:</Typography>
                                            <Typography className="card-content">
                                                {formData.NumberOfPassenger !== previousData[0].NumberOfPassenger ? (
                                                    <span style={{ color: 'red', fontWeight: 'bold' }}>{formData.NumberOfPassenger}</span>
                                                ) : (
                                                    formData.NumberOfPassenger
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} className="card-header">
                                            <Typography className="card-title">Information supp.:</Typography>
                                            <Typography className="card-content">
                                                {formData.SuppInformation !== previousData[0].SuppInformation ? (
                                                    <span style={{ color: 'red', fontWeight: 'bold' }}>{formData.SuppInformation}</span>
                                                ) : (
                                                    formData.SuppInformation
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography sx={{ mr: '16px' }}>Souhaitez-vous confirmer ?</Typography>
                                    <FormControlLabel
                                        control={<Checkbox checked={selectedOption === 'Oui'} onChange={() => handleCheckboxChange('Oui')} />}
                                        label="Oui"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={selectedOption === 'Non'} onChange={() => handleCheckboxChange('Non')} />}
                                        label="Non"
                                    />
                                </Grid>
                                {/* Message to edit */}
                                <Grid item xs={12} md={12}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12}>
                                            {isEditing ? (
                                                <Grid>
                                                    <textarea
                                                        value={text}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        autoFocus
                                                        rows={4}
                                                        cols={50}
                                                        style={{ whiteSpace: 'pre-wrap', resize: 'vertical', width: '100%' }} // Making textarea responsive
                                                    />
                                                    {/* close button */}
                                                    <Link to={``} onClick={handleToggleEdit}>{editLinkText}</Link>
                                                </Grid>
                                            ) : (
                                                <Grid>
                                                    <Typography onDoubleClick={handleDoubleClick}>
                                                        {text}
                                                    </Typography>
                                                    <Grid container alignItems="center" justifyContent="flex-end">
                                                        <Link to={``} onClick={handleToggleEdit}>
                                                            {editLinkText}
                                                            <EditIcon fontSize="small" style={{ marginLeft: '5px' }} />
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 2 }}>
                        <Button type="submit" variant="contained" color="primary">
                            Envoyer
                        </Button>
                        <Button onClick={onClose} variant="contained" color="secondary">
                            Fermer
                        </Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
};

export default Email;
